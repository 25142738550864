import React, { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import ReferenciaBancariaForm from './ReferenciaBancariaForm';
import { validations } from '../Validations/regexValidations';
import { ErrorMessage } from '@hookform/error-message';

function StartInvestPersonStep4() {
	const emptyFieldErrorMessage = 'Campo obligatorio';
	const {
		register,
		control,
		resetField,
		formState: { errors },
	} = useFormContext();

	const { append, fields, prepend } = useFieldArray({
		control,
		name: 'bankReferences',
	});

	const residencyCard = useWatch({ control, name: 'residencyCard' });
	const socialSecurity = useWatch({ control, name: 'socialSecurity' });

	useEffect(() => {
		!fields.length && prepend({});
	}, []);

	return (
		<React.Fragment>
			<div className="create__requisitos">
				<h4 className="create__requisitos-title">
					Personas Relacionadas - <i>Opcional</i>
				</h4>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Nombre</label>
						<input
							type="text"
							maxLength={40}
							className="global-input"
							placeholder="Nombre"
							{...register('relatedPersonName', {
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="relatedPersonName"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Número de identidad</label>
						<input
							type="text"
							maxLength={11}
							className="global-input"
							placeholder="000-0000000-0"
							{...register('idNoRelatedPerson', {
								pattern: validations.letrasNumerosEspacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="idNoRelatedPerson"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2 lg:pr-4">
						<label className="global-label">Tipo de relación</label>
						<input
							type="text"
							maxLength={20}
							className="global-input"
							placeholder="Tipo de relación"
							{...register('bondType', {
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="bondType"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-6 create__requisitos">
				<h4 className="create__requisitos-title">FATCA</h4>
				<div className="lg:items-start lg:flex lg:space-x-9 lg:place-items-end">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							¿Nació usted en Puerto Rico, Islas Vírgenes de los Estados Unidos, Samoa
							Americana, Guam o las Islas Marianas del Norte?
						</label>
						<div className="document-box">
							<input
								id="step4-birthPlace-yes"
								className="toggle toggle-left"
								type="radio"
								value="Si"
								{...register('isBirthPlace', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-birthPlace-yes" className="btn">
								Si
							</label>
							<input
								id="step4-birthPlace-no"
								className="toggle toggle-right"
								type="radio"
								value="No"
								{...register('isBirthPlace', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-birthPlace-no" className="btn">
								No
							</label>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							¿Es ciudadano o residente de los Estados Unidos?
						</label>
						<div className="document-box">
							<input
								id="step4-citizen-yes"
								className="toggle toggle-left"
								type="radio"
								value="Si"
								{...register('residentCitizen', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-citizen-yes" className="btn">
								Si
							</label>
							<input
								id="step4-citizen-no"
								className="toggle toggle-right"
								type="radio"
								value="No"
								{...register('residentCitizen', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-citizen-no" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				<div className="lg:items-start lg:flex lg:space-x-9 lg:place-items-end">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							¿Posee dirección “Retener correspondencia (Hold mail)” en Estados Unidos?
						</label>
						<div className="document-box">
							<input
								id="step4-hold-mail-yes"
								className="toggle toggle-left"
								type="radio"
								value="Si"
								{...register('holdMail', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-hold-mail-yes" className="btn">
								Si
							</label>
							<input
								id="step4-hold-mail-no"
								className="toggle toggle-right"
								type="radio"
								value="No"
								{...register('holdMail', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-hold-mail-no" className="btn">
								No
							</label>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							¿Posee dirección “Por cuenta de/ In care of” en Est
						</label>
						<div className="document-box">
							<input
								id="step4-care-of-yes"
								className="toggle toggle-left"
								type="radio"
								value="Si"
								{...register('careOf', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-care-of-yes" className="btn">
								Si
							</label>
							<input
								id="step4-care-of-no"
								className="toggle toggle-right"
								type="radio"
								value="No"
								{...register('careOf', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-care-of-no" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				<div className="lg:items-start lg:flex lg:space-x-9 lg:place-items-end">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">¿Posee número de tarjeta de residencia?</label>
						<div className="document-box">
							<input
								id="step4-residency-card-yes"
								className="toggle toggle-left"
								type="radio"
								value="Si"
								{...register('residencyCard', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
								onClick={() => {
									resetField('residencyCardNo');
								}}
							/>

							<label htmlFor="step4-residency-card-yes" className="btn">
								Si
							</label>
							<input
								id="step4-residency-card-no"
								className="toggle toggle-right"
								type="radio"
								value="No"
								{...register('residencyCard', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
								onClick={() => {
									resetField('residencyCardNo');
								}}
							/>

							<label htmlFor="step4-residency-card-no" className="btn">
								No
							</label>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							Si contesto Sí, indique su Número de Tarjeta de Residencia:
						</label>
						<input
							type="text"
							className="global-input"
							placeholder="Número de Tarjeta de Residencia"
							{...register('residencyCardNo', {
								required: {
									value: residencyCard === 'Si' ? true : false,
									message: emptyFieldErrorMessage,
								},
								pattern: validations.soloNumeros,
							})}
							disabled={residencyCard === 'No'}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="residencyCardNo"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-start lg:flex lg:space-x-9 lg:place-items-end">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							¿Posee número de Seguridad Social de Estados Unidos (SSN)?
						</label>
						<div className="document-box">
							<input
								id="step4-social-security-yes"
								className="toggle toggle-left"
								type="radio"
								value="Si"
								{...register('socialSecurity', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
								onClick={() => {
									resetField('socialSecurityNo');
								}}
							/>

							<label htmlFor="step4-social-security-yes" className="btn">
								Si
							</label>
							<input
								id="step4-social-security-no"
								className="toggle toggle-right"
								type="radio"
								value="No"
								{...register('socialSecurity', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
								onClick={() => {
									resetField('socialSecurityNo');
								}}
							/>

							<label htmlFor="step4-social-security-no" className="btn">
								No
							</label>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							Si contesto Sí, indique su Número de Seguridad Social (SSN):
						</label>
						<input
							type="text"
							className="global-input"
							placeholder="Número de Seguridad Social"
							{...register('socialSecurityNo', {
								required: {
									value: socialSecurity === 'Si',
									message: emptyFieldErrorMessage,
								},
								pattern: validations.soloNumeros,
							})}
							disabled={socialSecurity === 'No'}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="socialSecurityNo"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-start lg:flex lg:space-x-9 lg:place-items-end">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">¿Posee dirección PO Box en Estados Unidos?</label>
						<div className="document-box">
							<input
								id="step4-po-box-address-yes"
								className="toggle toggle-left"
								type="radio"
								value="Si"
								{...register('poBoxAddress', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-po-box-address-yes" className="btn">
								Si
							</label>
							<input
								id="step4-po-box-address-no"
								className="toggle toggle-right"
								type="radio"
								value="No"
								{...register('poBoxAddress', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-po-box-address-no" className="btn">
								No
							</label>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							¿Posee número telefónico en Estados Unidos?
						</label>
						<div className="document-box">
							<input
								id="step4-us-phone-number-yes"
								className="toggle toggle-left"
								type="radio"
								value="Si"
								{...register('usPhoneNumber', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-us-phone-number-yes" className="btn">
								Si
							</label>
							<input
								id="step4-us-phone-number-no"
								className="toggle toggle-right"
								type="radio"
								value="No"
								{...register('usPhoneNumber', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-us-phone-number-no" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				<div className="lg:items-start lg:flex lg:space-x-9 lg:place-items-end">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							¿Posee Instrucción para realizar transferencias recurrentes a Estados Unidos?
						</label>
						<div className="document-box">
							<input
								id="step4-transfer-availability-yes"
								className="toggle toggle-left"
								type="radio"
								value="Si"
								{...register('transferAvailability', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-transfer-availability-yes" className="btn">
								Si
							</label>
							<input
								id="step4-transfer-availability-no"
								className="toggle toggle-right"
								type="radio"
								value="No"
								{...register('transferAvailability', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-transfer-availability-no" className="btn">
								No
							</label>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							¿Posee algún relacionado a su cuenta, ya sea un cotitular, apoderado o firmante
							con dirección en los Estados Unidos?
						</label>
						<div className="document-box">
							<input
								id="step4-germane-yes"
								className="toggle toggle-left"
								type="radio"
								value="Si"
								{...register('germane', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-germane-yes" className="btn">
								Si
							</label>
							<input
								id="step4-germane-no"
								className="toggle toggle-right"
								defaultValue="true"
								type="radio"
								value="No"
								{...register('germane', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step4-germane-no" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9 lg:place-items-end">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">País de residencia fiscal</label>
						<input
							type="text"
							maxLength={20}
							className="global-input"
							placeholder="País de residencia fiscal"
							{...register('fiscalCountry', {
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="fiscalCountry"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Tax Identification Number</label>
						<input
							type="text"
							maxLength={9}
							className="global-input"
							placeholder="Tax Identification Number"
							{...register('taxNo', {
								pattern: validations.letrasYnumeros,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="taxNo"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>

			{fields.map((field, index) => (
				<ReferenciaBancariaForm
					key={field.id}
					number={index}
					errors={errors}
					register={register}
				/>
			))}

			<div className="mt-6 text-center create__requisitos">
				<button
					type="button"
					onClick={() => {
						append({});
					}}
					className="mb-4"
				>
					<div className="mx-auto text-[#012169] opacity-50 text-[40px]">+</div>
					<span className="text-[#012169] opacity-50 font-bold mb-3">
						Agregar otra Declaración de beneficiarios
					</span>
				</button>
			</div>
		</React.Fragment>
	);
}

export default StartInvestPersonStep4;
