import share1 from '../../img/general/share1.svg';
import share2 from '../../img/general/share2.svg';
import share3 from '../../img/general/share3.svg';
import PodcastAudio from './PodcastAudio';
import PodcastAuthorCard from './PodcastAuthorCard';
import PodcastReadDto from '../../dtos/PodcastReadDto';
import { useNavigate } from 'react-router-dom';
import blueBlackArrow from '../../img/sala-de-prensa-details/blue-back-arrow.svg';
import whiteBlackArrow from '../../img/sala-de-prensa-details/white-back-arrow.png';

interface IProps {
	podcast: PodcastReadDto;
}

function PodcastDetail({ podcast }: IProps) {
	const navigate = useNavigate();

	return (
		<div className="podcast-details">
			<div className="container mx-auto">
				<div className="prensa-detail-header flex items-center mb-4">
					<a
						className="prensa-detail-header__button-back mr-4 rounded-full border-2 border-[#012169] w-14 h-9 flex justify-center items-center hover:bg-ultramar cursor-pointer"
						onClick={() => {
							navigate(-1);
						}}
					>
						<img
							src={blueBlackArrow}
							alt="arrow back"
							className="prensa-detail-header-button__img w-4 h-4"
						/>
						<img
							src={whiteBlackArrow}
							alt="arrow back"
							className="prensa-detail-header-button__img-hover w-4 h-4 hidden"
						/>
					</a>
					<span className="prensa-detail-header__text text-xs text-[#414652] lg:text-base 3xl:text-xl font-bold">
						Publicado el {podcast.date}
					</span>
				</div>
				<h3 className="detalle-podcast-title generic-title generic-title--left generic-title--entremar">
					{podcast.title}
				</h3>
				<p className="generic-text">{podcast.content}</p>
				<div className="player-info">
					<div className="multimedia">
						<PodcastAudio episode={podcast.episode} />
					</div>
					<div className="share-widget w-fit h-fit md:flex-row lg:flex-col gap-y-[13px] gap-x-[13px]">
						<a href={podcast.appleUrl}>
							<img src={share1} alt="" />
						</a>
						<a href={podcast.googleUrl}>
							<img src={share2} alt="" />
						</a>
						<a href={podcast.spotifyUrl}>
							<img src={share3} alt="" />
						</a>
					</div>
				</div>
				<div className="author-card-list xl:grid xl:grid-cols-2 xl:gap-20">
					{podcast.participans.map((p) => (
						<PodcastAuthorCard
							imgUrl={p.imgUrl}
							name={p.name}
							category={p.category}
							biography={p.biography}
						/>
					))}
				</div>
			</div>
		</div>
	);
}

export default PodcastDetail;
