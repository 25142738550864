import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import InvestmentBeneficiaryContent from './InvestmentBeneficiaryContent';

function InvestmentBeneficiary() {
	const { control } = useFormContext();
	const { append, fields, prepend } = useFieldArray({
		control,
		name: 'investmentBeneficiaries',
	});

	useEffect(() => {
		!fields.length && prepend({});
	}, []);

	return (
		<React.Fragment>
			{fields.map((field, index) => {
				return <InvestmentBeneficiaryContent key={field.id} index={index} />;
			})}
			<div className="mt-6 mb-6 text-center create__requisitos">
				<button
					type="button"
					onClick={() => {
						append({});
					}}
					className="mb-4"
				>
					<div className="mx-auto text-[#012169] opacity-50 text-[40px]">+</div>
					<span className="text-[#012169] opacity-50 font-bold mb-3">
						Agregar otro Beneficiario de la inversión
					</span>
				</button>
			</div>
		</React.Fragment>
	);
}

export default InvestmentBeneficiary;
