import NewsDatePickerComponent from "../datepicker/NewsDatePickerComponent";
import CategoryFilter from "../filters/CategoryFilter";

interface IProps {
  categories: string[];
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  setDate: React.Dispatch<React.SetStateAction<string>>;
  dates: any;
  currentDate:string;
}

function BlogFilters({ categories, setCategory, setDate, dates,currentDate }: IProps) {
  return (
    <div className="main-prensa-selector-container">
      <CategoryFilter categories={categories} setCategory={setCategory} />
      {/* <DateFilter dates={dates} setDate={setDate} /> */}
      <NewsDatePickerComponent dates={dates} setDate={setDate} currentDate={currentDate}/>
    </div>
  );
}

export default BlogFilters;
