import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  ultimoPAso: number;
  close: () => void;
  handleSend: (email: string, code: string) => void;
}

function ContinuarMasTarde(props: IProps) {
  const [email, setEmail] = useState<string>("");
  const code = uuidv4().slice(0, 8);

  return (
    <div className="w-[419px] h-[368px] bg-white drop-shadow-2xl flex flex-col p-10">
      <div className="text-[28px] renaudar-title mb-[12px]">
        Continuar más tarde
      </div>
      <p className="renaudar-text mb-[40px]">
        Para completar la solicitud en otro momento le enviaremos un código de
        acceso por correo electrónico.
      </p>
      <div className="mb-[24px] group-form">
        <label className="global-label">Correo electrónico</label>
        <input
          type="text"
          className="global-input w-full"
          placeholder="ejemplo@correo.com"
          id="correo-input-text"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </div>
      <div className="grid grid-cols-2 gap-6 gap-y-6 lg:gap-y-0 lg:w-[300px] mb-4 lg:mb-0">
        <button
          type="button"
          className="global-button global-button--full global-button--block global-button-anterior"
          onClick={() => props.close()}
        >
          Cancelar
        </button>
        <button
          type="button"
          className="global-button global-button--full"
          onClick={() => {
            props.handleSend(email, code);
            props.close();
          }}
        >
          Enviar
        </button>
      </div>
    </div>
  );
}

export default ContinuarMasTarde;
