import { useDraggable } from 'react-use-draggable-scroll';
import AcknowledgmentReadDto from '../../dtos/AcknowledgmentReadDto';
import { useRef } from 'react';
import AcknowledgmentCard from './AcknowledgmentCard';

interface IProps {
    acknowledgments: AcknowledgmentReadDto[];
}

function AcknowledgmentContainer(props: IProps) {

    const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const { events } = useDraggable(ref);

    function renderAckCards() {
        return props.acknowledgments.map((a) => (<AcknowledgmentCard key={a.id} acknowledgment={a} />))
    }

    return (
        <div className="reconocimiento-section__container" {...events} ref={ref}>
            {
                renderAckCards()
            }
        </div>
    );
}

export default AcknowledgmentContainer;
