import axios from 'axios';
import AppVariables from '../Variables.json';
import CodeOfConductReadDto from '../dtos/CodeOfConductReadDto';
import ExecutivesReadDto from '../dtos/ExecutivesReadDto';
import GCBannerReadDto from '../dtos/GCBannerReadDto';
import GCDocumentsReadDto from '../dtos/GCDocumentsReadDto';
import { getDocSizeByUrl } from './PublicacionesServices';
import { SharePointConsts } from '../util/constants';

const docs = 'Documentos gobierno corporativo';

// Common function to make API requests
async function makeApiRequest(url: string): Promise<any> {
	try {
		const response = await axios.get(url, {
			headers: {
				'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
					.value,
				Accept: SharePointConsts.acceptVerbose,
			},
		});
		return response.data;
	} catch (error) {
		console.error(error);
		throw new Error('Failed to fetch data');
	}
}

// Function to process and return DTOs from the data
function processData<T>(data: any, mapper: (item: any) => T): T[] {
	if (!data.d.results.length) {
		return [];
	}
	return data.d.results.map(mapper);
}

// Utility function to build API URL
const buildApiUrl = (listTitle: string, additionalParams: string = '') =>
	`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('${listTitle}')/items?${additionalParams}`;

const buildApiUrlforChoices = (listTitle: string, additionalParams: string = '') =>
	`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('${listTitle}')/${additionalParams}`;

// Mapper functions for each DTO
const mapBanner = (item: any): GCBannerReadDto => {
	const banner = new GCBannerReadDto();
	banner.id = item.ID;
	banner.title = item.Title;
	banner.content = item.Contenido;
	banner.imgUrl = item.AttachmentFiles.results.length
		? item.AttachmentFiles.results[0].ServerRelativeUrl
		: '';

	return banner;
};

const mapExecutive = (item: any): ExecutivesReadDto => {
	const executive = new ExecutivesReadDto();

	executive.id = item.ID;
	executive.name = item.Nombre;
	executive.description = item.Descripcion;
	executive.area = item.Area;
	executive.rol = item.Rol;
	executive.imgUrl = item.AttachmentFiles.results.length
		? item.AttachmentFiles.results[0].ServerRelativeUrl
		: '';

	return executive;
};

const mapCodeOfConduct = (item: any): CodeOfConductReadDto => {
	const code = new CodeOfConductReadDto();

	code.id = item.ID;
	code.pdfUrl = item.AttachmentFiles.results.length
		? item.AttachmentFiles.results[0].ServerRelativeUrl
		: '';

	return code;
};

const mapDocument = async (item: any): Promise<GCDocumentsReadDto> => {
	const doc = new GCDocumentsReadDto();
	doc.id = item.ID;
	doc.title = item.Title;
	doc.date = item.Ultimamodificacion;
	doc.pdfUrl = item.AttachmentFiles.results.length
		? item.AttachmentFiles.results[0].ServerRelativeUrl
		: '';
	doc.fileSize = doc.pdfUrl === '' ? '' : await getDocSizeByUrl(doc.pdfUrl);

	return doc;
};

// Example refactored function to get active banners
export async function getActiveBanner(): Promise<GCBannerReadDto[]> {
	const url = buildApiUrl(
		'Gobierno Corporativo Banner',
		`$expand=AttachmentFiles&$filter=Estado eq 'Activo'`
	);
	const data = await makeApiRequest(url);
	return processData<GCBannerReadDto>(data, mapBanner);
}

// Refactored function to get executives by area
export async function getExecutivesByArea(area: string): Promise<ExecutivesReadDto[]> {
	const url = buildApiUrl(
		'Ejecutivos',
		`$expand=AttachmentFiles&$filter=Estado eq 'Activo' and Area eq '${area}'`
	);
	const data = await makeApiRequest(url);
	return processData<ExecutivesReadDto>(data, mapExecutive);
}

// Function to get all executive areas
export async function getAllExecutivesAreas(): Promise<string[]> {
	const url = buildApiUrlforChoices('Ejecutivos', `fields?$filter=EntityPropertyName eq 'Area'`);
	const data = await makeApiRequest(url);
	return data.d.results[0].Choices.results;
}

// Function to get active code of conduct
export async function getActiveCodeOfConduct(): Promise<CodeOfConductReadDto[]> {
	const url = buildApiUrl(
		'Codigo de conducta',
		`$expand=AttachmentFiles&$filter=Estado eq 'Activo'`
	);
	const data = await makeApiRequest(url);
	return processData<CodeOfConductReadDto>(data, mapCodeOfConduct);
}

// Function to get all documents categories
export async function getAllDocumentsCategories(): Promise<string[]> {
	const url = buildApiUrlforChoices(docs, `fields?$filter=EntityPropertyName eq 'Categoria'`);
	const data = await makeApiRequest(url);
	return data.d.results[0].Choices.results;
}

// Function to get all documents years
export async function getAllDocumentsYears(): Promise<string[]> {
	const url = buildApiUrlforChoices(docs, `fields?$filter=EntityPropertyName eq 'A_x00f1_o'`);
	const data = await makeApiRequest(url);
	return data.d.results[0].Choices.results;
}

// Function to get government corporate documents by year and category
export async function getGCDocsByYearAndCategory(
	category: string,
	year: string
): Promise<GCDocumentsReadDto[]> {
	const url = buildApiUrl(
		docs,
		`$expand=AttachmentFiles&$filter=Estado eq 'Activo' and A_x00f1_o eq '${year}' and Categoria eq '${category}'&$orderby=Orden desc,Ultimamodificacion desc`
	);
	const data = await makeApiRequest(url);
	const documents = await Promise.all(processData(data, async (item) => await mapDocument(item)));
	return documents;
}

// Function to get government corporate documents by category
export async function getGCDocsByCategory(category: string): Promise<GCDocumentsReadDto[]> {
	const url = buildApiUrl(
		docs,
		`$expand=AttachmentFiles&$filter=Estado eq 'Activo' and Categoria eq '${category}'&$orderby=Orden desc,Ultimamodificacion desc`
	);
	const data = await makeApiRequest(url);
	const documents = await Promise.all(processData(data, async (item) => await mapDocument(item)));
	return documents;
}
