import AppVariables from '../Variables.json';

class CodeOfConductReadDto {
	public id: number;
	private _pdfUrl: string;
	public get pdfUrl(): string {
		return this._pdfUrl;
	}
	public set pdfUrl(value: string) {
		this._pdfUrl = AppVariables.BASE_URL + value;
	}
}

export default CodeOfConductReadDto;
