import RespuestaReadDto from '../../dtos/RespuestaReadDto';

interface IProps {
	answers: RespuestaReadDto;
	question: string;
	register: any;
	id: number;
	qId: number;
}

function RiskProfileAnswers({ answers, question, register, id, qId }: IProps) {
	return (
		<label className="check-box-item">
			<input
				type="radio"
				id={`question-${qId}-${id}`}
				{...register(`question-${qId}`, { required: true })}
				value={answers.value}
			/>
			<span>{answers.option}</span>
		</label>
	);
}

export default RiskProfileAnswers;
