import { Link } from "react-router-dom";
import chevronLarimar from "../../img/general/chevron-right-larimar.svg";
import chevronUltramar from '../../img/general/chevron-right-ultramar.svg';
import "./privacyTerms.css";

export type privacyPolicyTermsConditionsProps = {
  title: string;
  subTitle: string;
  content: string;
  linkTitle: string;
  linkText: string;
  link: string;
  miniFooter: string;
};

export default function PrivacyPolicyTermsConditions({
  title,
  subTitle,
  content,
  linkTitle,
  linkText,
  link,
  miniFooter,
}: privacyPolicyTermsConditionsProps) {

  return (
		<main className="main">
			<div className="banner-intro">
				<div className="container mx-auto">
					<h1>{title}</h1>
					<h3>{subTitle}</h3>
				</div>
			</div>
			<div className="container mx-auto container-1000">
				<div className="lista-preguntas">
					<div dangerouslySetInnerHTML={{ __html: content }} />
					<div className="politicas-block">
						<h3>{linkTitle}</h3>
						<p>
							<span>
								<Link className="chevron-right-link" to={link}>
									<img className="chevron-right-larimar" src={chevronLarimar} alt="chevron" />
									<img className="chevron-right-ultramar" src={chevronUltramar} alt="chevron" />
									{linkText}
								</Link>
							</span>
						</p>
						<p>{miniFooter}</p>
					</div>
				</div>
			</div>
		</main>
  );
}
