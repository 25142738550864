import BlogReadDto from "../../dtos/BlogReadDto";
import AnotherBlogCard from "./AnotherBlogCard";

interface IProps {
  blogs: BlogReadDto[];
}

function BlogDetailAnotherBlogs({ blogs }: IProps) {
  const renderAnotherBlogs = () => {
    return blogs.map((blog) => <AnotherBlogCard key={blog.id} blog={blog} />);
  };

  return (
    <div className="podcast podcast--blue">
      <h1 className="generic-title generic-title--small mb-[32px]">
        También te puede interesar
      </h1>
      <div className="podcast-container podcast-container--blog">
        {renderAnotherBlogs()}
      </div>
    </div>
  );
}

export default BlogDetailAnotherBlogs;
