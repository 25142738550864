import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { validations } from "../Validations/regexValidations";

function EmpresasAfiliadasForm() {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const { append, fields, prepend } = useFieldArray({
    control,
    name: "afiliatedCompanies",
  });

  useEffect(() => {
    !fields.length && prepend({});
  }, []);

  return (
    <React.Fragment>
      {fields.map((field, index) => {
        const baseName = `afiliatedCompanies[${index}]`;
        return (
				<div key={field.id} className="mt-6 create__requisitos">
					<h4 className="create__requisitos-title">
						Empresas afiliadas, subsidiarias y compañías relacionadas - Opcional
					</h4>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Nombre de empresa</label>
							<input
								type="text"
								maxLength={20}
								className="global-input"
								placeholder="Nombre de empresa"
								{...register(`${baseName}.CompanyName`, {
									required: false,
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name={`${baseName}.CompanyName`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Persona de contacto</label>
							<input
								type="text"
								maxLength={40}
								className="global-input"
								placeholder="Persona de contacto"
								{...register(`${baseName}.ContactPerson`, {
									required: false,
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name={`${baseName}.ContactPerson`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2 xl:pr-4">
							<label className="global-label">Teléfono de contacto</label>
							<input
								type="text"
								maxLength={14}
								className="global-input"
								placeholder="(809) 000 - 0000"
								{...register(`${baseName}.ContactNumber`, {
									required: false,
									pattern: validations.soloNumeros,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name={`${baseName}.ContactNumber`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
				</div>
			);
      })}
      <div className="mt-6 mb-6 text-center create__requisitos">
        <button
          type="button"
          onClick={() => {
            append({});
          }}
          className="mb-4"
        >
          <div className="mx-auto text-[#012169] opacity-50 text-[40px]">+</div>
          <span className="text-[#012169] opacity-50 font-bold mb-3">
            Agregar otra empresa afiliada, subsidiada o compañía relacionada
          </span>
        </button>
      </div>
    </React.Fragment>
  );
}

export default EmpresasAfiliadasForm;
