import React, { useEffect, useState } from 'react';
import CodeOfConductReadDto from '../../dtos/CodeOfConductReadDto';
import ExecutivesReadDto from '../../dtos/ExecutivesReadDto';
import GCBannerReadDto from '../../dtos/GCBannerReadDto';
import GCDocumentsReadDto from '../../dtos/GCDocumentsReadDto';
import {
	getActiveBanner,
	getActiveCodeOfConduct,
	getAllDocumentsCategories,
	getAllDocumentsYears,
	getAllExecutivesAreas,
	getExecutivesByArea,
	getGCDocsByCategory,
	getGCDocsByYearAndCategory,
} from '../../services/GobiernoCorporativoServices';
import AboutUsBanner from '../about-us/AboutUsBanner';
import Conduct from '../conduct/Conduct';
import MainOrgChart from '../structure/MainOrgChart';
import Document from './Document';
import Executives from './Executive';

function MainGobiernoCorporativo() {
	const [bannerInfo, setBannerInfo] = useState<GCBannerReadDto>(new GCBannerReadDto());
	const [areas, setAreas] = useState<string[]>([]);
	const [currentArea, setCurrentArea] = useState<string>('');
	const [executives, setExecutives] = useState<ExecutivesReadDto[]>([]);
	const [codeOfConduct, setCodeOfConduct] = useState<CodeOfConductReadDto>(
		new CodeOfConductReadDto()
	);
	const [categories, setCategories] = useState<string[]>([]);
	const [currentCategory, setCurrentCategory] = useState<string>('');
	const [years, setYears] = useState<string[]>([]);
	const [currentYear, setCurrentYear] = useState<string>('Todos');
	const [documents, setDocuments] = useState<GCDocumentsReadDto[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			const bannerInfo1 = await getActiveBanner();
			setBannerInfo(bannerInfo1.length ? bannerInfo1[0] : new GCBannerReadDto());

			const areas1 = await getAllExecutivesAreas();
			setAreas(areas1);
			setCurrentArea(areas1[0]);

			const executives1 = await getExecutivesByArea(areas1[0]);
			setExecutives(executives1);

			const codeOfConduct1 = await getActiveCodeOfConduct();
			setCodeOfConduct(codeOfConduct1[0]);

			const categories1 = await getAllDocumentsCategories();
			setCategories(categories1);
			setCurrentCategory(categories1[0]);

			const years1 = await getAllDocumentsYears();
			setYears(years1);
			// setCurrentYear(years1[0]);

			const documents1 = await getGCDocsByYearAndCategory(categories1[0], years1[0]);
			setDocuments(documents1);
		};

		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			const executives2 = await getExecutivesByArea(currentArea);
			setExecutives(executives2);
		};
		fetchData();
	}, [currentArea]);

	useEffect(() => {
		const fetchData = async () => {

			let documents2;

			if(currentYear === 'Todos'){
				documents2 = await getGCDocsByCategory(currentCategory);
			}else{
				documents2 = await getGCDocsByYearAndCategory(currentCategory, currentYear);
			}
			setDocuments(documents2);
		};
		fetchData();
	}, [currentCategory, currentYear]);

	return (
		<React.Fragment>
			<AboutUsBanner BannerInfo={bannerInfo} />
			<MainOrgChart/>
			<Executives executives={executives} areas={areas} setCurrentArea={setCurrentArea} />
			<Conduct codeOfConduct={codeOfConduct} />
			<Document
				categories={categories}
				setCurrentCategory={setCurrentCategory}
				years={years}
				setCurrentYear={setCurrentYear}
				documents={documents}
			/>
		</React.Fragment>
	);
}

export default MainGobiernoCorporativo;
