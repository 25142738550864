import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

type faqProps = {
  title: string;
  placeholder: string;
  onSubmit: (userInput: string) => void;
};

export default function HeroFAQ({ title, placeholder, onSubmit }: faqProps) {
  const [userInput, setUserInput] = useState("");
  const handleChange = (e: any) => {
    setUserInput(e.target.value);
  };

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    onSubmit(userInput);
  };

  return (
    <div className="banner-intro">
      <div className="container mx-auto">
        <h1>{title}</h1>
        <div className="top-search">
          <input
            placeholder={placeholder}
            type="text"
            value={userInput}
            onChange={handleChange}
          />
          <button
            onClick={handleOnClick}
            className="top-search__icon"
            type="submit"
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </button>
        </div>
      </div>
    </div>
  );
}
