import axios from 'axios';
import AppVariables from '../Variables.json';
import StartInvestReadDto from '../dtos/StartInvestReadDto';

export async function getAllStartInvestCategories(): Promise<StartInvestReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Comienza invertir')/items?$expand=AttachmentFiles&?$filter=(Estado eq 'Activo')`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: 'application/json; odata=verbose',
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const startInvest: StartInvestReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const category = new StartInvestReadDto();

		category.id = data.d.results[index].ID;
		category.title = data.d.results[index].Title;
		category.description = data.d.results[index].Descripcion;
		category.imgUrl = data.d.results[index].AttachmentFiles.results.length
			? data.d.results[index].AttachmentFiles.results[0].ServerRelativeUrl
			: '';
		category.textUrl = data.d.results[index].UrlText;
		category.url = data.d.results[index].url;

		startInvest[index] = category;
	}

	return startInvest;
}
