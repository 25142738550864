import axios from 'axios';
import AppVariables from '../Variables.json';
import FormularioPersonaReadDto from '../dtos/FormularioPersonaReadDto';
import { getListMetadata } from './GenericServices';
import { v4 as uuidv4 } from 'uuid';
import { fp_s7_principal_objetivo } from '../components/Utility/SelectOptions';
import { SharePointConsts } from '../util/constants';

//generic formulario services
export async function getFormularioPersonaByCode(
	code: string
): Promise<FormularioPersonaReadDto | null> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Formulario persona')/items?$filter=Codigo eq '${code}'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const results = data.d.results[0];
	if (results === undefined || results === null) {
		return null;
	}
	const formulario = new FormularioPersonaReadDto();

	formulario.id = results?.ID;
	formulario.mail = results?.Correoelectronico;
	formulario.code = results?.Codigo;
	formulario.lastStep = results?.Ultimopaso;
	formulario.status = results?.Estado;

	return formulario;
}

let FORM_ID: any;
let EMAIL: any;

export async function getFormProgress(code: string) {
	return getFormularioPersonaByCode(code)
		.then(({ id, mail }) => {
			FORM_ID = id;
			EMAIL = mail;
			return Promise.allSettled([
				getFPDatosPersonales(id),
				getFPDatosDeLaCuenta(id),
				getFPDatosDelCotitular(id),
				getFPDatosDelConyugue(id),

				getFPCuentaMenoresEdad(id),
				getFPDatosPersonaExtranjera(id),
				getFPDatosInformacionLaboral(id),

				getFPDatosPersonaPoliticamenteExpuesta(id),
				getFPDatosDeclaracionBeneficiarios(id),

				getFPDatosPersonasRelacionadas(id),
				getFPDatosFATCA(id),
				getFPDatosReferenciaBancaria(id),

				getFPDatosClientesDeMayorRiesgo(id),
				getFPDatosPersonasAutorizadas(id),

				getFPDatosInformacionInversionista(id),

				getFPDatosInformacionCliente(id),
				getFPDatosObjetivosFinancieros(id),
			]);
		})
		.then((forms) => {
			const combinedForm = forms.reduce((acc, current: any) => {
				return { ...acc, ...current.value };
			}, {});
			return combinedForm;
		});
}

export async function saveFormProgress(
	progress: any,
	step: number,
	status: string,
	code?: string,
	email?: string
): Promise<any> {
	const stepMethods = [
		[
			postOrUpdateFPDatosPersonales,
			postOrUpdateFPDatosDeLaCuenta,
			postOrUpdateFPDatosDelCotitular,
			postOrUpdateFPDatosDelConyugue,
		],
		[
			postOrUpdateFPCuentaMenorDeEdad,
			postOrUpdateFPDatosPersonaExtranjera,
			postOrUpdateFPInformacionLaboral,
		],
		[postOrUpdateFPPersonaPoliticamenteExpuesta, postOrUpdateDeclaracionDeBeneficiario],
		[postOrUpdateDatosPersonasRelacionadas, postOrUpdateFATCA, postOrUpdateReferenciaBancaria],
		[postOrUpdateClientesMayorRiesgo, postOrUpdatePersonasAutorizadas],
		[postOrUpdateInfromacionRelativaInversionista],
		[postOrUpdateInformacionRelativaCliente, postOrUpdateObjetivosFinancieros],
	];

	const selectedMethods = stepMethods.flat();

	if (FORM_ID) {
		updateFormularioPersona(FORM_ID, step, status, EMAIL, code).then(() => {
			const savedProgress = selectedMethods.map((method: any) =>
				method({ ...progress, formId: FORM_ID })
			);
			return Promise.all(savedProgress);
		});
	}

	return postFormularioPersona(step, status, email, code)
		.then((data) => {
			return data.d.ID;
		})
		.then((formId) => {
			const savedProgress = selectedMethods.map((method: any) =>
				method({ ...progress, formId })
			);
			return Promise.all(savedProgress);
		});
}

export async function saveForm(progress: any, step: number, status: string, code?: string) {
	const stepMethods = [
		postOrUpdateFPDatosPersonales,
		postOrUpdateFPDatosDeLaCuenta,
		postOrUpdateFPDatosDelCotitular,
		postOrUpdateFPDatosDelConyugue,
		postOrUpdateFPCuentaMenorDeEdad,
		postOrUpdateFPDatosPersonaExtranjera,
		postOrUpdateFPInformacionLaboral,
		postOrUpdateFPPersonaPoliticamenteExpuesta,
		postOrUpdateDeclaracionDeBeneficiario,
		postOrUpdateDatosPersonasRelacionadas,
		postOrUpdateFATCA,
		postOrUpdateReferenciaBancaria,
		postOrUpdateClientesMayorRiesgo,
		postOrUpdatePersonasAutorizadas,
		postOrUpdateInfromacionRelativaInversionista,
		postOrUpdateInformacionRelativaCliente,
		postOrUpdateObjetivosFinancieros,
	];
	const selectedMethods = stepMethods.flat();
	const useCode = code || uuidv4().slice(0, 8);

	if (FORM_ID) {
		return updateFormularioPersona(FORM_ID, step, status, EMAIL, code).then(() => {
			const savedProgress = selectedMethods.map((method: any) =>
				method({ ...progress, formId: FORM_ID })
			);
			return Promise.all(savedProgress);
		});
	}

	const { email } = progress;

	return postFormularioPersona(step, status, email, useCode)
		.then((data) => {
			return data.d.ID;
		})
		.then((formId) => {
			const savedProgress = selectedMethods.map((method: any) =>
				method({ ...progress, formId })
			);
			return Promise.all(savedProgress);
		});
}

//FORMULARIO PERSONA POST AND UPDATE SERVICES
export async function postFormularioPersona(
	lastStep: number,
	status: string,
	email?: string,
	code?: string
) {
	const formularioPersona = JSON.stringify({
		Correoelectronico: email,
		Codigo: code,
		Ultimopaso: lastStep,
		Estado: status,
		__metadata: { type: await getListMetadata('Formulario persona') },
	});

	//query
	const query = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Formulario persona')/items`;

	//config
	const axiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	//post
	return axios
		.post(query, formularioPersona, axiosConfig)
		.then((res) => res.data)
		.catch((error) => console.error(error));
}
export async function updateFormularioPersona(
	ID: number,
	lastStep: number,
	status: string,
	email?: string,
	code?: string
) {
	const formularioPersona = JSON.stringify({
		Correoelectronico: email,
		Codigo: code,
		Ultimopaso: lastStep,
		Estado: status,
		__metadata: { type: await getListMetadata('Formulario persona') },
	});

	//query
	const query = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Formulario persona')/items/getbyid(${ID})`;

	//config
	const axiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post
	return axios
		.post(query, formularioPersona, axiosConfig)
		.then((res) => res.data)
		.catch((error) => console.error(error));
}

//STEP 1 SERVICES
//post and update datos personasles tested
export async function postOrUpdateFPDatosPersonales({
	firstName,
	secondName,
	firstLastName,
	secondLastName,
	idType,
	idNo,
	sex,
	maritalStatus,
	birthdate,
	birthplace,
	firstNationality,
	secondNationality,
	academicLevel,
	profession,
	resTel,
	addTel,
	cel,
	email,
	address,
	zipcode,
	formId,
	idDatosPersonales,
}: any) {
	const newDate = new Date(birthdate)
		.toLocaleString('ko-KR', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		})
		.replaceAll(' ', '')
		.replaceAll('.', '-')
		.slice(0, 10);

	const fpDatosPersonales = JSON.stringify({
		Primernombre: firstName,
		Segundonombre: secondName,
		Primerapellido: firstLastName,
		Segundoapellido: secondLastName,
		Tipodeidentificacion: idType,
		Numerodeidentificacion: idNo,
		Sexo: sex,
		Estadocivil: maritalStatus?.value || null,
		Fechadenacimiento: newDate,
		Lugardenacimiento: birthplace,
		Nacionalidad: firstNationality,
		Segundanacionalidad: secondNationality,
		Nivelacademico: academicLevel?.value || null,
		Profesion: profession,
		Telefonoresidencia: resTel,
		Telefonoadicional: addTel,
		Telefonocelular: cel,
		Correoelectronico: email,
		Direccionresidencia: address,
		Apartadopostal: zipcode,
		FormularioIDId: formId,
		__metadata: { type: await getListMetadata('FP Datos Personales') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Datos Personales')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Datos Personales')/items/getbyid(${idDatosPersonales})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post
	await axios
		.post(
			idDatosPersonales === undefined ? postQuery : updateQuery,
			fpDatosPersonales,
			idDatosPersonales === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
//get datos personales
export async function getFPDatosPersonales(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Datos Personales')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datosPersonales = {
		idDatosPersonales: data.d.results[0]?.ID,
		firstName: data.d.results[0]?.Primernombre,
		secondName: data.d.results[0]?.Segundonombre,
		firstLastName: data.d.results[0]?.Primerapellido,
		secondLastName: data.d.results[0]?.Segundoapellido,
		idType: data.d.results[0]?.Tipodeidentificacion,
		idNo: data.d.results[0]?.Numerodeidentificacion,
		sex: data.d.results[0]?.Sexo,
		maritalStatus:
			(data.d.results[0]?.Estadocivil && {
				value: data.d.results[0]?.Estadocivil,
				label: data.d.results[0]?.Estadocivil,
			}) ||
			null,
		birthdate: new Date(data.d.results[0]?.Fechadenacimiento),
		birthplace: data.d.results[0]?.Lugardenacimiento,
		firstNationality: data.d.results[0]?.Nacionalidad,
		secondNationality: data.d.results[0]?.Segundanacionalidad,
		academicLevel: data.d.results[0]?.Nivelacademico && {
			value: data.d.results[0]?.Nivelacademico,
			label: data.d.results[0]?.Nivelacademico,
		},
		profession: data.d.results[0]?.Profesion,
		resTel: data.d.results[0]?.Telefonoresidencia,
		addTel: data.d.results[0]?.Telefonoadicional,
		cel: data.d.results[0]?.Telefonocelular,
		email: data.d.results[0]?.Correoelectronico,
		address: data.d.results[0]?.Direccionresidencia,
		zipcode: data.d.results[0]?.Apartadopostal,
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datosPersonales;
}

//post and update datos de la cuenta tested
export async function postOrUpdateFPDatosDeLaCuenta({
	holder,
	coHolderQuestion,
	formId,
	idDatosCuenta,
}: any) {
	const fpDatosCuenta = JSON.stringify({
		Titular: holder,
		Tendrauncotitular: coHolderQuestion,
		FormularioIDId: formId,
		__metadata: { type: await getListMetadata('FP Datos de la cuenta') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Datos de la cuenta')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Datos de la cuenta')/items/getbyid(${idDatosCuenta})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idDatosCuenta === undefined ? postQuery : updateQuery,
			fpDatosCuenta,
			idDatosCuenta === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
//get datos de la cuenta
export async function getFPDatosDeLaCuenta(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Datos de la cuenta')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	if (data.d.results.length === 0) {
		return null;
	}

	const datosCuenta = {
		idDatosCuenta: data.d.results[0].ID,
		holder: data.d.results[0].Titular,
		coHolderQuestion: data.d.results[0].Tendrauncotitular,
		formId: data.d.results[0].FormularioIDId,
	};

	return datosCuenta;
}

//post and update datos del cotitular
export async function postOrUpdateFPDatosDelCotitular({
	coHolder,
	coHolderIdType,
	coHolderId,
	coHolderProfesion,
	coHolderWork,
	formId,
	idDatosCotitular,
}: any) {
	const fpDatosCotitular = JSON.stringify({
		Nombre: coHolder,
		Tipodeidentificacion: coHolderIdType,
		Profesion: coHolderProfesion,
		Lugardetrabajo: coHolderWork,
		FormularioIDId: formId,
		Numerodeidentificacion: coHolderId,
		__metadata: { type: await getListMetadata('FP Datos del cotitular') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Datos del cotitular')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Datos del cotitular')/items/getbyid(${idDatosCotitular})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idDatosCotitular === undefined ? postQuery : updateQuery,
			fpDatosCotitular,
			idDatosCotitular === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
//get datos del cotitular
export async function getFPDatosDelCotitular(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Datos del cotitular')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	if (data.d.results.length === 0) {
		return null;
	}

	const datosCotitular = {
		idDatosCotitular: data.d.results[0].ID,
		coHolder: data.d.results[0].Nombre,
		coHolderIdType: data.d.results[0].Tipodeidentificacion,
		coHolderId: data.d.results[0].Numerodeidentificacion,
		coHolderProfesion: data.d.results[0].Profesion,
		coHolderWork: data.d.results[0].Lugardetrabajo,
		formId: data.d.results[0].FormularioIDId,
	};

	return datosCotitular;
}

//post and update datos del conyugue
export async function postOrUpdateFPDatosDelConyugue({
	spouseName,
	spouseIdType,
	spouseId,
	spouseProfession,
	spouseWork,
	formId,
	idDatosConyugue,
}: any) {
	const datos = JSON.stringify({
		Nombredelconyugue: spouseName,
		Tipodeidentifiacion: spouseIdType,
		Numerodeidentificacion: spouseId,
		Profesion: spouseProfession,
		Lugardetrabajo: spouseWork,
		FormularioIDId: formId,
		__metadata: { type: await getListMetadata('FP Datos del conyugue') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Datos del conyugue')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Datos del conyugue')/items/getbyid(${idDatosConyugue})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idDatosConyugue === undefined ? postQuery : updateQuery,
			datos,
			idDatosConyugue === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}

export async function getFPDatosDelConyugue(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Datos del conyugue')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datosConyugue = {
		idDatosConyugue: data.d.results[0].ID,
		spouseName: data.d.results[0].Nombredelconyugue,
		spouseIdType: data.d.results[0].Tipodeidentifiacion,
		spouseId: data.d.results[0].Numerodeidentificacion,
		spouseProfession: data.d.results[0].Profesion,
		spouseWork: data.d.results[0].Lugardetrabajo,
		formId: data.d.results[0].FormularioIDId,
	};

	return datosConyugue;
}

//STEP 2 SERVICES
//post and update datos cuenta menor de edad
export async function postOrUpdateFPCuentaMenorDeEdad({
	namesMinor,
	lastNamesMinor,
	idNoMinor,
	birthCertificateNumber,
	book,
	folio,
	formId,
	idDatosMenoresEdad,
}: any) {
	const datos = JSON.stringify({
		Nombre: namesMinor,
		Apellidos: lastNamesMinor,
		NumeroIdentificacion: idNoMinor,
		Libro: book,
		Numeroactadenacimiento: birthCertificateNumber,
		Folio: folio,
		FormularioIDId: formId,
		__metadata: { type: await getListMetadata('FP Cuentas Menores de edad') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Cuentas Menores de edad')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Cuentas Menores de edad')/items/getbyid(${idDatosMenoresEdad})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idDatosMenoresEdad === undefined ? postQuery : updateQuery,
			datos,
			idDatosMenoresEdad === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}

export async function getFPCuentaMenoresEdad(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Cuentas Menores de edad')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datosMenoresEdad = {
		idDatosMenoresEdad: data.d.results[0].ID,
		namesMinor: data.d.results[0].Nombre,
		lastNamesMinor: data.d.results[0].Apellidos,
		idNoMinor: data.d.results[0].NumeroIdentificacion,
		birthCertificateNumber: data.d.results[0].Numeroactadenacimiento,
		book: data.d.results[0].Libro,
		folio: data.d.results[0].Folio,
		formId: data.d.results[0].FormularioIDId,
	};

	return datosMenoresEdad;
}

//post and update datos persona extranjera
export async function postOrUpdateFPDatosPersonaExtranjera({
	proofImmigrationProcedures,
	foreignAddress,
	formId,
	idDatosPersonaExtranjera,
}: any) {
	const datos = JSON.stringify({
		Constanciadetramitesmigratorios: proofImmigrationProcedures,
		Direccion: foreignAddress,
		FormularioIDId: formId,
		__metadata: { type: await getListMetadata('FP Datos persona extranjera') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Datos persona extranjera')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Datos persona extranjera')/items/getbyid(${idDatosPersonaExtranjera})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idDatosPersonaExtranjera === undefined ? postQuery : updateQuery,
			datos,
			idDatosPersonaExtranjera === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}

export async function getFPDatosPersonaExtranjera(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Datos persona extranjera')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datosPersonaExtranjera = {
		idDatosPersonaExtranjera: data.d.results[0].ID,
		proofImmigrationProcedures: data.d.results[0].Constanciadetramitesmigratorios,
		foreignAddress: data.d.results[0].Direccion,
		formId: data.d.results[0].FormularioIDId,
	};

	return datosPersonaExtranjera;
}

//post and update informacion laboral
export async function postOrUpdateFPInformacionLaboral({
	ocupation,
	workPlace,
	workRole,
	workAddress,
	workNumber,
	workEmail,
	lessThan1Year,
	workYears,
	revenue,
	otherRevenues,
	sourceOtherRevenues,
	stateProvider,
	formId,
	idDatosInformacionLaboral,
}: any) {
	const datos = JSON.stringify({
		Ocupacion: ocupation?.value || null,
		Lugardetrabajo: workPlace,
		Posicionenlaempresa: workRole,
		DireccionLugardetrabajo: workAddress,
		Telefonodeltrabajo: workNumber,
		EmailTrabajo: workEmail,
		Menorauna_x00f1_o: lessThan1Year,
		A_x00f1_osdelaborar: workYears,
		Ingresos: revenue || 0,
		Otrosingresos: otherRevenues || 0,
		Fuentedeesosingresos: sourceOtherRevenues,
		Esproveedordelestado: stateProvider,
		FormularioIDId: formId,
		__metadata: { type: await getListMetadata('FP Informacion laboral') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Informacion laboral')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Informacion laboral')/items/getbyid(${idDatosInformacionLaboral})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idDatosInformacionLaboral === undefined ? postQuery : updateQuery,
			datos,
			idDatosInformacionLaboral === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}

export async function getFPDatosInformacionLaboral(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Informacion laboral')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datosInformacionLaboral = {
		idDatosInformacionLaboral: data.d.results[0].ID,
		ocupation: data.d.results[0]?.Ocupacion && {
			value: data.d.results[0]?.Ocupacion,
			label: data.d.results[0]?.Ocupacion,
		},
		workPlace: data.d.results[0]?.Lugardetrabajo,
		workRole: data.d.results[0]?.Posicionenlaempresa,
		workAddress: data.d.results[0]?.DireccionLugardetrabajo,
		workNumber: data.d.results[0]?.Telefonodeltrabajo,
		workEmail: data.d.results[0]?.EmailTrabajo,
		lessThan1Year: data.d.results[0]?.Menorauna_x00f1_o,
		workYears: data.d.results[0]?.A_x00f1_osdelaborar,
		revenue: data.d.results[0]?.Ingresos,
		otherRevenues: data.d.results[0]?.Otrosingresos,
		sourceOtherRevenues: data.d.results[0]?.Fuentedeesosingresos,
		stateProvider: data.d.results[0]?.Esproveedordelestado,
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datosInformacionLaboral;
}

//STEP 3 SERVICES
//post and update datos persona politicamente expuesta
export async function postOrUpdateFPPersonaPoliticamenteExpuesta({
	politicallyExposed,
	bond,
	heldPosition,
	termStart,
	termEnd,
	country,
	formId,
	idDatosPersonaPoliticamenteExpuesta,
}: any) {
	const datos = JSON.stringify({
		Personaexpuestapoliticamente: politicallyExposed,
		Vinculacion: bond,
		Cargodesempe_x00f1_adoylugar: heldPosition,
		Desde: termStart,
		Hasta: termEnd,
		Pais: country,
		FormularioIDId: formId,
		__metadata: {
			type: await getListMetadata('FP Persona politicamente expuesta'),
		},
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Persona politicamente expuesta')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Persona politicamente expuesta')/items/getbyid(${idDatosPersonaPoliticamenteExpuesta})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idDatosPersonaPoliticamenteExpuesta === undefined ? postQuery : updateQuery,
			datos,
			idDatosPersonaPoliticamenteExpuesta === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}

export async function getFPDatosPersonaPoliticamenteExpuesta(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Persona politicamente expuesta')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datosPersonaPoliticamenteExpuesta = {
		idDatosPersonaPoliticamenteExpuesta: data.d.results[0].ID,
		politicallyExposed: data.d.results[0]?.Personaexpuestapoliticamente,
		bond: data.d.results[0]?.Vinculacion,
		heldPosition: data.d.results[0]?.Cargodesempe_x00f1_adoylugar,
		termStart: new Date(data.d.results[0]?.Desde),
		termEnd: new Date(data.d.results[0]?.Hasta),
		country: data.d.results[0]?.Pais,
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datosPersonaPoliticamenteExpuesta;
}
//post and update datos declaracion de beneficiarios
export async function postOrUpdateDeclaracionDeBeneficiario({ formId, beneficiaries = [] }: any) {
	const beneficiariesRequests = beneficiaries?.map(
		async ({
			intermediary,
			intermediaryRelation,
			names,
			lastNames,
			idType,
			idNoBeneficiary,
			ocupation,
			contactTel,
			address,
			idDatosDeclaracionBeneficiarios,
		}: any) => {
			const datos = JSON.stringify({
				OData__x00bf_Act_x00fa_austedcomointer: intermediary,
				Encasodemarcarafirmativo_x002c_d: intermediaryRelation?.value || null,
				Nombres: names,
				Apellidos: lastNames,
				Tipodeidentificacion: idType,
				Numerodeidentificacion: idNoBeneficiary,
				Ocupacion: ocupation,
				Telefonodecontacto: contactTel,
				Direccion: address,
				FormularioIdId: formId,
				__metadata: {
					type: await getListMetadata('FP Declaracion de beneficiarios'),
				},
			});

			//query
			const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Declaracion de beneficiarios')/items`;
			const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Declaracion de beneficiarios')/items/getbyid(${idDatosDeclaracionBeneficiarios})`;

			//config
			const postAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'IF-MATCH': '*',
				},
			};

			const updateAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'X-HTTP-Method': 'MERGE',
					'IF-MATCH': '*',
				},
			};

			//post or update
			return axios
				.post(
					idDatosDeclaracionBeneficiarios === undefined ? postQuery : updateQuery,
					datos,
					idDatosDeclaracionBeneficiarios === undefined ? postAxiosConfig : updateAxiosConfig
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => console.error(error));
		}
	);

	return Promise.all(beneficiariesRequests);
}

export async function getFPDatosDeclaracionBeneficiarios(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Declaracion de beneficiarios')/items?$filter=FormularioIdId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datosDeclaracionBeneficiarios = data.d.results.map((beneficiary: any) => ({
		idDatosDeclaracionBeneficiarios: beneficiary.ID,
		intermediary: beneficiary?.OData__x00bf_Act_x00fa_austedcomointer,
		intermediaryRelation: {
			value: beneficiary?.Encasodemarcarafirmativo_x002c_d,
			label: beneficiary?.Encasodemarcarafirmativo_x002c_d,
		},
		names: beneficiary?.Nombres,
		lastNames: beneficiary?.Apellidos,
		idType: beneficiary?.Tipodeidentificacion,
		idNoBeneficiary: beneficiary?.Numerodeidentificacion,
		ocupation: beneficiary?.Ocupacion,
		contactTel: beneficiary?.Telefonodecontacto,
		address: beneficiary?.Direccion,
		formId: beneficiary?.FormularioIdId,
	}));

	return { beneficiaries: datosDeclaracionBeneficiarios };
}

//STEP 4 SERVICES
//post and update datos personas relacionadas
export async function postOrUpdateDatosPersonasRelacionadas({
	relatedPersonName,
	idNoRelatedPerson,
	bondType,
	formId,
	idDatosPersonasRelacionadas,
}: any) {
	const datos = JSON.stringify({
		Nombre: relatedPersonName,
		Numerodeidentificacion: idNoRelatedPerson,
		Tipoderelacion: bondType,
		FormularioIDId: formId,
		__metadata: { type: await getListMetadata('FP Personas relacionadas') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Personas relacionadas')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Personas relacionadas')/items/getbyid(${idDatosPersonasRelacionadas})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idDatosPersonasRelacionadas === undefined ? postQuery : updateQuery,
			datos,
			idDatosPersonasRelacionadas === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}

export async function getFPDatosPersonasRelacionadas(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Personas relacionadas')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datosPersonasRelacionadas = {
		idDatosPersonasRelacionadas: data.d.results[0].ID,
		relatedPersonName: data.d.results[0]?.Nombre,
		idNoRelatedPerson: data.d.results[0]?.Numerodeidentificacion,
		bondType: data.d.results[0]?.Tipoderelacion,
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datosPersonasRelacionadas;
}
//post and update fatca
export async function postOrUpdateFATCA({
	isBirthPlace,
	residentCitizen,
	holdMail,
	careOf,
	residencyCard,
	residencyCardNo,
	socialSecurity,
	socialSecurityNo,
	poBoxAddress,
	usPhoneNumber,
	transferAvailability,
	germane,
	fiscalCountry,
	taxNo,
	formId,
	idDatosFATCA,
}: any) {
	const datos = JSON.stringify({
		OData__x00bf_Naci_x00f3_ustedenPuertoR: isBirthPlace,
		OData__x00bf_Esciudadanooresidentedelo: residentCitizen,
		OData__x00bf_Poseedirecci_x00f3_n_x201: holdMail,
		OData__x00bf_Poseedirecci_x00f3_n_x2010: careOf,
		OData__x00bf_Poseen_x00fa_merodetarjet: residencyCard,
		Numerodetarjetaderesidencia: residencyCardNo,
		OData__x00bf_Poseen_x00fa_merodeSeguri: socialSecurity,
		Numerodeseguridadsocial: socialSecurityNo,
		OData__x00bf_Poseedirecci_x00f3_nPOBox: poBoxAddress,
		OData__x00bf_Poseen_x00fa_merotelef_x0: usPhoneNumber,
		OData__x00bf_PoseeInstrucci_x00f3_npar: transferAvailability,
		OData__x00bf_Poseealg_x00fa_nrelaciona: germane,
		Pa_x00ed_sderesidenciafiscal: fiscalCountry,
		TaxIdentificationNumber: taxNo,
		FormularioIDId: formId,
		__metadata: { type: await getListMetadata('FP FATCA') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP FATCA')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP FATCA')/items/getbyid(${idDatosFATCA})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idDatosFATCA === undefined ? postQuery : updateQuery,
			datos,
			idDatosFATCA === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}

export async function getFPDatosFATCA(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP FATCA')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datosFATCA = {
		idDatosFATCA: data.d.results[0].ID,
		isBirthPlace: data.d.results[0]?.OData__x00bf_Naci_x00f3_ustedenPuertoR,
		residentCitizen: data.d.results[0]?.OData__x00bf_Esciudadanooresidentedelo,
		holdMail: data.d.results[0]?.OData__x00bf_Poseedirecci_x00f3_n_x201,
		careOf: data.d.results[0]?.OData__x00bf_Poseedirecci_x00f3_n_x2010,
		residencyCard: data.d.results[0]?.OData__x00bf_Poseen_x00fa_merodetarjet,
		residencyCardNo: data.d.results[0]?.Numerodetarjetaderesidencia,
		socialSecurity: data.d.results[0]?.OData__x00bf_Poseen_x00fa_merodeSeguri,
		socialSecurityNo: data.d.results[0]?.Numerodeseguridadsocial,
		poBoxAddress: data.d.results[0]?.OData__x00bf_Poseedirecci_x00f3_nPOBox,
		usPhoneNumber: data.d.results[0]?.OData__x00bf_Poseen_x00fa_merotelef_x0,
		transferAvailability: data.d.results[0]?.OData__x00bf_PoseeInstrucci_x00f3_npar,
		germane: data.d.results[0]?.OData__x00bf_Poseealg_x00fa_nrelaciona,
		fiscalCountry: data.d.results[0]?.Pa_x00ed_sderesidenciafiscal,
		taxNo: data.d.results[0]?.TaxIdentificationNumber,
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datosFATCA;
}

//post and update datos referencia bancaria
export async function postOrUpdateReferenciaBancaria({ formId, bankReferences = [] }: any) {
	const bankReferencesRequests = bankReferences?.map(
		async ({ bank, account, official, idDatosBankReferences }: any) => {
			const datos = JSON.stringify({
				Bancooempresa: bank,
				Tipodecuenta: account,
				Oficialdelacuentaopersonaenconta: official,
				FormularioIDId: formId,
				__metadata: { type: await getListMetadata('FP Referencia bancaria') },
			});

			//query
			const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Referencia bancaria')/items`;
			const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Referencia bancaria')/items/getbyid(${idDatosBankReferences})`;

			//config
			const postAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'IF-MATCH': '*',
				},
			};

			const updateAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'X-HTTP-Method': 'MERGE',
					'IF-MATCH': '*',
				},
			};

			//post or update
			await axios
				.post(
					idDatosBankReferences === undefined ? postQuery : updateQuery,
					datos,
					idDatosBankReferences === undefined ? postAxiosConfig : updateAxiosConfig
				)
				.then((response) => response.data)
				.catch((error) => console.error(error));
		}
	);
	return Promise.all(bankReferencesRequests);
}

export async function getFPDatosReferenciaBancaria(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Referencia bancaria')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datosBankReferences = data.d.results.map((bankReference: any) => ({
		idDatosBankReferences: bankReference.ID,
		bank: bankReference?.Bancooempresa,
		account: bankReference?.Tipodecuenta,
		official: bankReference?.Oficialdelacuentaopersonaenconta,
		formId: bankReference?.FormularioIDId,
	}));
	return { bankReferences: datosBankReferences };
}
//STEP 5 SERVICES
//post and update datos clientes con mayor riesgo
export async function postOrUpdateClientesMayorRiesgo({
	otherServices,
	whichServices,
	investmentAccounts,
	formId,
	idDatosClientesDeMayorRiesgo,
}: any) {
	const datos = JSON.stringify({
		OData__x00bf_Poseeotrosserviciosoprodu: otherServices,
		Cuales_x003f_: whichServices,
		Encasodeserextranjero_x002c_colo: investmentAccounts,
		FormularioIDId: formId,
		__metadata: { type: await getListMetadata('FP Clientes de mayor riesgo') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Clientes de mayor riesgo')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Clientes de mayor riesgo')/items/getbyid(${idDatosClientesDeMayorRiesgo})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idDatosClientesDeMayorRiesgo === undefined ? postQuery : updateQuery,
			datos,
			idDatosClientesDeMayorRiesgo === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}

export async function getFPDatosClientesDeMayorRiesgo(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Clientes de mayor riesgo')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datosClientesDeMayorRiesgo = {
		idDatosClientesDeMayorRiesgo: data.d.results[0].ID,
		otherServices: data.d.results[0]?.OData__x00bf_Poseeotrosserviciosoprodu,
		whichServices: data.d.results[0]?.Cuales_x003f_,
		investmentAccounts: data.d.results[0]?.Encasodeserextranjero_x002c_colo,
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datosClientesDeMayorRiesgo;
}

//post and updae datos personas autorizadas
export async function postOrUpdatePersonasAutorizadas({
	authName,
	idNoAuthPerson,
	actingRole,
	formId,
	idDatosPersonasAutorizadas,
}: any) {
	const datos = JSON.stringify({
		Nombre: authName,
		Numerodeidentificacion: idNoAuthPerson,
		Calidadenlacualactua: actingRole,
		FormularioIDId: formId,
		__metadata: { type: await getListMetadata('FP Personas autorizadas') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Personas autorizadas')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Personas autorizadas')/items/getbyid(${idDatosPersonasAutorizadas})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idDatosPersonasAutorizadas === undefined ? postQuery : updateQuery,
			datos,
			idDatosPersonasAutorizadas === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}

export async function getFPDatosPersonasAutorizadas(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Personas autorizadas')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datosPersonasAutorizadas = {
		idDatosPersonasAutorizadas: data.d.results[0]?.ID,
		authName: data.d.results[0]?.Nombre,
		idNoAuthPerson: data.d.results[0]?.Numerodeidentificacion,
		actingRole: data.d.results[0]?.Calidadenlacualactua,
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datosPersonasAutorizadas;
}
//STEP 6 SERVICES
//post and update datos inversionista
export async function postOrUpdateInfromacionRelativaInversionista({
	age,
	investorAcademicLevel,
	experienceLevel,
	stockTrading,
	holdTime,
	retirementYears,
	annualIncome,
	formId,
	idDatosInformacionInversionista,
}: any) {
	const datos = JSON.stringify({
		Edad: age?.value || null,
		Nivelacademico: investorAcademicLevel?.value || null,
		Experienciaenelmercadodevalores: experienceLevel?.value || null,
		Operacionesqeuharealizadocomoinv: stockTrading?.value || null,
		Cuantotiempoestadispuestoaespera: holdTime?.value || null,
		Encuantosa_x00f1_oplanearetirars: retirementYears?.value || null,
		Cualessonsusingresosanuales_x003: annualIncome?.value || null,
		FormularioIDId: formId,
		__metadata: {
			type: await getListMetadata('FP Informacion relativa inversionista'),
		},
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Informacion relativa inversionista')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Informacion relativa inversionista')/items/getbyid(${idDatosInformacionInversionista})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idDatosInformacionInversionista === undefined ? postQuery : updateQuery,
			datos,
			idDatosInformacionInversionista === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}

export async function getFPDatosInformacionInversionista(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Informacion relativa inversionista')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datosInformacionInversionista = {
		idDatosInformacionInversionista: data.d.results[0].ID,
		age: { value: data.d.results[0]?.Edad, label: data.d.results[0]?.Edad },
		investorAcademicLevel: data.d.results[0]?.Nivelacademico && {
			value: data.d.results[0]?.Nivelacademico,
			label: data.d.results[0]?.Nivelacademico,
		},
		experienceLevel: data.d.results[0]?.Experienciaenelmercadodevalores && {
			value: data.d.results[0]?.Experienciaenelmercadodevalores,
			label: data.d.results[0]?.Experienciaenelmercadodevalores,
		},
		stockTrading: data.d.results[0]?.Operacionesqeuharealizadocomoinv && {
			value: data.d.results[0]?.Operacionesqeuharealizadocomoinv,
			label: data.d.results[0]?.Operacionesqeuharealizadocomoinv,
		},
		holdTime: data.d.results[0]?.Cuantotiempoestadispuestoaespera && {
			value: data.d.results[0]?.Cuantotiempoestadispuestoaespera,
			label: data.d.results[0]?.Cuantotiempoestadispuestoaespera,
		},
		retirementYears: data.d.results[0]?.Encuantosa_x00f1_oplanearetirars && {
			value: data.d.results[0]?.Encuantosa_x00f1_oplanearetirars,
			label: data.d.results[0]?.Encuantosa_x00f1_oplanearetirars,
		},
		annualIncome: data.d.results[0]?.Cualessonsusingresosanuales_x003 && {
			value: data.d.results[0]?.Cualessonsusingresosanuales_x003,
			label: data.d.results[0]?.Cualessonsusingresosanuales_x003,
		},
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datosInformacionInversionista;
}

//STEP 7
//post and update datos cliente
export async function postOrUpdateInformacionRelativaCliente({
	incomeSource,
	totalOfLiquidAssets,
	equityVariation,
	formId,
	idDatosInformacionCliente,
}: any) {
	const datos = JSON.stringify({
		Comogeneraelcapitalquedeseainver: incomeSource?.value || null,
		Totaldeactivosliquidos: totalOfLiquidAssets?.value || null,
		Variaciondepatrimonioultimos2a_x: equityVariation?.value || null,
		FormularioIDId: formId,
		__metadata: {
			type: await getListMetadata('FP Informacion relativa al cliente'),
		},
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Informacion relativa al cliente')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Informacion relativa al cliente')/items/getbyid(${idDatosInformacionCliente})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idDatosInformacionCliente === undefined ? postQuery : updateQuery,
			datos,
			idDatosInformacionCliente === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}

export async function getFPDatosInformacionCliente(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Informacion relativa al cliente')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datosInformacionCliente = {
		idDatosInformacionCliente: data.d.results[0].ID,
		incomeSource: data.d.results[0]?.Comogeneraelcapitalquedeseainver && {
			value: data.d.results[0]?.Comogeneraelcapitalquedeseainver,
			label: data.d.results[0]?.Comogeneraelcapitalquedeseainver,
		},
		totalOfLiquidAssets: data.d.results[0]?.Totaldeactivosliquidos && {
			value: data.d.results[0]?.Totaldeactivosliquidos,
			label: data.d.results[0]?.Totaldeactivosliquidos,
		},
		equityVariation: data.d.results[0]?.Variaciondepatrimonioultimos2a_x && {
			value: data.d.results[0]?.Variaciondepatrimonioultimos2a_x,
			label: data.d.results[0]?.Variaciondepatrimonioultimos2a_x,
		},
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datosInformacionCliente;
}

//post and update objetivos financieros
export async function postOrUpdateObjetivosFinancieros({
	liquidAssetsDistribution,
	revenueDestination,
	investmentEquityPercentage,
	investmentObjective,
	capitalLossesAbility,
	formId,
	idDatosObjetivosFinancieros,
}: any) {
	const datos = JSON.stringify({
		Distribucionactivosliquidos: liquidAssetsDistribution?.value || null,
		Destinodelosingresosgeneradospor: revenueDestination?.value || null,
		Queporcentajedesupatrimoniototal: investmentEquityPercentage?.value || null,
		Cualessuprincipalobjetivodeinver: investmentObjective?.value || null,
		OData__x002c_Quecapacidaddeabsorberper: capitalLossesAbility?.value || null,
		FormularioIDId: formId,
		__metadata: { type: await getListMetadata('FP Objetivos financieros') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Objetivos financieros')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Objetivos financieros')/items/getbyid(${idDatosObjetivosFinancieros})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idDatosObjetivosFinancieros === undefined ? postQuery : updateQuery,
			datos,
			idDatosObjetivosFinancieros === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}

export async function getFPDatosObjetivosFinancieros(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FP Objetivos financieros')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datosObjetivosFinancieros = {
		idDatosObjetivosFinancieros: data.d.results[0].ID,
		liquidAssetsDistribution: data.d.results[0]?.Distribucionactivosliquidos && {
			value: data.d.results[0]?.Distribucionactivosliquidos,
			label: data.d.results[0]?.Distribucionactivosliquidos,
		},
		revenueDestination: data.d.results[0]?.Destinodelosingresosgeneradospor && {
			value: data.d.results[0]?.Destinodelosingresosgeneradospor,
			label: data.d.results[0]?.Destinodelosingresosgeneradospor,
		},
		investmentEquityPercentage: data.d.results[0]?.Queporcentajedesupatrimoniototal && {
			value: data.d.results[0]?.Queporcentajedesupatrimoniototal,
			label: data.d.results[0]?.Queporcentajedesupatrimoniototal,
		},
		investmentObjective: data.d.results[0]?.Cualessuprincipalobjetivodeinver && {
			value: data.d.results[0]?.Cualessuprincipalobjetivodeinver,
			label: fp_s7_principal_objetivo.filter(
				(v) => v.value === data.d.results[0]?.Cualessuprincipalobjetivodeinver
			)[0]?.label,
		},
		capitalLossesAbility: data.d.results[0]?.OData__x002c_Quecapacidaddeabsorberper && {
			value: data.d.results[0]?.OData__x002c_Quecapacidaddeabsorberper,
			label: data.d.results[0]?.OData__x002c_Quecapacidaddeabsorberper,
		},
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datosObjetivosFinancieros;
}
