import axios from 'axios';
import AppVariables from '../../Variables.json';
import OrgChartDto from './OrgChartDto';
import { SharePointConsts } from '../../util/constants';

async function fetchOrgChartData(filter: string): Promise<OrgChartDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Organigrama')/items${filter}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const nodes: OrgChartDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const node = new OrgChartDto();

		node.id = data.d.results[index].ID;
		node.rol = data.d.results[index].Rol;
		node.styled = data.d.results[index].Estilo;
		node.reportsTo = data.d.results[index].ReportaId;

		nodes.push(node);
	}

	return nodes;
}

export async function getOrgChartRoot(): Promise<OrgChartDto> {
	const filter = `?$filter=ReportaId eq null`;
	const data = await fetchOrgChartData(filter);
	return data.length > 0 ? data[0] : new OrgChartDto();
}

export async function getOrgChartNodes(): Promise<OrgChartDto[]> {
	const filter = `?$filter=ReportaId ne null&$orderby=Orden asc`;
	return fetchOrgChartData(filter);
}
