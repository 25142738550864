import PublicacionReadDto from '../../dtos/PublicacionReadDto';
import FileCard from '../publicaciones/FileCard';

interface IProps {
	docs: PublicacionReadDto[];
}

function MarcoRegulatorioDocSection({ docs }: IProps) {
	const renderMarcosRegulatorios = () => {
		return docs.map((doc) => (
			<FileCard key={doc.getId} title={doc.getTitle} date={doc.getDate} size={doc.getSize} url={doc.getUrl} />
		));
	};

	return (
		<section className="main-fc-documents">
			<h3 className="main-fc-documents__title mb-[25px]">Documentos</h3>
			{/* <div className="main-documents__file-container">
					{
                        renderMarcosRegulatorios()
                    }
				</div> */}
			<div className="file-section__file-container mb-[100px]">{renderMarcosRegulatorios()}</div>
		</section>
	);
}

export default MarcoRegulatorioDocSection;
