import axios from 'axios';
import AppVariables from '../Variables.json';
import ValorCuotaReadDto from '../dtos/ValorCuotaReadDto';
import { SharePointConsts } from '../util/constants';

async function fetchData(url: string): Promise<any> {
	try {
		const response = await axios.get(url, {
			headers: {
				'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
					.value,
				Accept: SharePointConsts.acceptVerbose,
			},
		});
		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

function createValorCuotaReadDto(item: any): ValorCuotaReadDto {
	const valorCuota = new ValorCuotaReadDto();
	valorCuota.id = item.ID;
	valorCuota.valorCuota = item.Valorcuota;
	valorCuota.fecha = item.Fecha;
	valorCuota.fundTitle = item.Fondo.Title;
	valorCuota.fundId = item.FondoId;
	return valorCuota;
}

export async function getAllCuotasByfundTitle(title: string): Promise<ValorCuotaReadDto[]> {
	let url = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Valor Cuota')/items?$select=*,Fondo/Title&$expand=Fondo&$filter=Fondo/Title eq '${title}'&$orderby=Fecha desc&$top=1000`;
	let response: ValorCuotaReadDto[] = [];

	while (url) {
		const data = await fetchData(url);
		if (data && data.d.results.length) {
			const fetchedValues = data.d.results.map(createValorCuotaReadDto);
			response = [...response, ...fetchedValues];
			url = data.d.__next || null;
		} else {
			break;
		}
	}

	return response;
}

export async function getCuotaByfundTitleAndDate(
	title: string,
	date: string
): Promise<ValorCuotaReadDto> {
	const url = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Valor Cuota')/items?$select=*,Fondo/Title&$expand=Fondo&$filter=(Fondo/Title eq '${title}') and (Fecha eq '${date}')`;
	const data = await fetchData(url);

	return data && data.d.results.length
		? createValorCuotaReadDto(data.d.results[0])
		: new ValorCuotaReadDto();
}

export async function getLastValorCuota(title: string): Promise<ValorCuotaReadDto> {
	const url = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Valor Cuota')/items?$select=*,Fondo/Title&$expand=Fondo&$filter=Fondo/Title eq '${title}'&$orderby=Fecha desc`;
	const data = await fetchData(url);

	return data && data.d.results.length
		? createValorCuotaReadDto(data.d.results[0])
		: new ValorCuotaReadDto();
}

export async function getValidFunds(): Promise<string[]> {
	const url = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Valor Cuota')/items?$select=Fondo/Title&$expand=Fondo`;
	const data = await fetchData(url);

	return data ? Array.from(new Set(data.d.results.map((item: any) => item.Fondo.Title))) : [];
}
