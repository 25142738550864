import React from 'react';
import { useState, useEffect } from 'react';
import FileCard from './FileCard';
import {
	getDocAttachmentFileById,
	getDocSizeByUrl,
	getDocumentsByCategory,
	getLastDocsByCategory,
	searchDocByCategory,
} from '../../services/PublicacionesServices';
import PublicacionReadDto from '../../dtos/PublicacionReadDto';
import Media from 'react-media';
import MobileFileCard from './MobileFileCard';

interface IProp {
	expression?: string;
}

function FileSection(prop: IProp) {
	const hechosRelevantesText = 'Hechos Relevantes';
	const informesGestionAnuelText = 'Informes de Gestion Anual';
	const estadosFinancierosText = 'Estados financieros';
	const calificacionesRiesgoText = 'Calificaciones de riesgo';
	const maxWidth = '(max-width:768px)';
	const minWidth = '(min-width:769px)';

	const fileSectionClasses = {
		active: "file-section__file-choser--button-active",
		inactive: "file-section__file-choser--button"
	};

	const [fileSection, setFileSection] = useState(hechosRelevantesText);
	const [hechosRelevantes, setHechosRelevantes] = useState<PublicacionReadDto[]>([]);
	const [gestionAnual, setGestionAnual] = useState<PublicacionReadDto[]>([]);
	const [estadosFinancieros, setEstadosFinancieros] = useState<PublicacionReadDto[]>([]);
	const [calificacionRiesgo, setCalificacionRiesgo] = useState<PublicacionReadDto[]>([]);
	const [numofResult, setNumOfResult] = useState(0);
	const [loadMore, setLoadMore] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			//retreive docs by category
			const data = await getLastDocsByCategory(fileSection, 10); //ese numero en realidad debe ser 10
			setNumOfResult(data.length);
			setLoadMore(true);
			//adding file attach url
			for (let index = 0; index < data.length; index++) {
				const fileInfo = await getDocAttachmentFileById(data[index].getId);
				data[index].setUrl = fileInfo.url;
				data[index].setFileName = fileInfo.filename;
			}
			//adding size to every item
			for (let index = 0; index < data.length; index++) {
				const size = await getDocSizeByUrl(data[index].getUrl);
				data[index].setSize = size;
			}

			switch (fileSection) {
				case hechosRelevantesText:
					setHechosRelevantes(data);
					break;
				case informesGestionAnuelText:
					setGestionAnual(data);
					break;
				case estadosFinancierosText:
					setEstadosFinancieros(data);
					break;
				case calificacionesRiesgoText:
					setCalificacionRiesgo(data);
					break;
			}
		};

		const searchDoc = async () => {
			//retreive docs by category
			const data = await searchDocByCategory(fileSection, prop.expression);
			setNumOfResult(data.length);
			setLoadMore(true);
			//adding file attach url
			for (let index = 0; index < data.length; index++) {
				const fileInfo = await getDocAttachmentFileById(data[index].getId);
				data[index].setUrl = fileInfo.url;
				data[index].setFileName = fileInfo.filename;
			}
			//adding size to every item
			for (let index = 0; index < data.length; index++) {
				const size = await getDocSizeByUrl(data[index].getUrl);
				data[index].setSize = size;
			}

			switch (fileSection) {
				case hechosRelevantesText:
					setHechosRelevantes(data);
					break;
				case informesGestionAnuelText:
					setGestionAnual(data);
					break;
				case estadosFinancierosText:
					setEstadosFinancieros(data);
					break;
				case calificacionesRiesgoText:
					setCalificacionRiesgo(data);
					break;
			}
		};

		if (prop.expression !== null && prop.expression !== '') {
			searchDoc();
		} else {
			fetchData();
		}
	}, [fileSection, prop.expression]);

	function renderCards() {

		switch (fileSection) {
			case hechosRelevantesText:
				return hechosRelevantes.map((c) => (

					<Media queries={{ mobile: maxWidth, desktop: minWidth }}>
						{(matches) => (
							<React.Fragment>
								{matches.mobile && (
									<MobileFileCard
										title={c.getTitle}
										date={c.getDate}
										size={c.getSize}
										url={c.getUrl}
										filename={c.getFileName}
									/>
								)}
								{matches.desktop && (
									<FileCard
										title={c.getTitle}
										date={c.getDate}
										size={c.getSize}
										url={c.getUrl}
									/>
								)}
							</React.Fragment>
						)}
					</Media>
				));
			case informesGestionAnuelText:
				return gestionAnual.map((c) => (
					<Media queries={{ mobile: maxWidth, desktop: minWidth }}>
						{(matches) => (
							<React.Fragment>
								{matches.mobile && (
									<MobileFileCard
										title={c.getTitle}
										date={c.getDate}
										size={c.getSize}
										url={c.getUrl}
										filename={c.getFileName}
									/>
								)}
								{matches.desktop && (
									<FileCard
										title={c.getTitle}
										date={c.getDate}
										size={c.getSize}
										url={c.getUrl}
									/>
								)}
							</React.Fragment>
						)}
					</Media>
				));
			case estadosFinancierosText:
				return estadosFinancieros.map((c) => (
					<Media queries={{ mobile: maxWidth, desktop: minWidth }}>
						{(matches) => (
							<React.Fragment>
								{matches.mobile && (
									<MobileFileCard
										title={c.getTitle}
										date={c.getDate}
										size={c.getSize}
										url={c.getUrl}
										filename={c.getFileName}
									/>
								)}
								{matches.desktop && (
									<FileCard
										title={c.getTitle}
										date={c.getDate}
										size={c.getSize}
										url={c.getUrl}
									/>
								)}
							</React.Fragment>
						)}
					</Media>
				));
			case calificacionesRiesgoText:
				return calificacionRiesgo.map((c) => (
					<Media queries={{ mobile: maxWidth, desktop: minWidth }}>
						{(matches) => (
							<React.Fragment>
								{matches.mobile && (
									<MobileFileCard
										title={c.getTitle}
										date={c.getDate}
										size={c.getSize}
										url={c.getUrl}
										filename={c.getFileName}
									/>
								)}
								{matches.desktop && (
									<FileCard
										title={c.getTitle}
										date={c.getDate}
										size={c.getSize}
										url={c.getUrl}
									/>
								)}
							</React.Fragment>
						)}
					</Media>
				));
		}
	}

	function loadAllDocs() {
		const fetchData = async () => {
			//retreive docs by category
			const data = await getDocumentsByCategory(fileSection);
			//adding file attach url
			for (let index = 0; index < data.length; index++) {
				const fileInfo = await getDocAttachmentFileById(data[index].getId);
				data[index].setUrl = fileInfo.url;
				data[index].setFileName = fileInfo.filename;
			}
			//adding size to every item
			for (let index = 0; index < data.length; index++) {
				const size = await getDocSizeByUrl(data[index].getUrl);
				data[index].setSize = size;
			}

			switch (fileSection) {
				case hechosRelevantesText:
					setHechosRelevantes(data);
					break;
				case informesGestionAnuelText:
					setGestionAnual(data);
					break;
				case estadosFinancierosText:
					setEstadosFinancieros(data);
					break;
				case calificacionesRiesgoText:
					setCalificacionRiesgo(data);
					break;
			}
		};

		fetchData();
	}

	return (
		<div className="file-section">
			<div className="file-section__file-choser">
				<div
					onClick={() => {
						setFileSection(hechosRelevantesText);

					}}
					className={fileSection === hechosRelevantesText ? fileSectionClasses.active : fileSectionClasses.inactive}
				>
					Hechos relevantes
				</div>
				<div
					onClick={() => {
						setFileSection(informesGestionAnuelText);

					}}
					className={fileSection === informesGestionAnuelText ? fileSectionClasses.active : fileSectionClasses.inactive}
				>
					Informes de Gestión Anual
				</div>
				<div
					onClick={() => {
						setFileSection(estadosFinancierosText);

					}}
					className={fileSection === estadosFinancierosText ? fileSectionClasses.active : fileSectionClasses.inactive}
				>
					Estados financieros
				</div>
				<div
					onClick={() => {
						setFileSection(calificacionesRiesgoText);

					}}
					className={fileSection === calificacionesRiesgoText ? fileSectionClasses.active : fileSectionClasses.inactive}
				>
					Calificaciones de riesgo
				</div>
			</div>
			<p className="search-counter">
				{prop.expression !== '' ? (
					<>
						{renderCards().length} resultados para <b>“{prop.expression}”</b>
					</>
				) : (
					<></>
				)}
			</p>
			<div className="file-section__file-container">{renderCards()}</div>
			<div className="file-section__load">
				{numofResult >= 10 && loadMore ? (
					<>
						<div
							className="file-section__load--button"
							role="button"
							onClick={() => { loadAllDocs(); setLoadMore(false) }}
						>
							Cargar más
						</div>
					</>
				) : (
					<></>
				)}
			</div>
		</div>
	);
}

export default FileSection;
