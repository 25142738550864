import axios from 'axios';
import AppVariables from '../Variables.json';
import PublicacionReadDto from '../dtos/PublicacionReadDto';

const headersAccept = 'application/json; odata=verbose';

export async function getDocumentsByCategory(category: string): Promise<PublicacionReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Publicaciones')/items?$filter= Categoria eq '${category}'&$orderby=Orden desc&$orderby=Fecha desc`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response: PublicacionReadDto[] = [];
	for (let index = 0; index < data.d.results.length; index++) {
		const publicacion = new PublicacionReadDto(
			data.d.results[index].ID,
			data.d.results[index].Title
		);
		publicacion.setDate = data.d.results[index].Fecha;
		response[index] = publicacion;
	}

	return response;
}

export async function getLastDocsByCategory(
	category: string,
	numOfCards: number
): Promise<PublicacionReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Publicaciones')/items?$filter=(Categoria eq '${category}')&$orderby=Orden desc&$orderby=Fecha desc&$top=${numOfCards}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response: PublicacionReadDto[] = [];
	for (let index = 0; index < data.d.results.length; index++) {
		const publicacion = new PublicacionReadDto(
			data.d.results[index].ID,
			data.d.results[index].Title
		);
		publicacion.setDate = data.d.results[index].Fecha;
		response[index] = publicacion;
	}

	return response;
}

export async function getDocAttachmentFileById(id: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Publicaciones')/items(${id})?$expand=AttachmentFiles`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response = {
		url: data.d.AttachmentFiles.results[0].ServerRelativeUrl,
		filename: data.d.AttachmentFiles.results[0].FileName,
	};

	return response;
}

export async function getDocSizeByUrl(url: string) {
	const data = await axios
		.get(`${AppVariables.SITE_COLLECTION_URL}_api/web/getfilebyserverrelativeurl('${url}')`, {
			headers: {
				'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
					.value,
				Accept: headersAccept,
			},
		})
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response = data.d.Length;

	return response;
}

export async function searchDocByCategory(
	category: string,
	expression: string
): Promise<PublicacionReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Publicaciones')/items?$filter=(Categoria eq '${category}' and substringof('${expression}',Title))`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response: PublicacionReadDto[] = [];
	for (let index = 0; index < data.d.results.length; index++) {
		const publicacion = new PublicacionReadDto(
			data.d.results[index].ID,
			data.d.results[index].Title
		);
		publicacion.setDate = data.d.results[index].Fecha;
		response[index] = publicacion;
	}

	return response;
}
