import { Helmet } from 'react-helmet';
import PrensaDetailContent from './PrensaDetailContent';
import PrensaDetailImage from './PrensaDetailImage';
import PrensaDetailTop from './PrensaDetailTop';

interface IProps {
	date: string;
	title: string;
	category: string;
	imgUrl: string;
	html: string;
	zoom: boolean;
	setZoom: React.Dispatch<React.SetStateAction<boolean>>;
}

function PrensaDetail(props: IProps) {
	return (
			<section className="prensa-detail pt-[120px]">
				<PrensaDetailTop date={props.date} title={props.title} category={props.category} />
				<PrensaDetailImage imgUrl={props.imgUrl} setZoom={props.setZoom} zoom={props.zoom} />
				<PrensaDetailContent html={props.html} zoom={props.zoom} />
			</section>
	);
}

export default PrensaDetail;
