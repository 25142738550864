import axios from 'axios';
import AppVariables from '../Variables.json';
import PublicacionReadDto from '../dtos/PublicacionReadDto';
import { getDocSizeByUrl } from './PublicacionesServices';

export async function getAllMarcoRegulatorio(): Promise<PublicacionReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Marco regulatorio')/items?$expand=AttachmentFiles&$filter=Estado eq 'Activo'&$orderby=Orden desc&$orderby=Fechadepublicacion desc`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: 'application/json; odata=verbose',
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response: PublicacionReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const publicacion = new PublicacionReadDto(
			data.d.results[index].ID,
			data.d.results[index].Title
		);

		publicacion.setDate = data.d.results[index].Fechadepublicacion;
		publicacion.setUrl = data.d.results[index].AttachmentFiles.results.length
			? data.d.results[index].AttachmentFiles.results[0].ServerRelativeUrl
			: '';
		publicacion.setFileName = data.d.results[index].AttachmentFiles.results[0].FileName;

		const size = await getDocSizeByUrl(publicacion.getUrl);

		publicacion.setSize = size;

		response[index] = publicacion;
	}

	return response;
}
