import axios from 'axios';
import AppVariables from '../../Variables.json';
import NewslettersDto from '../../dtos/NewslettersDto';

export async function getNewsletters(): Promise<NewslettersDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Boletines')/items?$expand=File&$filter=Estado eq 'Activo'&$orderby=Orden desc&$orderby=FechaPublicacion desc`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: 'application/json; odata=verbose',
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const newsletters: NewslettersDto[] = [];

	data.d.results.forEach(
		(element: {
			UrlPortada?: any;
			File: any;
			Orden: number;
			ID: number;
			FechaPublicacion: string;
			Descripcion: string;
		}) => {
			const newsletter = new NewslettersDto();
			newsletter.publishDate = element.FechaPublicacion?.split('T')[0];
			newsletter.ID = element.ID;
			newsletter.Order = element.Orden;
			newsletter.thumbnail = element.UrlPortada === null ? '' : element.UrlPortada.Url;
			newsletter.thumbnailName =
				element.UrlPortada === null ? '' : element.UrlPortada.Description;

			newsletter.downloadLink =
				window.location.protocol +
				'//' +
				window.location.host +
				element.File.ServerRelativeUrl.replaceAll(' ', '%20');

			newsletter.shortDescription = element.Descripcion;

			newsletters.push(newsletter);
		}
	);

	return newsletters;
}
