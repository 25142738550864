import AboutUsMvvReadDto from "../../dtos/AboutUsMvvReadDto";

interface IProps {
  mvvInfo: AboutUsMvvReadDto;
}

function MvvSection(props: IProps) {
  return (
    <div className="mvv-section">
      <div className="mvv-section__container">
        <div>
          <h1 className="mvv-section__title">Misión</h1>
          <p className="mvv-section__content">{props.mvvInfo.mision}</p>
        </div>
        <div>
          <h1 className="mvv-section__title">Visión</h1>
          <p className="mvv-section__content">{props.mvvInfo.vision}</p>
        </div>
      </div>
      <h1 className="mvv-section__title">Valores</h1>
      <div
        className="valores"
        dangerouslySetInnerHTML={{ __html: props.mvvInfo.values }}
      ></div>
    </div>
  );
}

export default MvvSection;
