import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';

type Iprops = {
	title: string;
	message?: string;
	link: string;
	close: () => void;
};
const FormSent = ({ title, message, link, close }: Iprops) => {
	const navigate = useNavigate();
	return (
		<div className="h-18 bg-white drop-shadow-2xl flex flex-col p-10 items-center justify-center">
			<div className="text-[28px] renaudar-title mb-6 text-center">{title}</div>
			{message && <p className="renaudar-text mb-[40px] text-center">{message}</p>}
			<div className="flex items-center lg:gap-y-0 lg:w-[300px] mb-4 lg:mb-0">
				<button
					type="button"
					className="global-button global-button--full w-1/2"
					onClick={() => {
						navigate(`${AppVariables.REACT_ROUTER_URL}/${link}`);
						close();
					}}
				>
					Continuar
				</button>
			</div>
		</div>
	);
};

export default FormSent;
