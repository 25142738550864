import SearchResultDto from "../../dtos/SearchResultDto";
import ResultCard from "./ResultCard";

interface IProps {
	results: SearchResultDto[];
	currentExpression: string;
	setSearchParam:any;
}

function ResultContainer({results,currentExpression,setSearchParam}:IProps) {
    return (
			<div className="container mx-auto">
				<div className="lista-preguntas">
					<p className="search-counter">
						{results.length === 0 ? (
							<>0 resultados</>
						) : (
							<>
								{results.length} resultados para <b>“{currentExpression}”</b>
							</>
						)}
					</p>
					{results.map((result) => (
						<ResultCard result={result} setSearchParam={setSearchParam} currentExpression={currentExpression} />
					))}
				</div>
			</div>
		);
}

export default ResultContainer;