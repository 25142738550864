const defaultOperationsOptions = {
  certificadosInversionBCRP: "Certificados de Inversión del BCRD",
  bonosEmpresasPrivadas: "Bonos de Empresas Privadas",
  forwardOperacionesPlazo: "Forwards(Operaciones a Plazo)",
  cuotasFondosCerrados: "Cuotas de Fondos Cerrados",
  valoresFideicomisoOfertasPublicas: "Valores de Fideicomiso de Ofertas Públicas",
  certificadosFinancieros: "Certificados Financieros",
  cuotasFondosInversionAbiertos: "Cuotas Fondos de Inversión Abiertos",
  mutuoTituloValores: "Mutuo Titulo Valores",
  bonosMinisteriosHacienda: "Bonos de Ministerios de Hacienda",
  opcionesFuturosTitulosValores: "Opciones y/o Futuros de Títulos Valores",
  cuentasAhorroCorriente: "Cuenta de Ahorro/Corriente"
};
const defaultAcademicLevels = {
  primariaSecundaria: "Primaria/Secundaria",
  universitario: "Universitario",
  postgrado: "Postgrado"
}
const defaultRelationships = {
  cliente: "Cliente",
  personaRelacionada: "Persona relacionada",
  familiarInmediato: "Familiar inmediato"
}
const defaultExperienceLevels = {
  alta: "Alto o excelente",
  media: "Media o limitada",
  baja: "Baja o ninguna"
}
const defaultTimes = {
  menosDeUno: "Menos de 1 año",
  entreUnoYCinco: "Entre 1 y 5 años",
  masDeCinco: "Más de 5 años"
}
const defaultInvestmentGoals = {
  reinvertirTodo: "Reinvertir todo"
}
const defaultPercentages = {
  menosDeDiez: "Menos de un 10%",
  entreDiezYCuarenta: "Entre un 10% y un 40%",
  masDeCuarenta: "Más de un 40%",
}
const defaultCapacities = {
  masDeQuince: "Más del 15%",
  deSeisAQuince: "De 6% a 15%",
  deCeroACinco: "De 0% a 5%",
}

export const fe_s7_operaciones_bursatiles = [
  {
    label: defaultOperationsOptions.certificadosInversionBCRP,
    value: defaultOperationsOptions.certificadosInversionBCRP,
  },
  {
    label: defaultOperationsOptions.bonosEmpresasPrivadas,
    value: defaultOperationsOptions.bonosEmpresasPrivadas,
  },
  {
    label: defaultOperationsOptions.forwardOperacionesPlazo,
    value: defaultOperationsOptions.forwardOperacionesPlazo,
  },
  {
    label: defaultOperationsOptions.cuotasFondosCerrados,
    value: defaultOperationsOptions.cuotasFondosCerrados,
  },
  {
    label: defaultOperationsOptions.valoresFideicomisoOfertasPublicas,
    value: defaultOperationsOptions.valoresFideicomisoOfertasPublicas,
  },
  {
    label: defaultOperationsOptions.certificadosFinancieros,
    value: defaultOperationsOptions.certificadosFinancieros,
  },
  {
    label: defaultOperationsOptions.cuotasFondosInversionAbiertos,
    value: defaultOperationsOptions.cuotasFondosInversionAbiertos,
  },
  {
    label: defaultOperationsOptions.mutuoTituloValores,
    value: defaultOperationsOptions.mutuoTituloValores,
  },
  {
    label: defaultOperationsOptions.bonosMinisteriosHacienda,
    value: defaultOperationsOptions.bonosMinisteriosHacienda,
  },
  {
    label: "Acciones",
    value: "Acciones",
  },
  {
    label: defaultOperationsOptions.opcionesFuturosTitulosValores,
    value: defaultOperationsOptions.opcionesFuturosTitulosValores,
  },
  {
    label: defaultOperationsOptions.cuentasAhorroCorriente,
    value: defaultOperationsOptions.cuentasAhorroCorriente,
  },
  {
    label: "Derivados",
    value: "Derivados",
  },
];

export const fe_s7_beneficios_anuales = [
  {
    label: "Menos de RD$10,000,000",
    value: "Menos de RD$10,000,000",
  },
  {
    label: "RD$10,000,001-RD$20,000,000",
    value: "RD$10,000,001-RD$20,000,000",
  },
  {
    label: "Más de RD$20,000,001",
    value: "Más de RD$20,000,001",
  },
];

export const fp_s1_estado_civil = [
  { label: "Soltero", value: "Soltero" },
  { label: "Casado", value: "Casado" },
  { label: "Divorciado", value: "Divorciado" },
];
export const fp_s1_nivel_academico = [
  { label: defaultAcademicLevels.primariaSecundaria, value: defaultAcademicLevels.primariaSecundaria },
  { label: defaultAcademicLevels.universitario, value: defaultAcademicLevels.universitario },
  { label: defaultAcademicLevels.postgrado, value: defaultAcademicLevels.postgrado },
  { label: "Maestría", value: "Maestría" },
];
export const fp_s2_ocupacion = [
  { label: "Empleado a tiempo completo", value: "Empleado a tiempo completo" },
  { label: "Pensionado", value: "Pensionado" },
  { label: "Empleado a tiempo parcial", value: "Empleado a tiempo parcial" },
  { label: "Comerciantes", value: "Comerciantes" },
  { label: "Desempleado", value: "Desempleado" },
  { label: "Ama de Casa", value: "Ama de Casa" },
  { label: "Retirado", value: "Retirado" },
  { label: "Estudiante", value: "Estudiante" },
  { label: "Independiente", value: "Independiente" },
  { label: "Otro", value: "Otro" },
];
export const fp_s3_relacion_intermediario = [
  { label: defaultRelationships.cliente, value: defaultRelationships.cliente },
  { label: defaultRelationships.personaRelacionada, value: defaultRelationships.personaRelacionada },
  { label: defaultRelationships.familiarInmediato, value: defaultRelationships.familiarInmediato },
  { label: "otro", value: "otro" },
];
export const fp_s6_edad = [
  { label: "Menos de 40 años", value: "Menos de 40 años" },
  { label: "Entre 40 y 55 años", value: "Entre 40 y 55 años" },
  { label: "Más de 56 años", value: "Más de 56 años" },
];
export const fp_s6_nivel_academico = [
  { label: "Maestría/Posgrado", value: "Maestría/Posgrado" },
  { label: defaultAcademicLevels.universitario, value: defaultAcademicLevels.universitario },
  { label: defaultAcademicLevels.primariaSecundaria, value: defaultAcademicLevels.primariaSecundaria },
];
export const fp_s6_nivel_experiencia = [
  { label: defaultExperienceLevels.alta, value: defaultExperienceLevels.alta },
  { label: defaultExperienceLevels.media, value: defaultExperienceLevels.media },
  { label: defaultExperienceLevels.baja, value: defaultExperienceLevels.baja },
];
export const fp_s6_operaciones_bursatiles = [
  {
    label: defaultOperationsOptions.certificadosInversionBCRP,
    value: defaultOperationsOptions.certificadosInversionBCRP,
  },
  { label: defaultOperationsOptions.bonosEmpresasPrivadas, value: defaultOperationsOptions.bonosEmpresasPrivadas },
  { label: defaultOperationsOptions.cuotasFondosCerrados, value: defaultOperationsOptions.cuotasFondosCerrados },
  {
    label: defaultOperationsOptions.valoresFideicomisoOfertasPublicas,
    value: defaultOperationsOptions.valoresFideicomisoOfertasPublicas,
  },
  { label: defaultOperationsOptions.certificadosFinancieros, value: defaultOperationsOptions.certificadosFinancieros },
  {
    label: defaultOperationsOptions.cuotasFondosInversionAbiertos,
    value: defaultOperationsOptions.cuotasFondosInversionAbiertos,
  },
  { label: defaultOperationsOptions.mutuoTituloValores, value: defaultOperationsOptions.mutuoTituloValores },
  {
    label: defaultOperationsOptions.bonosMinisteriosHacienda,
    value: defaultOperationsOptions.bonosMinisteriosHacienda,
  },
  { label: "Acciones", value: "Acciones" },
  {
    label: defaultOperationsOptions.opcionesFuturosTitulosValores,
    value: defaultOperationsOptions.opcionesFuturosTitulosValores,
  },
  { label: defaultOperationsOptions.cuentasAhorroCorriente, value: defaultOperationsOptions.cuentasAhorroCorriente },
  {
    label: defaultOperationsOptions.forwardOperacionesPlazo,
    value: defaultOperationsOptions.forwardOperacionesPlazo,
  },
];
export const fp_s6_tiempo_espera = [
  { label: defaultTimes.menosDeUno, value: defaultTimes.menosDeUno, },
  { label: defaultTimes.entreUnoYCinco, value: defaultTimes.entreUnoYCinco },
  { label: defaultTimes.masDeCinco, value: defaultTimes.masDeCinco },
];
export const fp_s6_tiempo_retiro = [
  {
    label: "En más de 16 años",
    value: "En más de 16 años",
  },
  { label: "Entre 5 y 15 años", value: "Entre 5 y 15 años" },
  { label: "En menos de 5 años", value: "En menos de 5 años" },
];
export const fp_s6_ingresos_anuales = [
  {
    label: "Menos de RD$ 1,500,00",
    value: "Menos de RD$ 1,500,00",
  },
  {
    label: "Entre RD$1,500,001 y RD$5,000,000",
    value: "Entre RD$1,500,001 y RD$5,000,000",
  },
  { label: "Más de RD$ 5,000,001", value: "Más de RD$ 5,000,001" },
];
export const fp_s7_fuente_ingresos = [
  {
    label: "Mediante su trabajo",
    value: "Mediante su trabajo",
  },
  {
    label: "De otras rentas no relacionadas",
    value: "De otras rentas no relacionadas",
  },
];
export const fp_s7_total_activos_liquidos = [
  {
    label: "Menos de RD$ 350,000",
    value: "Menos de RD$ 350,000",
  },
  {
    label: "RD$ 350,001-RD$5,000,001",
    value: "RD$ 350,001-RD$5,000,001",
  },
  {
    label: "Más de RG$ 5,000,001",
    value: "Más de RG$ 5,000,001",
  },
];
export const fp_s7_total_variacion_patrimonio = [
  {
    label: "Mi Patrimonio ha sufrido una variación",
    value: "Mi Patrimonio ha sufrido una variación",
  },
  {
    label: "Mi Patrimonio ha sufrido una variación razonable",
    value: "Mi Patrimonio ha sufrido una variación razonable",
  },
  {
    label: "Mi Patrimonio no ha sufrido una variación",
    value: "Mi Patrimonio no ha sufrido una variación",
  },
];
export const fp_s7_distribucion_activos_liquidos = [
  {
    label: "Acciones",
    value: "Acciones",
  },
  {
    label: "Fondos Mutuos o Abiertos",
    value: "Fondos Mutuos o Abiertos",
  },
  {
    label: "Préstamos de Títulos (mutuos) o préstamos de margen",
    value: "Préstamos de Títulos (mutuos) o préstamos de margen",
  },
  {
    label: "Fondos Cerrados",
    value: "Fondos Cerrados",
  },
  {
    label: "Cuenta Corriente",
    value: "Cuenta Corriente",
  },
  {
    label: defaultOperationsOptions.certificadosFinancieros,
    value: defaultOperationsOptions.certificadosFinancieros,
  },
  {
    label: "Cuentas de Ahorros",
    value: "Cuentas de Ahorros",
  },
];
export const fp_s7_destino_ingresos_generados = [
  {
    label: defaultInvestmentGoals.reinvertirTodo,
    value: defaultInvestmentGoals.reinvertirTodo,
  },
  {
    label: "Utilizar una parte y reinvertir otra",
    value: "Utilizar una parte y reinvertir otra",
  },
  {
    label: "Vivir de ellos",
    value: "Vivir de ellos",
  },
];
export const fp_s7_porcentaje_patrimonio_total = [
  { label: defaultPercentages.menosDeDiez, value: defaultPercentages.menosDeDiez, },
  { label: defaultPercentages.entreDiezYCuarenta, value: defaultPercentages.entreDiezYCuarenta, },
  { label: defaultPercentages.masDeCuarenta, value: defaultPercentages.masDeCuarenta, },
];
export const fp_s7_principal_objetivo = [
  {
    label:
      "Rentabilidad: Busca un rendimiento a largo plazo,combina instrumentos tomando mayores riesgos",
    value: "Rentabilidad",
  },
  {
    label:
      "Seguridad: Crecimiento sostenido de su capital. Acepta riesgos moderados, esperando obtener retornos estables",
    value: "Seguridad",
  },
  {
    label:
      "Mantener el capital: El objetivo de inversión del cliente es a largo plazo, busca conservar su capital con la menor volatilidad posible y riesgo",
    value: "Mantener el capital",
  },
];

export const fp_s7_capacidad_absorber_perdidas = [
  {
    label: defaultCapacities.masDeQuince,
    value: defaultCapacities.masDeQuince,
  },
  {
    label: defaultCapacities.deSeisAQuince,
    value: defaultCapacities.deSeisAQuince,
  },
  {
    label: defaultCapacities.deCeroACinco,
    value: defaultCapacities.deCeroACinco,
  },
];

export const fe_s3_tipo_socio = [
  {
    label: "Socio",
    value: "Socio",
  },
  {
    label: "Miembro",
    value: "Miembro",
  },
  {
    label: "Director",
    value: "Director",
  },
];
export const fe_s5_societario = [
  {
    label: "Corporación",
    value: "Corporación",
  },
  {
    label: "Fideicomiso",
    value: "Fideicomiso",
  },
  {
    label: "Patrimonio",
    value: "Patrimonio",
  },
  {
    label: "Entidad gubernamental",
    value: "Entidad gubernamental",
  },
  {
    label: "Fundación",
    value: "Fundación",
  },
  {
    label: "Organización exenta de impuestos",
    value: "Organización exenta de impuestos",
  },
  {
    label: "Entidad no conciderada",
    value: "Entidad no conciderada",
  },
  {
    label: "Fideicomiso complejo",
    value: "Fideicomiso complejo",
  },
  {
    label: "Banco central",
    value: "Banco central",
  },
  {
    label: "Sociedad",
    value: "Sociedad",
  },
  {
    label: "Grantor trust",
    value: "Grantor trust",
  },
];
export const fe_s5_asociacion_sin_fines_lucro = [
  {
    label:
      "Soy una asociación sin fines de lucro constituida fuera de los Estados Unidos",
    value: "opcion1",
  },
  {
    label: "Soy una asociación sin fines de lucro exenta en los Estados Unidos",
    value: "opcion2",
  },
  {
    label: "Soy una asociación de lucro constituida en los Estados Unidos",
    value: "opcion3",
  },
];
export const fe_s5_formularios = [
  {
    label: "formulario W-8ECI",
    value: "formulario W-8ECI",
  },
  {
    label: "formulario W-8IMY",
    value: "formulario W-8IMY",
  },
  {
    label: "formulario W-8EXP",
    value: "formulario W-8EXP",
  },
  {
    label: "No aplica",
    value: "No aplica",
  },
];
export const fe_s5_pais_residencia = [
  { value: "AF", label: "Afganistán" },
  { value: "AX", label: "Islas Aland" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Argelia" },
  { value: "AS", label: "Samoa Americana" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguila" },
  { value: "AQ", label: "Antártida" },
  { value: "AG", label: "Antigua y Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaiyán" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahréin" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Bielorrusia" },
  { value: "BE", label: "Bélgica" },
  { value: "BZ", label: "Belice" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "islas Bermudas" },
  { value: "BT", label: "Bután" },
  { value: "BO", label: "Bolivia" },
  { value: "BQ", label: "Bonaire, Sint Eustatius y Saba" },
  { value: "BA", label: "Bosnia y Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Isla Bouvet" },
  { value: "BR", label: "Brasil" },
  { value: "IO", label: "Territorio Británico del Océano Índico" },
  { value: "BN", label: "Brunei Darussalam" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Camboya" },
  { value: "CM", label: "Camerún" },
  { value: "CA", label: "Canadá" },
  { value: "CV", label: "Cabo Verde" },
  { value: "KY", label: "Islas Caimán" },
  { value: "CF", label: "República Centroafricana" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "porcelana" },
  { value: "CX", label: "Isla de Navidad" },
  { value: "CC", label: "Islas Cocos (Keeling)" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoras" },
  { value: "CG", label: "Congo" },
  { value: "CD", label: "Congo, República Democrática del Congo" },
  { value: "CK", label: "Islas Cook" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "Costa de Marfil" },
  { value: "HR", label: "Croacia" },
  { value: "CU", label: "Cuba" },
  { value: "CW", label: "Curazao" },
  { value: "CY", label: "Chipre" },
  { value: "CZ", label: "Republica checa" },
  { value: "DK", label: "Dinamarca" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "República Dominicana" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egipto" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Guinea Ecuatorial" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "ET", label: "Etiopía" },
  { value: "FK", label: "Islas Falkland (Malvinas)" },
  { value: "FO", label: "Islas Faroe" },
  { value: "FJ", label: "Fiyi" },
  { value: "FI", label: "Finlandia" },
  { value: "FR", label: "Francia" },
  { value: "GF", label: "Guayana Francesa" },
  { value: "PF", label: "Polinesia francés" },
  { value: "TF", label: "Territorios Franceses del Sur" },
  { value: "GA", label: "Gabón" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Alemania" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Grecia" },
  { value: "GL", label: "Groenlandia" },
  { value: "GD", label: "Granada" },
  { value: "GP", label: "Guadalupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernsey" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guayana" },
  { value: "HT", label: "Haití" },
  { value: "HM", label: "Islas Heard y McDonald" },
  { value: "VA", label: "Santa Sede (Estado de la Ciudad del Vaticano)" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Hungría" },
  { value: "IS", label: "Islandia" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Irán (República Islámica de" },
  { value: "IQ", label: "Irak" },
  { value: "IE", label: "Irlanda" },
  { value: "IM", label: "Isla del hombre" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italia" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japón" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordán" },
  { value: "KZ", label: "Kazajstán" },
  { value: "KE", label: "Kenia" },
  { value: "KI", label: "Kiribati" },
  { value: "KP", label: "República de Corea, Popular Democrática de" },
  { value: "KR", label: "Corea, república de" },
  { value: "XK", label: "Kosovo" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kirguistán" },
  { value: "LA", label: "República Democrática Popular Lao" },
  { value: "LV", label: "Letonia" },
  { value: "LB", label: "Líbano" },
  { value: "LS", label: "Lesoto" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Jamahiriya Arabe Libia" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lituania" },
  { value: "LU", label: "Luxemburgo" },
  { value: "MO", label: "Macao" },
  { value: "MK", label: "Macedonia, la ex República Yugoslava de" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malasia" },
  { value: "MV", label: "Maldivas" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Islas Marshall" },
  { value: "MQ", label: "Martinica" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauricio" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "México" },
  { value: "FM", label: "Micronesia, Estados Federados de" },
  { value: "MD", label: "Moldavia, República de" },
  { value: "MC", label: "Mónaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Marruecos" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Países Bajos" },
  { value: "AN", label: "Antillas Holandesas" },
  { value: "NC", label: "Nueva Caledonia" },
  { value: "NZ", label: "Nueva Zelanda" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Níger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Isla Norfolk" },
  { value: "MP", label: "Islas Marianas del Norte" },
  { value: "NO", label: "Noruega" },
  { value: "OM", label: "Omán" },
  { value: "PK", label: "Pakistán" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Territorio Palestino, Ocupado" },
  { value: "PA", label: "Panamá" },
  { value: "PG", label: "Papúa Nueva Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Perú" },
  { value: "PH", label: "Filipinas" },
  { value: "PN", label: "Pitcairn" },
  { value: "PL", label: "Polonia" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Katar" },
  { value: "RE", label: "Reunión" },
  { value: "RO", label: "Rumania" },
  { value: "RU", label: "Federación Rusa" },
  { value: "RW", label: "Ruanda" },
  { value: "BL", label: "San Bartolomé" },
  { value: "SH", label: "Santa elena" },
  { value: "KN", label: "Saint Kitts y Nevis" },
  { value: "LC", label: "Santa Lucía" },
  { value: "MF", label: "San Martín" },
  { value: "PM", label: "San Pedro y Miquelón" },
  { value: "VC", label: "San Vicente y las Granadinas" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "Santo Tomé y Príncipe" },
  { value: "SA", label: "Arabia Saudita" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "CS", label: "Serbia y Montenegro" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leona" },
  { value: "SG", label: "Singapur" },
  { value: "SX", label: "San Martín" },
  { value: "SK", label: "Eslovaquia" },
  { value: "SI", label: "Eslovenia" },
  { value: "SB", label: "Islas Salomón" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "Sudáfrica" },
  { value: "GS", label: "Georgia del sur y las islas Sandwich del sur" },
  { value: "SS", label: "Sudán del Sur" },
  { value: "ES", label: "España" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SD", label: "Sudán" },
  { value: "SR", label: "Surinam" },
  { value: "SJ", label: "Svalbard y Jan Mayen" },
  { value: "SZ", label: "Swazilandia" },
  { value: "SE", label: "Suecia" },
  { value: "CH", label: "Suiza" },
  { value: "SY", label: "República Árabe Siria" },
  { value: "TW", label: "Taiwan, provincia de China" },
  { value: "TJ", label: "Tayikistán" },
  { value: "TZ", label: "Tanzania, República Unida de" },
  { value: "TH", label: "Tailandia" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TG", label: "Para llevar" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad y Tobago" },
  { value: "TN", label: "Túnez" },
  { value: "TR", label: "pavo" },
  { value: "TM", label: "Turkmenistán" },
  { value: "TC", label: "Islas Turcas y Caicos" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ucrania" },
  { value: "AE", label: "Emiratos Árabes Unidos" },
  { value: "GB", label: "Reino Unido" },
  { value: "US", label: "Estados Unidos" },
  { value: "UM", label: "Islas menores alejadas de los Estados Unidos" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Vietnam" },
  { value: "VG", label: "Islas Vírgenes Británicas" },
  { value: "VI", label: "Islas Vírgenes, EE. UU." },
  { value: "WF", label: "Wallis y Futuna" },
  { value: "EH", label: "Sahara Occidental" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabue" },
];

export const fe_s6_descripcion_relacion = [
  { value: defaultRelationships.cliente, label: defaultRelationships.cliente },
  { value: defaultRelationships.personaRelacionada, label: defaultRelationships.personaRelacionada },
  { value: defaultRelationships.familiarInmediato, label: defaultRelationships.familiarInmediato },
  { value: "Otros", label: "Otros" },
];
export const fe_s7_tiempo_operacion = [
  { value: "Menos de 5 Años", label: "Menos de 5 Años" },
  { value: "Entre 5 y 10 Años", label: "Entre 5 y 10 Años" },
  { value: "Más de 10 años", label: "Más de 10 años" },
];
export const fe_s7_plazo_inversion = [
  { value: defaultTimes.menosDeUno, label: defaultTimes.menosDeUno },
  { value: defaultTimes.entreUnoYCinco, label: defaultTimes.entreUnoYCinco },
  { value: defaultTimes.masDeCinco, label: defaultTimes.masDeCinco },
];
export const fe_s7_nivel_experiencia = [
  { value: defaultExperienceLevels.alta, label: defaultExperienceLevels.alta },
  { value: defaultExperienceLevels.media, label: defaultExperienceLevels.media },
  { value: "Bajo o ninguna Experiencia", label: "Bajo o ninguna Experiencia" },
];
export const fe_s7_total_activos_liquidos = [
  { value: "Menos de RD$5,000,000", label: "Menos de RD$5,000,000" },
  { value: "RD$5,000,001-RD$20,000,000", label: "RD$5,000,001-RD$20,000,000" },
  { value: "Más de RD$20,000,00", label: "Más de RD$20,000,00" },
];
export const fe_s7_variacion_patrimonio = [
  {
    value: "option1",
    label:
      "Mi patrimonio ha sufrido una variación significativa (más de RD$5,000,000)",
  },
  {
    value: "option2",
    label: "Mi patrimonio no ha sufrido una variación significativa",
  },
  {
    value: "option3",
    label:
      "Mi patrimonio ha sufrido una variación razonable (menos de RD$5,000,000)",
  },
];
export const fe_s8_fondo_emergencia = [
  { value: "No", label: "No" },
  { value: "Si, menos de 1 año", label: "Si, menos de 1 año" },
  { value: "Si, más de 1 año", label: "Si, más de 1 año" },
];
export const fe_s8_distribucion_activos = [
  {
    value: "option1",
    label:
      "Acciones, fondos de inversión cerrados, operaciones a plazo (forwards), préstamos de títulos valores (mutuos) o préstamos de margen",
  },
  {
    value: "option2",
    label:
      "Certificados de Inversión o Bonos (Banco Central, Ministerio de Hacienda, Corporativos)",
  },
  {
    value: "option3",
    label:
      "Cuenta Corriente, cuentas de ahorros, certificados financieros, fondos de inversión mutuos o abiertos",
  },
];
export const fe_s8_destino_ingresos = [
  { value: defaultInvestmentGoals.reinvertirTodo, label: defaultInvestmentGoals.reinvertirTodo },
  {
    value: "Utilizar una parte y reinvertir otra parte",
    label: "Utilizar una parte y reinvertir otra parte",
  },
  {
    value: "Utilizarlo para gastos operativos de la empresa",
    label: "Utilizarlo para gastos operativos de la empresa",
  },
];
export const fe_s8_porcentaje_patrimonio = [
  { value: defaultPercentages.menosDeDiez, label: defaultPercentages.menosDeDiez },
  { value: defaultPercentages.entreDiezYCuarenta, label: defaultPercentages.entreDiezYCuarenta },
  { value: defaultPercentages.masDeCuarenta, label: defaultPercentages.masDeCuarenta },
];
export const fe_s8_principal_objetivo = [
  {
    value: "option1",
    label:
      "Rentabilidad, Cliente busca atractivos rendimientos, combinando instrumentos y tomando mayores riesgos de inversión",
  },
  {
    value: "option2",
    label:
      "Seguridad, Crecimiento sostenido de su capital. Acepta riesgos moderados, esperando obtener retornos estables",
  },
  {
    value: "option3",
    label:
      " Mantener Capital, El objetivo de inversión del cliente es a largo plazo, busca conservar su capital con la menor volatilidad y riesgos posibles",
  },
];
export const fe_s8_frases_identificacion = [
  {
    value: "option1",
    label:
      "Invertiría todo mi capital en instrumentos de alto rendimiento, para maximizar mis ganancia, aun cuando eso signifique asumir mayores riesgos",
  },
  {
    value: "option2",
    label:
      "Invertiría parte de mi capital en instrumentos de bajo riesgo y otra parte en aquellos que son más riesgosos",
  },
  {
    value: "option3",
    label:
      "Invertiría todo mi capital en instrumentos de bajo riesgo, aunque su rentabilidad sea baja",
  },
];
export const fe_s8_capacidad_perdidas = [
  { value: defaultCapacities.masDeQuince, label: defaultCapacities.masDeQuince },
  { value: defaultCapacities.deSeisAQuince, label: defaultCapacities.deSeisAQuince },
  { value: defaultCapacities.deCeroACinco, label: defaultCapacities.deCeroACinco },
];
