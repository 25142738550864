class DividendosReadDto {
  public id: number;
  private _fecha: string;
  public get fecha(): string {
    return this._fecha;
  }
  public set fecha(value: string) {
    const newDate = new Date(value);
    this._fecha = newDate.toLocaleDateString("en-CA");
  }

  public valorDividendo: number;
  public fondoId: number;
}

export default DividendosReadDto;
