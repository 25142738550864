// import { SetStateAction } from "react";
import SelectGeneral from "../Select/SelectGeneral";

interface IProps {
  categories: string[];
  setCategory: React.Dispatch<React.SetStateAction<string>>;
}

function CategoryFilter({ categories, setCategory }: IProps) {
  return (
    <SelectGeneral
      options={categories.map((category) => ({
        value: category,
        label: category,
      }))}
      onChange={setCategory}
      isMulti={false}
      placeHolder={`Categoría`}
    />
  );
}

export default CategoryFilter;
