import { SetStateAction } from "react";
import NewsDatePickerComponent from "../datepicker/NewsDatePickerComponent";
import DateFilter from "../filters/DateFilter";

interface IProps {
  dates: string[];
  setDate: React.Dispatch<React.SetStateAction<string>>;
  currentDate:string;
}

function PodcastFilter({ setDate, dates,currentDate }: IProps) {
  return (
		<section className="main-blog-header flex flex-col items-center pt-10 mx-[18px]">
			<div className="container podcast-title-fix mx-auto mt-16 lg:flex lg:justify-between lg:items-center">
				<h1 className="flex justify-center generic-title generic-title--mb generic-title--left">
					Podcast
				</h1>
				<div>
					{/* <DateFilter dates={dates} setDate={setDate} />
					 */}
					 <NewsDatePickerComponent dates={dates} setDate={setDate} currentDate={currentDate}/>
				</div>
			</div>
		</section>
  );
}

export default PodcastFilter;
