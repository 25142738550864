import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FundReadDto from "../../dtos/FundReadDto";
import { getAllFunds } from "../../services/FundServices";
import {
  getAllCuotasByfundTitle,
  getLastValorCuota,
} from "../../services/ValorCuotaServices";
import DatePickerComponent from "../datepicker/DatePickerComponent";
import SelectGeneral from "../Select/SelectGeneral";

interface IProps {
  setFund: React.Dispatch<React.SetStateAction<string>>;
  setCurrentDate: React.Dispatch<React.SetStateAction<string>>;
  setCuota: React.Dispatch<React.SetStateAction<string>>;
  setPurchaseDate: React.Dispatch<React.SetStateAction<string>>;
  onClickCalc: () => void;
  isValid: boolean;
}

function CalculadoraOptions(props: IProps) {
  const { control, getValues, setValue } = useForm({ mode: "onChange" });

  const [option, setOption] = useState("");
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [funds, setFunds] = useState<FundReadDto[]>([]);

  useEffect(() => {
    getAllFunds().then(setFunds);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const today = await getLastValorCuota(option);
      const cuotas = await getAllCuotasByfundTitle(option);
      setLastDate(today.fecha);
      props.setCurrentDate(today.fecha);
      if (cuotas.length !== 0) {
        setMaxDate(cuotas[0]?.fecha);
        setMinDate(cuotas[cuotas.length - 1]?.fecha);
      }
    };
    fetchData();
  }, [option, props]);

  useEffect(() => {
    option && setValue("purchaseDate", addDays(new Date(minDate), 1));
  }, [option, minDate, setValue]);

  function updateCurrentOption(value: any) {
    setOption(value.value);
    props.setFund(value.value);
  }

  // Set purchase date
  const onPurchaseDateChange = useCallback(() => {
    const selectedDay = new Date(getValues("purchaseDate"))
      .toLocaleString("ko-KR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replaceAll(" ", "")
      .replaceAll(".", "-")
      .slice(0, 10);
    props.setPurchaseDate(selectedDay);
  }, [getValues, props.setPurchaseDate]);

  function onCuotaChange() {
    const cuota = document.getElementById("cuotaValue") as HTMLInputElement;
    props.setCuota(cuota.value.toString());
  }

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, currentTarget } = event;
    const digitRegExp = new RegExp(/\d/);
    const isNumberKey = digitRegExp.exec(key) !== null;
    const isAllowedKey = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ].includes(key);
    const isSingleDot = key === "." && !currentTarget.value.includes(".");

    if (!isNumberKey && !isAllowedKey && !isSingleDot) {
      event.preventDefault();
    }
  };

  const addDays = function (
    date: { setDate: (arg0: any) => any; getDate: () => number },
    days: number
  ) {
    return date.setDate(date.getDate() + days);
  };

  return (
    <div className="comparador__box">
      <div>
        <div className="w-full mb-4 md:flex md:space-x-4">
          <div className="comparador__select-block md:w-1/2">
            <label>Fondo de inversión</label>
            <Controller
              control={control}
              name="investmentFund"
              render={({ field: { onChange, value } }) => (
                <SelectGeneral
                  options={funds.map((fund) => ({
                    value: fund.title,
                    label: fund.title,
                  }))}
                  onChange={(v: any) => {
                    onChange(v);
                    updateCurrentOption(v);
                  }}
                  isMulti={false}
                  value={value}
                  placeHolder="Elige un fondo"
                  zeroOnCorner={true}
                />
              )}
            />
          </div>
          <div className="comparador__select-block md:w-1/2">
            <label>Fecha de hoy</label>
            <DatePickerComponent
              isDisabled={true}
              currValue={addDays(new Date(lastDate), 1)}
            />
          </div>
        </div>
        <div className="w-full mb-4 md:flex md:space-x-4">
          <div className="comparador__select-block md:w-1/2">
            <div className="comparador__select-block">
              <label>Número de cuotas</label>
              <div className="date-input !pl-0">
                <input
                  id="cuotaValue"
                  type="text"
                  pattern="[0-9]{1,5}(\.[0-9]*)?"
                  placeholder="12"
                  onKeyDown={onKeyDownHandler}
                  onChange={() => onCuotaChange()}
                />
              </div>
            </div>
          </div>
          <div className="comparador__select-block md:w-1/2">
            <label>Fecha de compra</label>
            <Controller
              control={control}
              name="purchaseDate"
              render={({ field: { onChange, value } }) => (
                <DatePickerComponent
                  onChange={(date: any) => {
                    onChange(date);
                    onPurchaseDateChange();
                  }}
                  currValue={value}
                  minDate={addDays(new Date(minDate), 1)}
                  maxDate={addDays(new Date(maxDate), 1)}
                />
              )}
            />
          </div>
        </div>
        <div className="inline-block w-full md:w-1/2">
          <button
            className={
              !props.isValid
                ? "w-full global-button global-button--full !bg-gray-500"
                : "w-full global-button global-button--full"
            }
            type="button"
            onClick={() => props.onClickCalc()}
            disabled={!props.isValid}
          >
            Calcular
          </button>
        </div>
      </div>
    </div>
  );
}

export default CalculadoraOptions;
