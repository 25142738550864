import FeeValue from '../img/home-tools/fee-value-icon.svg';
import PerformanceCalc from '../img/home-tools/performance-calc-icon.svg';
import FundRecommender from '../img/home-tools/fund-recommender-icon.svg';
import Riskprofile from '../img/home-tools/risk-profile-icon.svg';
import { useEffect, useState } from 'react';
import { getAllFunds } from '../services/FundServices';
import ComparadorReadDto from '../dtos/ComparadorReadDto';
import { getChoosableFunds, getComparadorByfundTitle } from '../services/ComparadorService';
import { useNavigate } from 'react-router-dom';
import AppVariables from '../Variables.json';
import SelectGeneral from '../components/Select/SelectGeneral';
import PageInfoDto from '../dtos/PageInfoDto';
import { getPageInfo } from '../services/GenericServices';

function ComparadorDeFondos() {
	const navigate = useNavigate();
	const [option, setOption] = useState('');
	const [option2, setOption2] = useState('');
	const [isValid, setIsValid] = useState(false);
	const [funds, setFunds] = useState([]);
	const [comparador, setComparador] = useState<ComparadorReadDto>(new ComparadorReadDto());
	const [comparador2, setComparador2] = useState<ComparadorReadDto>(new ComparadorReadDto());

	const [pageInfo, setPageInfo] = useState<PageInfoDto>(new PageInfoDto());

	const fetchInfo = async () => {
		const fetchedInfo = await getPageInfo('Comparador de Fondos de Inversión');
		setPageInfo(fetchedInfo);
	};

	useEffect(() => {
		fetchInfo();
	}, []);

	useEffect(() => {
		getChoosableFunds().then(setFunds);
	}, []);

	useEffect(() => {
		clearFields();
		if (option.localeCompare(option2) === 0) {
			setIsValid(false);
		} else if (option === '' || option2 === '') {
			setIsValid(false);
		} else {
			setIsValid(true);
		}
	}, [option, option2]);

	const comparar = async () => {
		const fetchedComparador = await getComparadorByfundTitle(option);
		const fetchedComparador2 = await getComparadorByfundTitle(option2);
		setComparador(fetchedComparador);
		setComparador2(fetchedComparador2);
	};

	function updateCurrentOption1(value: string) {
		setOption(value);
	}

	function updateCurrentOption2(value: string) {
		setOption2(value);
	}

	const clearFields = () => {
		setComparador(new ComparadorReadDto());
		setComparador2(new ComparadorReadDto());
	};

	return (
		<div>
			<section
				className="comparador"
				style={{
					backgroundImage: `linear-gradient(
                              180deg,
                              #00235c00 0%,
                              #00235ca3 20%,
                              #00235c 100%
                            ),
                            url(${AppVariables.IMG_URL + 'bg-img-podcast1.jpg'})`,
				}}
			>
				<div className="container mx-auto">
					<div className="comparador__intro">
						<h1>{pageInfo.title}</h1>
						<p>{pageInfo.description}</p>
					</div>
					<div className="comparador__box md:flex md:space-x-4 md:items-center lg:justify-around">
						<div className="comparador__select-block md:w-1/3 lg:w-4/12">
							<label>Fondo de inversión</label>
							<SelectGeneral
								options={funds.map((fund) => ({
									value: fund,
									label: fund,
								}))}
								onChange={({ value }: any) => updateCurrentOption1(value)}
								isMulti={false}
								placeHolder="Elige un fondo"
							/>
						</div>
						<div className="comparador__select-block md:w-1/3 lg:w-4/12">
							<label>Fondo de inversión</label>
							<SelectGeneral
								options={funds.map((fund) => ({
									value: fund,
									label: fund,
								}))}
								onChange={({ value }: any) => updateCurrentOption2(value)}
								isMulti={false}
								placeHolder="Elige un fondo"
							/>
						</div>
						<button
							className={
								!isValid
									? 'global-button md:w-1/3 lg:w-4/12 mt-6 !bg-gray-500'
									: 'global-button md:w-1/3 lg:w-4/12 mt-6'
							}
							onClick={() => comparar()}
							type="button"
							disabled={!isValid}
						>
							Comparar
						</button>
					</div>
					<div className="comparador__table">
						<div className="justify-between md:space-x-4 md:flex comparador__table-top">
							<div className="flex-1 w-full md:w-1/3 lg:w-3/12">
								<h1>Resultado</h1>
							</div>
							<div className="flex justify-between space-x-4 flex-fix md:w-2/3 lg:w-9/12">
								<div className="w-1/2">
									<div className="comprador__table-title-box">
										<p className="comprate__table-orange">{option}</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="comprador__table-title-box">
										<p className="comprate__table-orange">{option2}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="comparador__table-mid md:flex md:space-x-4">
							<div className="flex-1 w-full md:w-1/3 lg:w-3/12">
								<h2>Perfil de Riesgo</h2>
							</div>
							<div className="flex justify-between space-x-4 flex-fix md:w-2/3 lg:w-9/12">
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">{comparador.perfilderiesgo}</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">{comparador2.perfilderiesgo}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="comparador__table-mid md:flex md:space-x-4">
							<div className="flex-1 w-full md:w-1/3 lg:w-3/12">
								<h2>Monto Total del Programa</h2>
							</div>
							<div className="flex justify-between space-x-4 flex-fix md:w-2/3 lg:w-9/12">
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador.montoTotaldelPrograma}
										</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador2.montoTotaldelPrograma}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="comparador__table-mid md:flex md:space-x-4">
							<div className="flex-1 w-full md:w-1/3 lg:w-3/12">
								<h2>Objeto del Fondo</h2>
							</div>
							<div className="flex justify-between space-x-4 flex-fix md:w-2/3 lg:w-9/12">
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">{comparador.objetodelFondo}</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">{comparador2.objetodelFondo}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="comparador__table-mid md:flex md:space-x-4">
							<div className="flex-1 w-full md:w-1/3 lg:w-3/12">
								<h2>Periodicidad de Pago de los Beneficios</h2>
							</div>
							<div className="flex justify-between space-x-4 flex-fix md:w-2/3 lg:w-9/12">
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador.periodicidaddePagodelosBeneficio}
										</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador2.periodicidaddePagodelosBeneficio}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="comparador__table-mid md:flex md:space-x-4">
							<div className="flex-1 w-full md:w-1/3 lg:w-3/12">
								<h2>Fecha de pago de los Rendimientos</h2>
							</div>
							<div className="flex justify-between space-x-4 flex-fix md:w-2/3 lg:w-9/12">
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador.fechadepagodelosRendimientos}
										</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador2.fechadepagodelosRendimientos}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="comparador__table-mid md:flex md:space-x-4">
							<div className="flex-1 w-full md:w-1/3 lg:w-3/12">
								<h2>Pago del Valor de Cuota Final</h2>
							</div>
							<div className="flex justify-between space-x-4 flex-fix md:w-2/3 lg:w-9/12">
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador.pagodelValordeCuotaFinal}
										</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador2.pagodelValordeCuotaFinal}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="comparador__table-mid md:flex md:space-x-4">
							<div className="flex-1 w-full md:w-1/3 lg:w-3/12">
								<h2>Fecha de Vencimiento del Fondo</h2>
							</div>
							<div className="flex justify-between space-x-4 flex-fix md:w-2/3 lg:w-9/12">
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador.fechadeVencimientodelFondo}
										</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador2.fechadeVencimientodelFondo}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="comparador__table-mid md:flex md:space-x-4">
							<div className="flex-1 w-full md:w-1/3 lg:w-3/12">
								<h2>Restricciones Generales</h2>
							</div>
							<div className="flex justify-between space-x-4 flex-fix md:w-2/3 lg:w-9/12">
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador.restriccionesGenerales}
										</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador2.restriccionesGenerales}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="comparador__table-mid md:flex md:space-x-4">
							<div className="flex-1 w-full md:w-1/3 lg:w-3/12">
								<h2>Benchmark</h2>
							</div>
							<div className="flex justify-between space-x-4 flex-fix md:w-2/3 lg:w-9/12">
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">{comparador.benchmark}</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">{comparador2.benchmark}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="comparador__table-mid md:flex md:space-x-4">
							<div className="flex-1 w-full md:w-1/3 lg:w-3/12">
								<h2>Estructura de comisiones</h2>
							</div>
							<div className="flex justify-between space-x-4 flex-fix md:w-2/3 lg:w-9/12">
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador.estructuradecomisiones}
										</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador2.estructuradecomisiones}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="comparador__table-mid md:flex md:space-x-4">
							<div className="flex-1 w-full md:w-1/3 lg:w-3/12">
								<h2>Calificación de Riesgo</h2>
							</div>
							<div className="flex justify-between space-x-4 flex-fix md:w-2/3 lg:w-9/12">
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador.calificacionderiesgo}
										</p>
									</div>
								</div>
								<div className="w-1/2">
									<div className="comprador__table-title-box comprador__table-title-box--semi">
										<p className="comprate__table-white">
											{comparador2.calificacionderiesgo}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/*tools*/}
			<div className="tools tools--fix">
				<h1 className="generic-title">Herramientas</h1>
				<div className="tools-container">
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/perfil-de-riesgo`);
						}}
					>
						<img className="generic-tool__logo" src={Riskprofile} alt="risk-profile-icon" />
						<h4 className="generic-tool__title">Perfil de Riesgo del Inversionista</h4>
					</div>
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/recomendador`);
						}}
					>
						<img
							className="generic-tool__logo"
							src={FundRecommender}
							alt="fund-recommender-icon"
						/>
						<h4 className="generic-tool__title">Recomendador de fondos</h4>
					</div>
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/valor-cuota`);
						}}
					>
						<img className="generic-tool__logo" src={FeeValue} alt="fee-value-icon" />
						<h4 className="generic-tool__title">Valor de las cuotas</h4>
					</div>
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/calculadora`);
						}}
					>
						<img
							className="generic-tool__logo"
							src={PerformanceCalc}
							alt="performance-calc-icon"
						/>
						<h4 className="generic-tool__title">Calculadora de rendimiento</h4>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ComparadorDeFondos;
