import ExecutivesReadDto from "../../dtos/ExecutivesReadDto";
import PromotorReadDto from "../../dtos/PromotorReadDto";
import ExecutivesContainer from "./ExecutivesContainer";
import PromotorContainer from "./PromotorContainer";

interface IProps{
    executives:ExecutivesReadDto[];
    promotors:PromotorReadDto[];
}

function Team(props:IProps) {
    return (
			<div className="team">
				<h1 className="team__title">Nuestro equipo</h1>
				<h3 className="team__subtitle">Ejecutivos</h3>
				<ExecutivesContainer executives={props.executives} />
				<h3 className="team__subtitle">Promotores</h3>
				<PromotorContainer promotors={props.promotors} />
			</div>
		);
}

export default Team;