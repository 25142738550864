import blueShare from '../../img/sala-de-prensa-details/blue-share.svg';
import orangeShare from '../../img/sala-de-prensa-details/orange-share.svg';
import blueTwitter from '../../img/sala-de-prensa-details/blue-twitter.svg';
import orangeTwitter from '../../img/sala-de-prensa-details/orange-twitter.svg';
import blueFacebook from '../../img/sala-de-prensa-details/blue-facebook.svg';
import orangeFacebook from '../../img/sala-de-prensa-details/orange-facebook.svg';
import blueLinkedIn from '../../img/sala-de-prensa-details/blue-linkedin.svg';
import orangeLinkedIn from '../../img/sala-de-prensa-details/orange-linkedin.svg';
import blueWhatsapp from '../../img/sala-de-prensa-details/blue-whatsapp.svg';
import orangeWhatsapp from '../../img/sala-de-prensa-details/orange-whatsapp.svg';
import blueEmail from '../../img/sala-de-prensa-details/blue-email.svg';
import orangeEmail from '../../img/sala-de-prensa-details/orange-email.svg';
import blueDoubleT from '../../img/sala-de-prensa-details/blue-doubleT.svg';
import orangeDoubleT from '../../img/sala-de-prensa-details/orange-doubleT.svg';
import {
	FacebookShareButton,
	LinkedinShareButton,
	WhatsappShareButton,
	TwitterShareButton,
	EmailShareButton,
} from 'react-share';
import blueBlackArrow from '../../img/sala-de-prensa-details/blue-back-arrow.svg';
import whiteBlackArrow from '../../img/sala-de-prensa-details/white-back-arrow.png';
import BlogReadDto from '../../dtos/BlogReadDto';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

interface IProps {
	blog: BlogReadDto;
}

function BlogDetails({ blog }: IProps) {
	const navigate = useNavigate();

	const[zoom,setZoom] = useState(false);

	return (
		<div className="podcast-details">
			<div className="container mx-auto">
				
				<div className="prensa-detail-header flex items-center mb-4">
				
					<a
						className="prensa-detail-header__button-back mr-4 rounded-full border-2 border-[#012169] w-14 h-9 flex justify-center items-center hover:bg-ultramar cursor-pointer"
						onClick={() => {
							navigate(-1);
						}}
					>
						<img
							src={blueBlackArrow}
							alt="arrow back"
							className="prensa-detail-header-button__img w-4 h-4"
						/>
						<img
							src={whiteBlackArrow}
							alt="arrow back"
							className="prensa-detail-header-button__img-hover w-4 h-4 hidden"
						/>
					</a>
					<span className="prensa-detail-header__text text-xs text-[#414652] lg:text-base 3xl:text-xl font-bold">
						Publicado el {blog.publicationDate}
					</span>
				</div>
				<h3 className="generic-title generic-title--left mb-[10px]">{blog.title}</h3>
				<p className="blog--categoria lg:text-lg md:text-base">{blog.category}</p>
				<div className="blog__open">
					<div className="blog__image-share">
						<img className="blog__image" src={blog.imgUrl} alt={blog.title} />
						
						<div className="prensa-detail-icons-container ml-6 3xl:ml-8 w-10 3xl:w-12 flex flex-col justify-between">
							<div className="prensa-detail-icons-top bg-white flex flex-col justify-between items-center w-full gap-5 py-4 3xl:gap-6">
								<div className="prensa-detail__icon-container">
									<a
										href="#"
										onClick={() => {
											navigator.clipboard.writeText(window.location.href);
											window.alert('url copied!!');
										}}
									>
										<img
											src={blueShare}
											alt="icon share"
											className="prensa-detail__icon"
										/>
										<img
											src={orangeShare}
											alt="icon share"
											className="prensa-detail__icon-hover"
										/>
									</a>
								</div>
								<div className="prensa-detail__icon-container">
									<a href="#">
										<TwitterShareButton url={window.location.href} className="!min-w-0">
											<img
												src={blueTwitter}
												alt="icon twitter"
												className="prensa-detail__icon"
											/>
											<img
												src={orangeTwitter}
												alt="icon twitter"
												className="prensa-detail__icon-hover"
											/>
										</TwitterShareButton>
									</a>
								</div>
								<div className="prensa-detail__icon-container">
									<a href="#">
										<FacebookShareButton className="!min-w-0" url={window.location.href}>
											<img
												src={blueFacebook}
												alt="icon facebook"
												className="prensa-detail__icon"
											/>
											<img
												src={orangeFacebook}
												alt="icon facebook"
												className="prensa-detail__icon-hover"
											/>
										</FacebookShareButton>
									</a>
								</div>
								<div className="prensa-detail__icon-container">
									<a href="#">
										<LinkedinShareButton className="!min-w-0" url={window.location.href}>
											<img
												src={blueLinkedIn}
												alt="icon linkedin"
												className="prensa-detail__icon"
											/>
											<img
												src={orangeLinkedIn}
												alt="icon linkedin"
												className="prensa-detail__icon-hover"
											/>
										</LinkedinShareButton>
									</a>
								</div>
								<div className="prensa-detail__icon-container">
									<a href="#">
										<WhatsappShareButton className="!min-w-0" url={window.location.href}>
											<img
												src={blueWhatsapp}
												alt="icon whatsapp"
												className="prensa-detail__icon"
											/>
											<img
												src={orangeWhatsapp}
												alt="icon whatsapp"
												className="prensa-detail__icon-hover"
											/>
										</WhatsappShareButton>
									</a>
								</div>
								<div className="prensa-detail__icon-container">
									<a href="#">
										<EmailShareButton className="!min-w-0" url={window.location.href}>
											<img
												src={blueEmail}
												alt="icon email"
												className="prensa-detail__icon"
											/>
											<img
												src={orangeEmail}
												alt="icon email"
												className="prensa-detail__icon-hover"
											/>
										</EmailShareButton>
									</a>
								</div>
							</div>
							<div className="prensa-detail-icon-bottom bg-white h-10 flex justify-center items-center">
								<div className="prensa-detail__icon-container" onClick={() => {zoom === false? setZoom(true):setZoom(false)}}>
									<a href="#">
										<img
											src={blueDoubleT}
											alt="icon double T"
											className="prensa-detail__icon"
										/>
										<img
											src={orangeDoubleT}
											alt="icon double T"
											className="prensa-detail__icon-hover"
										/>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div
						className="blog__text"
						dangerouslySetInnerHTML={{ __html: blog.description }}
						style={zoom? {zoom:'120%'}:{}}
					></div>
				</div>
			</div>
		</div>
	);
}

export default BlogDetails;
