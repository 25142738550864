import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDraggable } from "react-use-draggable-scroll";
import { useRef } from "react";

interface IProps {
	setCurrentExpr: React.Dispatch<React.SetStateAction<string>>;
	categories: string[];
	currentLetter: string;
	setCurrentLetter: React.Dispatch<React.SetStateAction<string>>;
	currentExpr: string;
}

function DictioinaryBannerIntro({
	setCurrentExpr,
	categories,
	currentLetter,
	setCurrentLetter,
	currentExpr
}: IProps) {
	const ref =
		useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
	const { events } = useDraggable(ref);

	const renderCategories = () => {
		return categories.map((category) => (
			<div
				key={category}
				className={
					currentLetter === category
						? "file-section__file-choser--div--active"
						: "file-section__file-choser--div"
				}
				onClick={() => {
					setCurrentLetter(category);
				}}
			>
				{category}
			</div>
		));
	};

	return (
		<div className="banner-intro">
			<div className="container mx-auto">
				<h1>Diccionario</h1>
				<div className="top-search">
					<input
						id="dictionary-input"
						placeholder="Buscar"
						type="text"
						onChange={(e) => {
							setCurrentExpr(e.target.value);
						}}
					/>
					<div className="top-search__icon">
						{currentExpr !== '' ? (
							<i
								className="fa-lg cursor-pointer"
								onClick={() => { (document.getElementById('dictionary-input') as HTMLInputElement).value = ''; setCurrentExpr('') }
								}
							>
								<FontAwesomeIcon icon={faTimes} />
							</i>
						) : (
							<i className="fa-sm">
								<FontAwesomeIcon icon={faSearch} />
							</i>
						)}
						{/* <i className="fa-sm cursor-pointer">
              <FontAwesomeIcon icon={faSearch} />
            </i> */}
					</div>
				</div>
				<div className="file-section__file-choser--alphabet " {...events} ref={ref}>
					{renderCategories()}
				</div>
			</div>
		</div>
	);
}

export default DictioinaryBannerIntro;
