import AcknowledgmentReadDto from "../../dtos/AcknowledgmentReadDto";

interface IProps {
	acknowledgment: AcknowledgmentReadDto;
}

function AcknowledgmentCard(props: IProps) {
	return (
		<div className="reconocimiento-section-card">
			<img
				src={props.acknowledgment.imgUrl}
				alt={props.acknowledgment.title}
				className="reconocimiento-section-card--img"
			/>
			<h3 className="reconocimiento-section-card--title">
				{props.acknowledgment.title}
			</h3>
			<p className="reconocimiento-section-card--content">
				{props.acknowledgment.content}
			</p>
		</div>
	);
}

export default AcknowledgmentCard;