import AppVariables from '../Variables.json';

class NewsReadDto {
	public id: number;
	public title: string;
	public category: string;
	public principalContent: string;
	public content: string;

	private _imgUrl: string;
	public get imgUrl(): string {
		return this._imgUrl;
	}
	public set imgUrl(value: string) {
		this._imgUrl = AppVariables.BASE_URL + value;
	}

	private _date: string;
	public get date(): string {
		return this._date;
	}
	public set date(value: string) {
		const newDate = new Intl.DateTimeFormat(['ban', 'id']).format(new Date(value));

		this._date = newDate;
	}

	constructor(
		id?: number,
		title?: string,
		category?: string,
		principalContent?: string,
		content?: string,
		imgUrl?: string,
		date?: string
	) {
		this.id = id;
		this.title = title;
		this.category = category;
		this.principalContent = principalContent;
		this.content = content;
		this.imgUrl = imgUrl; // This will call the setter and prepend BASE_URL
		this.date = date; // This will call the setter and format the date
	}
}

export default NewsReadDto;
