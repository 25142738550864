import { useNavigate } from "react-router-dom";
import CourseReadDto from "../../dtos/CourseReadDto";
import blueBlackArrow from '../../img/sala-de-prensa-details/blue-back-arrow.svg';
import whiteBlackArrow from '../../img/sala-de-prensa-details/white-back-arrow.png';

interface IProps{
    course:CourseReadDto;
}


function CoursesDetail({course}:IProps) {

	const navigate = useNavigate();

    return (
			<div className="podcast-details">
				<div className="container mx-auto">
					<div className="prensa-detail-header flex items-center mb-4">
						<a
							className="prensa-detail-header__button-back mr-4 rounded-full border-2 border-[#012169] w-14 h-9 flex justify-center items-center hover:bg-ultramar cursor-pointer"
							onClick={() => {
								navigate(-1);
							}}
						>
							<img
								src={blueBlackArrow}
								alt="arrow back"
								className="prensa-detail-header-button__img w-4 h-4"
							/>
							<img
								src={whiteBlackArrow}
								alt="arrow back"
								className="prensa-detail-header-button__img-hover w-4 h-4 hidden"
							/>
						</a>
						<span className="prensa-detail-header__text text-xs text-[#414652] lg:text-base 3xl:text-xl font-bold">
							Publicado el {course.fecha}
						</span>
					</div>
					<p className="!mt-10 blog--categoria lg:mt-14 prensa-detail-subtitle text-dark-gray text-xs lg:text-base xl:text-lg 3xl:text-xl font-bold">
						{course.categoty}
					</p>
					<div className="blog__open">
						<div className="relative blog__image-share">
							<video
								className="blog__image blog__image--full"
								poster={course.imgUrl}
								src={course.videoUrl}
								controls
							></video>
						</div>
						<div className="blog__text blog__text--small">
							<h3 className="generic-title generic-title--left ">{course.title}</h3>
							<p>{course.description}</p>
						</div>
					</div>
				</div>
			</div>
		);
}

export default CoursesDetail;