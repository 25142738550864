import { SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';
import { getFormularioEmpresaByCode } from '../../services/FormularioEmpresaServices';

interface IProps {
	close: () => void;
}

function ReanudarFormularioEmpresa(props: IProps) {
	const [reanudarCode, setReanudarCode] = useState('');
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [showCompletedMessage, setShowCompletedMessage] = useState(false);

	const handleChange = (e: { target: { value: SetStateAction<string> } }) => {
		setReanudarCode(e.target.value);
	};

	const navigate = useNavigate();

	async function onClickRenaudar() {
		const formularioE = await getFormularioEmpresaByCode(reanudarCode);

		if (formularioE === null) {
			// window.alert('El codigo es invalido');
			setShowErrorMessage(true);
			window.setTimeout(() => setShowErrorMessage(false), 2000);
		}
		if (formularioE.status === 'Completo') {
			setShowCompletedMessage(true);
			window.setTimeout(() => setShowCompletedMessage(false), 2000);
		} else {
			navigate(`${AppVariables.REACT_ROUTER_URL}/formulario-empresa`, {
				state: { code: reanudarCode, lastStep: formularioE.lastStep },
			});
		}
	}

	return (
		<div className="w-[419px] h-[368px] bg-white drop-shadow-2xl flex flex-col p-10">
			<div className="text-[28px] renaudar-title mb-[12px]">Reanudar solicitud</div>
			<p className="renaudar-text mb-[40px]">
				Ingrese el código enviado a su correo electrónico para completar la solicitud.
			</p>
			<div className="mb-[24px] group-form">
				<label className="global-label">Código</label>
				<input
					type="text"
					className="global-input w-full"
					placeholder="Código"
					onChange={handleChange}
					value={reanudarCode}
					id="renaudar-input-text"
				/>
				{showErrorMessage && <p className="text-red-600 ml-1">El codigo es invalido</p>}
				{showCompletedMessage && (
					<p className="text-red-600 ml-1">Su formulario ya ha sido completado</p>
				)}
			</div>
			<div className="grid grid-cols-2 gap-6 gap-y-6 lg:gap-y-0 lg:w-[300px] mb-4 lg:mb-0">
				<a
					className="global-button global-button--full global-button--block global-button-anterior"
					onClick={() => props.close()}
				>
					Cancelar
				</a>
				<a
					className="global-button global-button--full"
					onClick={() => {
						onClickRenaudar();
					}}
				>
					Reanudar
				</a>
			</div>
		</div>
	);
}

export default ReanudarFormularioEmpresa;
