import './privacyTerms.css';

export type cookiesProps = {
	title: string;
	subTitle: string;
	content: string;
};

export default function Cookies({
	title,
	subTitle,
	content,
}: cookiesProps) {
	return (
		<main className="main">
			<div className="banner-intro">
				<div className="container mx-auto">
					<h1>{title}</h1>
					<h3>{subTitle}</h3>
				</div>
			</div>
			<div className="container mx-auto container-1000">
				<div className="lista-preguntas">
					<div dangerouslySetInnerHTML={{ __html: content }} />
				</div>
			</div>
		</main>
	);
}
