import React from 'react';
import MainGobiernoCorporativo from '../components/gobierno-corporativo/MainGobiernoCorporativo';

function GobiernoCorporativo() {
	return (
		<React.Fragment>
			<MainGobiernoCorporativo />
		</React.Fragment>
	);
}

export default GobiernoCorporativo;
