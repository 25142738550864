class PdfReadDto {
	private readonly id: number;
	private readonly title: string;
	private date: string;
	private size?: string;
	private url?: string;
	private filename?: string;

	constructor(id: number, title: string) {
		this.id = id;
		this.title = title;
	}

	//getters
	public get getId() {
		return this.id;
	}

	public get getTitle() {
		return this.title;
	}

	public get getDate() {
		return this.date;
	}

	public get getSize() {
		return this.size;
	}

	public get getUrl() {
		return this.url;
	}

	public get getFileName() {
		return this.filename;
	}

	//setters
	public set setSize(size: string) {
		//convert from bytes to kylobytes
		const sizeInKb = parseFloat(size) * 0.000977;
		//rounds the number to two decimals
		const sizeInKbRound = sizeInKb.toFixed(2);
		size = sizeInKbRound.toString();
		this.size = size;
	}

	public set setDate(date: string) {
		//format day dd/mm/yyyy
		const newDate = new Intl.DateTimeFormat(['ban', 'id']).format(new Date(date));

		this.date = newDate;
	}

	public set setUrl(url: string) {
		this.url = url;
	}

	public set setFileName(filename: string) {
		this.filename = filename;
	}
}

export default PdfReadDto;
