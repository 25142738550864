import React, { useEffect, useState } from "react";
import AppVariables from "../../Variables.json";
import { getDiasTranscurridos } from "../../services/CalculadoraServices";
import { getLastDividendoByFund } from "../../services/DividendosServices";
import { getFundsDetailsByTitle } from "../../services/FundServices";
import { getCuotaByfundTitleAndDate } from "../../services/ValorCuotaServices";
import CalculadoraIntro from "./CalculadoraIntro";
import CalculadoraOptions from "./CalculadoraOptions";
import CalculadoraOthersTools from "./CalculadoraOthersTools";
import CalculadoraResult from "./CalculadoraResult";

function CalculadoraMain() {
  const [fund, setFund] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [cuota, setCuota] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [haveDividentPayment, setHaveDividentPayment] = useState(false);
  const [isValid, setIsValid] = useState(false);

  //results
  const [VCFC, setVCFC] = useState("");
  const [VCFH, setVCFH] = useState("");
  const [rendimiento, setRendimiento] = useState("");
  const [diasTranscurridos, setDiasTranscurridos] = useState("");
  const [gananciasPerdidas, setGananciasPerdidas] = useState("");

  const formatLargeNumber = (num: number): string => {
    const internationalNumberFormat = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 6,
      maximumFractionDigits: 6,
    });

    return internationalNumberFormat.format(num);
  };

  const formatNumber = (num: number): string => {
    const internationalNumberFormat = new Intl.NumberFormat("en-US");
    return internationalNumberFormat.format(num);
  };

  useEffect(() => {
    fund !== "" &&
      getFundsDetailsByTitle(fund).then((res) => {
        if (res.pagoDividendos === "Ninguno") {
          setHaveDividentPayment(false);
        } else {
          setHaveDividentPayment(true);
        }
      });
  }, [fund]);

  useEffect(() => {
    if (cuota === "" || purchaseDate === "" || fund === "") {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [cuota, purchaseDate, fund]);

  async function getResultsDP() {
    if (fund) {
      const vcfc = await getCuotaByfundTitleAndDate(fund, purchaseDate);
      const vcfh = await getCuotaByfundTitleAndDate(fund, currentDate);
      const diasTranscurridos1 = getDiasTranscurridos(
        purchaseDate,
        currentDate
      );
      const numOfCuotas = parseFloat(cuota);
      const dividendos = await getLastDividendoByFund(fund);
      const rendimiento1 = (
        (Math.pow(
          (vcfh.valorCuota + dividendos.valorDividendo) / vcfc.valorCuota,
          365 / diasTranscurridos1
        ) -
          1) *
        100
      ).toFixed(2);
      const gananciasPerdidas1: number =
        (vcfh.valorCuota - vcfc.valorCuota + dividendos.valorDividendo) *
        numOfCuotas;

      if (
        vcfh &&
        vcfc &&
        diasTranscurridos1 &&
        rendimiento1 &&
        gananciasPerdidas1
      ) {
        setVCFC(vcfc.valorCuota + "");
        setVCFH(vcfh.valorCuota + "");
        setDiasTranscurridos(diasTranscurridos1 + "");
        setRendimiento(rendimiento1 + "");
        setGananciasPerdidas(gananciasPerdidas1 + "");
      }
    }
  }

  async function getResults() {
    if (fund) {
      const vcfc = await getCuotaByfundTitleAndDate(fund, purchaseDate);
      const vcfh = await getCuotaByfundTitleAndDate(fund, currentDate);
      const diasTranscurridos1 = getDiasTranscurridos(
        purchaseDate,
        currentDate
      );
      const numOfCuotas = parseFloat(cuota);
      const rendimiento1 = (
        ((((vcfh.valorCuota - vcfc.valorCuota) / vcfc.valorCuota) * 365) /
          diasTranscurridos1) *
        100
      ).toFixed(2);
      const gananciasPerdidas1: number =
        (vcfh.valorCuota - vcfc.valorCuota) * numOfCuotas;
      if (
        vcfh &&
        vcfc &&
        diasTranscurridos1 &&
        rendimiento1 &&
        gananciasPerdidas1
      ) {
        setVCFC(vcfc.valorCuota + "");
        setVCFH(vcfh.valorCuota + "");
        setDiasTranscurridos(diasTranscurridos1 + "");
        setRendimiento(rendimiento1 + "");
        setGananciasPerdidas(gananciasPerdidas1 + "");
      }
    }
  }

  function renderResults() {
    if (VCFC && VCFH && rendimiento && diasTranscurridos && gananciasPerdidas) {
      return (
        <CalculadoraResult
          VCFC={formatLargeNumber(parseFloat(VCFC))}
          VCFH={formatLargeNumber(parseFloat(VCFH))}
          rendimiento={formatNumber(parseFloat(rendimiento))}
          diasTranscurridos={diasTranscurridos}
          gananciasPerdidas={formatLargeNumber(parseFloat(gananciasPerdidas))}
        />
      );
    } else {
      return (
        <CalculadoraResult
          VCFC=""
          VCFH=""
          rendimiento=""
          diasTranscurridos=""
          gananciasPerdidas=""
        />
      );
    }
  }

  const calc = () => {
    if (haveDividentPayment === true) {
      getResultsDP();
    } else {
      getResults();
    }
  };
  console.log("Running calculatior solution Test 2.");
  return (
    <React.Fragment>
      <section
        className="comparador"
        style={{
          backgroundImage: `linear-gradient(
                              180deg,
                              #00235c00 0%,
                              #00235ca3 20%,
                              #00235c 100%
                            ),
                            url(${
                              AppVariables.IMG_URL + "bg-img-podcast1.jpg"
                            })`,
        }}
      >
        <div className="container mx-auto container--fix">
          <CalculadoraIntro />
          <CalculadoraOptions
            setFund={setFund}
            setCurrentDate={setCurrentDate}
            setCuota={setCuota}
            setPurchaseDate={setPurchaseDate}
            onClickCalc={calc}
            isValid={isValid}
          />
          {renderResults()}
        </div>
      </section>
      <CalculadoraOthersTools />
    </React.Fragment>
  );
}

export default CalculadoraMain;
