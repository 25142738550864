import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { validations } from "../Validations/regexValidations";

export default function PrincipalesProveedoresForm() {
  const emptyFieldErrorMessage = "Campo obligatorio";
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const { append, fields, prepend } = useFieldArray({
    control,
    name: "mainProviders",
  });
  useEffect(() => {
    !fields.length && prepend({});
  }, []);
  return (
    <React.Fragment>
      {fields.map((field, index) => {
        const baseName = `mainProviders[${index}]`;
        return (
				<div key={field.id} className="mb-6 create__requisitos">
					<h4 className="create__requisitos-title">Principales proveedores o clientes</h4>

					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label" htmlFor="">
								Nombre de proveedor o cliente
							</label>
							<input
								type="text"
								maxLength={40}
								className="global-input"
								placeholder="Nombre de proveedor o cliente"
								{...register(`${baseName}.Name`, {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.letrasNumerosEspaciosEspeciales,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name={`${baseName}.Name`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label" htmlFor="">
								Productos que ofrece
							</label>
							<input
								type="text"
								maxLength={60}
								className="global-input"
								placeholder="Productos que ofrece"
								{...register(`${baseName}.OfferedProducts`, {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name={`${baseName}.OfferedProducts`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>

					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label" htmlFor="">
								Es proveedor del estado
							</label>
							<div className="document-box">
								<input
									id={`provedor-estado-si-${baseName}.`}
									className="toggle toggle-left"
									name="empresa"
									value="Si"
									type="radio"
									{...register(`${baseName}.IsStateProvider`, {
										required: {
											value: true,
											message: emptyFieldErrorMessage,
										},
									})}
								/>

								<label htmlFor={`provedor-estado-si-${baseName}.`} className="btn">
									Si
								</label>
								<input
									id={`provedor-estado-no-${baseName}.`}
									className="toggle toggle-right"
									name="empresa"
									value="No"
									type="radio"
									{...register(`${baseName}.IsStateProvider`, {
										required: {
											value: true,
											message: emptyFieldErrorMessage,
										},
									})}
								/>

								<label htmlFor={`provedor-estado-no-${baseName}.`} className="btn">
									No
								</label>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2"></div>
					</div>
				</div>
			);
      })}
      <div className="mt-6 mb-6 text-center create__requisitos">
        <button
          type="button"
          onClick={() => {
            append({});
          }}
          className="mb-4"
        >
          <div className="mx-auto text-[#012169] opacity-50 text-[40px]">+</div>
          <span className="text-[#012169] opacity-50 font-bold mb-3">
            Agregar otro proveedor o cliente
          </span>
        </button>
      </div>
    </React.Fragment>
  );
}
