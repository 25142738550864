import Scrollbars from 'react-custom-scrollbars-2';
import ExecutivesReadDto from '../../dtos/ExecutivesReadDto';

interface IProps {
	executive: ExecutivesReadDto;
}

function ExecutiveCard(props: IProps) {
	return (
		<div className="main-executives-card">
			<div
				className="main-executives-card-img"
				style={{ backgroundImage: `url(${props.executive.imgUrl})` }}
			></div>
			<div className="main-executives-card-info">
				<h1 className="main-executives-card-info__title">{props.executive.name}</h1>
				<h1 className="main-executives-card-info__position">{props.executive.rol}</h1>
				<div className="main-executives_scrollable-content">
					<Scrollbars>
						<p className='pr-4'>{props.executive.description}</p>
					</Scrollbars>
				</div>
			</div>
		</div>
	);
}

export default ExecutiveCard;
