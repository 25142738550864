import React, { useEffect, useState } from 'react';
import DiccionarioReadDto from '../../dtos/DiccionarioReadDto';
import {
	getAllDiccionaryCategories,
	getAllWordsByCategory,
	searchWordsByExprAndCategory,
} from '../../services/DiccionarioServices';
import Question from '../FATCA_FAQ/Question';
import DictioinaryBannerIntro from './DictioinaryBannerIntro';

function MainDictionary() {
	const [chooseLetter, setChooseLetter] = useState('A');
	const [currentExpr, setCurrentExpr] = useState('');
	const [words, setWords] = useState<DiccionarioReadDto[]>([]);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		const fetchWords = async () => {
			const data = await getAllWordsByCategory(chooseLetter);
			const categories1 = await getAllDiccionaryCategories();

			setCategories(categories1);
			setWords(data);
		};
		fetchWords();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			let data: DiccionarioReadDto[];
			if (currentExpr === '') {
				data = await getAllWordsByCategory(chooseLetter);
			} else {
				const newCurrentExpr = currentExpr.trim();
				const newChooseLetter = newCurrentExpr.slice(0, 1);
				setChooseLetter(newChooseLetter.toUpperCase());
				setCurrentExpr(newCurrentExpr);
				data = await searchWordsByExprAndCategory(chooseLetter, currentExpr);
			}

			setWords(data);
		};
		fetchData();
	}, [chooseLetter, currentExpr]);

	const renderWords = () => {
		return words.map((word, i) => (
			<Question key={word.id} order={i} question={word.title} content={word.description} />
		));
	};

	return (
		<React.Fragment>
			<DictioinaryBannerIntro
				setCurrentExpr={setCurrentExpr}
				categories={categories}
				currentLetter={chooseLetter}
				setCurrentLetter={setChooseLetter}
				currentExpr={currentExpr}
			/>
			<div className="container mx-auto">
				<p className="search-counter">
					{currentExpr !== '' ? (
						<>
							{renderWords().length} resultados para <b>“{currentExpr}”</b>
						</>
					) : (
						<></>
					)}
				</p>
				<div className="lista-preguntas">{renderWords()}</div>
			</div>
		</React.Fragment>
	);
}

export default MainDictionary;
