import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop(props: any) {
  const location = useLocation();

  useEffect(() => {
    const topDiv = document.getElementById("s4-workspace") as HTMLDivElement;
    topDiv.scrollTop = 0;
  }, [location]);

  return <>{props.children}</>;
}

export default ScrollToTop;
