import { Tree, TreeNode } from "react-organizational-chart";
import styled from "styled-components";
import OrgChartDto from "./OrgChartDto";


const DashedLarimar = styled.div`
	display: inline-flex;
	position: relative;
	align-items: center;
	justify-content: center;
	width: 130px;
	height: 104px;
	border-radius: 50%;
	border: 0.5vw dashed #00c1d5;
	flex-wrap: wrap;
	align-content: center;
`;
const SolidLarimar = styled.div`
	display: inline-flex;
	position: relative;
	align-items: center;
	justify-content: center;
	width: 130px;
	height: 104px;
	border-radius: 50%;
	border: 0.5vw solid #00c1d5;
	flex-wrap: wrap;
	align-content: center;
`;
const SolidBlue = styled.div`
	display: inline-flex;
	position: relative;
	align-items: center;
	justify-content: center;
	width: 130px;
	height: 104px;
	border-radius: 50%;
	border: 0.5vw solid #003b7a;
	flex-wrap: wrap;
	align-content: center;
`;
const SolidGray = styled.div`
	display: inline-flex;
	position: relative;
	align-items: center;
	justify-content: center;
	width: 130px;
	height: 104px;
	border-radius: 50%;
	border: 0.5vw solid #969696;
	flex-wrap: wrap;
	align-content: center;
`;

const Pantone7542C = styled.div`
	display: inline-flex;
	position: relative;
	align-items: center;
	justify-content: center;
	width: 130px;
	height: 104px;
	border-radius: 50%;
	border: 0.5vw solid #a4bcc2;
	flex-wrap: wrap;
	align-content: center;
`;

const Pantone7544C = styled.div`
	display: inline-flex;
	position: relative;
	align-items: center;
	justify-content: center;
	width: 130px;
	height: 104px;
	border-radius: 50%;
	border: 0.5vw solid #768692;
	flex-wrap: wrap;
	align-content: center;
`;

const Pantone429C = styled.div`
	display: inline-flex;
	position: relative;
	align-items: center;
	justify-content: center;
	width: 130px;
	height: 104px;
	border-radius: 50%;
	border: 0.5vw solid #a2aaad;
	flex-wrap: wrap;
	align-content: center;
`;
//#A4BCC2 -> Pantone 7542C
//#768692 -> Pantone 7544C
//#a2aaad -> Pantone 429C

interface IProps{
    rootNode:OrgChartDto;
    nodes:OrgChartDto[];
}

function OrganizationalChart({rootNode,nodes}:IProps) {

    const renderNodes = (fatherId: number) => {
		const childNodes = nodes.filter((cn) => cn.reportsTo === fatherId);

		if (childNodes.length > 0) {
			return childNodes.map((cn) => (
				<TreeNode key={cn.id} label={getCustomCircle(cn.styled, cn.rol)}>
					{renderNodes(cn.id)}
				</TreeNode>
			));
		}
	};

    const getCustomCircle = (circleStyled: string, rol: string) => {
		switch (circleStyled) {
			case 'DashedLarimar':
				return <DashedLarimar dangerouslySetInnerHTML={{ __html: rol }}></DashedLarimar>;
			case 'SolidLarimar':
				return <SolidLarimar dangerouslySetInnerHTML={{ __html: rol }}></SolidLarimar>;
			case 'SolidBlue':
				return <SolidBlue dangerouslySetInnerHTML={{ __html: rol }}></SolidBlue>;
			case 'SolidGray':
				return <SolidGray dangerouslySetInnerHTML={{ __html: rol }}></SolidGray>;
			case 'Pantone7542C':
				return <Pantone7542C dangerouslySetInnerHTML={{__html:rol}}></Pantone7542C>;
			case 'Pantone7544C':
				return <Pantone7544C dangerouslySetInnerHTML={{__html:rol}}></Pantone7544C>;
			case 'Pantone429C':
				return <Pantone429C dangerouslySetInnerHTML={{__html:rol}}></Pantone429C>;
			default:
				return null;			 	
		}
	};

    return (
			<div>
				<Tree
					lineWidth={'0.5vw'}
					lineColor={'#00C1D5'}
					lineBorderRadius={'2px'}
					lineHeight={'1.8vw'}
					nodePadding={'0.4vw'}
					label={
						<DashedLarimar
							style={{
								width: '159px',
								height: '130px'
							}}
							dangerouslySetInnerHTML={{ __html: rootNode.rol }}
						></DashedLarimar>
					}
				>
					{renderNodes(rootNode.id)}
				</Tree>
			</div>
		);
}

export default OrganizationalChart;