import { SetStateAction, useEffect, useState } from "react";
import NewslettersDto from "../../dtos/NewslettersDto";
import NewsDatePickerComponent from "../datepicker/NewsDatePickerComponent";
import DateFilter from "../filters/DateFilter";
import NewslettersCard from "./newslettersCard";
import { getNewsletters } from "./newslettersService";

function NewslettersMain() {
  const [newsletters, setNewsletters] = useState<NewslettersDto[]>([]);
  const [newslettersFiltered, setNewslettersFiltered] = useState<
    NewslettersDto[]
  >([]);
  const [datesDropdown, setDatesDropdown] = useState<string[]>([]);
  const [dateSelected, setDateSelected] = useState<string>("");
  const [loadMoreThanDefault, setLoadMoreThanDefault] =
    useState<boolean>(false);
  const DEFAULT_NEWSLETTERS_PAGE_SIZE: number = 9;

  useEffect(() => {
    loadNewsLetters();
    setLoadMoreThanDefault(false);
  }, []);

  async function loadNewsLetters() {
    const _newsletters = await getNewsletters();
    setNewsletters(_newsletters);
    setNewslettersFiltered(spliceNewsletter(_newsletters));

    const uniqueDates = Array.from(
      new Set(_newsletters.map((_newsletter) => _newsletter.publishDate))
    );
    setDatesDropdown(uniqueDates);
    setDateSelected("");
  }

  function updateDateFilter(event: React.ChangeEvent<HTMLSelectElement>) {
    event.stopPropagation();
    const _dateSelected = event.target.value;
    setDateSelected(_dateSelected);
  }

  useEffect(() => {
    filterNewsLetter();
  }, [dateSelected]);

  function handleLoadMore() {
    setLoadMoreThanDefault(true);
  }

  useEffect(() => {
    filterNewsLetter();
  }, [loadMoreThanDefault]);

  function filterNewsLetter() {
    if (dateSelected === "") {
      setNewslettersFiltered(spliceNewsletter(newsletters));
      return;
    }

    const _newslettersFiltered = newsletters.filter(
      (newsletter) => newsletter.publishDate === dateSelected
    );
    setNewslettersFiltered(spliceNewsletter(_newslettersFiltered));
  }

  function spliceNewsletter(newsletters2: NewslettersDto[]) {
    if (loadMoreThanDefault) {
      return newsletters2;
    }

    return newsletters2.slice(0, DEFAULT_NEWSLETTERS_PAGE_SIZE);
  }

  return (
    <>
      <main className="main">
        <section className="main-blog-header flex flex-col items-center pt-10 mx-[18px]">
          <div className="container mx-auto lg:flex lg:justify-between lg:items-center mt-16">
            <h1 className="generic-title generic-title--left">
              Boletín Informativo
            </h1>
            <div className="">
              {/* <DateFilter dates={datesDropdown} setDate={setDateSelected} /> */}
              <NewsDatePickerComponent dates={datesDropdown} setDate={setDateSelected}  currentDate={dateSelected} />
            </div>
          </div>
        </section>
        <section className="main-blog-cards mt-10 mx-[18px] font-['Roboto']">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 gap-8 mt-10 main-blog-cards-container md:grid-cols-2 lg:grid-cols-3">
              {newslettersFiltered.map((newsletter) => (
                <NewslettersCard
                  key={newsletter.ID}
                  newsletter={newsletter}
                ></NewslettersCard>
              ))}
            </div>

            <div className="load-more-box">
              {newsletters.length >= 10 ? (
                <>
                  {!loadMoreThanDefault ? (
                    <div className="container mx-auto">
                      <a onClick={handleLoadMore} className="global-button">
                        Cargar Más
                      </a>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default NewslettersMain;
