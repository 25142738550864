import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { useFormContext } from "react-hook-form";
import { validations } from "../Validations/regexValidations";
import AuthorizedPeople from "./AuthorizedPeople";
import FinalCompanyBeneficiaries from "./FinalCompanyBeneficiaries";
import InvestmentBeneficiary from "./InvestmentBeneficiary";
import RelatedPeople from "./RelatedPeople";

function StartInvestCompanyStep6() {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
		<React.Fragment>
			<InvestmentBeneficiary />

			<div className="mb-6 create__requisitos">
				<h4 className="create__requisitos-title">Origen del Patrimonio</h4>

				<div className="group-form lg:items-end lg:flex lg:space-x-9">
					<div className="mb-6 lg:w-1/2 lg:pr-4">
						<label className="global-label">Describa el origen del patrimonio</label>
						<input
							type="text"
							maxLength={40}
							className="global-input"
							placeholder="Describa el origen del patrimonio"
							{...register('heritageOrigin', {
								required: { value: true, message: 'Campo obligatorio' },
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="heritageOrigin"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>

			<FinalCompanyBeneficiaries />
			<RelatedPeople />
			<AuthorizedPeople />
		</React.Fragment>
  );
}

export default StartInvestCompanyStep6;
