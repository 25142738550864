import NewsReadDto from '../../dtos/NewsReadDto';
import NewsCard from '../news/NewsCard';

interface IProps {
	news: NewsReadDto[];
}

function PrensaOtherNews(props: IProps) {
	return (
		<section className="prensa-other-news bg-ultramar text-white pb-10 xl:pb-12 w-full 2xl:pb-14 3xl:pb-16">
			<div className="prensa-other-news__title text-2xl pt-10 pb-8 font-bold flex justify-center xl:text-3xl 2xl:text-4xl 2xl:py-14 3xl:text-[40px] xl:py-12 3xl:py-16">
				Otras noticias de interés
			</div>
			<div className="prensa-other-news-cards-container items-center flex flex-col lg:flex-row md:mx-12 xl:mx-[150px] 2xl:mx-[200px] 3xl:mx-[300px] mx-[18px] lg:mx-24 gap-4 2xl:grid 2xl:grid-cols-2 md:gap-8 2xl:gap-10">
				{props.news.map((n) => (
					<NewsCard news={n} />
				))}
			</div>
		</section>
	);
}

export default PrensaOtherNews;
