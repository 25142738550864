import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';

interface IProps {
	idFondo: number;
	title: string;
	fechaDiaActual: string;
	fechaDiaAnterior: string;
	cuotaDiaActual: string;
	cuotaDiaAnterior: string;
	totalCuotas: string;
}

function ValorCuotaCard(props: IProps) {
	const navigate = useNavigate();

	const formatNumber = (num: number): string => {
		return num.toLocaleString('en-Us', {
			minimumFractionDigits: 6,
			maximumFractionDigits: 6,
		});
	};

	return (
		<div className=" comparador__bg">
			<h2 className="comparador__desc">{props.title}</h2>
			<div className="grid grid-cols-2 gap-4">
				<div className="comparador__bg">
					<p className="small-title">
						Día actual
						<span className="block">{`(${props.fechaDiaActual})`}</span>
					</p>
					<h4 className="comparador__number comparador__number--margin">
						$ {formatNumber(parseFloat(props.cuotaDiaActual))}
					</h4>
				</div>
				<div className="comparador__bg">
					<p className="small-title">
						Día Anterior
						<span className="block">{`(${props.fechaDiaAnterior})`}</span>
					</p>
					<h4 className="comparador__number comparador__number--margin">
						$ {formatNumber(parseFloat(props.cuotaDiaAnterior))}
					</h4>
				</div>
			</div>
			<div className="my-4 comparador__bg">
				<p className="flex justify-between small-title small-title--margin small-title--long">
					Total de cuotas colocadas
					<span>{formatNumber(parseFloat(props.totalCuotas))}</span>
				</p>
			</div>
			<h6
				className="justify-end blog-card-footer__link flex items-baseline lg:text-lg hover:gap-1 hover:text-[#ED8B00] text-[#00C1D5] text-sm font-bold gap-2 transition-[gap] duration-200 cursor-pointer"
				onClick={() =>
					navigate({
						pathname: `${AppVariables.REACT_ROUTER_URL}/generic-fund`,
						search: `?titulo=${props.title}`,
					})
				}
			>
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>{' '}
				Ver más detalles
			</h6>
		</div>
	);
}

export default ValorCuotaCard;
