import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';
import BlogReadDto from '../../dtos/BlogReadDto';
import SocialMediaPopup from '../social-media/SocialMediaPopup';

interface IProps {
	blog: BlogReadDto;
}

function AnotherBlogCard({ blog }: IProps) {
	const navigate = useNavigate();

	return (
		<div className="main-blog-card w-[413px] h-[422px] flex flex-col shadow-[2px_2px_8px_#00235C29]">
			<img
				src={blog.imgUrl}
				alt={blog.title}
				className="object-cover object-center w-full blog-card-img img-blog-card"
			/>
			<div className="flex-col p-4 bg-white blog-card-content">
				<div className="flex justify-between pb-4 blog-card-content-header">
					<h5 className="text-xs font-bold blog-card-header__text">{blog.category}</h5>
					<SocialMediaPopup
						position={'left center'}
						url={`${window.location.protocol}//${
							window.location.host
						}/detalle-blog?titulo=${blog?.title?.replaceAll(' ', '%20')}`}
					/>
				</div>
				<h5
					className="pb-4 another-blog-card-content__title blog-card-content__title--normal cursor-pointer"
					onClick={() => {
						navigate({
							pathname: `${AppVariables.REACT_ROUTER_URL}/detalle-blog`,
							search: `?titulo=${blog.title}`,
						});
					}}
				>
					{blog.title}
				</h5>
				<div className="flex justify-between pb-4 blog-card-content-footer">
					<h5 className="blog-card-footer__date text-xs text-[#414652]/70 font-bold">
						{blog.publicationDate}
					</h5>
					<h6
						onClick={() => {
							navigate({
								pathname: `${AppVariables.REACT_ROUTER_URL}/detalle-blog`,
								search: `?titulo=${blog.title}`,
							});
						}}
						className="blog-card-footer__link flex items-baseline hover:gap-1 hover:text-[#003B7A] text-[#00A5C2] text-sm font-bold gap-2 transition-[gap] duration-200 cursor-pointer"
					>
						<i>
							<FontAwesomeIcon icon={faChevronRight} />
						</i>
						Seguir leyendo
					</h6>
				</div>
			</div>
		</div>
	);
}

export default AnotherBlogCard;
