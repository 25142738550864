import axios from 'axios';
import AppVariables from '../Variables.json';
import SearchResultDto from '../dtos/SearchResultDto';
import {
	blogBreadcrumb,
	boletinBreadcrumb,
	courseBreadcrumb,
	diccionarioBreadcrumb,
	ebooksBreadcrumb,
	fondoBreadcrumb,
	noticiasBreadcrumb,
	podcastBreadcrumb,
	preguntasBreadcrumb,
} from './BreadCrumbs';

const cursosYTalleresNameLabel = "Cursos y Talleres";
const FaqLabel = "Preguntas frecuentes";
const headersAccept = 'application/json; odata=verbose';

const getProperUrl = (listName: string): string => {
	const map_url = new Map<string, string>([
		['Noticias', `${AppVariables.REACT_ROUTER_URL}/detalle-noticia`],
		['Podcast', `${AppVariables.REACT_ROUTER_URL}/detalle-podcast`],
		['Blog', `${AppVariables.REACT_ROUTER_URL}/detalle-blog`],
		['Boletines', `${AppVariables.REACT_ROUTER_URL}/boletin`],
		['Ebooks', `${AppVariables.REACT_ROUTER_URL}/ebooks`],
		[cursosYTalleresNameLabel, `${AppVariables.REACT_ROUTER_URL}/courses`],
		['Diccionario', `${AppVariables.REACT_ROUTER_URL}/diccionario`],
		[FaqLabel, `${AppVariables.REACT_ROUTER_URL}/preguntas-frecuentes`],
		['Fondos', `${AppVariables.REACT_ROUTER_URL}/generic-fund`],
	]);
	return map_url.get(listName);
};

const getProperBreadrumb = (listName: string): JSX.Element => {
	const map_breadcrumb = new Map<string, JSX.Element>([
		['Noticias', noticiasBreadcrumb],
		['Podcast', podcastBreadcrumb],
		['Blog', blogBreadcrumb],
		['Boletines', boletinBreadcrumb],
		['Ebooks', ebooksBreadcrumb],
		[cursosYTalleresNameLabel, courseBreadcrumb],
		['Diccionario', diccionarioBreadcrumb],
		[FaqLabel, preguntasBreadcrumb],
		['Fondos', fondoBreadcrumb],
	]);
	return map_breadcrumb.get(listName);
};

const getProperContent = (text: string, shouldBeBold: string): JSX.Element => {
	const textArray = text.split(shouldBeBold);
	return (
		<h4>
			{textArray.map((item, index) => (
				<>
					{item}
					{index !== textArray.length - 1 && <b>{shouldBeBold}</b>}
				</>
			))}
		</h4>
	);
};

export async function searchNoticias(expression: string): Promise<SearchResultDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Noticias')/items?$filter=(Estado eq 'Activo' and (substringof('${expression}',Title) or substringof('${expression}',ContenidoPrincipal)))`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const searchResults: SearchResultDto[] = [];

	data.d.results.forEach((element: { ID: number; Title: string; ContenidoPrincipal: string }) => {
		const searchResult = new SearchResultDto();
		searchResult.id = element.ID;
		searchResult.title = element.Title;
		searchResult.principalContent = getProperContent(element.ContenidoPrincipal, expression);
		searchResult.url = getProperUrl('Noticias');
		searchResult.breadcrumb = getProperBreadrumb('Noticias');

		searchResults.push(searchResult);
	});

	return searchResults;
}

export async function searchPodcast(expression: string): Promise<SearchResultDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Podcast')/items?$filter=Estado eq 'Activo' and substringof('${expression}',Title)`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const searchResults: SearchResultDto[] = [];

	data.d.results.forEach((element: { ID: number; Title: string; Contenido: string }) => {
		const searchResult = new SearchResultDto();
		searchResult.id = element.ID;
		searchResult.title = element.Title;
		searchResult.principalContent = getProperContent(element.Contenido, expression);
		searchResult.url = getProperUrl('Podcast');
		searchResult.breadcrumb = getProperBreadrumb('Podcast');

		searchResults.push(searchResult);
	});

	return searchResults;
}

export async function searchBlog(expression: string): Promise<SearchResultDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Blog')/items?$filter=(Estado eq 'Activo' and (substringof('${expression}',Title) or substringof('${expression}',Descripcioncorta)))`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const searchResults: SearchResultDto[] = [];

	data.d.results.forEach((element: { ID: number; Title: string; Descripcioncorta: string }) => {
		const searchResult = new SearchResultDto();
		searchResult.id = element.ID;
		searchResult.title = element.Title;
		searchResult.principalContent = getProperContent(element.Descripcioncorta, expression);
		searchResult.url = getProperUrl('Blog');
		searchResult.breadcrumb = getProperBreadrumb('Blog');

		searchResults.push(searchResult);
	});

	return searchResults;
}

export async function searchBoletin(expression: string): Promise<SearchResultDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Boletines')/items?$filter=Estado eq 'Activo' and substringof('${expression}',Descripcion)`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const searchResults: SearchResultDto[] = [];

	data.d.results.forEach((element: { ID: number; Descripcion: string }) => {
		const searchResult = new SearchResultDto();
		searchResult.id = element.ID;
		searchResult.title = element.Descripcion;
		searchResult.principalContent = getProperContent(element.Descripcion, expression);
		searchResult.url = getProperUrl('Boletines');
		searchResult.breadcrumb = getProperBreadrumb('Boletines');

		searchResults.push(searchResult);
	});

	return searchResults;
}

export async function searchEbooks(expression: string): Promise<SearchResultDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Ebooks')/items?$filter=substringof('${expression}',Title)`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const searchResults: SearchResultDto[] = [];

	data.d.results.forEach((element: { ID: number; Title: string; Descripcion: string }) => {
		const searchResult = new SearchResultDto();
		searchResult.id = element.ID;
		searchResult.title = element.Title;
		searchResult.principalContent = getProperContent(element.Descripcion, expression);
		searchResult.url = getProperUrl('Ebooks');
		searchResult.breadcrumb = getProperBreadrumb('Ebooks');

		searchResults.push(searchResult);
	});

	return searchResults;
}

export async function searchCuorses(expression: string): Promise<SearchResultDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle(cursosYTalleresName)/items?$filter=Estado eq 'Activo' and substringof('${expression}',Title)`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const searchResults: SearchResultDto[] = [];

	data.d.results.forEach((element: { ID: number; Title: string; Descripcion: string }) => {
		const searchResult = new SearchResultDto();
		searchResult.id = element.ID;
		searchResult.title = element.Title;
		searchResult.principalContent = getProperContent(element.Descripcion, expression);
		searchResult.url = getProperUrl(cursosYTalleresNameLabel);
		searchResult.breadcrumb = getProperBreadrumb(cursosYTalleresNameLabel);

		searchResults.push(searchResult);
	});

	return searchResults;
}

export async function searchDiccionario(expression: string): Promise<SearchResultDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Diccionario')/items?$filter=Estado eq 'Activo' and substringof('${expression}',Title)`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const searchResults: SearchResultDto[] = [];

	data.d.results.forEach((element: { ID: number; Title: string }) => {
		const searchResult = new SearchResultDto();
		searchResult.id = element.ID;
		searchResult.title = element.Title;
		searchResult.principalContent = getProperContent(element.Title, expression);
		searchResult.url = getProperUrl('Diccionario');
		searchResult.breadcrumb = getProperBreadrumb('Diccionario');

		searchResults.push(searchResult);
	});

	return searchResults;
}

export async function searchPreguntas(expression: string): Promise<SearchResultDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Preguntas-FATCA-FAQ')/items?$filter=substringof('${expression}',Pregunta)`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const searchResults: SearchResultDto[] = [];

	data.d.results.forEach((element: { ID: number; Pregunta: string }) => {
		const searchResult = new SearchResultDto();
		searchResult.id = element.ID;
		searchResult.title = element.Pregunta;
		searchResult.principalContent = getProperContent(element.Pregunta, expression);
		searchResult.url = getProperUrl(FaqLabel);
		searchResult.breadcrumb = getProperBreadrumb(FaqLabel);

		searchResults.push(searchResult);
	});

	return searchResults;
}

export async function searchFondo(expression: string): Promise<SearchResultDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Fondos')/items?$filter=substringof('${expression}',Title)`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const searchResults: SearchResultDto[] = [];

	data.d.results.forEach((element: { ID: number; Title: string; definicion: string }) => {
		const searchResult = new SearchResultDto();
		searchResult.id = element.ID;
		searchResult.title = element.Title;
		searchResult.principalContent = getProperContent(element.definicion, expression);
		searchResult.url = getProperUrl('Fondos');
		searchResult.breadcrumb = getProperBreadrumb('Fondos');

		searchResults.push(searchResult);
	});

	return searchResults;
}
