import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';
import StartInvestReadDto from '../../dtos/StartInvestReadDto';

interface IProps {
	data: StartInvestReadDto;
}

function StartInvestCard({ data }: IProps) {
	const navigate = useNavigate();

	return (
		<div className="generic-invest">
			<div className="generic-invest__title">
				<img
					src={data.imgUrl}
					alt="no-experience-icon"
					className="generic-invest__title--img"
				/>
				<h2 className="generic-invest__title--text">{data.title}</h2>
			</div>
			<p className="generic-invest__content">{data.description}</p>
			<a
				className="generic-invest__go-to-funds"
				onClick={() => navigate(`${AppVariables.REACT_ROUTER_URL}${data.url}`)}
			>
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>
				<span className="">{data.textUrl}</span>
			</a>
		</div>
	);
}

export default StartInvestCard;
