import axios from 'axios';
import AppVariables from '../Variables.json';
import AboutUsBannerReadDto from '../dtos/AboutUsBannerReadDto';
import AboutUsMvvReadDto from '../dtos/AboutUsMvvReadDto';
import AboutUsVideoReadDto from '../dtos/AboutUsVideoReadDto';
import AcknowledgmentReadDto from '../dtos/AcknowledgmentReadDto';
import ExecutivesReadDto from '../dtos/ExecutivesReadDto';
import PromotorReadDto from '../dtos/PromotorReadDto';
import TimelineReadDto from '../dtos/TimelineReadDto';
import { SharePointConsts } from '../util/constants';

export async function getActiveBanner(): Promise<AboutUsBannerReadDto> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Sobre Nosotros Banner')/items?$expand=AttachmentFiles&$filter=Estado eq 'Activo'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const bannerInfo = new AboutUsBannerReadDto();

	if (!data.d.results.length) { return bannerInfo; }

	bannerInfo.id = data.d.results[0].ID;
	bannerInfo.title = data.d.results[0].Title;
	bannerInfo.content = data.d.results[0].Contenido;
	bannerInfo.imgUrl = data.d.results[0].AttachmentFiles.results.length
		? data.d.results[0].AttachmentFiles.results[0].ServerRelativeUrl
		: '';

	return bannerInfo;
}

export async function getActiveAndOrderTimeline(): Promise<TimelineReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Sobre Nosotos Timeline')/items?$expand=AttachmentFiles&$filter=Estado eq 'Activo'&$orderby=Orden asc`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const timelineItmes: TimelineReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const item = new TimelineReadDto();

		item.id = data.d.results[index].ID;
		item.contenido = data.d.results[index].Contenido;
		item.year = data.d.results[index].A_x00f1_o_x002f_s;
		item.imgUrl = data.d.results[index].AttachmentFiles.results.length
			? data.d.results[index].AttachmentFiles.results[0].ServerRelativeUrl
			: '';

		timelineItmes[index] = item;
	}

	return timelineItmes;
}

export async function getActiveMvv(): Promise<AboutUsMvvReadDto> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Mision Vision Valores')/items?$filter=Estado eq 'Activo'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const Mvv = new AboutUsMvvReadDto();

	if (!data.d.results.length) { return Mvv; }

	Mvv.id = data.d.results[0].ID;
	Mvv.mision = data.d.results[0].Mision;
	Mvv.vision = data.d.results[0].Vision;
	Mvv.values = data.d.results[0].Valores;

	return Mvv;
}

export async function getActiveVideo(): Promise<AboutUsVideoReadDto> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Sobre Nosotros Video')/items?$expand=AttachmentFiles&$filter=Estado eq 'Activo'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const video = new AboutUsVideoReadDto();

	if (!data.d.results.length) { return video; }

	video.id = data.d.results[0].ID;

	const filename: string = data.d.results[0].AttachmentFiles.results[0].FileName;
	const splitFilename: string[] = filename.split('.');
	const fileExtension = splitFilename[splitFilename.length - 1];

	if (fileExtension === 'mp4' || fileExtension === 'MP4' || fileExtension === 'mov' || fileExtension === 'MOV') {
		if (data.d.results[0].AttachmentFiles.results.length > 1) {
			video.videoUrl = data.d.results[0].AttachmentFiles.results[0].ServerRelativeUrl;
			video.posterUrl = data.d.results[0].AttachmentFiles.results[1].ServerRelativeUrl;
		} else {
			video.videoUrl = '';
			video.posterUrl = '';
		}
	} else {
		if (data.d.results[0].AttachmentFiles.results.length > 1) {
			video.videoUrl = data.d.results[0].AttachmentFiles.results[1].ServerRelativeUrl;
			video.posterUrl = data.d.results[0].AttachmentFiles.results[0].ServerRelativeUrl;
		} else {
			video.videoUrl = '';
			video.posterUrl = '';
		}
	}

	return video;
}

export async function getActiveAcknowledgmentsByYear(
	year: string
): Promise<AcknowledgmentReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Reconocimientos')/items?$expand=AttachmentFiles&$filter=Estado eq 'Activo' and A_x00f1_o eq '${year}'&$orderby=Orden asc`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const acknowledgments: AcknowledgmentReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const acknowledgment = new AcknowledgmentReadDto();

		acknowledgment.id = data.d.results[index].ID;
		acknowledgment.title = data.d.results[index].Title;
		acknowledgment.content = data.d.results[index].Contenido;
		acknowledgment.year = data.d.results[index].A_x00f1_o;
		acknowledgment.imgUrl = data.d.results[index].AttachmentFiles.results.length
			? data.d.results[index].AttachmentFiles.results[0].ServerRelativeUrl
			: '';

		acknowledgments[index] = acknowledgment;
	}

	return acknowledgments;
}

export async function getActiveAcknowledgments(): Promise<AcknowledgmentReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Reconocimientos')/items?$expand=AttachmentFiles&$filter=Estado eq 'Activo'&$orderby=Orden asc`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const acknowledgments: AcknowledgmentReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const acknowledgment = new AcknowledgmentReadDto();

		acknowledgment.id = data.d.results[index].ID;
		acknowledgment.title = data.d.results[index].Title;
		acknowledgment.content = data.d.results[index].Contenido;
		acknowledgment.year = data.d.results[index].A_x00f1_o;
		acknowledgment.imgUrl = data.d.results[index].AttachmentFiles.results.length
			? data.d.results[index].AttachmentFiles.results[0].ServerRelativeUrl
			: '';

		acknowledgments[index] = acknowledgment;
	}

	return acknowledgments;
}

export async function getActiveAcknowledgmentsYears(): Promise<string[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Reconocimientos')/fields?$filter=EntityPropertyName eq 'A_x00f1_o'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	let years: string[] = [];

	years = data.d.results[0].Choices.results;

	return years;
}

export async function getActiveExecutives(): Promise<ExecutivesReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Nuestro Equipo Ejecutivos')/items?$expand=AttachmentFiles&$filter=Estado eq 'Activo'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const executives: ExecutivesReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const executive = new ExecutivesReadDto();

		executive.id = data.d.results[index].ID;
		executive.name = data.d.results[index].Nombre;
		executive.description = data.d.results[index].Descripcion;
		executive.rol = data.d.results[index].Rol;
		executive.imgUrl = data.d.results[index].AttachmentFiles.results.length
			? data.d.results[index].AttachmentFiles.results[0].ServerRelativeUrl
			: '';

		executives[index] = executive;
	}

	return executives;
}

export async function getActivePromotors(): Promise<PromotorReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Promotores')/items?$expand=AttachmentFiles&$filter=Estado eq 'Activo'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const promotors: PromotorReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const promotor = new PromotorReadDto();

		promotor.id = data.d.results[index].ID;
		promotor.name = data.d.results[index].Nombre;
		promotor.registryNumber = data.d.results[index].Numeroderegistro;
		promotor.fecha = data.d.results[index].Fechadevencimiento;
		promotor.imgUrl = data.d.results[index].AttachmentFiles.results.length
			? data.d.results[index].AttachmentFiles.results[0].ServerRelativeUrl
			: '';

		promotors[index] = promotor;
	}

	return promotors;
}
