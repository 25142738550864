import PodcasReadDto from '../../dtos/PodcastReadDto';
import MainPodcast from './MainPodcast';
import PodcastCard from './PodcastCard';

interface IProps {
	mainPodcast: PodcasReadDto;
	secundaryPodcasts: PodcasReadDto[];
}

function AllPodcasts({ mainPodcast, secundaryPodcasts }: IProps) {
	return (
		<div className="podcast">
			<div className="container mx-auto">
				<div className="flex gap-y-[10px] flex-wrap justify-between podcast-list md:flex lg:grid lg:grid-cols-4 lg:grid-rows-4 lg:gap-5 xl:gap-10">
					{mainPodcast ? <MainPodcast podcast={mainPodcast} /> : <></>}
					{secundaryPodcasts.map((p) => (
						<PodcastCard podcast={p} />
					))}
				</div>
			</div>
		</div>
	);
}

export default AllPodcasts;
