import PromotorReadDto from "../../dtos/PromotorReadDto";

interface IProps{
    promotor:PromotorReadDto;
}

function PromotorCard(props:IProps) {
    return (
			<div className="promotores__container--card">
				<img
					src={props.promotor.imgUrl}
					alt=""
					className="promotores__card--img"
				/>
				<h3 className="promotores__card--title">{props.promotor.name}</h3>
				<h3 className="promotores__card--subtitle">Registro No. {props.promotor.registryNumber}</h3>
				<h4 className="promotores__card--metadata">Vence el {props.promotor.fecha}</h4>
			</div>
		);
}

export default PromotorCard;