class ComparadorReadDto {
  public id: number;
  public perfilderiesgo: string;

  public montoTotaldelPrograma: string;

  public objetodelFondo: string;
  public periodicidaddePagodelosBeneficio: string;
  public fechadepagodelosRendimientos: string;

  public pagodelValordeCuotaFinal: string;
  public fechadeVencimientodelFondo: string;

  public restriccionesGenerales: string;
  public benchmark: string;

  public estructuradecomisiones: string;

  public calificacionderiesgo: string;
}

export default ComparadorReadDto;
