import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import Popup from "reactjs-popup";
import { PopupPosition } from "reactjs-popup/dist/types";
import NewsReadDto from "../../dtos/NewsReadDto";
import ShareIcon from '../../img/podcast/share-icon.svg';

interface IProps {
	position: PopupPosition;
	url: string;
	quote?:string;
}

function SocialMediaPopup({position,url,quote}:IProps) {
    return (
			<Popup
				trigger={
					<img
						src={ShareIcon}
						alt="share-icon"
						className="generic-podcast__autor--share inline cursor-pointer"
					/>
				}
				closeOnDocumentClick
				position={position}
				on={['hover', 'focus', 'click']}
				mouseLeaveDelay={0}
				mouseEnterDelay={200}
				arrow={false}
			>
				<div className="bg-white h-16 rounded-lg flex flex-row gap-2 justify-end items-center p-4 drop-shadow-lg ">
					<FacebookShareButton className="!min-w-0 !outline-0" url={url} quote={quote} >
						<FacebookIcon size={26} />
					</FacebookShareButton>
					<TwitterShareButton className="!min-w-0 !outline-0" url={url}>
						<TwitterIcon size={26} />
					</TwitterShareButton>
					<LinkedinShareButton className="!min-w-0 !outline-0" url={url}>
						<LinkedinIcon size={26} />
					</LinkedinShareButton>
					<WhatsappShareButton className="!min-w-0 !outline-0" url={url}>
						<WhatsappIcon size={26} />
					</WhatsappShareButton>
					<EmailShareButton className="!min-w-0 !outline-0" url={url}>
						<EmailIcon size={26} />
					</EmailShareButton>
				</div>
			</Popup>
		);
}

export default SocialMediaPopup;