import React from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import AppVariables from '../../../Variables.json';
import RenaudarFormulario from '../../pop-up-messages/RenaudarFormulario';

function StartInvestPersonRequirements() {
	const navigate = useNavigate();
	const overlayStyle = { background: 'rgba(0,0,0,0.7)' };
	return (
		<React.Fragment>
			<div className="create">
				<div className="container mx-auto">
					<h2 className="create__title create__title--margin">Invierte con nosotros</h2>
					<h3 className="create__subtitle">Persona</h3>
				</div>
			</div>
			<div className="create__requisitos">
				<h4 className="create__requisitos-title">Requisitos</h4>
				<div className="create__list">
					<p className="list-top">
						<span />
						Copia de dos documentos de identidad legibles:
					</p>
					<p className="list-bottom">
						<span />
						Cédula de identidad para dominicanos
					</p>
					<p className="list-top">
						<span />
						Justificación de sus ingresos mensuales.
					</p>
					<p className="list-top">
						<span />
						Carta de referencia bancaria dirigida a Inversiones Populares S.A.,Puesto de
						Bolsa.
					</p>
					<p className="list-top">
						<span />
						Formularios de apertura debidamente completados y firmados.
					</p>
				</div>
				<div className="create__note">
					<h5>
						<b>Nota adicional</b>
					</h5>
					<p>
						Si el cliente es extranjero es imprescindible el pasaporte vigente. Si este reside
						en el país, otro documento de identidad de dicho país como la tarjeta de SSN; y si
						es menor de edad, se requiere el acta de nacimiento.
					</p>
				</div>
			</div>
			<div className="px-4 pb-10">
				<div className="grid lg:grid-cols-2 lg:gap-6 create__actions gap-y-6 lg:gap-y-0">
					<a
						className="global-button global-button--full lg:order-last"
						onClick={() => navigate(`${AppVariables.REACT_ROUTER_URL}/formulario-persona`)}
					>
						Nueva solicitud
					</a>
					<Popup
						trigger={
							<a className="global-button global-button--orange global-button--full">
								Reanudar solicitud
							</a>
						}
						position="right center"
						modal
						nested
						{...{ overlayStyle }}
					>
						{(close: undefined) => <RenaudarFormulario close={close} />}
					</Popup>
				</div>
			</div>
		</React.Fragment>
	);
}

export default StartInvestPersonRequirements;
