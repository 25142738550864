// import { useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import './date.css';
registerLocale('es', es);

interface IProps {
	dates: string[];
	setDate: React.Dispatch<React.SetStateAction<string>>;
	currentDate: string;
}

const NewsDatePickerComponent = ({ dates, setDate, currentDate }: IProps) => {
	function convertToDate(dateString: string) {
		//  Convert a "dd/MM/yyyy" string into a Date object
		const d = dateString.split('/');
		return new Date(`${d[2]}/${d[1]}/${d[0]}`)
	}

	return (
		<DatePicker
			dateFormat="dd/MM/yyyy"
			onChange={(date) => {
				setDate(date === null ? '' : date.toLocaleDateString('en-CA'));
				console.log(date);
				console.log(currentDate);
			}}
			selected={currentDate === '' ? null : new Date(currentDate.replaceAll('-', '/'))}
			value={currentDate === '' ? 'Fecha' : currentDate.replaceAll('-', '/')}
			// placeholderText="Fecha"
			locale="es"
			// customInput={<CustomInput />}
			className="news-datepicker"
			includeDates={dates.map((d) => convertToDate(d))}
			peekNextMonth
			showMonthDropdown
			showYearDropdown
			dropdownMode="select"
			isClearable
		/>
	);
};

export default NewsDatePickerComponent;
// "2022-08-15"
// "Thu Jan 06 2022 00:00:00 GMT-0400 (Bolivia Time)".slice(11, 15) + "-"+ months["Thu Jan 06 2022 00:00:00 GMT-0400 (Bolivia Time)".slice(4, 7)]+"-"+"Thu Jan 06 2022 00:00:00 GMT-0400 (Bolivia Time)".slice(8, 10);
