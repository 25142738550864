import React, { useEffect, useState } from 'react';
import Media from 'react-media';
import DesktopDevicesCarousel from '../components/home-carousel/DesktopDevicesCarousel';
import TouchDevicesCarousel from '../components/home-carousel/TouchDevicesCarousel';
import Tools from '../components/home-tools/Tools';
import News from '../components/news/News';
import HomePodcasts from '../components/podcast/HomePodcasts';
import StartInvest from '../components/start-invest/StartInvest';
import PodcastReadDto from '../dtos/PodcastReadDto';
import { getSectionStatus } from '../services/GenericServices';
import { getActivePodcasttopN } from '../services/PodcastServices';

function Home() {
	const [podcasts, setPodcasts] = useState<PodcastReadDto[]>([]);
	const [podcastSectionStatus, setPodcastSectionStatus] = useState<boolean>();

	const fetchPodcasts = async () => {
		const fetchedPodcasts = await getActivePodcasttopN(4);
		setPodcasts(fetchedPodcasts);
	};

	const setSectionStatus = async () => {
		const PodcastStatus = await getSectionStatus('Home - Podcast');
		setPodcastSectionStatus(PodcastStatus.visibility);
	};

	useEffect(() => {
		setSectionStatus();
		fetchPodcasts();
	}, []);

	return (
		<React.Fragment>
			<Media queries={{ mobile: '(max-width:768px)', desktop: '(min-width:769px)' }}>
				{(matches) => (
					<React.Fragment>
						{matches.mobile && <TouchDevicesCarousel />}
						{matches.desktop && <DesktopDevicesCarousel />}
					</React.Fragment>
				)}
			</Media>

			<StartInvest />
			<News />
			{podcastSectionStatus === true ? <HomePodcasts podcasts={podcasts} /> : <></>}
			<Tools />
		</React.Fragment>
	);
}

export default Home;
