import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';
import CourseReadDto from '../../dtos/CourseReadDto';
import playVideoIcon from '../../img/general/play.svg';

interface IProps {
	course: CourseReadDto;
}

function CourseCard(props: IProps) {
	const navigate = useNavigate();

	const goToDetalleDeCurso = () => {
		navigate({
			pathname: `${AppVariables.REACT_ROUTER_URL}/detalle-curso`,
			search: `?titulo=${props.course.title}`,
		});
	};

	return (
		<div className="flex flex-col w-full main-blog-card h-fit">
			<h5 className="mb-3 text-xs font-bold lg:text-sm blog-card-header__text ">
				{props.course.categoty}
			</h5>
			<div className="relative cursor-pointer" onClick={() => goToDetalleDeCurso()}>
				<div className="absolute w-full h-[100%] overlay flex items-center justify-center">
					<img src={playVideoIcon} alt="video" />
				</div>
				<img
					src={props.course.imgUrl}
					alt=""
					className="object-cover object-center w-full blog-card-img"
				/>
			</div>
			<div
				className="flex-col pt-4 blog-card-content cursor-pointer"
				onClick={() => goToDetalleDeCurso()}
			>
				<h5 className="blog-card-content__title blog-card-content__title--normal">
					{props.course.title}
				</h5>
			</div>
		</div>
	);
}

export default CourseCard;
