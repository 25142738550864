import { useNavigate } from 'react-router-dom';
import AppVariables from '../../../Variables.json';
import Persona from '../../../img/general/create-icon.svg';
import Empresa from '../../../img/general/create-empresa.svg';

function BaseStartInvest() {
	const navigate = useNavigate();

	return (
		<div className="create">
			<div className="container mx-auto">
				<h2 className="create__title">Invierte con nosotros</h2>
				<div className="grid gap-y-6 lg:grid-cols-2 lg:gap-y-0 gap-x-16 xl:pt-10 xl:pb-20">
					<a
						className="create__box"
						onClick={() => navigate(`${AppVariables.REACT_ROUTER_URL}/requisitos-persona`)}
					>
						<img src={Persona} alt="" className="create__icon" />
						<h3 className="create__type">Persona</h3>
					</a>
					<a
						className="create__box"
						onClick={() => navigate(`${AppVariables.REACT_ROUTER_URL}/requisitos-empresa`)}
					>
						<img src={Empresa} alt="" className="create__icon" />
						<h3 className="create__type">Empresa o institución</h3>
					</a>
				</div>
			</div>
		</div>
	);
}

export default BaseStartInvest;
