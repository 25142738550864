import { useEffect, useState } from 'react';
import Select from 'react-select';
import { yearFilterStyleDesktop, yearFilterStyleMobile } from '../Utility/FormStyles';

interface IProps {
	years: string[];
	setCurrentYear: React.Dispatch<React.SetStateAction<string>>;
}

function DocumentFilterByYear({ years, setCurrentYear }: IProps) {
	const [isDesktop, setIsDesktop] = useState(false);

	useEffect(() => {
		window.screen.width >= 1024 && setIsDesktop(true);
	}, [window.screen.width]);

	return (
		<div className="mx-5 mt-5 lg:mt-0">
			<Select
				styles={isDesktop ? yearFilterStyleDesktop : yearFilterStyleMobile}
				options={[{ value: 'Todos', label: 'Todos' }].concat(
					years.map((y) => ({ value: y, label: y }))
				)}
				onChange={(value: any) => {
					setCurrentYear(value.value);
				}}
				isMulti={false}
				placeholder="Fecha"
				isLoading={false}
			/>
		</div>
	);
}

export default DocumentFilterByYear;
