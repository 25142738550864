import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import SelectComponent from "../../Select/SelectComponent";
import {
  fe_s8_capacidad_perdidas,
  fe_s8_destino_ingresos,
  fe_s8_distribucion_activos,
  fe_s8_fondo_emergencia,
  fe_s8_frases_identificacion,
  fe_s8_porcentaje_patrimonio,
  fe_s8_principal_objetivo,
} from "../../Utility/SelectOptions";

function StartInvestCompanyStep8() {
  const emptyFieldErrorMessage = "Campo obligatorio";
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
		<React.Fragment>
			<div className="create__requisitos">
				<h4 className="create__requisitos-title">
					Objetivos financieros y tolerancia de riesgo del inversionista
				</h4>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">¿Mantiene usted un fondo para emergencias?</label>
						<Controller
							control={control}
							name="financialObjectivesHasEmergyFund"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fe_s8_fondo_emergencia}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="financialObjectivesHasEmergyFund"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">Distribución activos líquidos</label>
						<Controller
							control={control}
							name="financialObjectivesLiquidAssetsDistribution"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fe_s8_distribucion_activos}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="financialObjectivesLiquidAssetsDistribution"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">
							Destino de los ingresos generados por sus inversiones
						</label>
						<Controller
							control={control}
							name="financialObjectivesIncomeDestination"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fe_s8_destino_ingresos}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="financialObjectivesIncomeDestination"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">
							¿Qué porcentaje de su patrimonio total tiene previsto invertir en este momento?
						</label>
						<Controller
							control={control}
							name="financialObjectivesTotalAssetsPercentage"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fe_s8_porcentaje_patrimonio}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="financialObjectivesTotalAssetsPercentage"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">
							¿Cuál es su principal objetivo de inversión?
						</label>
						<Controller
							control={control}
							name="financialObjectivesOfInvestment"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fe_s8_principal_objetivo}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="financialObjectivesOfInvestment"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">
							¿Qué frase lo identifica mejor con respecto a las inversiones?
						</label>
						<Controller
							control={control}
							name="financialObjectivesBestPhrase"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fe_s8_frases_identificacion}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="financialObjectivesBestPhrase"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9 lg:pr-9">
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">
							¿Qué capacidad de absorber perdidas de capital usted entiende tener?
						</label>
						<Controller
							control={control}
							name="financialObjectivesCapitalLossesAbsorption"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fe_s8_capacidad_perdidas}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="financialObjectivesCapitalLossesAbsorption"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
  );
}

export default StartInvestCompanyStep8;
