import { ErrorMessage } from '@hookform/error-message';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import SelectComponent from '../../Select/SelectComponent';
import { fp_s3_relacion_intermediario } from '../../Utility/SelectOptions';
import { validations } from '../Validations/regexValidations';
import { ValidationMessages } from '../../Utility/ValidationMessages';

function RequisitosBeneficiariosForm(props: any) {
	const intermediaryText = '.intermediary';
	const emptyFieldErrorMessage = 'Campo obligatorio';
	const baseName = `beneficiaries[${props.number}]`;
	const intermediary = props.useWatch({
		control: props.control,
		name: `${baseName}${intermediaryText}`,
	});
	const idType = props.useWatch({
		control: props.control,
		name: `${baseName}.idType`,
	});

	const [numIdPattern, setNumIdPattern] = useState<RegExp>();

	const getNumIdPattern = (idType: string): RegExp => {
		if (idType === 'Cedula') {
			return /^\d{11}/;
		} else {
			return /^[A-Za-z0-9? ,-]+$/i;
		}
	};

	return (
		<div className="mt-6 create__requisitos datos-beneficiarios submitable-form form">
			<h4 className="create__requisitos-title">Declaración de beneficiarios</h4>
			<div className="lg:items-start lg:flex lg:space-x-9">
				<div className="mb-4 group-form lg:w-1/2">
					<label className="global-label">
						¿Actúa usted como intermediario de otra persona (física o jurídica) que sea el
						verdadero beneficiario de la inversión?
					</label>
					<div className="document-box">
						<input
							id={`step3-intermediary-yes-${props.number}`}
							className="toggle toggle-left"
							type="radio"
							value="Si"
							{...props.register(`${baseName}${intermediaryText}`, {
								required: { value: true, message: emptyFieldErrorMessage },
							})}
							onClick={() => {
								props.resetField(`${baseName}.intermediaryRelation`);
								props.resetField('country');
							}}
						/>

						<label htmlFor={`step3-intermediary-yes-${props.number}`} className="btn">
							Si
						</label>
						<input
							id={`step3-intermediary-no-${props.number}`}
							className="toggle toggle-right"
							type="radio"
							value="No"
							{...props.register(`${baseName}${intermediaryText}`, {
								required: { value: true, message: emptyFieldErrorMessage },
							})}
							onClick={() => {
								props.resetField(`${baseName}.intermediaryRelation`);
								props.resetField('country');
							}}
						/>

						<label htmlFor={`step3-intermediary-no-${props.number}`} className="btn">
							No
						</label>
					</div>
				</div>
				{intermediary === 'Si' && (
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							En caso de marcar afirmativo, describa la relación que existe con el cliente o
							beneficiario final:
						</label>
						<Controller
							control={props.control}
							name={`${baseName}.intermediaryRelation`}
							rules={{
								required: {
									value: intermediary === 'Si',
									message: emptyFieldErrorMessage,
								},
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s3_relacion_intermediario}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>

						<div className="h-6 w-full">
							<ErrorMessage
								errors={props.errors}
								name={`${baseName}.intermediaryRelation`}
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				)}
			</div>
			{intermediary === 'Si' && (
				<>
					{' '}
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Nombres</label>
							<input
								type="text"
								className="global-input"
								placeholder="Nombres"
								{...props.register(`${baseName}.names`, {
									required: {
										value: intermediary === 'Si',
										message: emptyFieldErrorMessage,
									},
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={props.errors}
									name={`${baseName}.names`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Apellidos</label>
							<input
								type="text"
								className="global-input"
								placeholder="Apellidos"
								{...props.register(`${baseName}.lastNames`, {
									required: {
										value: intermediary === 'Si',
										message: emptyFieldErrorMessage,
									},
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={props.errors}
									name={`${baseName}.lastNames`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="lg:items-start lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Tipo de identificación</label>
							<div className="document-box">
								<input
									id={`step3-idType-cedula-${props.number}`}
									className="toggle toggle-left"
									type="radio"
									value="Cedula"
									{...props.register(`${baseName}.idType`, {
										required: {
											value: intermediary === 'Si',
											message: emptyFieldErrorMessage,
										},
										onChange: () =>
											setNumIdPattern(
												getNumIdPattern(props.getValues(`${baseName}.idType`))
											),
									})}
								/>

								<label htmlFor={`step3-idType-cedula-${props.number}`} className="btn">
									Cédula
								</label>
								<input
									id={`step3-idType-pasaporte-${props.number}`}
									className="toggle toggle-right"
									type="radio"
									value="Pasaporte"
									{...props.register(`${baseName}.idType`, {
										required: {
											value: intermediary === 'Si',
											message: emptyFieldErrorMessage,
										},
										onChange: () =>
											setNumIdPattern(
												getNumIdPattern(props.getValues(`${baseName}.idType`))
											),
									})}
								/>

								<label htmlFor={`step3-idType-pasaporte-${props.number}`} className="btn">
									Pasaporte
								</label>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Número de identificación</label>
							<input
								type="text"
								maxLength={11}
								className="global-input"
								placeholder="000-0000000-0"
								{...props.register(`${baseName}.idNoBeneficiary`, {
									required: {
										value: intermediary === 'Si',
										message: emptyFieldErrorMessage,
									},
									pattern: {
										value: numIdPattern,
										message: ValidationMessages.invalidCharacters,
									},
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={props.errors}
									name={`${baseName}.idNoBeneficiary`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Ocupación</label>
							<input
								type="text"
								className="global-input"
								placeholder="Ocupación"
								{...props.register(`${baseName}.ocupation`, {
									required: {
										value: intermediary === 'Si',
										message: emptyFieldErrorMessage,
									},
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={props.errors}
									name={`${baseName}.ocupation`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Teléfono de contacto</label>
							<input
								type="text"
								className="global-input"
								placeholder="809 000 0000"
								{...props.register(`${baseName}.contactTel`, {
									required: {
										value: intermediary === 'Si',
										message: emptyFieldErrorMessage,
									},
									pattern: validations.soloNumeros,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={props.errors}
									name={`${baseName}.contactTel`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2 xl:pr-4">
						<label className="global-label">Dirección</label>
						<input
							type="text"
							className="global-input"
							placeholder="Dirección"
							{...props.register(`${baseName}.address`, {
								required: {
									value: intermediary === 'Si',
									message: emptyFieldErrorMessage,
								},
								pattern: validations.letrasNumerosEspaciosEspeciales,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={props.errors}
								name={`${baseName}.address`}
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default RequisitosBeneficiariosForm;
