import { useState, useEffect } from 'react';
import FundReadDto from '../../dtos/FundReadDto';
import { getFundsByFilter, getTiposDeFondo, getTiposDeMoneda, getTiposDePlazo } from '../../services/FundServices';
import FundCard from './FundCard';
import FundFilter from './FundFilter';

function MainFunds() {
	const [funds, setFunds] = useState<FundReadDto[]>([]);

	const [fundSelected, setFund] = useState('Todos');
	const [currencySelected, setCurrency] = useState('Todos');
	const [plazoSelected, setPlazo] = useState('Todos');

	const [fundTypes, setFundTypes] = useState([]);
	const [currencyTypes, setCurrencyTypes] = useState([]);
	const [termTypes, setTermTypes] = useState([]);

	useEffect(() => {
		const fetchTypes = async () => {
			const fetchedFundTypes = await getTiposDeFondo();
			const fetchedCurrencyTypes = await getTiposDeMoneda();
			const fetchedTermTypes = await getTiposDePlazo();

			setFundTypes(fetchedFundTypes);
			setCurrencyTypes(fetchedCurrencyTypes);
			setTermTypes(fetchedTermTypes);
		}

		fetchTypes();
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			const data = await getFundsByFilter(fundSelected, currencySelected, plazoSelected);
			setFunds(data);
		};
		fetchData();
	}, [fundSelected, currencySelected, plazoSelected]);

	function renderFundsCards() {
		return funds.map((f) => (
			<FundCard
				key={f.id}
				title={f.title}
				definicion={f.definicion}
				moneda={f.moneda}
				plazoDeInversion={f.plazoDeInversion === 'ninguno' ? f.fecha : f.plazoDeInversion}
				rendimientoUltimos30dias={f.rendimientoUltimos30dias}
				tipoDeFondo={f.tipoDeFodo}
				valorCuota={f.valorCuota}
				id={f.id}
			/>
		));
	}

	return (
		<section className="main-fonds">
			<h1 className="main-fonds__title">Listado de Fondos</h1>
			<FundFilter
				fundtypes={fundTypes}
				currencyTypes={currencyTypes}
				termTypes={termTypes}
				setFundTypes={setFund}
				setCurrencyTypes={setCurrency}
				setTermTypes={setPlazo}
			/>
			<div className="main-fonds-cards-container">
				<div className="main-fonds-cards">{renderFundsCards()}</div>
			</div>
		</section>
	);
}

export default MainFunds;
