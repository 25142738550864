import { useNavigate } from 'react-router-dom';

interface IProps {
	imgUrl: string;
	subtitle: string;
	title: string;
	content: string;
	linkText: string;
	linkUrl: string;
}

function CarouselStaticContent(props: IProps) {
	// const navigate = useNavigate();

	return (
		<div className="static-content">
			{props.subtitle !== '' ? (
				<div className="main-banner__subtitle">
					{props.imgUrl ? (
						<img
							src={props.imgUrl}
							alt="banner-subtitle-icon"
							className="main-banner__subtitle--img"
						/>
					) : (
						<></>
					)}

					<h3
						className="main-banner__subtitle--text"
						dangerouslySetInnerHTML={{ __html: props.subtitle }}
					></h3>
				</div>
			) : (
				<></>
			)}

			<h1 className="main-banner__title" dangerouslySetInnerHTML={{ __html: props.title }}></h1>
			<p
				className="main-banner__content"
				dangerouslySetInnerHTML={{ __html: props.content }}
			></p>

			{props.linkText !== '' ? (
				<a
					className="main-banner__visit-inversionista-popular"
					// onClick={() => {
					// 	navigate(props.linkUrl);
					// }}
					href={props.linkUrl}
					target="_blank"
				>
					{props.linkText}
				</a>
			) : (
				<></>
			)}
		</div>
	);
}

export default CarouselStaticContent;
