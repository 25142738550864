import React, { useEffect, useState } from 'react';
import AllPodcasts from '../components/podcast/AllPodcasts';
import PodcastFilter from '../components/podcast/Podcastfilter';
import PodcastReadDto from '../dtos/PodcastReadDto';

import {
	getActivePodcast,
	getActivePodcasttopN,
	getPodcastsByDate,
} from '../services/PodcastServices';

function Podcast() {
	const [mainPodcast, setMainPodcast] = useState<PodcastReadDto>(new PodcastReadDto());
	const [secundaryPodcast, setSecPodcast] = useState<PodcastReadDto[]>([]);
	const [allPodcast, setAllPodcast] = useState<PodcastReadDto[]>([]);
	const [dates, setDates] = useState([]);
	const [date, setDate] = useState<string>('');
	const [numOfPodcast, setNumOfPodcast] = useState(0);
	const [loadMore, setLoadMore] = useState<boolean>(true);

	const fetchPodcasts = async () => {
		let podcasts;
		let main: PodcastReadDto;
		let secundary: PodcastReadDto[] = [];

		if (date === '') {
			podcasts = await getActivePodcasttopN(10);
			setAllPodcast(podcasts);
			setNumOfPodcast(podcasts.length);
			main = podcasts.shift(); //remove first element of the array and returns it
			secundary = podcasts;
		} else if (date !== '') {
			podcasts = await getPodcastsByDate(date);
			main = podcasts.shift();
			secundary = podcasts;
		}

		setMainPodcast(main);
		setSecPodcast(secundary);
	};

	const loadAllPodcast = async () => {
		const podcasts = await getActivePodcast();
		const main = podcasts[0];
		const secundary = podcasts.filter((p) => p.id !== main.id);

		setAllPodcast(podcasts);
		setNumOfPodcast(podcasts.length);
		setMainPodcast(main);
		setSecPodcast(secundary);
	};

	const fetchDates = async () => {
		const fetchedPodcasts = await getActivePodcast();
		const uniqueDates = Array.from(new Set(fetchedPodcasts.map((p) => p.date)));
		setDates(uniqueDates);
	};

	useEffect(() => {
		fetchDates();
	}, []);

	useEffect(() => {
		fetchPodcasts();
	}, [date]);

	return (
		<React.Fragment>
			<PodcastFilter setDate={setDate} dates={dates} currentDate={date} />

			{allPodcast.length > 0 ? (
				<AllPodcasts mainPodcast={mainPodcast} secundaryPodcasts={secundaryPodcast} />
			) : (
				<></>
			)}

			{loadMore === true && (
				<div
					className="load-more-box"
					onClick={() => {
						loadAllPodcast();
						setLoadMore(false);
					}}
				>
					<div className="container mx-auto">
						<a className="global-button">Cargar Más</a>
					</div>
				</div>
			)}
		</React.Fragment>
	);
}

export default Podcast;
