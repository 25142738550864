class NewslettersDto {
  shortDescription: string;
  ID: number;
  Order: number;
  thumbnail: string;
  thumbnailName: string;
  downloadLink: string;
  publishDate: string;
}

export default NewslettersDto;
