interface IProps {
	html: string;
	zoom: boolean;
}

function PrensaDetailContent(props: IProps) {
  return (
		<div
			dangerouslySetInnerHTML={{ __html: props.html }}
			className={`prensa-detail-content flex flex-col mt-2 md:mx-12 xl:mx-[150px] 2xl:mx-[200px] 3xl:mx-[300px] mx-[18px] lg:mx-24 mb-10 3xl:mt-8 3xl:mb-16`}
			style={props.zoom? {zoom:'120%'}:{}}
		></div>
  );
}

export default PrensaDetailContent;
