import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';

interface IProps {
	id: number;
	title: string;
	definicion: string;
	rendimientoUltimos30dias: number;
	valorCuota: string;
	tipoDeFondo: string;
	moneda: string;
	plazoDeInversion: string;
}

function FundCard(props: IProps) {
	const navigate = useNavigate();

	return (
		<div className="main-fonds-card">
			<h1 className="main-fonds-card__title">{props.title}</h1>
			<p className="main-fonds-card__text">{props.definicion}</p>
			<div className="main-fonds-card-subcards">
				<div className="main-fonds-card-subcard">
					<h1 className="main-fonds-card-subcard__title">
						Rendimiento <br />
						(Últimos 30 días)
					</h1>
					<span className="main-fonds-card-subcard__value">
						{props.rendimientoUltimos30dias}%
					</span>
				</div>
				<div className="main-fonds-card-subcard">
					<h1 className="main-fonds-card-subcard__title">Valor Cuota</h1>
					<span className="main-fonds-card-subcard__value">$ {props.valorCuota}</span>
				</div>
			</div>
			<div className="">
				<div className="main-fonds-card-description__container">
					<span>Tipo de fondo</span>
					<span>{props.tipoDeFondo}</span>
				</div>
				<div className="main-fonds-card-description__container">
					<span>Moneda</span>
					<span>{props.moneda}</span>
				</div>
				<div className="main-fonds-card-description__container">
					<span>Plazo de inversión</span>
					<span>{props.plazoDeInversion}</span>
				</div>
			</div>

			<div className="main-fonds-card-buttons__container">
				<button
					onClick={() => navigate(`${AppVariables.REACT_ROUTER_URL}/comparador-de-fondos`)}
				>
					Comparar
				</button>
				<button
					onClick={() =>
						navigate({
							pathname: `${AppVariables.REACT_ROUTER_URL}/generic-fund`,
							search: `?titulo=${props.title}`,
						})
					}
				>
					Más detalles
				</button>
			</div>
		</div>
	);
}

export default FundCard;
