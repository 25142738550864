import TimelineReadDto from "../../dtos/TimelineReadDto";

interface IProps {
  item: TimelineReadDto;
}

function TimelineCard(props: IProps) {
  return (
    <div className="timeline-container-card">
      <div className="timeline-container-card--section">
        <img
          src={props.item.imgUrl}
          alt={props.item.id.toString()}
          className="timeline-container-card--img"
        />
        <p
          className="timeline-container-card--content"
          dangerouslySetInnerHTML={{ __html: props.item.contenido }}
        ></p>
      </div>
      <div className="timeline-container-card--section2">
        <h1 className="timeline-container-card-year">{props.item.year}</h1>
      </div>
    </div>
  );
}

export default TimelineCard;
