import React from 'react';
import FundAdvisor from '../components/funds-advisor/FundAdvisor';
import MainFunds from '../components/funds-advisor/MainFunds';
import FundTools from '../components/funds-advisor/FundTools';
import Tools from '../components/home-tools/Tools';


function FondosDeInversion() {
    return (
			<React.Fragment>
				<FundAdvisor />
				<MainFunds />
				<Tools />
			</React.Fragment>
		);
}

export default FondosDeInversion;