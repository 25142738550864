import EbookReadDto from "../../dtos/EbookReadDto";
import ArrowDown from '../../img/general/arrow-down.svg';

interface IProps {
	ebook: EbookReadDto;
}

function EbookCard(props:IProps) {

    return (
			<div className="ebooks__item">
				<div className="ebooks__preview">
					<img src={props.ebook.portadaUrl} alt="" className="ebooks__image" />
				</div>
				<div className="ebooks__text">
					<h2 className="ebooks__title">{props.ebook.title}</h2>
					<p className="ebooks__author">{props.ebook.autor}</p>
					<div className="ebooks__actions">
						<a className="ebooks__read global-button global-button--orange" href={props.ebook.pdfUrl} target="_blank">Leer</a>
						<a className="ebooks__download" href={props.ebook.pdfUrl} download>
							<span>
								<img src={ArrowDown} alt="" />
							</span>
						</a>
					</div>
				</div>
			</div>
		);
}

export default EbookCard;