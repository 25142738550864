import { useEffect, useState } from 'react';
import StartInvestReadDto from '../../dtos/StartInvestReadDto';
import StartInvestCard from './StartInvestCard';
import { getAllStartInvestCategories } from '../../services/StartInvestServices';

function StartInvest() {
	
	const [categories, setCategories] = useState<StartInvestReadDto[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			const data = await getAllStartInvestCategories();
			setCategories(data);
		};

		fetchData();
	}, []);

	const renderCategories = () => {
		return categories.map((c) => <StartInvestCard data={c} />);
	};

	return (
		<div className="start-invest">
			<h1 className="generic-title">Comienza a invertir</h1>
			<p className="generic-subtitle">Selecciona tu nivel de experiencia</p>
			<div className="start-invest-container">{renderCategories()}</div>
		</div>
	);
}

export default StartInvest;
