import AboutUsBannerReadDto from "../../dtos/AboutUsBannerReadDto";
import GCBannerReadDto from "../../dtos/GCBannerReadDto";

interface IProps{
    BannerInfo:AboutUsBannerReadDto | GCBannerReadDto;
}

function AboutUsBanner(props:IProps) {
    return (
			<div className="banner-wraper">
				<div className="top-banner">
					<div className="top-banner-section1__white-rectangle" />
					<div className="top-banner-section1">
						<div className="top-banner-section1__orange-rectangle" />
						<img src={props.BannerInfo.imgUrl} alt={props.BannerInfo.title} className="top-banner-section1__img" />
						<div className="top-banner-section1__blue-rectangle" />
					</div>
					<div className="top-banner__conocenos">
						<h1 className="top-banner__conocenos--title">{props.BannerInfo.title}</h1>
						<p className="top-banner__conocenos--content">{props.BannerInfo.content}</p>
					</div>
				</div>
			</div>
		);
}

export default AboutUsBanner;