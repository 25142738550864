import { useNavigate } from 'react-router-dom';
import SearchResultDto from '../../dtos/SearchResultDto';

interface IProps {
	result: SearchResultDto;
	setSearchParam: any;
	currentExpression: string;
}

function ResultCard({ result, setSearchParam, currentExpression }: IProps) {
	const navigate = useNavigate();

	return (
		<div
			className="search-box cursor-pointer"
			onClick={() => {
				const search = {
					expr: currentExpression,
				};

				setSearchParam(search, { replace: true });
				navigate({ pathname: result.url, search: `?titulo=${result.title}` });
			}}
		>
			<h3>{result.title}</h3>
			{result.principalContent}
			{result.breadcrumb}
		</div>
	);
}

export default ResultCard;
