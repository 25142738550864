import PrivacyPolicyTermsConditions from '../../components/PrivacyPolicy_TermsConditions/PrivacyPolicyTermsConditions';
import { privacyPolicyTermsConditionsProps } from '../../components/PrivacyPolicy_TermsConditions/PrivacyPolicyTermsConditions';
import axios from 'axios';
import { useState, useEffect } from 'react';
import AppVariables from '../../Variables.json';
import { SharePointConsts } from '../../util/constants';

export default function PrivacyPolicy() {
	const [pageData, setPageData] = useState<privacyPolicyTermsConditionsProps>({
		title: '',
		subTitle: '',
		content: '',
		linkTitle: '',
		linkText: '',
		link: '',
		miniFooter: '',
	});

	async function getPageData() {
		const resultsArr = await axios
			.get(
				`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Politicas-Privacidad-Terminos-Condiciones')/items?$filter=Page eq 'Políticas'`,

				{
					headers: {
						'X-RequestDigest': (
							document.getElementById('__REQUESTDIGEST') as HTMLInputElement
						).value,

						Accept: SharePointConsts.acceptNoMetadata,
						'Content-Type': SharePointConsts.contentTypeNoMetadata,
					},
				}
			)
			.then((response) => response.data.value[0])
			.catch((e) => console.error(e));

		setPageData({
			title: resultsArr?.Title,
			subTitle: resultsArr?.SubTitle,
			content: resultsArr?.Content,
			linkTitle: resultsArr?.LinkTitle0,
			linkText: resultsArr?.LinkText,
			link: resultsArr?.Link?.Description,
			miniFooter: resultsArr?.MiniFooter,
		});
	}

	useEffect(() => {
		getPageData();
	}, []);

	return (
		<PrivacyPolicyTermsConditions
			title={pageData.title}
			subTitle={pageData.subTitle}
			content={pageData.content}
			linkTitle={pageData.linkTitle}
			linkText={pageData.linkText}
			link={pageData.link}
			miniFooter={pageData.miniFooter}
		/>
	);
}
