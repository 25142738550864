import axios from 'axios';
import CarouselContentReadDto from '../dtos/CarouselContentReadDto';
import AppVariables from '../Variables.json';
import CarouselImageReadDto from '../dtos/CarouselImageReadDto';
import CarouselReadDto from '../dtos/CarouselReadDto';
import { SharePointConsts } from '../util/constants';

export async function getCarouselContent(): Promise<CarouselContentReadDto> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Contenido Carousel')/items?$expand=AttachmentFiles&$filter=Estado eq 'Activo'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const content = new CarouselContentReadDto();

	if (!data.d.results.length) { return content; }

	content.id = data.d.results[0].ID;
	content.imgUrl = data.d.results[0].AttachmentFiles.results.length
		? data.d.results[0].AttachmentFiles.results[0].ServerRelativeUrl
		: '';
	content.subtitle = data.d.results[0].Subtitulo;
	content.title = data.d.results[0].Titulo;
	content.content = data.d.results[0].Contenido;
	content.buttonText = data.d.results[0].Textoboton;
	content.buttonUrl = data.d.results[0].Urlboton;

	return content;
}

export async function getCarouselImagesByCategory(
	category: string
): Promise<CarouselImageReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Imagenes Carousel')/items?$expand=AttachmentFiles&$filter=(Estado eq 'Activo') and (Categoria eq '${category}')&$orderby=Orden asc`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const images: CarouselImageReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const image = new CarouselImageReadDto();

		image.id = data.d.results[index].ID;
		image.order = data.d.results[index].Orden;
		image.category = data.d.results[index].Categoria;
		image.imgUrl = data.d.results[index].AttachmentFiles.results.length
			? data.d.results[index].AttachmentFiles.results[0].ServerRelativeUrl
			: '';

		images[index] = image;
	}

	return images;
}

export async function getCarouselByCategory(category: string): Promise<CarouselReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Imagenes Carousel')/items?$expand=AttachmentFiles&$filter=(Estado eq 'Activo') and (Categoria eq '${category}')&$orderby=Orden asc`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const carousel: CarouselReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const content = new CarouselReadDto();

		content.id = data.d.results[index].ID;
		content.order = data.d.results[index].Orden;
		content.category = data.d.results[index].Categoria;
		content.imgUrl = data.d.results[index].AttachmentFiles.results.length
			? data.d.results[index].AttachmentFiles.results[0].ServerRelativeUrl
			: '';

		content.subtitle = data.d.results[index].Subtitulo ? data.d.results[index].Subtitulo : '';
		content.title = data.d.results[index].Titulo ? data.d.results[index].Titulo : '';
		content.content = data.d.results[index].Contenido ? data.d.results[index].Contenido : '';
		content.icon = data.d.results[index].Icon ? data.d.results[index].Icon.Url : '';
		content.buttonText = data.d.results[index].Textoboton ? data.d.results[index].Textoboton : '';
		content.buttonUrl = data.d.results[index].Urlboton ? data.d.results[index].Urlboton : '';

		carousel[index] = content;
	}

	return carousel;
}
