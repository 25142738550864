import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useFormContext, useWatch, Controller } from 'react-hook-form';
import { validations } from '../Validations/regexValidations';
import SelectComponent from '../../Select/SelectComponent';
import { fp_s2_ocupacion } from '../../Utility/SelectOptions';

function StartInvestPersonStep2() {
	const emptyFieldErrorMessage = 'Campo obligatorio';
	const workRules = {
		tiempoCompleto: "Empleado a tiempo completo",
		tiempoParcial: "Empleado a tiempo parcial",
		independiente: "Independiente"
	};
	const {
		register,
		control,
		resetField,
		formState: { errors },
	} = useFormContext();

	const ocupation = useWatch({ control, name: 'ocupation' });

	return (
		<React.Fragment>
			<div>
				<div className="create__requisitos">
					<h4 className="create__requisitos-title">
						Cuenta menores de edad - <i>Opcional</i>
					</h4>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Nombres</label>
							<input
								type="text"
								maxLength={40}
								className="global-input"
								placeholder="Nombres"
								{...register('namesMinor', {
									pattern: validations.letrasNumerosEspaciosEspeciales,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="namesMinor"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Apellidos</label>
							<input
								type="text"
								maxLength={40}
								className="global-input"
								placeholder="Apellidos"
								{...register('lastNamesMinor', {
									pattern: validations.letrasNumerosEspaciosEspeciales,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="lastNamesMinor"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Número de identificación o Pasaporte</label>
							<input
								type="text"
								maxLength={11}
								className="global-input"
								placeholder="Número de identificación o Pasaporte"
								{...register('idNoMinor', {
									pattern: validations.letrasNumerosEspaciosEspeciales,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="idNoMinor"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Número de acta de nacimiento</label>
							<input
								type="text"
								maxLength={10}
								className="global-input"
								placeholder="Número de acta de nacimiento"
								{...register('birthCertificateNumber', {
									pattern: validations.letrasNumerosEspaciosEspeciales,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="birthCertificateNumber"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Libro</label>
							<input
								type="text"
								maxLength={5}
								className="global-input"
								placeholder="Libro"
								{...register('book', {
									pattern: validations.letrasNumerosEspaciosEspeciales,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="book"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Folio</label>
							<input
								type="text"
								maxLength={10}
								className="global-input"
								placeholder="Folio"
								{...register('folio', {
									pattern: validations.letrasNumerosEspaciosEspeciales,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="folio"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-6 create__requisitos">
				<h4 className="create__requisitos-title">Datos persona extranjera</h4>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							Constancia de trámites migratorios - <i>Opcional</i>
						</label>
						<input
							type="text"
							className="global-input"
							placeholder="Constancia de trámites migratorios"
							{...register('proofImmigrationProcedures', {
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="proofImmigrationProcedures"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							Dirección <i>Opcional</i>
						</label>
						<input
							type="text"
							maxLength={60}
							className="global-input"
							placeholder="Direccion"
							{...register('foreignAddress', {
								pattern: validations.letrasNumerosEspaciosEspeciales,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="foreignAddress"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-6 create__requisitos">
				<h4 className="create__requisitos-title">Información laboral</h4>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div
						className="mb-4 group-form lg:w-1/2"
						onClick={() => {
							resetField('workPlace');
							resetField('workRole');
							resetField('workAddress');
							resetField('workNumber');
							resetField('workEmail');
							resetField('lessThan1Year');
							resetField('workYears');
							resetField('revenue');
							resetField('otherRevenues');
							resetField('sourceOtherRevenues');
						}}
					>
						<label className="global-label">Ocupación</label>
						<Controller
							control={control}
							name="ocupation"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s2_ocupacion}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>

						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="ocupation"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2 lg:pr-4">
						<label className="global-label">Lugar de trabajo</label>
						<input
							type="text"
							maxLength={60}
							className="global-input"
							placeholder="Lugar de trabajo"
							{...register('workPlace', {
								required: {
									value:
										ocupation?.value === workRules.tiempoCompleto ||
										ocupation?.value === workRules.tiempoParcial ||
										ocupation?.value === workRules.independiente,
									message: emptyFieldErrorMessage,
								},
								pattern: validations.letrasYespacios,
							})}
							disabled={
								ocupation?.value !== workRules.tiempoCompleto &&
								ocupation?.value !== workRules.tiempoParcial &&
								ocupation?.value !== workRules.independiente
							}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="workPlace"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 lg:pr-4 group-form lg:w-1/2">
						<label className="global-label">Posición en la empresa</label>
						<input
							type="text"
							maxLength={40}
							className="global-input"
							placeholder="Posición en la empresa"
							{...register('workRole', {
								required: {
									value:
										ocupation?.value === workRules.tiempoCompleto ||
										ocupation?.value === workRules.tiempoParcial ||
										ocupation?.value === workRules.independiente,
									message: emptyFieldErrorMessage,
								},
								pattern: validations.letrasYespacios,
							})}
							disabled={
								ocupation?.value !== workRules.tiempoCompleto &&
								ocupation?.value !== workRules.tiempoParcial &&
								ocupation?.value !== workRules.independiente
							}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="workRole"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Dirección del trabajo</label>
						<input
							type="text"
							maxLength={60}
							className="global-input"
							placeholder="Dirección del trabajo"
							{...register('workAddress', {
								required: {
									value:
										ocupation?.value === workRules.tiempoCompleto ||
										ocupation?.value === workRules.tiempoParcial ||
										ocupation?.value === workRules.independiente,
									message: emptyFieldErrorMessage,
								},
								pattern: validations.letrasNumerosEspaciosEspeciales,
							})}
							disabled={
								ocupation?.value !== workRules.tiempoCompleto &&
								ocupation?.value !== workRules.tiempoParcial &&
								ocupation?.value !== workRules.independiente
							}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="workAddress"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Teléfono del trabajo</label>
						<input
							type="text"
							maxLength={14}
							className="global-input"
							placeholder="809 000 0000"
							{...register('workNumber', {
								required: {
									value:
										ocupation?.value === workRules.tiempoCompleto ||
										ocupation?.value === workRules.tiempoParcial ||
										ocupation?.value === workRules.independiente,
									message: emptyFieldErrorMessage,
								},
								pattern: validations.soloNumeros,
							})}
							disabled={
								ocupation?.value !== workRules.tiempoCompleto &&
								ocupation?.value !== workRules.tiempoParcial &&
								ocupation?.value !== workRules.independiente
							}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="workNumber"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2 lg:pr-4">
						<label className="global-label">Correo electrónico del trabajo</label>
						<input
							type="text"
							className="global-input"
							placeholder="Correo electrónico del trabajo"
							{...register('workEmail', {
								required: {
									value:
										ocupation?.value === workRules.tiempoCompleto ||
										ocupation?.value === workRules.tiempoParcial ||
										ocupation?.value === workRules.independiente,
									message: emptyFieldErrorMessage,
								},
								pattern: validations.emails,
							})}
							disabled={
								ocupation?.value !== workRules.tiempoCompleto &&
								ocupation?.value !== workRules.tiempoParcial &&
								ocupation?.value !== workRules.independiente
							}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="workEmail"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-start lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<div>
							<label className="global-label">
								¿Tiene menos de un año laborando en la empresa?
							</label>
							<div className="document-box">
								<input
									id="step2-woking-time-yes"
									className="toggle toggle-left"
									value="Si"
									type="radio"
									{...register('lessThan1Year', {
										required: {
											value:
												ocupation?.value === workRules.tiempoCompleto ||
												ocupation?.value === workRules.tiempoParcial ||
												ocupation?.value === workRules.independiente,
											message: emptyFieldErrorMessage,
										},
									})}
									disabled={
										ocupation?.value !== workRules.tiempoCompleto &&
										ocupation?.value !== workRules.tiempoParcial &&
										ocupation?.value !== workRules.independiente
									}
								/>

								<label htmlFor="step2-woking-time-yes" className="btn">
									Si
								</label>
								<input
									id="step2-woking-time-no"
									className="toggle toggle-right"
									value="No"
									type="radio"
									{...register('lessThan1Year', {
										required: {
											value:
												ocupation?.value === workRules.tiempoCompleto ||
												ocupation?.value === workRules.tiempoParcial ||
												ocupation?.value === workRules.independiente,
											message: emptyFieldErrorMessage,
										},
									})}
									disabled={
										ocupation?.value !== workRules.tiempoCompleto &&
										ocupation?.value !== workRules.tiempoParcial &&
										ocupation?.value !== workRules.independiente
									}
								/>

								<label htmlFor="step2-woking-time-no" className="btn">
									No
								</label>
							</div>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Años laborando en la empresa</label>
						<input
							type="text"
							className="global-input"
							placeholder={'0'}
							{...register('workYears', {
								required: {
									value:
										ocupation?.value === workRules.tiempoCompleto ||
										ocupation?.value === workRules.tiempoParcial ||
										ocupation?.value === workRules.independiente,
									message: emptyFieldErrorMessage,
								},
								pattern: validations.soloNumeros,
							})}
							disabled={
								ocupation?.value !== workRules.tiempoCompleto &&
								ocupation?.value !== workRules.tiempoParcial &&
								ocupation?.value !== workRules.independiente
							}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="workYears"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Ingresos</label>
						<input
							type="text"
							maxLength={10}
							className="global-input"
							placeholder="$0.00"
							{...register('revenue', {
								required: {
									value:
										ocupation?.value === workRules.tiempoCompleto ||
										ocupation?.value === workRules.tiempoParcial ||
										ocupation?.value === workRules.independiente,
									message: emptyFieldErrorMessage,
								},
								pattern: validations.soloNumeros,
							})}
							disabled={
								ocupation?.value !== workRules.tiempoCompleto &&
								ocupation?.value !== workRules.tiempoParcial &&
								ocupation?.value !== workRules.independiente
							}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="revenue"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Otros ingresos</label>
						<input
							type="text"
							maxLength={10}
							className="global-input"
							placeholder="$0.00"
							{...register('otherRevenues', {
								required: {
									value:
										ocupation?.value === workRules.tiempoCompleto ||
										ocupation?.value === workRules.tiempoParcial ||
										ocupation?.value === workRules.independiente,
									message: emptyFieldErrorMessage,
								},
								pattern: validations.soloNumeros,
							})}
							disabled={
								ocupation?.value !== workRules.tiempoCompleto &&
								ocupation?.value !== workRules.tiempoParcial &&
								ocupation?.value !== workRules.independiente
							}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="otherRevenues"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2 lg:pr-4">
						<label className="global-label">Fuente de los ingresos adicionales</label>
						<input
							type="text"
							maxLength={40}
							className="global-input"
							placeholder="Fuente de los ingresos adicionales"
							{...register('sourceOtherRevenues', {
								required: {
									value:
										ocupation?.value === workRules.tiempoCompleto ||
										ocupation?.value === workRules.tiempoParcial ||
										ocupation?.value === workRules.independiente,
									message: emptyFieldErrorMessage,
								},

								pattern: validations.letrasYespacios,
							})}
							disabled={
								ocupation?.value !== workRules.tiempoCompleto &&
								ocupation?.value !== workRules.tiempoParcial &&
								ocupation?.value !== workRules.independiente
							}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="sourceOtherRevenues"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2 lg:pr-4">
						<label className="global-label">¿Es proveedor del Estado?</label>
						<div className="document-box">
							<input
								id="step2-state-provider-yes"
								className="toggle toggle-left"
								type="radio"
								value="Si"
								{...register('stateProvider', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
								onClick={() => {
									resetField('sourceOtherRevenues');
								}}
							/>

							<label htmlFor="step2-state-provider-yes" className="btn">
								Si
							</label>
							<input
								id="step2-state-provider-no"
								className="toggle toggle-right"
								type="radio"
								value="No"
								{...register('stateProvider', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="step2-state-provider-no" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default StartInvestPersonStep2;
