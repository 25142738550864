import React from "react";
import { useFormContext } from "react-hook-form";
import EmpresasAfiliadasForm from "./EmpresasAfiliadasForm";
import PrincipalesProveedoresForm from "./PrincipalesProveedoresForm";
import PrincipalesAccionistasForm from "./PrincipalesAccionistasForm";
import DirectoresForm from "./DirectoresForm";
import ReferenciasBancariasComercialesForm from "./ReferenciasBancariasComercialesForm";
import { validations } from "../Validations/regexValidations";
import { ErrorMessage } from "@hookform/error-message";

function StartInvestCompanyStep2() {
  const emptyFieldErrorMessage = "Campo obligatorio";
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
		<React.Fragment>
			<EmpresasAfiliadasForm />

			<PrincipalesProveedoresForm />

			<PrincipalesAccionistasForm />

			<DirectoresForm />

			<div className="mb-6 create__requisitos">
				<h4 className="create__requisitos-title">Información financiera</h4>

				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label" htmlFor="">
							Ventas o ingresos (último período fiscal o promedio mensual)
						</label>
						<input
							type="text"
							maxLength={15}
							className="global-input"
							placeholder="$0.00"
							{...register('financialInfoSalesIncome', {
								required: { value: true, message: emptyFieldErrorMessage },
								pattern: validations.soloNumeros,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="financialInfoSalesIncome"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label" htmlFor="">
							Porcentaje aproximado de los ingresos que recibe en efectivo
						</label>
						<input
							type="text"
							maxLength={15}
							className="global-input"
							placeholder="0%"
							{...register('financialInfoIncomePercentage', {
								required: { value: true, message: emptyFieldErrorMessage },
								pattern: validations.soloNumeros,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="financialInfoIncomePercentage"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>

			<ReferenciasBancariasComercialesForm />

			<div className="mb-6 create__requisitos">
				<h4 className="create__requisitos-title">
					Sociedades sin fines de lucro (ONG) - <i>Opcional</i>
				</h4>

				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label" htmlFor="">
							Descripción y objetivos
						</label>
						<input
							type="text"
							maxLength={120}
							className="global-input"
							placeholder="Descripción y objetivos"
							{...register('nonProfitDescriptionObjectives', {
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="nonProfitDescriptionObjectives"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label" htmlFor="">
							Origen de los fondos que recibe para financiar sus proyectos y otros gastos
						</label>
						<input
							type="text"
							maxLength={120}
							className="global-input"
							placeholder="Origen de los Fondos"
							{...register('nonProfitFundSource', {
								pattern: validations.letrasNumerosEspacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="nonProfitFundSource"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
  );
}

export default StartInvestCompanyStep2;
