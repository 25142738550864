import pdf from '../../img/general/pdf-icon.svg';

interface IProps {
	title: string;
	date: string;
	size: string;
	url: string;
}

function FileCard(props: IProps) {
	return (
		<div className="file-section__file-container--card shadow-lg">
			<img src={pdf} alt="" className="file-section__file-container--card--img" />
			<div className="file-section__file-container--card--content">
				<p className="file-section__file-container--card--content--text">{props.title}</p>
				<h4 className="file-section__file-container--card--content--meta-data">
					Publicado:<b>{props.date}</b> - Tamaño:<b>{props.size} Kb</b>
				</h4>
			</div>
			<div>
				<a
					className="file-section-container--card--download"
					href={props.url}
					download
				>
					Descargar
				</a>
			</div>
		</div>
	);
}

export default FileCard;
