import CourseReadDto from "../../dtos/CourseReadDto";
import CourseCard from "../courses/CourseCard";

interface IProps {
	courses: CourseReadDto[];
}

function OtherCoursesSection({ courses }: IProps) {
	return (
		<div className="bg-white podcast">
			<h1 className="generic-title generic-title--ultramar generic-title--small">
				Otros videos relacionados
			</h1>
			<div className="podcast-container podcast-container--blog">
				{
					courses.map((course) => <CourseCard key={course.id} course={course} />)
				}
			</div>
		</div>
	);
}

export default OtherCoursesSection;