import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AppVariables from "./Variables.json";
import Footer from "./components/footer/Footer";
import Nav from "./components/nav/Nav";
import Blog from "./pages/Blog";
import BlogDetalle from "./pages/BlogDetalle";
import Boletin from "./pages/Boletin";
import Calculadora from "./pages/Calculadora";
import ComparadorDeFondos from "./pages/ComparadorDeFondos";
import Course from "./pages/Course";
import Ebook from "./pages/Ebook";
import FondosDeInversion from "./pages/FondosDeInversion";
import FundGeneric from "./pages/FundGeneric";
import GobiernoCorporativo from "./pages/GobiernoCorporativo";
import Home from "./pages/Home";
import PerfilDeRiesgo from "./pages/PerfilDeRiesgo";
import PersonForm from "./pages/PersonForm";
import PersonFormRequirements from "./pages/PersonFormRequirements";
import Podcast from "./pages/Podcast";
import PodcastDetalle from "./pages/PodcastDetalle";
import Publicaciones from "./pages/Publicaciones";
import Recomendador from "./pages/Recomendador";
import SalaDePrensa from "./pages/SalaDePrensa";
import SalaDePrensaDetalle from "./pages/SalaDePrensaDetalle";
import SobreNosotros from "./pages/SobreNosotros";
import StartInvest from "./pages/StartInvest";
import ValorCuota from "./pages/ValorCuota";
import FATCA from "./pages/FATCA/FATCA";
import FAQ from "./pages/FAQ/FAQ";
import PrivacyPolicy from "./pages/PrivacyPolicy_TermsConditions/PrivacyPolicy";
import TermsConditions from "./pages/PrivacyPolicy_TermsConditions/TermsConditions";
import MarcoRegulatorio from "./pages/MarcoRegulatorio";
import Diccionario from "./pages/Diccionario";
import ScrollToTop from "./components/scroll/ScrollToTop";
import { PageHeaders } from "./components/page-headers/PageHeaders";
import CursoDetalle from "./pages/CursoDetalle";
import Contactanos from "./pages/Contactanos";
import PerfilDeRiesgoResultado from "./pages/PerfilDeRiesgoResultado";
import { ToastContainer } from "react-toastify";
import MainSearchResult from "./components/searchResult/MainSearchResult";
import CompanyForm from "./pages/CompanyForm";
import CompanyFormRequirements from "./pages/CompanyFormRequirements";
import NotFoundPage from "./components/not-found/NotFoundPage";
import CookiesPage from "./pages/PrivacyPolicy_TermsConditions/CookiesPage";

function App() {
  return (
		<>
			<Router>
				<Nav />
				<div className="main">
					<ScrollToTop>
						<Routes>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/`}
								element={
									<>
										<PageHeaders
											title="Home"
											description="Conoce nuestra amplia gama de fondos de inversión altamente competitivos y rentables. Ofrecemos un servicio diferenciado, para ayudarlo a a cumplir sus objetivos de inversión."
										/>
										<Home />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/publicaciones`}
								element={
									<>
										<PageHeaders
											title="Publicaciones Institucionales"
											description="Ponemos a su disposición nuestros hechos relevantes, calificación de riesgo, informes de Gestión Annual y los Estados Financieros auditados de AFI Popular."
										/>
										<Publicaciones />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/gobierno-corporativo`}
								element={
									<>
										<PageHeaders
											title="Gobierno Corporativo"
											description="Nuestro modelo de gobierno corporativo nos permite cumplir el plan de negocios, generando transparencia a clientes, empleados y accionistas. "
										/>
										<GobiernoCorporativo />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/sobre-nosotros`}
								element={
									<>
										<PageHeaders
											title="Sobre Nosotros"
											description="Primera Administradora de Fondos de Pensiones establecida en República Dominicana. Filial del Grupo Popular S.A. Conoce más aquí."
										/>
										<SobreNosotros />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/fondos-de-inversion`}
								element={
									<>
										<PageHeaders
											title="Fondos de Inversión"
											description="Conoce las condiciones y características de nuestros fondos de inversión e identifica el que más se ajuste a tu objetivo de inversión."
										/>
										<FondosDeInversion />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/generic-fund`}
								element={
									<>
										<PageHeaders
											title="Fondos de Inversión"
											description="Conoce las condiciones y características de nuestros fondos de inversión e identifica el que más se ajuste a tu objetivo de inversión."
										/>
										<FundGeneric />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/sala-de-prensa`}
								element={
									<>
										<PageHeaders
											title="Sala de Prensa"
											description="Entérate de todas las novedades acerca de AFI Popular y sus productos y servicios. "
										/>
										<SalaDePrensa />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/podcast`}
								element={
									<>
										<PageHeaders
											title="Podcast"
											// description="Aprende los fundamentos para convertirte en inversionista del mercado de valores dominicano."
										/>
										<Podcast />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/boletin`}
								element={
									<>
										<PageHeaders
											title="Boletín Informativo"
											description="Navega y descarga todos nuestros boletines informativos con informaciones de interés sobre el mercado de valores y nuestros productos."
										/>
										<Boletin />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/blog`}
								element={
									<>
										<PageHeaders
											title="Blog"
											description="Aprende los fundamentos para convertirte en inversionista del mercado de valores dominicano."
										/>
										<Blog />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/detalle-noticia`}
								element={
									<>
										<PageHeaders
											title="Detalle de Noticia"
											description="Entérate de todas las novedades acerca de AFI Popular y sus productos y servicios. "
										/>
										<SalaDePrensaDetalle />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/detalle-podcast`}
								element={
									<>
										<PageHeaders title="Detalle de Podcast" />
										<PodcastDetalle />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/detalle-blog`}
								element={
									<>
										<PageHeaders
											title="Detalle de Blog"
											description="Aprende los fundamentos para convertirte en inversionista del mercado de valores dominicano."
										/>
										<BlogDetalle />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/calculadora`}
								element={
									<>
										<PageHeaders
											title="Calculadora de Rendimiento"
											description="Calcula el posible rendimiento, ganancia o pérdida que podrás obtener en un tiempo estimado."
										/>
										<Calculadora />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/comparador-de-fondos`}
								element={
									<>
										<PageHeaders
											title="Comparador de Fondos"
											description="Compara las características de nuestros fondos de inversión e identifica el que más se ajuste a tu objetivo de inversión."
										/>
										<ComparadorDeFondos />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/valor-cuota`}
								element={
									<>
										<PageHeaders
											title="Valor de las Cuotas"
											description="Consulta el precio de suscripción de cuotas de participación para cada uno de nuestros fondos de inversión."
										/>
										<ValorCuota />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/perfil-de-riesgo`}
								element={
									<>
										<PageHeaders
											title="Perfil de Riesgo"
											description="Identifica tu perfil de riesgo como inversionista y toma mejores decisiones financieras que impacten de manera positiva tu futuro."
										/>
										<PerfilDeRiesgo />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/perfil-de-riesgo-resultado`}
								element={
									<>
										<PageHeaders
											title="Perfil de Riesgo Resultado"
											description="Identifica tu perfil de riesgo como inversionista y toma mejores decisiones financieras que impacten de manera positiva tu futuro."
										/>
										<PerfilDeRiesgoResultado />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/recomendador`}
								element={
									<>
										<PageHeaders
											title="Recomendador de Fondos"
											description="Te ayudamos a elegir el fondo de inversión que más se ajusta a tus necesidades financieras, de acuerdo a tu perfil de inversionista."
										/>
										<Recomendador />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/ebooks`}
								element={
									<>
										<PageHeaders title="Ebooks" />
										<Ebook />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/courses`}
								element={
									<>
										<PageHeaders title="Cursos y Talleres" />
										<Course />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/detalle-curso`}
								element={
									<div className="main">
										<PageHeaders title="Detalle de curso" />
										<CursoDetalle />
									</div>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/empieza-invertir`}
								element={
									<>
										<PageHeaders
											title="Invierte con Nosotros"
											// description="Solicita la apertura de tu cuenta de corretaje y empieza a invertir en el mercado de valores dominicano."
										/>
										<StartInvest />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/formulario-persona`}
								element={
									<>
										<PageHeaders title="Formulario Persona" />
										<PersonForm />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/requisitos-persona`}
								element={
									<>
										<PageHeaders title="Requisitos" />
										<PersonFormRequirements />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/formulario-empresa`}
								element={
									<>
										<PageHeaders title="Formulario Empresa" />
										<CompanyForm />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/requisitos-empresa`}
								element={
									<>
										<PageHeaders title="Requisitos" />
										<CompanyFormRequirements />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/FATCA`}
								element={
									<>
										<PageHeaders
											title="FATCA"
											description="Consulta los documentos e informaciones oficiales relacionadas al cumplimiento fiscal de cuentas extranjeras."
										/>
										<FATCA />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/preguntas-frecuentes`}
								element={
									<>
										<PageHeaders
											title="Preguntas Frecuentes"
											description="Aprende sobre el mercado de valores y los fondos de inversión."
										/>
										<FAQ />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/politicas-privacidad`}
								element={
									<>
										<PageHeaders title="Políticas de Privacidad y Seguridad" />
										<PrivacyPolicy />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/terminos-condiciones`}
								element={
									<>
										<PageHeaders title="Términos y Condiciones de Uso" />
										<TermsConditions />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/politicas-cookies`}
								element={
									<>
										<PageHeaders title="Políticas de Cookies" />
										<CookiesPage />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/marco-regulatorio`}
								element={
									<>
										<PageHeaders
											title="Marco Regulatorio"
											description="Consulta los documentos oficiales que conforman el marco regulatorio que rige las entidades que participan en el mercado de valores."
										/>
										<MarcoRegulatorio />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/diccionario`}
								element={
									<>
										<PageHeaders
											title="Diccionario"
											description="Consulta en nuestro glosario de términos todas las definiciones relacionadas al mercado de valores y los fondos de inversión."
										/>
										<Diccionario />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/contactanos`}
								element={
									<>
										<PageHeaders
											title="Contactanos"
											description="Consulta a nuestro equipo de experto sobre cualquier duda sobre tus inversiones en el mercado de valores."
										/>
										<Contactanos />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/resultado-busqueda`}
								element={
									<>
										<PageHeaders title="Resultado de la busqueda" />
										<MainSearchResult />
									</>
								}
							></Route>
							<Route
								path={`${AppVariables.REACT_ROUTER_URL}/404`}
								element={<NotFoundPage link={`${AppVariables.REACT_ROUTER_URL}/`} />}
							></Route>
							<Route
								path="*"
								element={<Navigate to={`${AppVariables.REACT_ROUTER_URL}/404`} />}
							></Route>
						</Routes>
					</ScrollToTop>
				</div>
				<ToastContainer limit={1} />
				<Footer />
			</Router>
		</>
  );
}

export default App;
