import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';
import BlogReadDto from '../../dtos/BlogReadDto';
import SocialMediaPopup from '../social-media/SocialMediaPopup';

interface IProps {
	blog: BlogReadDto;
}

function BlogBigCard({ blog }: IProps) {
	const navigate = useNavigate();

	return (
		<div className="main-blog-big-card-container w-full shadow-[2px_2px_8px_#00235C29]">
			<div className="flex-col w-full main-blog-big-card">
				<img
					src={blog.imgUrl}
					alt={blog.title}
					className="object-cover object-center w-full blog-big-card-img main-blog-big-card__image"
				/>
				<div className="flex-col p-4 bg-white blog-big-card-content">
					<div className="flex justify-between pb-4 blog-card-content-header">
						<h5 className="blog-card-header__text">{blog.category}</h5>
						<SocialMediaPopup
							position={'left center'}
							url={`${window.location.protocol}//${
								window.location.host
							}/detalle-blog?titulo=${blog?.title?.replaceAll(' ', '%20')}`}
						/>
					</div>
					<h5
						className="pb-4 blog-card-content__title cursor-pointer"
						onClick={() => {
							navigate({
								pathname: `${AppVariables.REACT_ROUTER_URL}/detalle-blog`,
								search: `?titulo=${blog.title}`,
							});
						}}
					>
						{blog.title}
					</h5>
					<p className=" blog-card-content__text blog-card-content__text--bottom">
						{blog.shortDescription}
					</p>
					<div className="flex justify-between pb-4 blog-card-content-footer">
						<h5 className="blog-card-footer__date text-[#414652]/70 font-bold">
							{blog.publicationDate}
						</h5>
						<h6
							onClick={() => {
								navigate({
									pathname: `${AppVariables.REACT_ROUTER_URL}/detalle-blog`,
									search: `?titulo=${blog.title}`,
								});
							}}
							className="blog-card-footer__link flex items-baseline hover:gap-1 hover:text-[#003B7A] text-[#00A5C2] text-sm font-bold gap-2 transition-[gap] duration-200 cursor-pointer"
						>
							<i>
								<FontAwesomeIcon icon={faChevronRight} aria-hidden="true" />
							</i>
							Seguir leyendo
						</h6>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BlogBigCard;
