import AcknowledgmentReadDto from "../../dtos/AcknowledgmentReadDto";
import AcknowledgmentContainer from "./AcknowledgmentContainer";
import AcknowledgmentFilter from "./AcknowledgmentFilter";

interface IProps {
  years: string[];
  ack: AcknowledgmentReadDto[];
  setYear: React.Dispatch<React.SetStateAction<string>>;
}

function Acknowledgment(props: IProps) {
  return (
    <div className="reconocimiento-section">
      <h1 className="reconocimiento-section__title">Reconocimientos</h1>
      <AcknowledgmentFilter years={props.years} setYear={props.setYear} />
      <AcknowledgmentContainer acknowledgments={props.ack} />
    </div>
  );
}

export default Acknowledgment;
