import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CoursesDetail from "../components/courses-detail/CoursesDetail";
import OtherCoursesSection from "../components/courses-detail/OtherCoursesSection";
import CourseReadDto from "../dtos/CourseReadDto";
import {getCourseDetailByTitle, getRandomCourses } from "../services/CourseServices";

function CursoDetalle() {

  const [course, setCourse] = useState<CourseReadDto>(new CourseReadDto());
  const [otherCourses,setOtherCourses] = useState<CourseReadDto[]>([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {

    const fetchData = async () => {
      const course1 = await getCourseDetailByTitle(searchParams.get('titulo'));
      setCourse(course1);
      const randomCourses = await getRandomCourses(course1.id);
      setOtherCourses(randomCourses);
    };

    fetchData();
  }, [searchParams]);

  return (
		<React.Fragment>
			<CoursesDetail course={course} />
			<OtherCoursesSection courses={otherCourses} />
		</React.Fragment>
  );
}

export default CursoDetalle;
