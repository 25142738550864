import Select from "react-select";
import {
  multiSelectStyle,
  selectStyle,
} from "../../components/Utility/FormStyles";

const SelectComponent = ({
  isMulti,
  options,
  value,
  placeHolder,
  onChange,
}: any) => {
  return (
    <Select
      styles={isMulti ? multiSelectStyle : selectStyle}
      options={options}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      placeholder={placeHolder}
      isLoading={false}
    />
  );
};

export default SelectComponent;
