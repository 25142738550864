import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import SelectComponent from "../../Select/SelectComponent";
import { fe_s3_tipo_socio } from "../../Utility/SelectOptions";
import { validations } from "../Validations/regexValidations";

export default function PartnerDataForm() {
  const emptyFieldErrorMessage = "Campo obligatorio";
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const { append, fields, prepend } = useFieldArray({
    control,
    name: "partnerData",
  });
  useEffect(() => {
    !fields.length && prepend({});
  }, []);
  return (
    <React.Fragment>
      {fields.map((field, index) => {
        const baseName = `partnerData[${index}]`;
        return (
				<div key={field.id} className="mb-6 create__requisitos">
					<h4 className="create__requisitos-title">Datos de los socios y directores</h4>
					<div className="lg:items-start lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Nombres y apellidos</label>
							<input
								type="text"
								maxLength={60}
								className="global-input"
								placeholder="Nombres y apellidos"
								{...register(`${baseName}.Names`, {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name={`${baseName}.Names`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-6 group-form lg:w-1/2 lg:mb-0 ">
							<label className="global-label">Selecciona el tipo</label>
							<Controller
								control={control}
								name={`${baseName}.Type`}
								rules={{
									required: { value: true, message: emptyFieldErrorMessage },
								}}
								render={({ field: { onChange, value } }) => (
									<SelectComponent
										options={fe_s3_tipo_socio}
										value={value}
										onChange={onChange}
										isMulti={false}
										placeHolder="Seleccione una opción"
									/>
								)}
							/>

							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name={`${baseName}.Type`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
				</div>
			);
      })}
      <div className="mt-6 mb-6 text-center create__requisitos">
        <button
          type="button"
          onClick={() => {
            append({});
          }}
          className="mb-4"
        >
          <div className="mx-auto text-[#012169] opacity-50 text-[40px]">+</div>
          <span className="text-[#012169] opacity-50 font-bold mb-3">
            Agregar otro socio o director
          </span>
        </button>
      </div>
    </React.Fragment>
  );
}
