import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import PartnerDataForm from "./PartnerDataForm";
import { validations } from "../Validations/regexValidations";
import { ErrorMessage } from "@hookform/error-message";
import DatePickerComponent from "../../datepicker/DatePickerComponent";

function StartInvestCompanyStep3() {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const emptyFieldErrorMessage = "Campo obligatorio";
  return (
		<React.Fragment>
			<div className="mb-6 create__requisitos">
				<h4 className="create__requisitos-title">
					Si el personal directivo o responsables son personas jurídicas -<i>Opcional</i>
				</h4>

				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Nombre/Razón social</label>
						<input
							type="text"
							maxLength={20}
							className="global-input"
							placeholder="Nombre/Razón social"
							{...register('legalPersonNamesSocialReason', {
								pattern: validations.letrasNumerosEspacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalPersonNamesSocialReason"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">País de constitución</label>
						<input
							type="text"
							maxLength={20}
							className="global-input"
							placeholder="País de constitución"
							{...register('legalPersonEstablishmentCountry', {
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalPersonEstablishmentCountry"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="group-form lg:items-end lg:flex lg:space-x-9">
					<div className="mb-6 comparador__select-block lg:w-1/2 ">
						<label className="global-label">Fecha de constitución</label>
						<Controller
							control={control}
							name="legalPersonEstablishmentDate"
							render={({ field: { onChange, value } }) => (
								<DatePickerComponent onChange={onChange} currValue={value} />
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalPersonEstablishmentDate"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-6 lg:w-1/2 ">
						<label className="global-label">No. de Identificación</label>
						<input
							type="text"
							maxLength={11}
							className="global-input"
							placeholder="No. de Identificación"
							{...register('legalPersonIdentificationNumber', {
								pattern: validations.letrasNumerosEspacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalPersonIdentificationNumber"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Teléfono de oficina</label>
						<input
							type="text"
							maxLength={14}
							className="global-input"
							placeholder="(809) 000 - 0000"
							{...register('legalPersonOfficePhoneNumber', {
								pattern: validations.soloNumeros,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalPersonOfficePhoneNumber"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Télefono Adicional</label>
						<input
							type="text"
							maxLength={14}
							className="global-input"
							placeholder="(809) 000 - 0000"
							{...register('legalPersonAdditionalPhoneNumber', {
								pattern: validations.soloNumeros,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalPersonAdditionalPhoneNumber"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2 lg:pr-4">
						<label className="global-label">Dirección (calle, no., edificio, apart.)</label>
						<input
							type="text"
							maxLength={40}
							className="global-input"
							placeholder="Dirección"
							{...register('legalPersonResponsibleAddress', {
								pattern: validations.letrasNumerosEspaciosEspeciales,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalPersonResponsibleAddress"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>

			<PartnerDataForm />

			<div className="mb-6 create__requisitos">
				<h4 className="create__requisitos-title">
					Datos del representante legal - <i>Opcional</i>
				</h4>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Nombres</label>
						<input
							type="text"
							maxLength={40}
							className="global-input"
							placeholder="Nombres"
							{...register('legalRepresentativeNames', {
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalRepresentativeNames"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">apellidos</label>
						<input
							type="text"
							maxLength={40}
							className="global-input"
							placeholder="apellidos"
							{...register('legalRepresentativeLastNames', {
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalRepresentativeLastNames"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Nacionalidad</label>
						<input
							type="text"
							maxLength={20}
							className="global-input"
							placeholder="Nacionalidad"
							{...register('legalRepresentativeNationality', {
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalRepresentativeNationality"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">No. de Identificación</label>
						<input
							type="text"
							maxLength={11}
							className="global-input"
							placeholder="No. de Identificación"
							{...register('legalRepresentativeIdNo', {
								pattern: validations.letrasNumerosEspacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalRepresentativeIdNo"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Posición en la empresa</label>
						<input
							type="text"
							maxLength={20}
							className="global-input"
							placeholder="Posición en la empresa"
							{...register('legalRepresentativeCompanyPosition', {
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalRepresentativeCompanyPosition"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Correo electrónico</label>
						<input
							type="text"
							className="global-input"
							placeholder="Correo electrónico"
							{...register('legalRepresentativeEmail', {
								pattern: validations.emails,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalRepresentativeEmail"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Teléfono celular</label>
						<input
							type="text"
							maxLength={14}
							className="global-input"
							placeholder="(809) 000 - 0000"
							{...register('legalRepresentativePhoneNumber', {
								pattern: validations.soloNumeros,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalRepresentativePhoneNumber"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Teléfono oficina</label>
						<input
							type="text"
							maxLength={14}
							className="global-input"
							placeholder="(809) 000 - 0000"
							{...register('legalRepresentativeOfficePhoneNumber', {
								pattern: validations.numerosYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalRepresentativeOfficePhoneNumber"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="mb-6 create__requisitos">
				<h4 className="create__requisitos-title">
					Referencias bancarias del representante legal - <i>Opcional</i>
				</h4>

				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Banco</label>
						<input
							type="text"
							maxLength={60}
							className="global-input"
							placeholder="Banco"
							{...register('legalBankReferencesBank', {
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalBankReferencesBank"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Tipo de Cuenta</label>
						<input
							type="text"
							maxLength={60}
							className="global-input"
							placeholder="Tipo de Cuenta"
							{...register('legalBankReferencesAccountType', {
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalBankReferencesAccountType"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2 lg:pr-4">
						<label className="global-label">Número de Cuenta</label>
						<input
							type="text"
							maxLength={60}
							className="global-input"
							placeholder="Número de Cuenta"
							{...register('legalBankReferencesAccountNumber', {
								pattern: validations.letrasNumerosEspacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="legalBankReferencesAccountNumber"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
  );
}

export default StartInvestCompanyStep3;
