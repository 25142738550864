type fatcaProps = {
  title: string;
  subTitle1: string;
  subTitle2: string;
  downloadLink: string;
  downloadText: string;
};

export default function HeroFATCA({
  title,
  subTitle1,
  subTitle2,
  downloadLink,
  downloadText,
}: fatcaProps) {
  return (
    <div className="banner-intro">
      <div className="container mx-auto">
        <h1>{title}</h1>
        <h2>
          {subTitle1} <br className="lg:hidden" /> {subTitle2}
        </h2>
        <div className="text-center">
          <a
            download
            href={downloadLink}
            className=" global-button global-button--orange"
          >
            {downloadText}
          </a>
        </div>
      </div>
    </div>
  );
}
