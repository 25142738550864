import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PreguntaReadDto from '../../dtos/PreguntaReadDto';
import RespuestaReadDto from '../../dtos/RespuestaReadDto';
import RiskProfileAnswers from './RiskProfileAnswers';

interface IProps {
	question: PreguntaReadDto;
	answers: RespuestaReadDto[];
	qId:number;
  register:any;
}

function RiskProfileQuestion({ question, answers,qId,register }: IProps) {
	
	function renderOptions() {
		return answers.map((a, i) => (
			<RiskProfileAnswers answers={a} question={question.question} register={register} id={i} qId={qId} />
		));
	}

	return (
		<React.Fragment>
			<p className="comparador__chekbox-title">{question.question}</p>
			<div className="mb-6 checkbox-list lg:mb-9">{renderOptions()}</div>
		</React.Fragment>
	);
}

export default RiskProfileQuestion;
