import PrensaDetail from '../components/sala-de-prensa-detalle/PrensaDetail';
import { useEffect, useState } from 'react';
import NewsReadDto from '../dtos/NewsReadDto';
import { useSearchParams } from 'react-router-dom';
import { getNewsByTitle, getRandomNews } from '../services/NewsServices';
import PrensaOtherNews from '../components/sala-de-prensa-detalle/PrensaOtherNews';
import { Helmet } from 'react-helmet';

function SalaDePrensaDetalle() {
	const [news, setNews] = useState<NewsReadDto>();
	const [otherNews, setOtherNews] = useState<NewsReadDto[]>();
	const [searchParams] = useSearchParams();
	const [zoom, setZoom] = useState(false);

	const fetchData = async () => {
		const news1 = await getNewsByTitle(searchParams.get('titulo'));
		const otherNews1 = await getRandomNews(news1.id);
		setNews(news1);
		setOtherNews(otherNews1);
	};

	useEffect(() => {
		fetchData();
	}, [searchParams]);

	return (
		<main className="main w-[100%]">
			
			{news ? (
				<PrensaDetail
					date={news.date}
					title={news.title}
					category={news.category}
					imgUrl={news.imgUrl}
					html={news.content}
					zoom={zoom}
					setZoom={setZoom}
				/>
			) : (
				<></>
			)}
			{otherNews ? <PrensaOtherNews news={otherNews} /> : <></>}
		</main>
	);
}

export default SalaDePrensaDetalle;
