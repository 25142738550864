import GCDocumentsReadDto from '../../dtos/GCDocumentsReadDto';
import DocumentCard from './DocumentCard';
import DocumentFilterByCategory from './DocumentFilterByCategory';
import DocumentFilterByYear from './DocumentFilterByYear';

interface IProps {
	categories: string[];
	setCurrentCategory: React.Dispatch<React.SetStateAction<string>>;
	years: string[];
	setCurrentYear: React.Dispatch<React.SetStateAction<string>>;
	documents: GCDocumentsReadDto[];
}

function Document(props: IProps) {
	function renderDocCards() {
		return props.documents.map((doc) => <DocumentCard key={doc.id} document={doc} />);
	}

	return (
		<section className="main-documents">
			<h1 className="main-documents__title">Documentos</h1>

			<div className="main-documents-bar__container">
				<DocumentFilterByCategory
					categories={props.categories}
					setCurrentCategory={props.setCurrentCategory}
				/>
				<DocumentFilterByYear years={props.years} setCurrentYear={props.setCurrentYear} />
			</div>

			<div className="main-documents__file-container">{renderDocCards()}</div>
		</section>
	);
}

export default Document;
