import { useEffect, useState } from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

type Props = {
  setIsCaptcha: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Captcha({ setIsCaptcha }: Props) {
  const [displayError, setDisplayError] = useState(false);
  const [captcha, setCaptcha] = useState("");
  useEffect(() => {
    loadCaptchaEnginge(8);
  }, []);
  const handleChange = async (e: any) => {
    setCaptcha(e.target.value);
    if (validateCaptcha(e.target.value, false) === true) {
      setIsCaptcha(true);
      setDisplayError(false);
    } else {
      setIsCaptcha(false);
      setDisplayError(true);
    }
  };
  return (
    <div className="mt-6 create__requisitos">
      <h4 className="create__requisitos-title">Captcha</h4>
      <div className="lg:items-end lg:flex lg:space-x-9 lg:items-center">
        <div className="flex items-center justify-center capchat-box lg:w-1/2">
          <h3 className="capchat-box__text">
            <LoadCanvasTemplateNoReload />
          </h3>
        </div>
        <div className="mt-6 group-form lg:w-1/2 lg:mt-0">
          <label className="global-label">
            Ingrese los caracteres anteriores
          </label>
          <input
            type="text"
            className="global-input"
            onChange={handleChange}
            placeholder="captcha"
            value={captcha}
          />
          <p className="text-red-600 ml-1">
            {displayError === true && captcha.length === 8
              ? "Captcha equivocado"
              : " "}
          </p>
        </div>
      </div>
    </div>
  );
}
