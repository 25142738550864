import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';
import PodcastReadDto from '../../dtos/PodcastReadDto';
import SocialMediaPopup from '../social-media/SocialMediaPopup';

interface IProps {
	podcast: PodcastReadDto;
}

function PodcastCard({ podcast }: IProps) {
	const navigate = useNavigate();
	return (
		<div
			className="generic-podcast shadow-lg cursor-pointer w-full"
			style={{
				backgroundImage: `linear-gradient(
									180deg,
									#00235c00 0%,
									#00235ca3 50%,
									#00235c 100%
      			), url(${podcast.imgUrl})`,
			}}
			onClick={() => {
				navigate({
					pathname: `${AppVariables.REACT_ROUTER_URL}/detalle-podcast`,
					search: `?titulo=${podcast.title}`,
				});
			}}
		>
			<div className="generic-podcast__duration">
				<p className="generic-podcast__duration--time">▶ {podcast.duration}</p>
			</div>
			<div className="generic-podcast__content">
				<div className="generic-podcast__title">
					<p className="generic-podcast__title-content">{podcast.title}</p>
				</div>
				<div className="generic-podcast__autor">
					<h5 className="generic-podcast__autor--name inline">{podcast.author}</h5>
					<SocialMediaPopup
						position={'left center'}
						url={`${window.location.protocol}//${
							window.location.host
						}/detalle-podcast?titulo=${podcast?.title?.replaceAll(' ', '%20')}`}
					/>
				</div>
			</div>
		</div>
	);
}

export default PodcastCard;
