import PromotorReadDto from '../../dtos/PromotorReadDto';
import PromotorCard from './PromotorCard';

interface IProps {
	promotors: PromotorReadDto[];
}

function PromotorContainer(props: IProps) {
	function renderPromotorCards() {
		return props.promotors.map((p) => <PromotorCard key={p.id} promotor={p} />);
	}

	return <div className="promotores__container">{renderPromotorCards()}</div>;
}

export default PromotorContainer;
