import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import SelectComponent from "../../Select/SelectComponent";
import {
  fp_s6_edad,
  fp_s6_ingresos_anuales,
  fp_s6_nivel_academico,
  fp_s6_nivel_experiencia,
  fp_s6_operaciones_bursatiles,
  fp_s6_tiempo_espera,
  fp_s6_tiempo_retiro,
} from "../../Utility/SelectOptions";

function StartInvestPersonStep6() {
  const emptyFieldErrorMessage = "Campo obligatorio";
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
		<React.Fragment>
			<div className="create__requisitos">
				<h4 className="create__requisitos-title">Información relativa al inversionista</h4>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">Edad</label>
						<Controller
							control={control}
							name="age"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s6_edad}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="age"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">Nivel académico</label>
						<Controller
							control={control}
							name="investorAcademicLevel"
							rules={{ required: true }}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s6_nivel_academico}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="investorAcademicLevel"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">
							Nivel de Experiencia y conocimiento en el mercado de valores (Experiencia de
							Inversión)
						</label>
						<Controller
							control={control}
							name="experienceLevel"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s6_nivel_experiencia}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="experienceLevel"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">
							Operaciones Bursátiles y Financieras que ha realizado como inversionista
						</label>
						<Controller
							control={control}
							name="stockTrading"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s6_operaciones_bursatiles}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="stockTrading"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">
							¿Cuánto tiempo está dispuesto a esperar para obtener una ganancia de su
							inversión?
						</label>
						<Controller
							control={control}
							name="holdTime"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s6_tiempo_espera}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>

						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="holdTime"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">
							¿En cuántos año planea retirarse o jubilarse?
						</label>
						<Controller
							control={control}
							name="retirementYears"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s6_tiempo_retiro}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="retirementYears"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9 lg:pr-9">
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">¿Cúales son sus ingresos anuales?</label>
						<Controller
							control={control}
							name="annualIncome"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s6_ingresos_anuales}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="annualIncome"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
  );
}

export default StartInvestPersonStep6;
