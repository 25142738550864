import ExecutivesReadDto from "../../dtos/ExecutivesReadDto";
import ExecutiveCard from "./ExecutiveCard";
import ExecutivesFilter from "./ExecutivesFilter";

interface IProps {
  executives: ExecutivesReadDto[];
  areas: string[];
  setCurrentArea: React.Dispatch<React.SetStateAction<string>>;
}

function Executive(props: IProps) {
  function renderExecutives() {
    return props.executives.map((e) => <ExecutiveCard key={e.id} executive={e} />);
  }

  return (
    <section className="main-executives">
      <h3 className="main-executives__title">Principales ejecutivos</h3>
      <ExecutivesFilter
        areas={props.areas}
        setCurrentArea={props.setCurrentArea}
      />
      <div className="main-executives-cards lg:gap-7">{renderExecutives()}</div>
    </section>
  );
}

export default Executive;
