import CodeOfConductReadDto from "../../dtos/CodeOfConductReadDto";

interface IProps{
	codeOfConduct:CodeOfConductReadDto;
}

function Conduct(props:IProps) {
	return (
		<section className="main-conduct">
			<div className="main-conduct__container">
				<h1 className="main-conduct__title">
					Código de Conducta <br className="main-conduct__break" />
					Ética AFI Popular
				</h1>
				<a href={props.codeOfConduct.pdfUrl} download>
					<div className="main-conduct--button"> Descargar</div>
				</a>
			</div>
		</section>
	);
}

export default Conduct;
