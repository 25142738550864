import axios from 'axios';
import AppVariables from '../Variables.json';
import DividendosReadDto from '../dtos/DividendosReadDto';
import { SharePointConsts } from '../util/constants';

async function fetchDividendosData(filter: string): Promise<DividendosReadDto> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Dividendos pagados')/items?$select=*,Fondo/Title&$expand=Fondo&${filter}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const response = new DividendosReadDto();

	if (!data.d.results.length) {
		return response;
	}

	response.id = data.d.results[0].ID;
	response.fecha = data.d.results[0].Fecha;
	response.valorDividendo = data.d.results[0].Valordividendo;
	response.fondoId = data.d.results[0].FondoId;

	return response;
}

export async function getDividendosByfundTitleAndDate(
	title: string,
	date: string
): Promise<DividendosReadDto> {
	const filter = `$filter=(Fondo/Title eq '${title}') and (Fecha eq '${date}')`;
	return fetchDividendosData(filter);
}

export async function getLastDividendoByFund(title: string): Promise<DividendosReadDto> {
	const filter = `$filter=Fondo/Title eq '${title}'&$orderby=Fecha desc`;
	return fetchDividendosData(filter);
}
