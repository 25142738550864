import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import instagramIcon from '../../img/general/des-instagram-icon.svg';
import linkedinIcon from '../../img/general/des-linkedin-icon.svg';
import { faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AppVariables from '../../Variables.json';
import { getFundsByFundType } from '../../services/FundServices';
import FundReadDto from '../../dtos/FundReadDto';
import AfiContactoDto from '../../dtos/AfiContactosDto';
import { getAfiContactosInfo, getSectionStatus } from '../../services/GenericServices';

interface IProps {
	display: string;
	action: () => void;
}

function MobileMenu(props: IProps) {
	//state variables
	const [activeMenuOption, setActiveMenuOption] = useState('');
	const [openFunds, setOpenFunds] = useState<FundReadDto[]>([]);
	const [closeFunds, setCloseFunds] = useState<FundReadDto[]>([]);
	const [contactos, setContactos] = useState<AfiContactoDto>(new AfiContactoDto());
	const navigate = useNavigate();

	const [ebookSectionStatus, setEbookSectionStatus] = useState<boolean>();
	const [podcastSectionStatus, setPodcastSectionStatus] = useState<boolean>();
	const [coursesSectionStatus, setCoursesSectionStatus] = useState<boolean>();

	const arrowStateClasses = {
		rotate: 'rotate-arrow',
		reverse: 'reverse-arrow',
	};
	const menuStateClasses = {
		active: 'submenu-active',
		hide: 'submenu-hide',
	};

	const setSectionStatus = async () => {
		const EbookStatus = await getSectionStatus('E-book');
		const PodcastStatus = await getSectionStatus('Podcast');
		const CoursesStatus = await getSectionStatus('Cursos y Talleres');
		setEbookSectionStatus(EbookStatus.visibility);
		setPodcastSectionStatus(PodcastStatus.visibility);
		setCoursesSectionStatus(CoursesStatus.visibility);
	};

	const getContactos = async () => {
		const fetchedContactos = await getAfiContactosInfo();
		setContactos(fetchedContactos);
	};

	function handleMenuOptionOnClick(optionClicked: string) {
		if (activeMenuOption === optionClicked) {
			setActiveMenuOption('');
		} else {
			setActiveMenuOption(optionClicked);
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			const openFundsfetched = await getFundsByFundType('Abierto');
			const closedFundsfetched = await getFundsByFundType('Cerrado');
			setOpenFunds(openFundsfetched);
			setCloseFunds(closedFundsfetched);
		};

		setSectionStatus();
		getContactos();
		fetchData();
	}, []);

	return (
		<div>
			<div className={'mobile-menu' + props.display}>
				<div className="botton-nav__options--search-box">
					<input
						id="mobile-nav-search-input"
						type="text"
						className="botton-nav__options--search-box--input"
						placeholder="Buscar"
					/>
					<div
						className="botton-nav__options--search-box--button"
						onClick={() => {
							props.action();
							navigate({
								pathname: `${AppVariables.REACT_ROUTER_URL}/resultado-busqueda`,
								search: `?expr=${
									(document.getElementById('mobile-nav-search-input') as HTMLInputElement)
										.value
								}`,
							});

							(
								document.getElementById('mobile-nav-search-input') as HTMLInputElement
							).value = '';
						}}
					>
						<i className="fa-sm">
							<FontAwesomeIcon icon={faSearch} />
						</i>
					</div>
				</div>

				<div className="mobile-menu--social-media">
					<a href={contactos.instagramUrl}>
						<img
							src={instagramIcon}
							alt="instagram-icon"
							className="mobile-menu--social-media--icon"
						/>
					</a>
					{/* <a href="https://twitter.com/Popularenlinea">
						<img
							src={twitterIcon}
							alt="twitter-icon"
							className="mobile-menu--social-media--icon"
						/>
					</a>
					<a href="https://www.facebook.com/AFIPopular/">
						<img
							src={facebookIcon}
							alt="facebook-icon"
							className="mobile-menu--social-media--icon"
						/>
					</a> */}
					<a href={contactos.linkedInUrl}>
						<img
							src={linkedinIcon}
							alt="linkedin-icon"
							className="mobile-menu--social-media--icon"
						/>
					</a>
				</div>

				<div className="mobile-menu__list">
					<ul>
						<li onClick={() => handleMenuOptionOnClick('conocenos')}>
							Conócenos
							<i>
								<FontAwesomeIcon
									icon={faChevronRight}
									className={
										activeMenuOption === 'conocenos'
											? arrowStateClasses.rotate
											: arrowStateClasses.reverse
									}
								/>
							</i>
							<div
								className={
									activeMenuOption === 'conocenos'
										? 'submenu-generic submenu-active'
										: 'submenu-generic submenu-hide'
								}
							>
								<a
									href=""
									className="block px-4 pb-2 mt-4"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/sobre-nosotros`);
									}}
								>
									Sobre nosotros
								</a>
								<a
									href=""
									className="block px-4 py-2"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/gobierno-corporativo`);
									}}
								>
									Gobierno Corporativo
								</a>
								<a
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/publicaciones`);
									}}
									className="block px-4 py-2"
								>
									Publicaciones institucionales
								</a>
							</div>
						</li>

						<li onClick={() => handleMenuOptionOnClick('fondos')}>
							Fondos de Inversión
							<i>
								<FontAwesomeIcon
									icon={faChevronRight}
									className={
										activeMenuOption === 'fondos'
											? arrowStateClasses.rotate
											: arrowStateClasses.reverse
									}
								/>
							</i>
							<div
								className={
									activeMenuOption === 'fondos'
										? menuStateClasses.active
										: menuStateClasses.hide
								}
							>
								<label htmlFor="" className="block mt-4 px-2 ml-2 label-menu">
									Abiertos
								</label>
								<div>
									{openFunds.map((fund) => (
										<a
											className="block px-4 py-2"
											onClick={() => {
												props.action();
												navigate({
													pathname: `${AppVariables.REACT_ROUTER_URL}/generic-fund`,
													search: `?titulo=${fund.title}`,
												});
											}}
										>
											{fund.title}
										</a>
									))}
								</div>
								<label htmlFor="" className="block mt-4 px-2 ml-2 label-menu">
									Cerrados
								</label>
								<div>
									{closeFunds.map((fund) => (
										<a
											className="block px-4 py-2"
											onClick={() => {
												props.action();
												navigate({
													pathname: `${AppVariables.REACT_ROUTER_URL}/generic-fund`,
													search: `?titulo=${fund.title}`,
												});
											}}
										>
											{fund.title}
										</a>
									))}
								</div>
								<br />
								<a
									href=""
									className="block px-4 pb-2"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/fondos-de-inversion`);
									}}
								>
									<FontAwesomeIcon icon={faChevronRight} />
									Ver todos los fondos
								</a>
								<a
									href=""
									className="block px-4 pb-2"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/comparador-de-fondos`);
									}}
								>
									<FontAwesomeIcon icon={faChevronRight} />
									Comparar fondos
								</a>
							</div>
						</li>

						<li onClick={() => handleMenuOptionOnClick('herramientas')}>
							Herramientas
							<i>
								<FontAwesomeIcon
									icon={faChevronRight}
									className={
										activeMenuOption === 'herramientas'
											? arrowStateClasses.rotate
											: arrowStateClasses.reverse
									}
								/>
							</i>
							<div
								className={
									activeMenuOption === 'herramientas'
										? menuStateClasses.active
										: menuStateClasses.hide
								}
							>
								<a
									href=""
									className="block px-4 mt-4 pb-2"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/perfil-de-riesgo`);
									}}
								>
									Perfil de Riesgo del Inversionista
								</a>
								<a
									href=""
									className="block px-4 py-2"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/recomendador`);
									}}
								>
									Recomendador de fondos
								</a>
								<a
									href=""
									className="block px-4 py-2"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/recomendador`);
									}}
								>
									Valor de las cuotas
								</a>
								<a
									href=""
									className="block px-4 py-2"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/calculadora`);
									}}
								>
									Calculadora de rendimiento
								</a>
								<a
									href=""
									className="block px-4 py-2"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/comparador-de-fondos`);
									}}
								>
									Comparador de fondos
								</a>
							</div>
						</li>

						<li onClick={() => handleMenuOptionOnClick('noticias')}>
							Noticias
							<i>
								<FontAwesomeIcon
									icon={faChevronRight}
									className={
										activeMenuOption === 'noticias'
											? arrowStateClasses.rotate
											: arrowStateClasses.reverse
									}
								/>
							</i>
							<div
								className={
									activeMenuOption === 'noticias'
										? menuStateClasses.active
										: menuStateClasses.hide
								}
							>
								<a
									href=""
									className="block px-4 mt-4 pb-2"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/blog`);
									}}
								>
									Blog
								</a>
								{podcastSectionStatus === true ? (
									<a
										href=""
										className="block px-4 py-2"
										onClick={() => {
											props.action();
											navigate(`${AppVariables.REACT_ROUTER_URL}/podcast`);
										}}
									>
										Podcast
									</a>
								) : (
									<></>
								)}
								<a
									href=""
									className="block px-4 py-2"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/sala-de-prensa`);
									}}
								>
									Sala de Prensa
								</a>
								<a
									href=""
									className="block px-4 py-2"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/boletin`);
									}}
								>
									Boletín informativo
								</a>
							</div>
						</li>

						<li onClick={() => handleMenuOptionOnClick('info')}>
							Informaciones
							<i>
								<FontAwesomeIcon
									icon={faChevronRight}
									className={
										activeMenuOption === 'info'
											? arrowStateClasses.rotate
											: arrowStateClasses.reverse
									}
								/>
							</i>
							<div
								className={
									activeMenuOption === 'info'
										? menuStateClasses.active
										: menuStateClasses.hide
								}
							>
								{ebookSectionStatus === true ? (
									<a
										href=""
										className="block px-4 mt-4 pb-2"
										onClick={() => {
											props.action();
											navigate(`${AppVariables.REACT_ROUTER_URL}/ebooks`);
										}}
									>
										Ebook
									</a>
								) : (
									<></>
								)}
								{coursesSectionStatus === true ? (
									<a
										href=""
										className="block px-4 mt-4 pb-2"
										onClick={() => {
											props.action();
											navigate(`${AppVariables.REACT_ROUTER_URL}/courses`);
										}}
									>
										Cursos y talleres
									</a>
								) : (
									<></>
								)}
								<a
									href=""
									className="block px-4 py-2"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/preguntas-frecuentes`);
									}}
								>
									Preguntas frecuentes
								</a>
								<a
									href=""
									className="block px-4 py-2"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/diccionario`);
									}}
								>
									Diccionario
								</a>
								<a
									href=""
									className="block px-4 py-2"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/marco-regulatorio`);
									}}
								>
									Marco regulatorio
								</a>
								<a
									href=""
									className="block px-4 py-2"
									onClick={() => {
										props.action();
										navigate(`${AppVariables.REACT_ROUTER_URL}/FATCA`);
									}}
								>
									FATCA
								</a>
							</div>
						</li>

						<li
							onClick={() => {
								props.action();
								navigate(`${AppVariables.REACT_ROUTER_URL}/contactanos`);
							}}
						>
							Contáctanos
						</li>
					</ul>
				</div>
			</div>

			<div className={'black-background' + props.display}></div>
		</div>
	);
}

export default MobileMenu;
