import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const noticiasBreadcrumb = (
	<ol className="flex list-reset breadcrumbs">
		<li>
			<a href="#" className="text-black">
				Inicio
			</a>
		</li>
		<li>
			<span className="mx-2 text-black">
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>
			</span>
		</li>
		<li>
			<a href="#" className="text-black">
				Noticias
			</a>
		</li>
		<li>
			<span className="mx-2 text-black">
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>
			</span>
		</li>
		<li>
			<a href="#" className="text-black">
				Detalle de noticia
			</a>
		</li>
	</ol>
);

export const podcastBreadcrumb = (
	<ol className="flex list-reset breadcrumbs">
		<li>
			<a href="#" className="text-black">
				Inicio
			</a>
		</li>
		<li>
			<span className="mx-2 text-black">
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>
			</span>
		</li>
		<li>
			<a href="#" className="text-black">
				Podcast
			</a>
		</li>
		<li>
			<span className="mx-2 text-black">
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>
			</span>
		</li>
		<li>
			<a href="#" className="text-black">
				Detalle de podcast
			</a>
		</li>
	</ol>
);

export const blogBreadcrumb = (
	<ol className="flex list-reset breadcrumbs">
		<li>
			<a href="#" className="text-black">
				Inicio
			</a>
		</li>
		<li>
			<span className="mx-2 text-black">
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>
			</span>
		</li>
		<li>
			<a href="#" className="text-black">
				Blog
			</a>
		</li>
		<li>
			<span className="mx-2 text-black">
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>
			</span>
		</li>
		<li>
			<a href="#" className="text-black">
				Detalle de Blog
			</a>
		</li>
	</ol>
);

export const boletinBreadcrumb = (
	<ol className="flex list-reset breadcrumbs">
		<li>
			<a href="#" className="text-black">
				Inicio
			</a>
		</li>
		<li>
			<span className="mx-2 text-black">
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>
			</span>
		</li>
		<li>
			<a href="#" className="text-black">
				Boletines
			</a>
		</li>
	</ol>
);

export const ebooksBreadcrumb = (
	<ol className="flex list-reset breadcrumbs">
		<li>
			<a href="#" className="text-black">
				Inicio
			</a>
		</li>
		<li>
			<span className="mx-2 text-black">
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>
			</span>
		</li>
		<li>
			<a href="#" className="text-black">
				Ebooks
			</a>
		</li>
	</ol>
);

export const courseBreadcrumb = (
	<ol className="flex list-reset breadcrumbs">
		<li>
			<a href="#" className="text-black">
				Inicio
			</a>
		</li>
		<li>
			<span className="mx-2 text-black">
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>
			</span>
		</li>
		<li>
			<a href="#" className="text-black">
				Cursos y talleres
			</a>
		</li>
		<li>
			<span className="mx-2 text-black">
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>
			</span>
		</li>
		<li>
			<a href="#" className="text-black">
				Detalle de curso
			</a>
		</li>
	</ol>
);

export const diccionarioBreadcrumb = (
	<ol className="flex list-reset breadcrumbs">
		<li>
			<a href="#" className="text-black">
				Inicio
			</a>
		</li>
		<li>
			<span className="mx-2 text-black">
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>
			</span>
		</li>
		<li>
			<a href="#" className="text-black">
				Diccionario
			</a>
		</li>
	</ol>
);

export const preguntasBreadcrumb = (
	<ol className="flex list-reset breadcrumbs">
		<li>
			<a href="#" className="text-black">
				Inicio
			</a>
		</li>
		<li>
			<span className="mx-2 text-black">
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>
			</span>
		</li>
		<li>
			<a href="#" className="text-black">
				Preguntas frecuentes
			</a>
		</li>
	</ol>
);

export const fondoBreadcrumb = (
	<ol className="flex list-reset breadcrumbs">
		<li>
			<a href="#" className="text-black">
				Inicio
			</a>
		</li>
		<li>
			<span className="mx-2 text-black">
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>
			</span>
		</li>
		<li>
			<a href="#" className="text-black">
				Fondos
			</a>
		</li>
		<li>
			<span className="mx-2 text-black">
				<i>
					<FontAwesomeIcon icon={faChevronRight} />
				</i>
			</span>
		</li>
		<li>
			<a href="#" className="text-black">
				Detalle de fondo
			</a>
		</li>
	</ol>
);