class GCDocumentsReadDto {
  public id: number;
  public title: string;

  private _date: string;
  public get date(): string {
    return this._date;
  }
  public set date(value: string) {
    const newDate = new Intl.DateTimeFormat(["ban", "id"]).format(
      new Date(value)
    );
    this._date = newDate;
  }

  public pdfUrl: string;
  public fileSize: string;
}

export default GCDocumentsReadDto;
