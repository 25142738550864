import axios from 'axios';
import AppVariables from '../Variables.json';
import AfiContactoDto from '../dtos/AfiContactosDto';
import PageInfoDto from '../dtos/PageInfoDto';
import SectionReadDto from '../dtos/SectionReadDto';
import { SharePointConsts } from '../util/constants';

export async function getItemAttachmentFileById(list: string, id: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('${list}')/items(${id})?$expand=AttachmentFiles`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	return {
		url: data.d.AttachmentFiles.results.length
			? data.d.AttachmentFiles.results[0].ServerRelativeUrl
			: '',
		filename: data.d.AttachmentFiles.results.length
			? data.d.AttachmentFiles.results[0].FileName
			: '',
	};
}

export async function getListMetadata(listName: string): Promise<string> {
	return axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/lists/getbytitle('${listName}')?$select=ListItemEntityTypeFullName`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose
				},
			}
		)
		.then((res) => res.data.d.ListItemEntityTypeFullName)
		.catch((error) => console.error(error));
}

export async function getAfiContactosInfo(): Promise<AfiContactoDto> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('AFI Contactos')/items?$filter=Estado eq 'Activo'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const contactos = new AfiContactoDto();

	if (!data.d.results.length) {
		return contactos;
	}

	contactos.id = data.d.results[0].ID;
	contactos.facebookUrl = data.d.results[0].Facebook.Url;
	contactos.instagramUrl = data.d.results[0].Instagram.Url;
	contactos.twitterUrl = data.d.results[0].Twitter.Url;
	contactos.linkedInUrl = data.d.results[0].LinkedIn.Url;
	contactos.escribenosMail = data.d.results[0].Correooficial;
	contactos.reclamosMail = data.d.results[0].Correodedenuncias;
	contactos.telefono = data.d.results[0].Telefono;
	contactos.direccion = data.d.results[0].Direccion;
	contactos.direccionUrl = data.d.results[0].DireccionUrl.Url;
	contactos.denunciasPlaft = data.d.results[0].Correodenunciasplaft;

	return contactos;
}

export async function getPageInfo(page: string): Promise<PageInfoDto> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Titulos y Descripciones')/items?$filter=Pagina eq '${page}'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const pageInfo = new PageInfoDto();
	pageInfo.title = data.d.results[0].Titulo;
	pageInfo.description = data.d.results[0].Descripcion;

	return pageInfo;
}

export async function getSectionStatus(section: string): Promise<SectionReadDto> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Secciones Ocultables')/items?$filter= Seccion eq '${section}'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const fetchedSection = new SectionReadDto();

	if (!data.d.results.length) {
		return fetchedSection;
	}

	fetchedSection.id = data.d.results[0].ID;
	fetchedSection.section = data.d.results[0].Seccion;
	fetchedSection.visibility = data.d.results[0].Ocultar;

	return fetchedSection;
}