import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SearchResultDto from '../../dtos/SearchResultDto';
import {
	searchBlog,
	searchBoletin,
	searchCuorses,
	searchDiccionario,
	searchEbooks,
	searchFondo,
	searchNoticias,
	searchPodcast,
	searchPreguntas,
} from '../../services/SearchServices';
import ResultContainer from './ResultContainer';
import SearchBanner from './SearchBanner';

function MainSearchResult() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [currentExpression, setCurrentExprs] = useState('');
	const [results, setResults] = useState<SearchResultDto[]>([]);

	const fetchCurrentExpr = () => {
		setCurrentExprs(searchParams.get('expr'));
	};

	const fetchResults = async () => {
		const fetchedResults: SearchResultDto[] = [];

		const noticiaResults = await searchNoticias(currentExpression);
		const podcastResults = await searchPodcast(currentExpression);
		const blogResults = await searchBlog(currentExpression);
		const boletinResults = await searchBoletin(currentExpression);
		const ebooksResults = await searchEbooks(currentExpression);
		const cursosResults = await searchCuorses(currentExpression);
		const diccionarioResults = await searchDiccionario(currentExpression);
		const preguntasResults = await searchPreguntas(currentExpression);
		const fondosResults = await searchFondo(currentExpression);

		fetchedResults.push(
			...noticiaResults,
			...podcastResults,
			...blogResults,
			...boletinResults,
			...ebooksResults,
			...cursosResults,
			...diccionarioResults,
			...preguntasResults,
			...fondosResults
		);

		setResults(fetchedResults);
	};

	useEffect(() => {
		fetchCurrentExpr();
	}, [searchParams]);

	useEffect(() => {
		fetchResults();
	}, [currentExpression]);

	return (
		<div>
			<SearchBanner setCurrentExprs={setCurrentExprs} currentExprs={currentExpression} />
			<ResultContainer
				results={results}
				currentExpression={currentExpression}
				setSearchParam={setSearchParams}
			/>
		</div>
	);
}

export default MainSearchResult;
