// import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { forwardRef } from "react";
import "./date.css";
registerLocale("es", es);

const DatePickerComponent = ({
  onChange,
  currValue = new Date(),
  isDisabled,
  minDate,
  maxDate,
}: any) => {
  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <button
      type="button"
      className="global-input date-input"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  return (
    <DatePicker
      dateFormat="dd/MM/yyyy"
      selected={currValue}
      onChange={(date) => {
        onChange(date);
      }}
      placeholderText="Seleccione una fecha"
      locale="es"
      customInput={<CustomInput />}
      disabled={isDisabled}
      minDate={minDate}
      maxDate={maxDate}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
    />
  );
};

export default DatePickerComponent;
// "2022-08-15"
// "Thu Jan 06 2022 00:00:00 GMT-0400 (Bolivia Time)".slice(11, 15) + "-"+ months["Thu Jan 06 2022 00:00:00 GMT-0400 (Bolivia Time)".slice(4, 7)]+"-"+"Thu Jan 06 2022 00:00:00 GMT-0400 (Bolivia Time)".slice(8, 10);
