import axios from 'axios';
import { useState, useEffect } from 'react';
import AppVariables from '../../Variables.json';
import Cookies, { cookiesProps } from '../../components/PrivacyPolicy_TermsConditions/Cookies';
import { SharePointConsts } from '../../util/constants';

export default function CookiesPage() {
	const [pageData, setPageData] = useState<cookiesProps>({
		title: '',
		subTitle: '',
		content: '',
	});

	async function getPageData() {
		const resultsArr = await axios
			.get(
				`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Politicas-Privacidad-Terminos-Condiciones')/items?$filter=Page eq 'Cookies'`,

				{
					headers: {
						'X-RequestDigest': (
							document.getElementById('__REQUESTDIGEST') as HTMLInputElement
						).value,

						Accept: SharePointConsts.acceptNoMetadata,
						'Content-Type': SharePointConsts.contentTypeNoMetadata,
					},
				}
			)
			.then((response) => response.data.value[0])
			.catch((e) => console.error(e));

		setPageData({
			title: resultsArr?.Title,
			subTitle: resultsArr?.SubTitle,
			content: resultsArr?.Content,
		});
	}

	useEffect(() => {
		getPageData();
	}, []);

	return (
		<Cookies title={pageData.title} subTitle={pageData.subTitle} content={pageData.content} />
	);
}
