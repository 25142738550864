import { useEffect, useState } from 'react';
import BlogReadDto from '../../dtos/BlogReadDto';
import {
	getAllBlogs,
	getAllBlogsCategories,
	getBlogsByCategory,
	getBlogsByPublicationDate,
	getBlogsByPublicationDateAndCategory,
	getLastNBlogs,
} from '../../services/BlogServices';
import BlogContainer from './BlogContainer';
import BlogFilters from './BlogFilters';

function MainBlog() {
	const [categories, setCategories] = useState([]);
	const [category, setCategory] = useState('Todas');
	const [date, setDate] = useState('');
	const [dates, setDates] = useState([]);
	const [principalBlog, setPrincipalBlog] = useState<BlogReadDto>();
	const [secundaryblogs, setSecBlogs] = useState<BlogReadDto[]>([]);
	const [blogs, setBlogs] = useState<BlogReadDto[]>([]);
	const [loadMore, setLoadMore] = useState<boolean>(true);

	const onChangeCategory = (category: any) => {
		setCategory(category.value);
	};
	const updateBlogs = async () => {
		let fetchedBlog: BlogReadDto[];
		let fetchedMainBlog: BlogReadDto;
		let fetchedSecBlog: BlogReadDto[];

		if (category === 'Todas' && date === '') {
			fetchedBlog = await getLastNBlogs(10);
			setBlogs(fetchedBlog);
			fetchedMainBlog = fetchedBlog.shift();
			fetchedSecBlog = fetchedBlog;
		} else if (category !== 'Todas' && date === '') {
			fetchedBlog = await getBlogsByCategory(category);
			setBlogs(fetchedBlog);
			fetchedMainBlog = fetchedBlog.shift();
			fetchedSecBlog = fetchedBlog;
		} else if (category === 'Todas' && date !== '') {
			fetchedBlog = await getBlogsByPublicationDate(date);
			setBlogs(fetchedBlog);
			fetchedMainBlog = fetchedBlog.shift();
			fetchedSecBlog = fetchedBlog;
		} else if (category !== 'Todas' && date !== '') {
			fetchedBlog = await getBlogsByPublicationDateAndCategory(category, date);
			setBlogs(fetchedBlog);
			fetchedMainBlog = fetchedBlog.shift();
			fetchedSecBlog = fetchedBlog;
		}

		setPrincipalBlog(fetchedMainBlog);
		setSecBlogs(fetchedSecBlog);
	};

	const fetchCategories = async () => {
		const fetchedCategories = await getAllBlogsCategories();
		setCategories(fetchedCategories);
	};

	const fetchDates = async () => {
		const fetchedBlogs = await getAllBlogs();
		const uniqueDates = Array.from(new Set(fetchedBlogs.map((n) => n._publicationDate)));
		setDates(uniqueDates);
	};

	useEffect(() => {
		fetchCategories();
		fetchDates();
	}, []);

	useEffect(() => {
		updateBlogs();
	}, [category, date]);

	const loadAllBlogs = async () => {
		const data = await getAllBlogs();
		setBlogs(data);
		setPrincipalBlog(data.shift());
		setSecBlogs(data);
	};

	return (
		<section className="main-prensa">
			<div className="main-prensa-container">
				<div className="main-prensa-header">
					<h1 className="main-prensa__title">Blog</h1>
					<BlogFilters
						categories={categories}
						setCategory={onChangeCategory}
						setDate={setDate}
						dates={dates}
						currentDate={date}
					/>
				</div>
				<BlogContainer
					principalBlog={principalBlog}
					secundaryBlogs={secundaryblogs}
					loadAllBlogs={loadAllBlogs}
					Blogs={blogs}
					loadMore={loadMore}
					setLoadMore={setLoadMore}
				/>
			</div>
		</section>
	);
}

export default MainBlog;
