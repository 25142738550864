interface IProps {
  imgUrl: string;
  name: string;
  category: string;
  biography: string;
}

function PodcastAuthorCard(props: IProps) {
  return (
    <div className="author-card-list__card">
      <img src={props.imgUrl} alt="" className="author-card-list__image" />
      <div className="author-card-info">
        <h3>{props.name}</h3>
        <p>{props.category}</p>
      </div>
      <div
        className="author-card-text"
        dangerouslySetInnerHTML={{ __html: props.biography }}
      ></div>
    </div>
  );
}

export default PodcastAuthorCard;
