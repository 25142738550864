import axios from 'axios';
import AppVariables from '../Variables.json';
import DiccionarioReadDto from '../dtos/DiccionarioReadDto';

const headersAccept = 'application/json; odata=verbose';

export async function getAllWordsByCategory(category: string): Promise<DiccionarioReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Diccionario')/items?$expand=AttachmentFiles&$filter=Estado eq 'Activo' and Categoria eq '${category}'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const words: DiccionarioReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const word = new DiccionarioReadDto();

		word.id = data.d.results[index].ID;
		word.title = data.d.results[index].Title;
		word.description = data.d.results[index].Descripcion;
		word.category = data.d.results[index].Categoria;

		words[index] = word;
	}

	return words;
}

export async function searchWordsByExprAndCategory(
	category: string,
	expr: string
): Promise<DiccionarioReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Diccionario')/items?$expand=AttachmentFiles&$filter=Estado eq 'Activo' and Categoria eq '${category}' and substringof('${expr}',Title)`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const words: DiccionarioReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const word = new DiccionarioReadDto();

		word.id = data.d.results[index].ID;
		word.title = data.d.results[index].Title;
		word.description = data.d.results[index].Descripcion;
		word.category = data.d.results[index].Categoria;

		words[index] = word;
	}

	return words;
}

export async function getAllDiccionaryCategories(): Promise<string[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Diccionario')/fields?$filter=EntityPropertyName eq 'Categoria'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const categories: string[] = data.d.results[0].Choices.results;

	return categories;
}
