function MarcoRegulatorioBanner() {
    return (
			<div className="banner-intro">
				<div className="container mx-auto">
					<h1>Marco regulatorio</h1>
					<p>
						Las operaciones de las administradoras de fondos están reguladas por la
						Superintendencia de Valores y las leyes emitidas por dicha institución así como
						las normas prudenciales que se derivan de las mismas. De manera enunciativa mas no
						limitativa, citamos las siguientes:
					</p>
				</div>
			</div>
		);
}

export default MarcoRegulatorioBanner;