import { useEffect, useState } from "react";
import PageInfoDto from "../../dtos/PageInfoDto";
import { getPageInfo } from "../../services/GenericServices";

function CalculadoraIntro() {

	const [pageInfo, setPageInfo] = useState<PageInfoDto>(new PageInfoDto());

	const fetchInfo = async () => {
		const fetchedInfo = await getPageInfo('Calculadora de Rendimiento');
		setPageInfo(fetchedInfo);
	};

	useEffect(() => {
		fetchInfo();
	}, []);

    return (
			<div className="comparador__intro">
				<h1>{pageInfo.title}</h1>
				<p>
					{pageInfo.description}
				</p>
			</div>
		);
}

export default CalculadoraIntro;