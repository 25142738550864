import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
	setCurrentExprs: any;
	currentExprs: string;
}

function SearchBanner({ setCurrentExprs, currentExprs }: IProps) {

	return (
		<div className="banner-intro">
			<div className="container mx-auto">
				<h1>Resultados de la búsqueda</h1>
				<div className="top-search">
					<input
						id="search-result-input"
						placeholder="Buscar"
						type="text"
						defaultValue={currentExprs}
						onChange={(e) => {
							setCurrentExprs(e.target.value);
						}}
					/>
					<div className="top-search__icon">
						{currentExprs !== '' ? (
							<i
								className="fa-lg cursor-pointer"
								onClick={() => {
									setCurrentExprs('');
									(
										document.getElementById('search-result-input') as HTMLInputElement
									).value = '';
								}}
							>
								<FontAwesomeIcon icon={faTimes} />
							</i>
						) : (
							<i className="fa-sm">
								<FontAwesomeIcon icon={faSearch} />
							</i>
						)}
						{/* <i className="fa-sm cursor-pointer">
              <FontAwesomeIcon icon={faSearch} />
            </i> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default SearchBanner;
