import { ErrorMessage } from '@hookform/error-message';
import { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import SelectComponent from '../../Select/SelectComponent';
import { fe_s6_descripcion_relacion } from '../../Utility/SelectOptions';
import { validations } from '../Validations/regexValidations';
import { ValidationMessages } from '../../Utility/ValidationMessages';

export default function InvestmentBeneficiaryContent({ index }: any) {
	const emptyFieldErrorMessage = 'Campo obligatorio';
	const {
		register,
		control,
		getValues,
		resetField,
		formState: { errors },
	} = useFormContext();
	const baseName = `investmentBeneficiaries[${index}]`;
	const IsActingAsIntermediary = useWatch({
		control,
		name: `${baseName}.IsActingAsIntermediary`,
	});
	const BondDescriptionSelect = useWatch({
		control,
		name: `${baseName}.BondDescriptionSelect`,
	});
	const IdType = useWatch({
		control,
		name: `${baseName}.IdType`,
	});

	const [numIdPattern, setNumIdPattern] = useState<RegExp>();

	const getNumIdPattern = (idType: string): RegExp => {
		if (idType === 'Cedula') {
			return /^\d{11}/;
		} else {
			return /^[A-Za-z0-9? ,-]+$/i;
		}
	};

	return (
		<div className="mb-6 create__requisitos">
			<h4 className="create__requisitos-title">Beneficiario de la inversión</h4>

			<div className=" lg:flex lg:space-x-9 lg:items-start">
				<div className="lg:w-1/2 lg:pr-4">
					<label className="global-label">
						¿Actúa usted como intermediario del verdadero beneficiario de la inversión?
					</label>
					<div className="mb-4 document-box">
						<input
							id={`intermediario-si-${index}`}
							className="toggle toggle-left"
							value="Si"
							type="radio"
							{...register(`${baseName}.IsActingAsIntermediary`, {
								required: { value: true, message: emptyFieldErrorMessage },
							})}
							onClick={() => resetField(`${baseName}.BondDescriptionSelect`)}
						/>

						<label htmlFor={`intermediario-si-${index}`} className="btn">
							Si
						</label>
						<input
							id={`intermediario-no-${index}`}
							className="toggle toggle-right"
							value="No"
							type="radio"
							{...register(`${baseName}.IsActingAsIntermediary`, {
								required: { value: true, message: emptyFieldErrorMessage },
							})}
							onClick={() => resetField(`${baseName}.BondDescriptionSelect`)}
						/>

						<label htmlFor={`intermediario-no-${index}`} className="btn">
							No
						</label>
					</div>
				</div>
				<div className="mb-6 group-form lg:w-1/2 lg:mb-0">
					<label className="global-label">
						En caso de marcar afirmativo, describa la relación que existe con el cliente o
						beneficiario final
					</label>
					<Controller
						control={control}
						name={`${baseName}.BondDescriptionSelect`}
						rules={{
							required: {
								value: IsActingAsIntermediary === 'Si',
								message: emptyFieldErrorMessage,
							},
						}}
						render={({ field: { onChange, value } }) => (
							<div onClick={() => resetField(`${baseName}.BondDescription`)}>
								<SelectComponent
									options={fe_s6_descripcion_relacion}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
									isDisabled={IsActingAsIntermediary === 'No'}
								/>
							</div>
						)}
					/>

					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name={`${baseName}.BondDescriptionSelect`}
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
			</div>
			<div className="group-form lg:items-end lg:flex lg:space-x-9">
				<div className="mb-6 lg:w-1/2 lg:pr-4">
					<label className="global-label">
						En caso de seleccionar Otro, describa la relación que existe con el cliente o
						beneficiario final
					</label>
					<input
						type="text"
						maxLength={40}
						className="global-input"
						placeholder="Relación"
						{...register(`${baseName}.BondDescription`, {
							required: {
								value: BondDescriptionSelect?.value === 'Otros',
								message: emptyFieldErrorMessage,
							},
							pattern: validations.letrasYespacios,
						})}
						disabled={!(BondDescriptionSelect?.value === 'Otros')}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name={`${baseName}.BondDescription`}
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
			</div>
			<div className="group-form lg:items-end lg:flex lg:space-x-9">
				<div className="mb-6 lg:w-1/2 lg:pr-4">
					<label className="global-label">Nombres</label>
					<input
						type="text"
						maxLength={40}
						className="global-input"
						placeholder="Nombres"
						{...register(`${baseName}.Names`, {
							required: {
								value: IsActingAsIntermediary === 'Si' ? true : false,
								message: emptyFieldErrorMessage,
							},
							pattern: validations.letrasYespacios,
						})}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name={`${baseName}.Names`}
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
				<div className="mb-6 lg:w-1/2 lg:pr-4">
					<label className="global-label">Apellidos</label>
					<input
						type="text"
						maxLength={40}
						className="global-input"
						placeholder="Apellidos"
						{...register(`${baseName}.LastNames`, {
							required: {
								value: IsActingAsIntermediary === 'Si' ? true : false,
								message: emptyFieldErrorMessage,
							},
							pattern: validations.letrasYespacios,
						})}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name={`${baseName}.LastNames`}
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
			</div>
			<div className="group-form lg:flex lg:space-x-9 lg:items-start">
				<div className="lg:w-1/2 lg:pr-4">
					<label className="global-label">Tipo de identificación</label>
					<div className="mb-4 document-box">
						<input
							id={`indentificacion-si-${index}`}
							className="toggle toggle-left"
							name="identificacion"
							value="Cedula"
							type="radio"
							{...register(`${baseName}.IdType`, {
								required: {
									value: IsActingAsIntermediary === 'Si',
									message: emptyFieldErrorMessage,
								},
								onChange: () =>
									setNumIdPattern(getNumIdPattern(getValues(`${baseName}.IdType`))),
							})}
							onClick={() => resetField(`${baseName}.IdNo`)}
						/>

						<label htmlFor={`indentificacion-si-${index}`} className="btn">
							Cédula
						</label>
						<input
							id={`identificación-no-${index}`}
							className="toggle toggle-right"
							name="identificacion"
							value="Pasaporte"
							type="radio"
							{...register(`${baseName}.IdType`, {
								required: {
									value: IsActingAsIntermediary === 'Si',
									message: emptyFieldErrorMessage,
								},
								onChange: () =>
									setNumIdPattern(getNumIdPattern(getValues(`${baseName}.IdType`))),
							})}
							onClick={() => resetField(`${baseName}.IdNo`)}
						/>

						<label htmlFor={`identificación-no-${index}`} className="btn">
							Pasaporte
						</label>
					</div>
				</div>
				<div className="mb-6 lg:w-1/2 lg:pr-4">
					<label className="global-label">Número de identificación</label>
					<input
						type="text"
						maxLength={11}
						className="global-input"
						placeholder="000-000000-0"
						{...register(`${baseName}.IdNo`, {
							required: {
								value: IsActingAsIntermediary === 'Si' ? true : false,
								message: emptyFieldErrorMessage,
							},
							pattern: {
								value: numIdPattern,
								message: ValidationMessages.invalidCharacters,
							},
						})}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name={`${baseName}.IdNo`}
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
			</div>
			<div className="group-form  lg:flex lg:space-x-9 lg:items-end">
				<div className="mb-6 lg:w-1/2 lg:pr-4">
					<label className="global-label">Teléfono de contacto</label>
					<input
						type="text"
						maxLength={14}
						className="global-input"
						placeholder="(809) 000 - 0000"
						{...register(`${baseName}.ContactPhoneNumber`, {
							required: {
								value: IsActingAsIntermediary === 'Si' ? true : false,
								message: emptyFieldErrorMessage,
							},
							pattern: validations.soloNumeros,
						})}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name={`${baseName}.ContactPhoneNumber`}
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
				<div className="mb-6 lg:w-1/2 lg:pr-4">
					<label className="global-label">Dirección</label>
					<input
						type="text"
						maxLength={40}
						className="global-input"
						placeholder="Dirección"
						{...register(`${baseName}.Address`, {
							required: {
								value: IsActingAsIntermediary === 'Si' ? true : false,
								message: emptyFieldErrorMessage,
							},
							pattern: validations.letrasNumerosEspaciosEspeciales,
						})}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name={`${baseName}.Address`}
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
