import axios from 'axios';
import AppVariables from '../Variables.json';
import ComiteReadDto from '../dtos/ComiteReadDto';
import FundReadDto from '../dtos/FundReadDto';
import PdfReadDto from '../dtos/PdfReadDto';
import ValorCuotasReadDto from '../dtos/ValorCuotasReadDto';
import { SharePointConsts } from '../util/constants';

export async function getAllFunds(): Promise<FundReadDto[]> {
	const data = await axios
		.get(`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Fondos')/items`, {
			headers: {
				'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
					.value,
				Accept: SharePointConsts.acceptVerbose,
			},
		})
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response: FundReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const fund = new FundReadDto();

		fund.id = data.d.results[index].ID;
		fund.title = data.d.results[index].Title;
		fund.definicion = data.d.results[index].definicion;
		fund.tipoDeFodo = data.d.results[index].Tipodefondo; //arreglar nombre
		fund.moneda = data.d.results[index].Moneda;
		fund.setValorCuota(data.d.results[index].Valorcuota);
		fund.rendimientoUltimos30dias = data.d.results[index].Rendimientoutlimos30dias * 100;
		fund.plazoDeInversion = data.d.results[index].Plazodeinversion;

		response[index] = fund;
	}

	return response;
}

export async function getFundsDetailsByID(id: number): Promise<FundReadDto> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Fondos')/items?$filter= ID eq ${id}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response: FundReadDto = new FundReadDto();

	if (!data.d.results.length) { return response; }

	response.id = data.d.results[0].ID;
	response.title = data.d.results[0].Title;
	response.definicion = data.d.results[0].definicion;
	response.tipoDeFodo = data.d.results[0].Tipodefondo; //arreglar nombre
	response.moneda = data.d.results[0].Moneda;
	response.plazoDeInversion = data.d.results[0].Plazodeinversion;
	response.pagoDividendos = data.d.results[0].Pagodividendos;
	response.setValorCuota(data.d.results[0].Valorcuota);
	response.setValorCuotaDiaAnterior(data.d.results[0].Valorcuotadeldiaanterior);
	response.setTotalCuotas(data.d.results[0].Totalcuotascolocadas);
	response.setPatrimonioEnCirculacion(data.d.results[0].Patrimonioencirculacion);
	response.rendimientoUltimos30dias = data.d.results[0].Rendimientoutlimos30dias * 100;
	response.rendimientoUltimos90dias = data.d.results[0].Rendimientoultimos90dias * 100;
	response.rendimientoUltimos180dias = data.d.results[0].Rendimientoultimos180dias * 100;
	response.rendimientoUltimos365dias = data.d.results[0].Rendimientoultimos365dias * 100;
	response.fecha = data.d.results[0].Fecha;
	response.benchmark = data.d.results[0].Benchmark * 100;
	response.year = data.d.results[0].Fecha;

	return response;
}

export async function getFundsDetailsByTitle(title: string): Promise<FundReadDto> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Fondos')/items?$filter= Title eq '${title}'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response: FundReadDto = new FundReadDto();

	if (!data.d.results.length) { return response; }

	response.id = data.d.results[0].ID;
	response.title = data.d.results[0].Title;
	response.definicion = data.d.results[0].definicion;
	response.tipoDeFodo = data.d.results[0].Tipodefondo; //arreglar nombre
	response.moneda = data.d.results[0].Moneda;
	response.plazoDeInversion = data.d.results[0].Plazodeinversion;
	response.pagoDividendos = data.d.results[0].Pagodividendos;
	response.setValorCuota(data.d.results[0].Valorcuota);
	response.setValorCuotaDiaAnterior(data.d.results[0].Valorcuotadeldiaanterior);
	response.setTotalCuotas(data.d.results[0].Totalcuotascolocadas);
	response.setPatrimonioEnCirculacion(data.d.results[0].Patrimonioencirculacion);
	response.rendimientoUltimos30dias = data.d.results[0].Rendimientoutlimos30dias * 100;
	response.rendimientoUltimos90dias = data.d.results[0].Rendimientoultimos90dias * 100;
	response.rendimientoUltimos180dias = data.d.results[0].Rendimientoultimos180dias * 100;
	response.rendimientoUltimos365dias = data.d.results[0].Rendimientoultimos365dias * 100;
	response.fecha = data.d.results[0].Fecha;
	response.benchmark = data.d.results[0].Benchmark * 100;
	response.year = data.d.results[0].Fecha;

	return response;
}

export async function getComiteByfundTitle(title: string): Promise<ComiteReadDto> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Comite de inversion')/items?$select=*,Fondo/Title&$expand=Fondo&$filter=Fondo/Title eq '${title}'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response = new ComiteReadDto();

	if (!data.d.results.length) { return response; }

	response.id = data.d.results[0].ID;
	response.fondoTitle = data.d.results[0].Fondo.Title;
	response.definicion = data.d.results[0].Definicion;
	response.presidente = data.d.results[0].Presidente;
	response.vicepresidente = data.d.results[0].Vicepresidente;
	response.miembro = data.d.results[0].Miembro;

	return response;
}

type Member = {
	nombre: string;
	rol: string;
};

export async function getMiembrosComiteByfundTitle(title: string): Promise<Member[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('miembros comite de inversion')/items?$select=*,Fondo/Title&$expand=Fondo&$filter=Fondo/Title eq '${title}' and Status eq 'Activo'&$orderby=Orden asc`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response: Member[] = [];

	if (!data.d.results.length) { return response; }

	for (let index = 0; index < data.d.results.length; index++) {
		const miembro: Member = {
			nombre: data.d.results[index].Nombre,
			rol: data.d.results[index].Rol,
		};
		response[index] = miembro;
	}

	return response;
}

export async function getDocsByFundAndCategory(
	fund: string,
	category: string
): Promise<PdfReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Fondos Documentos')/items?$select=*,Fondo/Title&$expand=Fondo&$filter=Fondo/Title eq '${fund}' and Categoria eq '${category}'&$orderby=Orden desc`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response: PdfReadDto[] = [];
	for (let index = 0; index < data.d.results.length; index++) {
		const publicacion = new PdfReadDto(data.d.results[index].ID, data.d.results[index].Title);
		publicacion.setDate = data.d.results[index].Modified; //esto debve ser date o modified?
		response[index] = publicacion;
	}

	return response;
}

export async function getFundAttachmentFileById(id: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Fondos Documentos')/items(${id})?$expand=AttachmentFiles`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response = {
		url: data.d.AttachmentFiles.results[0].ServerRelativeUrl,
		filename: data.d.AttachmentFiles.results[0].FileName,
	};

	return response;
}

export async function getFundImageById(id: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Fondos')/items(${id})?$expand=AttachmentFiles`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response = {
		url: data.d.AttachmentFiles.results[0].ServerRelativeUrl,
	};

	return response;
}

export async function getFundsByFilter(
	fundType?: string,
	currencyType?: string,
	plazoType?: string
): Promise<FundReadDto[]> {
	let fundUrl = '';
	let currencyUrl = '';
	let plazoUrl = '';

	if (fundType === 'Todos') {
		fundUrl = "(Tipodefondo eq 'Abierto' or Tipodefondo eq 'Cerrado')";
	} else {
		fundUrl = `Tipodefondo eq '${fundType}'`;
	}

	if (currencyType === 'Todos') {
		currencyUrl = "(Moneda eq 'Pesos (DOP)' or Moneda eq 'Dólares (USD)')";
	} else {
		currencyUrl = `Moneda eq '${currencyType}'`;
	}

	if (plazoType === 'Todos') {
		plazoUrl =
			"(Plazodeinversion eq '30 dias' or Plazodeinversion eq '60 dias' or Plazodeinversion eq '90 dias' or Plazodeinversion eq '180 dias' or Plazodeinversion eq 'ninguno')";
	} else {
		plazoUrl = `Plazodeinversion eq '${plazoType}'`;
	}

	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Fondos')/items?$filter=${fundUrl} and ${currencyUrl} and ${plazoUrl}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response: FundReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const fund = new FundReadDto();

		fund.id = data.d.results[index].ID;
		fund.title = data.d.results[index].Title;
		fund.definicion = data.d.results[index].definicion;
		fund.tipoDeFodo = data.d.results[index].Tipodefondo; //arreglar nombre
		fund.moneda = data.d.results[index].Moneda;
		fund.setValorCuota(data.d.results[index].Valorcuota);
		fund.rendimientoUltimos30dias = data.d.results[index].Rendimientoutlimos30dias * 100;
		fund.fecha = data.d.results[index].Fecha;
		fund.plazoDeInversion = data.d.results[index].Plazodeinversion;

		response[index] = fund;
	}

	return response;
}

export async function getFundsByFundType(fundType: string): Promise<FundReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Fondos')/items?$filter= Tipodefondo eq '${fundType}'&$orderby=Orden asc`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response: FundReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const fund = new FundReadDto();

		fund.id = data.d.results[index].ID;
		fund.title = data.d.results[index].Title;

		response[index] = fund;
	}

	return response;
}

export async function getValorCuotasFromFund(): Promise<ValorCuotasReadDto[]> {
	const data = await axios
		.get(`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Fondos')/items`, {
			headers: {
				'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
					.value,
				Accept: SharePointConsts.acceptVerbose,
			},
		})
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const funds: ValorCuotasReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const fund = new ValorCuotasReadDto();

		fund.fundId = data.d.results[index].ID;
		fund.currentDate = data.d.results[index].Fechaactual;
		fund.currentValue = data.d.results[index].Valorcuota;
		fund.prevDate = data.d.results[index].Fechadiaanterior;
		fund.prevValue = data.d.results[index].Valorcuotadeldiaanterior;
		fund.totalValues = data.d.results[index].Totalcuotascolocadas;
		fund.fundTitle = data.d.results[index].Title;

		funds[index] = fund;
	}

	return funds;
}

export async function getTiposDeFondo(): Promise<string[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Fondos')/fields?$filter=EntityPropertyName eq 'Tipodefondo'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const tiposDeFondo: string[] = data.d.results[0].Choices.results;

	return tiposDeFondo;
}

export async function getTiposDeMoneda(): Promise<string[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Fondos')/fields?$filter=EntityPropertyName eq 'Moneda'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const tiposDeMoneda: string[] = data.d.results[0].Choices.results;

	return tiposDeMoneda;
}

export async function getTiposDePlazo(): Promise<string[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Fondos')/fields?$filter=EntityPropertyName eq 'Plazodeinversion'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const tiposDePlazo: string[] = data.d.results[0].Choices.results;

	return tiposDePlazo;
}

export async function getAllFundDocsCategories(): Promise<string[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Fondos Documentos')/fields?$filter=EntityPropertyName eq 'Categoria'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	let categories: string[] = [];

	categories = data.d.results[0].Choices.results;

	return categories;
}
