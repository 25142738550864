import axios from 'axios';
import AppVariables from '../Variables.json';
import ComparadorReadDto from '../dtos/ComparadorReadDto';
import { SharePointConsts } from '../util/constants';

export async function getComparadorByfundTitle(title: string): Promise<ComparadorReadDto> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Comparador de fondos')/items?$select=*,Fondo/Title&$expand=Fondo&$filter=Fondo/Title eq '${title}'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const response = new ComparadorReadDto();

	if (!data.d.results.length) { return response; }

	response.id = data.d.results[0].ID;
	response.perfilderiesgo = data.d.results[0].Perfilderiesgo;
	response.montoTotaldelPrograma = data.d.results[0].MontoTotaldelPrograma;
	response.objetodelFondo = data.d.results[0].ObjetodelFondo;
	response.periodicidaddePagodelosBeneficio = data.d.results[0].PeriodicidaddePagodelosBeneficio;
	response.fechadepagodelosRendimientos = data.d.results[0].FechadepagodelosRendimientos;
	response.pagodelValordeCuotaFinal = data.d.results[0].PagodelValordeCuotaFinal;
	response.fechadeVencimientodelFondo = data.d.results[0].FechadeVencimientodelFondo;
	response.restriccionesGenerales = data.d.results[0].RestriccionesGenerales;
	response.benchmark = data.d.results[0].Benchmark;
	response.estructuradecomisiones = data.d.results[0].Estructuradecomisiones;
	response.calificacionderiesgo = data.d.results[0].Calificaci_x00f3_ndeRiesgo;

	return response;
}

export async function getChoosableFunds(): Promise<string[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Comparador de fondos')/items?$select=*,Fondo/Title&$expand=Fondo`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((res) => res.data)
		.catch((error) => console.error(error));

	const funds: string[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const fundTitle = data.d.results[index].Fondo.Title;
		funds.push(fundTitle);
	}

	return funds;
}
