import { useDraggable } from "react-use-draggable-scroll";
import TimelineReadDto from "../../dtos/TimelineReadDto";
import { useRef } from 'react';
import TimelineCard from "./TimelineCard";

interface IProps {
    items: TimelineReadDto[];
}

function TimelineContainer(props: IProps) {

    const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const { events } = useDraggable(ref);

    function renderTimelineCards() {
        return props.items.map((t) => (<TimelineCard key={t.id} item={t} />))
    }

    return (
        <div className="timeline">
            <div className="timeline-container" {...events} ref={ref}>
                {renderTimelineCards()}
            </div>
        </div>
    );
}

export default TimelineContainer;