import { useEffect, useState } from 'react';
import CarouselStaticContent from './CarouselStaticContent';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@splidejs/react-splide/css/skyblue';
import { getCarouselByCategory } from '../../services/CarouselServices';
import ChevronLeft from '../../img/banner/chevron-left-48-filled.svg';
import ChevronRight from '../../img/banner/chevron-right-48-filled.svg';
import CarouselReadDto from '../../dtos/CarouselReadDto';

function DesktopDevicesCarousel() {
	const [carousel, setCarousel] = useState<CarouselReadDto[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			const fetchedCarousel = await getCarouselByCategory('Desktop');
			setCarousel(fetchedCarousel);
		};

		fetchData();
	}, []);

	return (
		<div className="hero-section">
			<Splide
				options={{
					rewind: true,
				}}
				hasTrack={false}
			>
				<SplideTrack>
					{carousel.map((carousel, i) => (
						<SplideSlide key={i}>
							<img src={carousel.imgUrl} alt="" className="slider-img" />
							<CarouselStaticContent
								imgUrl={carousel.icon}
								subtitle={carousel.subtitle}
								title={carousel.title}
								content={carousel.content}
								linkText={carousel.buttonText}
								linkUrl={carousel.buttonUrl}
							/>
						</SplideSlide>
					))}
				</SplideTrack>

				<div className="splide__arrows">
					<div className="splide__arrow splide__arrow--prev " hidden={true}>
						<img src={ChevronLeft} alt="" className="prev-arrow" />
					</div>
					<div className="splide__arrow splide__arrow--next disabled">
						<img src={ChevronRight} alt="" className="next-arrow" />
					</div>
				</div>
			</Splide>
		</div>
	);
}

export default DesktopDevicesCarousel;
