import AppVariables from '../Variables.json';
import PodcastParticipans from './PodcastParticipans';

class PodcastReadDto {
	public id: number;
	public title: string;
	public content: string;
	public author: string;
	public duration: string;

	private _date: string;
	public get date(): string {
		return this._date;
	}
	public set date(value: string) {
		const newDate = new Intl.DateTimeFormat(['ban', 'id']).format(new Date(value));

		this._date = newDate;
	}

	public episode: string;

	private _imgUrl: string;
	public get imgUrl(): string {
		return this._imgUrl;
	}
	public set imgUrl(value: string) {
		this._imgUrl = AppVariables.BASE_URL + value;
	}

	public participans: PodcastParticipans[];
	public spotifyUrl: string;
	public appleUrl: string;
	public googleUrl: string;
}

export default PodcastReadDto;
