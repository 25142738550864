
import SelectGeneral from "../Select/SelectGeneral";
import Select from 'react-select';
import { fundSelectStyle } from "../Utility/FormStyles";

interface IProps {
	fundtypes: string[];
	currencyTypes: string[];
	termTypes: string[];
	setFundTypes: React.Dispatch<React.SetStateAction<string>>;
	setCurrencyTypes: React.Dispatch<React.SetStateAction<string>>;
	setTermTypes: React.Dispatch<React.SetStateAction<string>>;
}

function FundFilter({fundtypes,currencyTypes,termTypes,setFundTypes,setCurrencyTypes,setTermTypes}:IProps) {

    return (
			<div className="main-fonds__select-container">
				<Select
					className="funds-filter-select-container"
					classNamePrefix="funds-filter-select"
					styles={fundSelectStyle}
					options={[{ value: 'Todos', label: 'Todos' }].concat(
						fundtypes.map((category) => ({
							value: category,
							label: category,
						}))
					)}
					onChange={(item: any) => setFundTypes(item.value)}
					isMulti={false}
					placeholder="Tipo"
				/>

				<Select
					className="funds-filter-select-container"
					classNamePrefix="funds-filter-select"
					styles={fundSelectStyle}
					options={[{ value: 'Todos', label: 'Todos' }].concat(
						currencyTypes.map((category) => ({
							value: category,
							label: category,
						}))
					)}
					onChange={(item: any) => setCurrencyTypes(item.value)}
					isMulti={false}
					placeholder="Moneda"
				/>

				<Select
					className="funds-filter-select-container"
					classNamePrefix="funds-filter-select"
					styles={fundSelectStyle}
					options={[{ value: 'Todos', label: 'Todos' }].concat(
						termTypes.map((category) => ({
							value: category,
							label: category,
						}))
					)}
					onChange={(item: any) => setTermTypes(item.value)}
					isMulti={false}
					placeholder="Plazo"
				/>
			</div>
		);
}

export default FundFilter;