import { useState } from 'react';
import FileSection from './FileSection';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SearchSection() {
	const [search, setSearch] = useState('');

	const getInputValue = () => {
		const text = (document.getElementById('input-searchBox') as HTMLInputElement).value;
		setSearch(text);
	};

	const clearInput = () => {
		setSearch('');
		(document.getElementById('input-searchBox') as HTMLInputElement).value = '';
	};

	return (
		<div>
			<div className="search-section">
				<div className="search-section__title">
					<h1 className="search-section__title--text">Publicaciones institucionales</h1>
				</div>
				<div className="search-section__search-box">
					<input
						className="search-section__search-box--input"
						type="text"
						placeholder="Buscar"
						id="input-searchBox"
						onChange={() => getInputValue()}
					/>
					<a className="search-section__search-box--button">
						{search !== '' ? (
							<i className="fa-lg" onClick={() => clearInput()}>
								<FontAwesomeIcon icon={faTimes} />
							</i>
						) : (
							<i className="fa-lg">
								<FontAwesomeIcon icon={faSearch} />
							</i>
						)}
					</a>
				</div>
			</div>
			<FileSection expression={search} />
		</div>
	);
}

export default SearchSection;
