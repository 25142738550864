import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';
import {} from 'react-share';
import NewsReadDto from '../../dtos/NewsReadDto';
import SocialMediaPopup from '../social-media/SocialMediaPopup';

interface IProps {
	news: NewsReadDto;
}

function NewsCard({ news }: IProps) {
	const navigate = useNavigate();

	return (
		<div className="generic-news shadow-lg">
			<img src={news.imgUrl} alt="test-img-news" className="generic-news__img" />
			<div className="generic-news__content">
				<div className="generic-news__content--title flex justify-between">
					<h5 className="generic-news__content--title--text inline">{news.category}</h5>

					<SocialMediaPopup
						position={'left center'}
						url={`${window.location.protocol}//${
							window.location.host
						}/detalle-noticia?titulo=${news?.title?.replaceAll(' ', '%20')}`}
						quote={news.title}
					/>
				</div>
				<div className="generic-news__content--info">
					<p
						className="generic-news__content--info--text cursor-pointer"
						onClick={() => {
							navigate({
								pathname: `${AppVariables.REACT_ROUTER_URL}/detalle-noticia`,
								search: `?titulo=${news.title}`,
							});
						}}
					>
						{news.title}
					</p>
					<p className="generic-news__content--info--text2">{news.principalContent}</p>
				</div>
				<h4
					className="generic-news__content--watch-all"
					onClick={() => {
						navigate({
							pathname: `${AppVariables.REACT_ROUTER_URL}/detalle-noticia`,
							search: `?titulo=${news.title}`,
						});
					}}
				>
					{' '}
					<i>
						<FontAwesomeIcon icon={faChevronRight} />
					</i>{' '}
					<span>Seguir leyendo</span>
				</h4>
			</div>
		</div>
	);
}

export default NewsCard;
