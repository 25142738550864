import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';
import NewsReadDto from '../../dtos/NewsReadDto';
import ShareIcon from '../../img/podcast/share-icon.svg';
import SocialMediaPopup from '../social-media/SocialMediaPopup';

interface IProps {
	news: NewsReadDto;
}

function SalaDePrensaCard({ news }: IProps) {
	const navigate = useNavigate();

	return (
		<div className="main-prensa-card-container">
			<div className="main-prensa-card-content">
				<div
					className="main-prensa-card-img"
					style={{
						backgroundImage: `url(${news.imgUrl})`,
					}}
				/>
				<div className="main-prensa-card-side-container">
					<div className="main-prensa-card-category">
						<span className="main-prensa-card-category__name">{news.category}</span>
						<SocialMediaPopup
							position={'left center'}
							url={`${window.location.protocol}//${
								window.location.host
							}/detalle-noticia?titulo=${news?.title?.replaceAll(' ', '%20')}`}
							quote={'ssss'}
						/>
					</div>
					<div
						className="main-prensa-card__title cursor-pointer"
						onClick={() => {
							navigate({
								pathname: `${AppVariables.REACT_ROUTER_URL}/detalle-noticia`,
								search: `?titulo=${news.title}`,
							});
						}}
					>
						{news.title}
					</div>
					<div className="main-prensa-card__text">
						<p className="generic-news__content--info--text2">{news.principalContent}</p>
					</div>
					<div className="main-prensa-card-footer">
						<span className="main-prensa-card-footer__date">{news.date}</span>
						<a
							className="main-prensa-card-footer__link"
							onClick={() => {
								navigate({
									pathname: `${AppVariables.REACT_ROUTER_URL}/detalle-noticia`,
									search: `?titulo=${news.title}`,
								});
							}}
						>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>{' '}
							<span>Seguir leyendo</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SalaDePrensaCard;
