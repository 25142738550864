import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AppVariables from '../Variables.json';
import FeeValue from '../img/home-tools/fee-value-icon.svg';
import FundBuyer from '../img/home-tools/fund-buyer-icon.svg';
import FundRecommender from '../img/home-tools/fund-recommender-icon.svg';
import Riskprofile from '../img/home-tools/risk-profile-icon.svg';
import PerformanceCalc from '../img/home-tools/performance-calc-icon.svg';
import PageInfoDto from '../dtos/PageInfoDto';
import { getPageInfo } from '../services/GenericServices';

function PerfilDeRiesgoResultado() {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [value, setValue] = useState(0);

	const [pageInfo, setPageInfo] = useState<PageInfoDto>(new PageInfoDto());

	const fetchInfo = async () => {
		const fetchedInfo = await getPageInfo('Perfil de Riesgo del Inversionista');
		setPageInfo(fetchedInfo);
	};

	useEffect(() => {
		fetchInfo();
	}, []);

	useEffect(() => {
		const fetchedValue = parseInt(searchParams.get('Value'));
		setValue(fetchedValue);
	}, [location]);

	return (
		<React.Fragment>
			<section
				className="comparador"
				style={{
					backgroundImage: `linear-gradient(
                              180deg,
                              #00235c00 0%,
                              #00235ca3 20%,
                              #00235c 100%
                            ),
                            url(${AppVariables.IMG_URL + 'bg-img-podcast1.jpg'})`,
				}}
			>
				<div className="container mx-auto container--fix">
					<div className="comparador__intro">
						<h1>{pageInfo.title}</h1>
						<p>{pageInfo.description}</p>
					</div>

					{value <= 12 && (
						<div className="comparador__box">
							<h3 className="comparador__title">Resultado</h3>
							<h4 className="comparador__subtitle">
								Su perfil de riesgo es : <span>CONSERVADOR</span>
							</h4>
							<p className="comparador__text">
								El objetivo principal de este tipo de inversionista es la preservación del
								capital y la obtención de ingresos estables. El inversionista conservador
								tiene una alta aversión al riesgo y suele aceptar únicamente fluctuaciones
								moderadas en el valor de sus activos.
							</p>
						</div>
					)}
					{value > 12 && value <= 23 && (
						<div className="comparador__box">
							<h3 className="comparador__title">Resultado</h3>
							<h4 className="comparador__subtitle">
								Su perfil de riesgo es : <span>MODERADO</span>
							</h4>
							<p className="comparador__text">
								El objetivo principal de este tipo de inversionista es lograr un balance
								entre sus ingresos por rendimiento y la apreciación o aumento moderado de su
								capital resultante de las fluctuaciones en los precios de sus activos. El
								inversionista moderado tolera cierto nivel derivado de los cambios en el
								precio de sus activos en el corto y mediano plazo, con el objetivo de
								obtener mejores resultados en el largo plazo.
							</p>
						</div>
					)}
					{value > 23 && (
						<div className="comparador__box">
							<h3 className="comparador__title">Resultado</h3>
							<h4 className="comparador__subtitle">
								Su perfil de riesgo es : <span>AGRESIVO</span>
							</h4>
							<p className="comparador__text">
								El objetivo principal de este tipo de inversionista es lograr la mayor
								apreciación o aumento de sus inversiones. El inversionista agresivo asume un
								amplio nivel de riesgo y tiene una alta tolerancia a los movimientos en el
								precio de sus activos.
							</p>
						</div>
					)}

					<div className="inline-block w-full ml-auto text-center perfil-result-buttom">
						<a
							className="w-full global-button"
							onClick={() => {
								navigate(`${AppVariables.REACT_ROUTER_URL}/perfil-de-riesgo`);
							}}
						>
							Volver a calcular
						</a>
					</div>
				</div>
			</section>
			{/* tools*/}
			<div className="tools tools--fix">
				<h1 className="generic-title">Herramientas</h1>
				<div className="tools-container">
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/calculadora`);
						}}
					>
						<img
							className="generic-tool__logo"
							src={PerformanceCalc}
							alt="risk-profile-icon"
						/>
						<h4 className="generic-tool__title">Calculadora de rendimiento</h4>
					</div>
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/recomendador`);
						}}
					>
						<img
							className="generic-tool__logo"
							src={FundRecommender}
							alt="fund-recommender-icon"
						/>
						<h4 className="generic-tool__title">Recomendador de fondos</h4>
					</div>
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/valor-cuota`);
						}}
					>
						<img className="generic-tool__logo" src={FeeValue} alt="fee-value-icon" />
						<h4 className="generic-tool__title">Valor de las cuotas</h4>
					</div>
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/comparador-de-fondos`);
						}}
					>
						<img className="generic-tool__logo" src={FundBuyer} alt="fund-buyer-icon" />
						<h4 className="generic-tool__title">
							Comparador <br />
							de fondos
						</h4>
					</div>
				</div>
			</div>
			{/*tools end */}
		</React.Fragment>
	);
}

export default PerfilDeRiesgoResultado;
