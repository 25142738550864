import FeeValue from '../../img/home-tools/fee-value-icon.svg';
import PerformanceCalc from '../../img/home-tools/performance-calc-icon.svg';
import FundBuyer from '../../img/home-tools/fund-buyer-icon.svg';
import FundRecommender from '../../img/home-tools/fund-recommender-icon.svg';
import Riskprofile from '../../img/home-tools/risk-profile-icon.svg';
import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';

function Tools() {
	const navigate = useNavigate();

	return (
		<div className="tools">
			<h1 className="generic-title">Herramientas</h1>
			<div className="tools-container">
				<div
					className="generic-tool cursor-pointer"
					onClick={() => {
						navigate(`${AppVariables.REACT_ROUTER_URL}/perfil-de-riesgo`);
					}}
				>
					<img className="generic-tool__logo" src={Riskprofile} alt="risk-profile-icon" />
					<h4 className="generic-tool__title">Perfil de Riesgo del Inversionista</h4>
				</div>
				<div
					className="generic-tool cursor-pointer"
					onClick={() => {
						navigate(`${AppVariables.REACT_ROUTER_URL}/recomendador`);
					}}
				>
					<img
						className="generic-tool__logo"
						src={FundRecommender}
						alt="fund-recommender-icon"
					/>
					<h4 className="generic-tool__title">Recomendador de fondos</h4>
				</div>
				<div
					className="generic-tool cursor-pointer"
					onClick={() => {
						navigate(`${AppVariables.REACT_ROUTER_URL}/valor-cuota`);
					}}
				>
					<img className="generic-tool__logo" src={FeeValue} alt="fee-value-icon" />
					<h4 className="generic-tool__title">Valor de las cuotas</h4>
				</div>
				<div
					className="generic-tool cursor-pointer"
					onClick={() => {
						navigate(`${AppVariables.REACT_ROUTER_URL}/calculadora`);
					}}
				>
					<img
						className="generic-tool__logo"
						src={PerformanceCalc}
						alt="performance-calc-icon"
					/>
					<h4 className="generic-tool__title">Calculadora de rendimiento</h4>
				</div>
				<div
					className="generic-tool cursor-pointer"
					onClick={() => {
						navigate(`${AppVariables.REACT_ROUTER_URL}/comparador-de-fondos`);
					}}
				>
					<img className="generic-tool__logo" src={FundBuyer} alt="fund-buyer-icon" />
					<h4 className="generic-tool__title">
						Comparador <br />
						de fondos
					</h4>
				</div>
			</div>
		</div>
	);
}

export default Tools;
