import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';
import PageInfoDto from '../../dtos/PageInfoDto';
import { getPageInfo } from '../../services/GenericServices';

function FundAdvisor() {
	const navigate = useNavigate();

	const [pageInfo, setPageInfo] = useState<PageInfoDto>(new PageInfoDto());

	const fetchInfo = async () => {
		const fetchedInfo = await getPageInfo('Fondos de Inversión');
		setPageInfo(fetchedInfo);
	};

	useEffect(() => {
		fetchInfo();
	}, []);

	return (
		<section className="main-advisor">
			<div className="main-advisor-upper">
				<h1 className="main-advisor__title">{pageInfo.title}</h1>
				<p className="main-advisor__text">{pageInfo.description}</p>
			</div>
			<div className="main-advisor__blue-rectangle" />
			<div className="main-advisor__orange-rectangle" />
			<div
				className="main-advisor__hero"
				style={{
					backgroundImage: `url(${AppVariables.IMG_URL + 'listfile_download2.png'})`,
				}}
			/>
			<div className="main-advisor-lower">
				<div className="main-advisor-lower-container">
					<h2 className="main-advisor__subtitle">
						Elige el fondo de inversión que mejor se adapta a ti
					</h2>
					<div
						className="main-advisor__link cursor-pointer"
						onClick={() => navigate(`${AppVariables.REACT_ROUTER_URL}/recomendador`)}
					>
						Ir al Recomendador de fondos
					</div>
				</div>
			</div>
		</section>
	);
}

export default FundAdvisor;
