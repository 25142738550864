
interface IProps {
	fecha: string;
	moneda: string;
	valorCuota: string;
	valorCuotaDiaAnterior: string;
	totalCuotas: string;
	patrimonioEnCirculacion:string;
}

function FundMainValoracion(props:IProps) {

	
    return (
			<section className="main-valoracion">
				<div className="main-valoracion__bg-box" />
				<div className="main-valoracion-container">
					<div className="main-valoracion-container-header">
						<h3 className="main-valoracion-container-header__title">
							Valoración de la cuota
						</h3>
						<span className="main-valoracion-container-header__date">
							<i className="calendar-icon" /> <b>Fecha</b> &nbsp;: {props.fecha}
						</span>
						<span className="main-valoracion-container-header__coin">
							<i className="money-icon" />
							<b>Moneda</b> &nbsp;: {props.moneda}
						</span>
					</div>
					<div className="main-valoracion-container-content">
						<div className="main-valoracion-container-content-container">
							<span className="main-valoracion-content__title">Valor Cuota</span>
							<span className="main-valoracion-content__amount">$ {props.valorCuota}</span>
						</div>
						<div className="main-valoracion-content__separator" />
						<div className="main-valoracion-container-content-container">
							<span className="main-valoracion-content__title">
								Valor Cuota del día anterior
							</span>
							<span className="main-valoracion-content__amount">$ {props.valorCuotaDiaAnterior}</span>
						</div>
						<div className="main-valoracion-content__separator" />
						<div className="main-valoracion-container-content-container">
							<span className="main-valoracion-content__title">Total cuotas colocadas</span>
							<span className="main-valoracion-content__amount">{props.totalCuotas}</span>
						</div>
						<div className="main-valoracion-content__separator" />
						<div className="main-valoracion-container-content-container">
							<span className="main-valoracion-content__title">
								Patrimonio en circulación
							</span>
							<span className="main-valoracion-content__amount">$ {props.patrimonioEnCirculacion}</span>
						</div>
					</div>
				</div>
			</section>
		);
}

export default FundMainValoracion;