import React from "react";
import SearchSection from "../components/publicaciones/SearchSection";

function Publicaciones() {
  return (
    <React.Fragment>
      <SearchSection />
    </React.Fragment>
  );
}

export default Publicaciones;
