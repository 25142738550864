import ExecutivesReadDto from "../../dtos/ExecutivesReadDto";
import AboutUsExecutiveCard from "./AboutUsExecutiveCard";

interface IProps {
    executives: ExecutivesReadDto[]
}

function ExecutivesContainer(props: IProps) {

    function renderExecutivesCards() {
        return props.executives.map((e) => <AboutUsExecutiveCard key={e.id} executive={e} />)
    }

    return (
        <div className="ejecutivos__container">
            {renderExecutivesCards()}
        </div>
    );
}

export default ExecutivesContainer;