import { SetStateAction } from "react";
import NewsDatePickerComponent from "../datepicker/NewsDatePickerComponent";
import CategoryFilter from "../filters/CategoryFilter";
import DateFilter from "../filters/DateFilter";

interface IProps {
  categories: string[];
  dates: string[];
  currentDate:string;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  setDate: React.Dispatch<React.SetStateAction<string>>;
}

function SalaDePrensaSelector({
  categories,
  dates,
  currentDate,
  setCategory,
  setDate,
}: IProps) {
  const onChangeCategory = (category: any) => {
    setCategory(category.value);
  };
  return (
    <div className="main-prensa-selector-container">
      <CategoryFilter categories={categories} setCategory={onChangeCategory} />
      <NewsDatePickerComponent dates={dates} setDate={setDate} currentDate={currentDate} />
      {/* <DateFilter dates={dates} setDate={setDate} /> */}
    </div>
  );
}

export default SalaDePrensaSelector;
