import axios from 'axios';
import AppVariables from '../Variables.json';
import NewsReadDto from '../dtos/NewsReadDto';
import { getItemAttachmentFileById } from './GenericServices';
import { getRand } from '../util/util';
import { AxiosError, AxiosResponse } from '../dtos/types/Axios';
import { SharePointConsts } from '../util/constants';

async function fetchData(query: string): Promise<any[]> {
	try {
		const response: AxiosResponse = await axios.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Noticias')/items?${query}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		);
		return response.data.d.results;
	} catch (error: any) {
		const axiosError = error as AxiosError;
		console.log(axiosError);
		return [];
	}
}

async function createNewsObject(item: any): Promise<NewsReadDto> {
	const file = await getItemAttachmentFileById('Noticias', item.Id);

	return new NewsReadDto(
		item.Id,
		item.Title,
		item.Categoria,
		item.ContenidoPrincipal,
		item.Contenido,
		file.url,
		item.FechadePublicacion,
	);
}

async function getNews(query: string): Promise<NewsReadDto[]> {
	const results = await fetchData(query);
	return Promise.all(results.map(createNewsObject));
}

export async function getActiveNews(): Promise<NewsReadDto[]> {
	return getNews("$filter=(Estado eq 'Activo')&$orderby=Orden desc, FechadePublicacion desc");
}

export async function getNewsById(id: number): Promise<NewsReadDto> {
	const news = await getNews(`$filter=(Id eq ${id})`);
	return news[0] || new NewsReadDto();
}

export async function getNewsByTitle(title: string): Promise<NewsReadDto> {
	const news = await getNews(`$filter=(Title eq '${title}')`);
	return news[0] || new NewsReadDto();
}

export async function getRandomNews(excludeId: number): Promise<NewsReadDto[]> {
	const activeNews = await getActiveNews();
	const validNews = activeNews.filter((n) => n.id !== excludeId);
	const shuffled = validNews.sort(() => 0.5 - getRand());
	return shuffled.slice(0, 2);
}

export async function getNewsCategories(): Promise<string[]> {
	const results = await fetchData(
		"_api/web/lists/getbytitle('Noticias')/fields?$filter=EntityPropertyName eq 'Categoria'"
	);
	return results.length > 0 ? results[0].Choices.results : [];
}

export async function getNewsByDate(date: string): Promise<NewsReadDto[]> {
	return getNews(
		`$filter=(Estado eq 'Activo' and FechadePublicacion eq '${date}')&$orderby=Orden desc, FechadePublicacion desc`
	);
}

export async function getNewsByCategory(category: string): Promise<NewsReadDto[]> {
	return getNews(
		`$filter=(Estado eq 'Activo' and Categoria eq '${category}')&$orderby=Orden desc, FechadePublicacion desc`
	);
}

export async function getNewsByCategoryAndDate(
	category: string,
	date: string
): Promise<NewsReadDto[]> {
	return getNews(
		`$filter=(Estado eq 'Activo' and Categoria eq '${category}' and FechadePublicacion eq '${date}')&$orderby=Orden desc, FechadePublicacion desc`
	);
}

export async function getTop10News(): Promise<NewsReadDto[]> {
	return getNews(
		"$filter=(Estado eq 'Activo')&$orderby=Orden desc, FechadePublicacion desc&$top=10"
	);
}

export async function getTopNNews(n: number): Promise<NewsReadDto[]> {
	return getNews(
		`$filter=(Estado eq 'Activo')&$orderby=Orden desc, FechadePublicacion desc&$top=${n}`
	);
}
