import { useEffect } from 'react';

interface IProps {
	progress: number;
	numOfSteps: number;
	title: string;
}

function ProgressBar(props: IProps) {
  useEffect(() => {
    const updateProgressBar = () => {
      const divContainer = document.getElementById("progress-bar-form1");
      divContainer.innerHTML = "";
      for (let index = 1; index <= props.numOfSteps; index++) {
        const newDiv = document.createElement("div");

				if (index <= props.progress) {
					newDiv.classList.add('active');
				}
				divContainer.appendChild(newDiv);
			}
		};

		updateProgressBar();
	}, [props.progress, props.numOfSteps]);

	return (
		<div className="create">
			<div className="container mx-auto">
				<h2 className="create__title create__title--margin">Invierte con nosotros</h2>
				<h3 className="create__subtitle">{props.title}</h3>
				<div
					className={`grid grid-cols-${props.numOfSteps} gap-2 create__steps`}
					id="progress-bar-form1"
				></div>
			</div>
		</div>
	);
}

export default ProgressBar;
