import AppVariables from '../Variables.json';

class PodcastParticipans {
	public id: number;
	public category: string;
	public name: string;
	public biography: string;
	private _imgUrl: string;
	public get imgUrl(): string {
		return this._imgUrl;
	}
	public set imgUrl(value: string) {
		this._imgUrl = AppVariables.BASE_URL + value;
	}
}

export default PodcastParticipans;
