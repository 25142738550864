import Select from "react-select";
import {
  selectStyle,
  selectStyleGeneral,
} from "../../components/Utility/FormStyles";

const SelectComponent = ({
  isMulti,
  options,
  placeHolder,
  onChange,
  zeroOnCorner,
}: any) => {
  return (
    <Select
      styles={zeroOnCorner ? selectStyle : selectStyleGeneral}
      options={options}
      onChange={onChange}
      isMulti={isMulti}
      placeholder={placeHolder}
      isLoading={false}
    />
  );
};

export default SelectComponent;
