interface IProps {
	option: string;
	currentCategory: string;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function RecomendadorOption({option,currentCategory,handleChange}:IProps) {
    return (
			<label className="check-box-item">
				<input type="radio" name={currentCategory} value={option} onChange={(e) => handleChange(e)}/>
				<span>{option}</span>
			</label>
		);
}

export default RecomendadorOption;