import HeroFATCA from '../../components/FATCA_FAQ/HeroFATCA';
import Questions from '../../components/FATCA_FAQ/Questions';
import axios from 'axios';
import { useState, useEffect } from 'react';
import AppVariables from '../../Variables.json';
import { SharePointConsts } from '../../util/constants';

export default function FATCA() {
	const [heroFATCA, setHeroFATCA] = useState({
		title: '',
		subTitle1: '',
		subTitle2: '',
		downloadLink: '',
		downloadText: '',
	});

	const [questions, setQuestions] = useState([
		{
			order: 0,
			question: '',
			content: '',
		},
	]);

	const getHeroFATCA = async () => {
		const resultsArr = await axios
			.get(
				`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('S1-FATCA-FAQ')/items`,

				{
					headers: {
						'X-RequestDigest': (
							document.getElementById('__REQUESTDIGEST') as HTMLInputElement
						).value,

						Accept: SharePointConsts.acceptNoMetadata,
						'Content-Type': SharePointConsts.contentTypeNoMetadata,
					},
				}
			)
			.then((response) => response.data.value)
			.catch((e) => console.error(e));

		const result = resultsArr.find((r: any) => r.Title === 'FATCA');

		setHeroFATCA({
			title: result?.Title,
			subTitle1: result?.SubTitle1,
			subTitle2: result?.SubTitle2,
			downloadLink: result?.Link?.Url,
			downloadText: result?.Link?.Description,
		});
	};
	const getQuestions = async () => {
		const resultsArr = await axios
			.get(
				`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Preguntas-FATCA-FAQ')/items?$select=*,Categoria/Title&$expand=Categoria&$filter=Categoria/Title eq 'FATCA'`,

				{
					headers: {
						'X-RequestDigest': (
							document.getElementById('__REQUESTDIGEST') as HTMLInputElement
						).value,
						Accept: SharePointConsts.acceptNoMetadata,
						'Content-Type': SharePointConsts.contentTypeNoMetadata,
					},
				}
			)
			.then((response) => response.data.value)
			.catch((e) => console.error(e));

		setQuestions(
			resultsArr.map((item: any, i: number) => {
				return {
					order: item?.Order0,
					question: item?.Pregunta,
					content: item?.Content,
				};
			})
		);
	};

	useEffect(() => {
		getHeroFATCA();
		getQuestions();
	}, []);

	return (
		<main className="main">
			<HeroFATCA
				title={heroFATCA.title}
				subTitle1={heroFATCA.subTitle1}
				subTitle2={heroFATCA.subTitle2}
				downloadLink={heroFATCA.downloadLink}
				downloadText={heroFATCA.downloadText}
			/>
			<div className="container mx-auto">
				<div className="lista-preguntas">
					<Questions data={questions} />
				</div>
			</div>
		</main>
	);
}
