import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import PodcastReadDto from '../../dtos/PodcastReadDto';
import { useDraggable } from 'react-use-draggable-scroll';
import AppVariables from '../../Variables.json';
import { useNavigate } from 'react-router-dom';
import HomePodcastCard from './HomePodcastCard';
import { getSectionStatus } from '../../services/GenericServices';

interface IProps {
	podcasts: PodcastReadDto[];
}

function HomePodcasts({ podcasts }: IProps) {
	const navigate = useNavigate();

	const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
	const { events } = useDraggable(ref);

	const renderPodcastCards = () => {
		return podcasts.map((c) => <HomePodcastCard podcast={c} />);
	};

	return (
		<div className="podcast">
			<h1 className="generic-title pb-2">Podcast</h1>
			<div className="podcast-container " {...events} ref={ref}>
				{renderPodcastCards()}
			</div>
			<div className="watch-all-podcast">
				<a
					className="generic-watch-all"
					onClick={() => {
						navigate(`${AppVariables.REACT_ROUTER_URL}/podcast`);
					}}
				>
					<i>
						<FontAwesomeIcon icon={faChevronRight} />
					</i>{' '}
					<span>Ver todos los podcast</span>
				</a>
			</div>
		</div>
	);
}

export default HomePodcasts;
