import AppVariables from '../Variables.json';

class PromotorReadDto {
	public id: number;
	public name: string;
	public registryNumber: string;

	private _fecha: string;
	public get fecha(): string {
		return this._fecha;
	}
	public set fecha(value: string) {
		const newDate = new Intl.DateTimeFormat(['ban', 'id']).format(new Date(value));
		this._fecha = newDate;
	}

	private _imgUrl: string;
	public get imgUrl(): string {
		return this._imgUrl;
	}
	public set imgUrl(value: string) {
		this._imgUrl = AppVariables.BASE_URL + value;
	}
}

export default PromotorReadDto;
