import React, { useEffect, useState } from 'react';
import EbookReadDto from '../../dtos/EbookReadDto';
import { getAllEbooks, getLastEbooks } from '../../services/EbookServices';
import EbookBigCard from './EbookBigCard';
import EbookCard from './EbookCard';

function MainEbook() {
	const [ebooks, setEbooks] = useState<EbookReadDto[]>([]);
	const [loadMore, setLoadMore] = useState<boolean>(true);

	useEffect(() => {
		const fetchData = async () => {
			const data = await getLastEbooks(10);
			setEbooks(data);
		};

		fetchData();
	}, []);

	function renderBigCard() {
		for (let index = 0; index < ebooks.length; index++) {
			if (index === 0) {
				return <EbookBigCard ebook={ebooks[index]} />;
			}
		}
	}

	function renderCards() {
		const cards = ebooks.filter((n) => n.id !== 1);

		return cards.map((ebook) => <EbookCard key={ebook.id} ebook={ebook} />);
	}

	async function LoadAllEbooks() {
		const data = await getAllEbooks();
		setEbooks(data);
	}

	return (
		<React.Fragment>
			<div className="container mx-auto">
				<div className="podcast ebook-c">
					<div className="container mx-auto">
						<h3 className="generic-title generic-title--left">Ebook</h3>
					</div>
					<div className="ebooks">
						<div className="grid grid-cols-1 gap-8 mt-10 md:gap-14 ebooks__list main-blog-cards-container md:grid-cols-2 lg:grid-cols-3">
							{renderBigCard()}
							{renderCards()}
						</div>
					</div>
				</div>
			</div>
			{/* podcast details end here */}
			{/* podcast list end here */}
			<div className="load-more-box">
				{loadMore === true ? (
					<>
						<div className="container px-5 mx-auto">
							<a
								className="global-button"
								onClick={() => {
									LoadAllEbooks();
									setLoadMore(false);
								}}
							>
								Cargar Más
							</a>
						</div>
					</>
				) : (
					<></>
				)}
			</div>
		</React.Fragment>
	);
}

export default MainEbook;
