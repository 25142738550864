import axios from 'axios';
import AppVariables from '../Variables.json';
import BlogReadDto from '../dtos/BlogReadDto';
import { getRand } from '../util/util';
import { AxiosError, AxiosResponse } from '../dtos/types/Axios';
import { SharePointConsts } from '../util/constants';

const base_blog_url = "_api/web/lists/getbytitle('Blog')/items";

async function fetchData(apiEndpoint: string, filter: string): Promise<BlogReadDto[]> {
	const data = await axios
		.get(`${AppVariables.SITE_COLLECTION_URL}${apiEndpoint}${filter}`, {
			headers: {
				'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
					?.value,
				Accept: SharePointConsts.acceptVerbose,
			},
		})
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const blogs: BlogReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const blog = new BlogReadDto();

		blog.id = data.d.results[index].ID;
		blog.title = data.d.results[index].Title;
		blog.category = data.d.results[index].Categoria;
		blog.shortDescription = data.d.results[index].Descripcioncorta;
		blog.description = data.d.results[index].Descripcion;
		blog.publicationDate = data.d.results[index].Fechadepublicacion;
		blog.imgUrl = data.d.results[index].AttachmentFiles.results.length
			? data.d.results[index].AttachmentFiles.results[0].ServerRelativeUrl
			: '';

		blogs.push(blog);
	}

	return blogs;
}

export async function getAllBlogsCategories(): Promise<string[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Blog')/fields?$filter=EntityPropertyName eq 'Categoria'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response: AxiosResponse) => response.data)
		.catch((error: AxiosError) => console.error(error));

	let categories: string[] = [];

	categories = data.d.results[0].Choices.results;

	return categories;
}

export async function getBlogsByPublicationDate(date: string): Promise<BlogReadDto[]> {
	const filter = `?$expand=AttachmentFiles&$filter=Estado eq 'Activo' and Fechadepublicacion eq '${date}'&$orderby=Orden desc&$orderby=Fechadepublicacion desc`;
	return fetchData(base_blog_url, filter);
}

export async function getBlogsByCategory(category: string): Promise<BlogReadDto[]> {
	const filter = `?$expand=AttachmentFiles&$filter=Estado eq 'Activo' and Categoria eq '${category}'&$orderby=Orden desc&$orderby=Fechadepublicacion desc`;
	return fetchData(base_blog_url, filter);
}

export async function getLastNBlogs(n: number): Promise<BlogReadDto[]> {
	const filter = `?$expand=AttachmentFiles&$filter=Estado eq 'Activo'&$orderby=Orden desc&$orderby=Fechadepublicacion desc&$top=${n}`;
	return fetchData(base_blog_url, filter);
}

export async function getAllBlogs(): Promise<BlogReadDto[]> {
	const filter = `?$expand=AttachmentFiles&$filter=Estado eq 'Activo'&$orderby=Orden desc&$orderby=Fechadepublicacion desc`;
	return fetchData(base_blog_url, filter);
}

export async function getBlogsByPublicationDateAndCategory(
	category: string,
	date: string
): Promise<BlogReadDto[]> {
	const filter = `?$expand=AttachmentFiles&$filter=Estado eq 'Activo' and Fechadepublicacion eq '${date}'  and Categoria eq '${category}'&$orderby=Orden desc&$orderby=Fechadepublicacion desc`;
	return fetchData(base_blog_url, filter);
}

export async function getBlogById(id: number): Promise<BlogReadDto> {
	const filter = `?$expand=AttachmentFiles&$filter=ID eq ${id}`;
	const data = await fetchData(base_blog_url, filter);
	return data.length ? data[0] : new BlogReadDto();
}

export async function getBlogByTitle(title: string): Promise<BlogReadDto> {
	const filter = `?$expand=AttachmentFiles&$filter=Title eq '${title}'`;
	const data = await fetchData(base_blog_url, filter);
	return data.length ? data[0] : new BlogReadDto();
}

export async function getRandomBlogs(id: number): Promise<BlogReadDto[]> {
	const blogs = await getAllBlogs();

	const validBlogs = blogs.map((b) => b.id !== id && b);

	const shuffled = [...validBlogs].sort(() => 0.5 - getRand());

	return shuffled.slice(0, 3);
}
