class ComiteReadDto {
  public id: string;
  public definicion: string;
  public presidente: string;
  public vicepresidente: string;
  public miembro: string;
  public fondoTitle: string;
}

export default ComiteReadDto;
