import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import AppVariables.IMG_URL + 'afi-popular_logo.png' from '../../img/nav/afi-popular_logo.png';
import becomeClientDesktop from '../../img/nav/des-become-client.svg';
import becomeClientDesktopHover from '../../img/nav/des-become-client-hover.svg';
import becomeClientMobile from '../../img/nav/become-client-icon.svg';
import menuIcon from '../../img/nav/menu-icon.svg';
import closeMenuIcon from '../../img/general/close-menu-icon.svg';
import { faChevronRight, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import MobileMenu from '../mobile-menu/MobileMenu';
import { getFundsByFundType } from '../../services/FundServices';
import AppVariables from '../../Variables.json';
import FundReadDto from '../../dtos/FundReadDto';
import AfiContactoDto from '../../dtos/AfiContactosDto';
import { getAfiContactosInfo, getSectionStatus } from '../../services/GenericServices';
import afilogo from '../../img/general/MarcaAFIPopular.svg';

function Nav() {
	const [menuIconClicked, setMenuIconClicked] = useState(false);
	const navigate = useNavigate();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [openFund, setOpenFunds] = useState<FundReadDto[]>([]);
	const [closeFund, setCloseFund] = useState<FundReadDto[]>([]);
	const [contactos, setContactos] = useState<AfiContactoDto>(new AfiContactoDto());

	const [ebookSectionStatus, setEbookSectionStatus] = useState<boolean>();
	const [podcastSectionStatus, setPodcastSectionStatus] = useState<boolean>();
	const [coursesSectionStatus, setCoursesSectionStatus] = useState<boolean>();
	const [becomeClientSectionStatus, setBecomeClientSectionStatus] = useState<boolean>();

	function handleMenuIcon(): void {
		setMenuIconClicked(!menuIconClicked);
	}

	const getContactos = async () => {
		const fetchedContactos = await getAfiContactosInfo();
		setContactos(fetchedContactos);
	};

	function noscroll() {
		window.scrollTo(0, 0);
	}

	const isUserAuthenticated = () => {
		if ((window as any)._spPageContextInfo.userId > 0) {
			setIsAuthenticated(true);
		}
	};

	const setSectionStatus = async () => {
		const EbookStatus = await getSectionStatus('E-book');
		const PodcastStatus = await getSectionStatus('Podcast');
		const CoursesStatus = await getSectionStatus('Cursos y Talleres');
		const BecomeClientStatus = await getSectionStatus('Hazte Cliente');

		setEbookSectionStatus(EbookStatus.visibility);
		setPodcastSectionStatus(PodcastStatus.visibility);
		setCoursesSectionStatus(CoursesStatus.visibility);
		setBecomeClientSectionStatus(BecomeClientStatus.visibility);
	};

	useEffect(() => {
		isUserAuthenticated();

		const fetchData = async () => {
			const openFunds = await getFundsByFundType('Abierto');
			const closedFunds = await getFundsByFundType('Cerrado');
			setOpenFunds(openFunds);
			setCloseFund(closedFunds);
		};

		setSectionStatus();
		getContactos();
		fetchData();
	}, []);

	return (
		<nav className={isAuthenticated ? 'main-nav top-[50px]' : 'main-nav'}>
			<div className="top-nav">
				<div className="top-nav__logo">
					<img
						src={afilogo}
						alt="logo-afi-popular"
						className="top-nav__logo--img"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/`);
						}}
					/>
				</div>

				<div className="top-nav__options">
					<div className="top-nav__options--social-media">
						<a href={contactos.instagramUrl}>
							<div className="top-nav_instagram-icon">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									xmlnsXlink="http://www.w3.org/1999/xlink"
									width={20}
									height={20}
									viewBox="0 0 20 20"
								>
									<defs>
										<clipPath id="clip-path">
											<rect
												id="Rectangle_3712"
												data-name="Rectangle 3712"
												width={20}
												height={20}
												transform="translate(4.291 4.291)"
												fill="#012169"
												stroke="#707070"
												strokeWidth={1}
											/>
										</clipPath>
									</defs>
									<g
										id="Group_5013"
										data-name="Group 5013"
										transform="translate(-4.291 -4.291)"
									>
										<g
											id="Mask_Group_620"
											data-name="Mask Group 620"
											clipPath="url(#clip-path)"
										>
											<path
												id="instagram"
												d="M10,6.291a5.127,5.127,0,1,0,5.127,5.127A5.118,5.118,0,0,0,10,6.291Zm0,8.46a3.333,3.333,0,1,1,3.333-3.333A3.339,3.339,0,0,1,10,14.751Zm6.532-8.669a1.2,1.2,0,1,1-1.2-1.2A1.193,1.193,0,0,1,16.531,6.081Zm3.4,1.214a5.918,5.918,0,0,0-1.615-4.19,5.956,5.956,0,0,0-4.19-1.615c-1.651-.094-6.6-.094-8.25,0A5.948,5.948,0,0,0,1.682,3.1,5.937,5.937,0,0,0,.067,7.291c-.094,1.651-.094,6.6,0,8.25a5.918,5.918,0,0,0,1.615,4.19,5.964,5.964,0,0,0,4.19,1.615c1.651.094,6.6.094,8.25,0a5.918,5.918,0,0,0,4.19-1.615,5.956,5.956,0,0,0,1.615-4.19c.094-1.651.094-6.595,0-8.245ZM17.794,17.312a3.374,3.374,0,0,1-1.9,1.9c-1.316.522-4.439.4-5.894.4s-4.582.116-5.894-.4a3.374,3.374,0,0,1-1.9-1.9c-.522-1.316-.4-4.439-.4-5.894s-.116-4.582.4-5.894a3.374,3.374,0,0,1,1.9-1.9c1.316-.522,4.439-.4,5.894-.4s4.582-.116,5.894.4a3.374,3.374,0,0,1,1.9,1.9c.522,1.316.4,4.439.4,5.894S18.316,16,17.794,17.312Z"
												transform="translate(4.294 2.873)"
												fill="#012169"
											/>
										</g>
									</g>
								</svg>
							</div>
						</a>

						<a href={contactos.linkedInUrl}>
							<div className="top-nav_linkedIn-icon">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									xmlnsXlink="http://www.w3.org/1999/xlink"
									width={20}
									height={20}
									viewBox="0 0 20 20"
								>
									<defs>
										<clipPath id="clip-path">
											<rect
												id="Rectangle_3712"
												data-name="Rectangle 3712"
												width={20}
												height={20}
												transform="translate(4.291 4.291)"
												fill="#012169"
												stroke="#707070"
												strokeWidth={1}
											/>
										</clipPath>
									</defs>
									<g
										id="Group_5013"
										data-name="Group 5013"
										transform="translate(-4.291 -4.291)"
									>
										<g
											id="Mask_Group_622"
											data-name="Mask Group 622"
											clipPath="url(#clip-path)"
										>
											<path
												id="linkedin-in"
												d="M4.477,20H.33V6.647H4.477ZM2.4,4.826A2.413,2.413,0,1,1,4.8,2.4,2.422,2.422,0,0,1,2.4,4.826ZM20,20H15.858V13.5c0-1.549-.031-3.536-2.156-3.536-2.156,0-2.486,1.683-2.486,3.424V20H7.074V6.647h3.977V8.469h.058a4.357,4.357,0,0,1,3.923-2.156c4.2,0,4.968,2.763,4.968,6.353V20Z"
												transform="translate(4.291 4.29)"
												fill="#012169"
											/>
										</g>
									</g>
								</svg>
							</div>
						</a>
					</div>

					{becomeClientSectionStatus === true ? (
						<div className="top-nav__options--become-client">
							<img
								src={becomeClientMobile}
								alt="become-client-icon"
								className="top-nav__options--become-client--img"
							/>
							<img
								src={becomeClientDesktop}
								alt="become-client-icon-des"
								className="top-nav__options--become-client--img-blue"
							/>
							<img
								src={becomeClientDesktopHover}
								alt="become-client-icon-des"
								className="top-nav__options--become-client--img-hover"
							/>
							<a
								onClick={() => {
									navigate(`${AppVariables.REACT_ROUTER_URL}/empieza-invertir`);
								}}
								className="top-nav__options--become-client--text"
							>
								Hazte cliente
							</a>
						</div>
					) : (
						<></>
					)}

					<div className="top-nav__options--search-box">
						<input
							id="top-nav-search-input"
							type="text"
							className="top-nav__options--search-box--input"
							placeholder="Buscar"
						/>
						<div
							className="top-nav__options--search-box--button"
							onClick={() => {
								navigate({
									pathname: `${AppVariables.REACT_ROUTER_URL}/resultado-busqueda`,
									search: `?expr=${
										(document.getElementById('top-nav-search-input') as HTMLInputElement)
											.value
									}`,
								});

								(
									document.getElementById('top-nav-search-input') as HTMLInputElement
								).value = '';
							}}
						>
							<i>
								<FontAwesomeIcon icon={faSearch} />
							</i>
						</div>
					</div>
				</div>
			</div>

			<div className="botton-nav shadow-md">
				<img
					src={afilogo}
					alt="afi-popular-logo"
					className="botton-nav__logo"
					onClick={() => {
						navigate(`${AppVariables.REACT_ROUTER_URL}/`);
					}}
				/>
				<img
					src={menuIconClicked ? closeMenuIcon : menuIcon}
					alt="menu-icon"
					className="botton-nav__menu"
					onClick={() => {
						handleMenuIcon();
						menuIconClicked
							? window.addEventListener('scroll', noscroll)
							: window.removeEventListener('scroll', noscroll);
					}}
				/>
			</div>

			<MobileMenu display={menuIconClicked ? '-active' : ''} action={handleMenuIcon} />

			<div className="botton-nav-desktop">
				<div className="botton-nav-desktop__menu-list">
					<div className="dropdown">
						<div>
							Conócenos{' '}
							<i>
								<FontAwesomeIcon icon={faChevronDown} />
							</i>
						</div>
						<div className="dropdown-content">
							<div className="trs"></div>
							<div className="info">
								<a
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/sobre-nosotros`);
									}}
								>
									Sobre nosotros
								</a>
								<a
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/gobierno-corporativo`);
									}}
								>
									Gobierno Corporativo
								</a>
								<a
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/publicaciones`);
									}}
								>
									Publicaciones institucionales
								</a>
							</div>
						</div>
					</div>

					<div className="dropdown">
						<div>
							Fondos de Inversión{' '}
							<i>
								<FontAwesomeIcon icon={faChevronDown} />
							</i>
						</div>
						<div className="dropdown-content">
							<div className="trs"></div>
							<div className="info">
								<label htmlFor="">Abiertos</label>
								<div>
									{openFund.map((fund) => (
										<a
											onClick={() =>
												navigate({
													pathname: `${AppVariables.REACT_ROUTER_URL}/generic-fund`,
													search: `?titulo=${fund.title}`,
												})
											}
										>
											{fund.title}
										</a>
									))}
								</div>
								<br />
								<label htmlFor="">Cerrados</label>
								<div>
									{closeFund.map((fund) => (
										<a
											onClick={() =>
												navigate({
													pathname: `${AppVariables.REACT_ROUTER_URL}/generic-fund`,
													search: `?titulo=${fund.title}`,
												})
											}
										>
											{fund.title}
										</a>
									))}
								</div>
								<br />
								<a
									href=""
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/fondos-de-inversion`);
									}}
								>
									<FontAwesomeIcon icon={faChevronRight} /> Ver todos los fondos
								</a>
								<a
									href=""
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/comparador-de-fondos`);
									}}
								>
									<FontAwesomeIcon icon={faChevronRight} /> Comparar fondos
								</a>
							</div>
						</div>
					</div>

					<div className="dropdown">
						<div>
							Herramientas{' '}
							<i>
								<FontAwesomeIcon icon={faChevronDown} />
							</i>
						</div>
						<div className="dropdown-content">
							<div className="trs"></div>
							<div className="info">
								<a
									href=""
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/perfil-de-riesgo`);
									}}
								>
									Perfil de Riesgo del Inversionista
								</a>
								<a
									href=""
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/recomendador`);
									}}
								>
									Recomendador de fondos
								</a>
								<a
									href=""
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/valor-cuota`);
									}}
								>
									Valor de las cuotas
								</a>
								<a
									href=""
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/calculadora`);
									}}
								>
									Calculadora de rendimiento
								</a>
								<a
									href=""
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/comparador-de-fondos`);
									}}
								>
									Comparador de fondos
								</a>
							</div>
						</div>
					</div>

					<div className="dropdown">
						<div>
							Noticias{' '}
							<i>
								<FontAwesomeIcon icon={faChevronDown} />
							</i>
						</div>
						<div className="dropdown-content">
							<div className="trs"></div>
							<div className="info">
								<a
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/blog`);
									}}
								>
									Blog
								</a>
								{podcastSectionStatus === true ? (
									<a
										href=""
										onClick={() => {
											navigate(`${AppVariables.REACT_ROUTER_URL}/podcast`);
										}}
									>
										Podcast
									</a>
								) : (
									<></>
								)}

								<a
									href=""
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/sala-de-prensa`);
									}}
								>
									Sala de Prensa
								</a>
								<a
									href=""
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/boletin`);
									}}
								>
									Boletín informativo
								</a>
							</div>
						</div>
					</div>

					<div className="dropdown">
						<div>
							Informaciones{' '}
							<i>
								<FontAwesomeIcon icon={faChevronDown} />
							</i>
						</div>
						<div className="dropdown-content">
							<div className="trs"></div>
							<div className="info">
								{ebookSectionStatus === true ? (
									<a
										href=""
										onClick={() => {
											navigate(`${AppVariables.REACT_ROUTER_URL}/ebooks`);
										}}
									>
										Ebooks
									</a>
								) : (
									<></>
								)}
								{coursesSectionStatus === true ? (
									<a
										href=""
										onClick={() => {
											navigate(`${AppVariables.REACT_ROUTER_URL}/courses`);
										}}
									>
										Cursos y talleres
									</a>
								) : (
									<></>
								)}
								<a
									href=""
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/preguntas-frecuentes`);
									}}
								>
									Preguntas frecuentes
								</a>
								<a
									href=""
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/diccionario`);
									}}
								>
									Diccionario
								</a>
								<a
									href=""
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/marco-regulatorio`);
									}}
								>
									Marco regulatorio
								</a>
								<a
									href=""
									onClick={() => {
										navigate(`${AppVariables.REACT_ROUTER_URL}/FATCA`);
									}}
								>
									FATCA
								</a>
							</div>
						</div>
					</div>

					<div className="dropdown">
						<div
							onClick={() => {
								navigate(`${AppVariables.REACT_ROUTER_URL}/contactanos`);
							}}
						>
							Contáctanos{' '}
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
}

export default Nav;
