interface IProps {
  id: string;
  area: string;
  setCurrentArea: React.Dispatch<React.SetStateAction<string>>;
}

function ExecutiveOption(props: IProps) {
  function toggleChoice(id: string) {
    const testBox = document.getElementById(
      "GCOptionsContainer"
    ) as HTMLDivElement;
    const choices = testBox.querySelectorAll("div");
    choices.forEach((div) => {
      if (div.id === id) {
        div.classList.add("active");
      } else {
        div.classList.remove("active");
      }
    });
  }

  return (
    <div className="main-executives-option-container">
      <div
        id={props.id}
        onClick={() => {
          toggleChoice(props.id);
          props.setCurrentArea(props.area);
        }}
        className={
          props.id === "1"
            ? "cursor-pointer main-executives-option active"
            : "cursor-pointer main-executives-option"
        }
      >
        {props.area}
      </div>
    </div>
  );
}

export default ExecutiveOption;
