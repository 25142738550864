function PodcastAudio(props) {

    const baseUrl = 'https://widget.spreaker.com/player?episode_id=';

    return (
			<iframe
				id="audio-component"
				src={baseUrl+props.episode}
				frameBorder="0"
				width="100%"
				height="200px"
				theme="light"
				playlist="false"
				playlist-continuous="false"
				chapters-image="true"
				episode-image-position="right"
				hide-logo="false"
				hide-likes="false"
				hide-comments="false"
				hide-sharing="false"
				hide-download="true"
			></iframe>
		);
}

export default PodcastAudio;