import { useEffect, useState } from 'react';
import CarouselStaticContent from './CarouselStaticContent';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@splidejs/react-splide/css/skyblue';
import { getCarouselByCategory } from '../../services/CarouselServices';
import CarouselReadDto from '../../dtos/CarouselReadDto';

function TouchDevicesCarousel() {
	const [carousel, setCarousel] = useState<CarouselReadDto[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			const fetchedCarousel = await getCarouselByCategory('Mobile');
			setCarousel(fetchedCarousel);
		};

		fetchData();
	}, []);

	const renderImages = () => {
		return carousel.map((carousel, index) => (
			<SplideSlide key={index}>
				<img src={carousel.imgUrl} alt="" className="slider-img" />
				<CarouselStaticContent
					imgUrl={carousel.icon}
					subtitle={carousel.subtitle}
					title={carousel.title}
					content={carousel.content}
					linkText={carousel.buttonText}
					linkUrl={carousel.buttonUrl}
				/>
			</SplideSlide>
		));
	};

	return (
		<div className="hero-section">
			<Splide
				options={{
					rewind: true,
					arrows: false,
				}}
				hasTrack={false}
			>
				<SplideTrack>{renderImages()}</SplideTrack>
			</Splide>
		</div>
	);
}

export default TouchDevicesCarousel;
