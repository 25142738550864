import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { validations } from "../Validations/regexValidations";

function StartInvestPersonStep5() {
  const emptyFieldErrorMessage = "Campo obligatorio";
  const {
    register,
    control,
    resetField,
    formState: { errors },
  } = useFormContext();

  const otherServices = useWatch({ control, name: "otherServices" });
  return (
		<React.Fragment>
			<div className="mb-6 create__requisitos">
				<h4 className="create__requisitos-title">Clientes de mayor riesgo</h4>
				<div className="lg:items-start lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							¿Posee otros servicios o productos de inversión contratados o adquiridos a
							través de otros participantes del mercado de valores o en el extranjero?
						</label>
						<div className="document-box">
							<input
								id="step5-other-services-yes"
								className="toggle toggle-left"
								type="radio"
								value="Si"
								{...register('otherServices', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
								onClick={() => {
									resetField('whichServices');
								}}
							/>

							<label htmlFor="step5-other-services-yes" className="btn">
								Si
							</label>
							<input
								id="step5-other-services-no"
								className="toggle toggle-right"
								type="radio"
								value="No"
								{...register('otherServices', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
								onClick={() => {
									resetField('whichServices');
								}}
							/>

							<label htmlFor="step5-other-services-no" className="btn">
								No
							</label>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">¿Cuáles?</label>
						<input
							type="text"
							maxLength={60}
							className="global-input"
							placeholder="Otros productos de inversión"
							{...register('whichServices', {
								required: {
									value: otherServices === 'Si',
									message: emptyFieldErrorMessage,
								},
								pattern: validations.letrasYespacios,
							})}
							disabled={otherServices === 'No'}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="whichServices"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							En caso de ser extranjero, coloque sus cuentas de inversión locales o
							extranjeras - Opcional
						</label>
						<input
							type="text"
							className="global-input"
							placeholder="Cuentas de inversión"
							{...register('investmentAccounts', {
								pattern: validations.soloNumeros,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="investmentAccounts"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="mb-6 create__requisitos">
				<h4 className="create__requisitos-title">
					Personas autorizadas para concertar, instruir o realizar las operaciones y
					transacciones con el intermediario de valores - <i>Opcional</i>
				</h4>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Nombre</label>
						<input
							type="text"
							maxLength={40}
							className="global-input"
							placeholder="Nombre"
							{...register('authName', {
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="authName"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Número de identificación</label>
						<input
							type="text"
							maxLength={11}
							className="global-input"
							placeholder="000-0000000-0"
							{...register('idNoAuthPerson', {
								pattern: validations.letrasYnumeros,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="idNoAuthPerson"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Calidad en la cual actúa</label>
						<input
							type="text"
							maxLength={40}
							className="global-input"
							{...register('actingRole', {
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="actingRole"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
  );
}

export default StartInvestPersonStep5;
