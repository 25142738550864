import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { validations } from "../Validations/regexValidations";

export default function ReferenciasBancariasComercialesForm() {
  const emptyFieldErrorMessage = "Campo obligatorio";
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const { append, fields, prepend } = useFieldArray({
    control,
    name: "comercialBankReferences",
  });
  useEffect(() => {
    !fields.length && prepend({});
  }, []);
  return (
    <React.Fragment>
      {fields.map((field, index) => {
        const baseName = `comercialBankReferences[${index}]`;
        return (
				<div key={field.id} className="mb-6 create__requisitos">
					<h4 className="create__requisitos-title">Referencias bancarias y/o comerciales</h4>

					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Banco o Empresa</label>
							<input
								type="text"
								maxLength={20}
								className="global-input"
								placeholder="Banco o Empresa"
								{...register(`${baseName}.Company`, {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name={`${baseName}.Company`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Número de Cuenta o Tarjeta</label>
							<input
								type="text"
								maxLength={20}
								className="global-input"
								placeholder="Número de Cuenta o Tarjeta"
								{...register(`${baseName}.AccountCardNumber`, {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.numerosYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name={`${baseName}.AccountCardNumber`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="lg:items-end lg:flex lg:space-x-9 lg:pr-4">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">
								Oficial de la Cuenta o persona de contacto
							</label>
							<input
								type="text"
								maxLength={40}
								className="global-input"
								placeholder="Oficial de la Cuenta o persona de contacto"
								{...register(`${baseName}.ContactOfficialPerson`, {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name={`${baseName}.ContactOfficialPerson`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
				</div>
			);
      })}
      <div className="mt-6 mb-6 text-center create__requisitos">
        <button
          type="button"
          onClick={() => {
            append({});
          }}
          className="mb-4"
        >
          <div className="mx-auto text-[#012169] opacity-50 text-[40px]">+</div>
          <span className="text-[#012169] opacity-50 font-bold mb-3">
            Agregar otra referencia bancaria y/o comercial
          </span>
        </button>
      </div>
    </React.Fragment>
  );
}
