import React, { useEffect, useState } from "react";
import {useSearchParams } from "react-router-dom";
import FundDocuments from "../components/funds-details/FundDocuments";
import FundMainComite from "../components/funds-details/FundMainComite";
import FundMainInfo from "../components/funds-details/FundMainInfo";
import FundMainRendimiento from "../components/funds-details/FundMainRendimiento";
import FundMainValoracion from "../components/funds-details/FundMainValoracion";
import ComiteReadDto from "../dtos/ComiteReadDto";
import FundReadDto from "../dtos/FundReadDto";
import {
  getComiteByfundTitle,
  getFundImageById,
  getFundsDetailsByTitle,
  getMiembrosComiteByfundTitle,
} from "../services/FundServices";

function FundGeneric() {

  type Member = {
		nombre: string;
		rol: string;
  };
  
  const [fund, setFund] = useState<FundReadDto>(new FundReadDto());
  const [comite, setComite] = useState<ComiteReadDto>(new ComiteReadDto());
  const [miembros,setMiembros] = useState<Member[]>([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchData = async () => {
      const fund1 = await getFundsDetailsByTitle(searchParams.get('titulo'));
      const attachment = await getFundImageById(fund1.id);
      fund1.mainImage = attachment.url;
      const comite1 = await getComiteByfundTitle(fund1.title);
      const miembrosComite = await getMiembrosComiteByfundTitle(fund1.title);
      setFund(fund1);
      setComite(comite1);
      setMiembros(miembrosComite);
    };

    fetchData();
  }, [searchParams]);


  return (
    <React.Fragment>
      <FundMainInfo
        title={fund.title}
        definition={fund.definicion}
        img={fund.mainImage}
      />
      <FundMainValoracion
        fecha={fund.fecha}
        moneda={fund.moneda}
        patrimonioEnCirculacion={fund.patrimonioEnCirculacion}
        totalCuotas={fund.totalCuotas}
        valorCuota={fund.valorCuota}
        valorCuotaDiaAnterior={fund.valorCuotaDiaAnterior}
      />
      <FundMainRendimiento
        rendimientoUltimos30dias={fund.rendimientoUltimos30dias}
        rendimientoUltimos90dias={fund.rendimientoUltimos90dias}
        rendimientoUltimos180dias={fund.rendimientoUltimos180dias}
        rendimientoUltimos365dias={fund.rendimientoUltimos365dias}
        year={fund.year}
        benchmark={fund.benchmark}
      />
      <FundMainComite
        definition={comite.definicion}
        title={fund.title}
        members={miembros}
      />

      <FundDocuments fondo={fund.title} />
    </React.Fragment>
    
  );
}

export default FundGeneric;
