import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { validations } from "../Validations/regexValidations";

function OtherInvestmentAccounts() {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const { append, fields, prepend } = useFieldArray({
    control,
    name: "otherInvestmentAccounts",
  });

  useEffect(() => {
    !fields.length && prepend({});
  }, []);

  return (
    <React.Fragment>
      {fields.map((field, index) => {
        const baseName = `otherInvestmentAccounts[${index}]`;
        return (
				<div key={field.id} className="mt-6 create__requisitos">
					<h4 className="create__requisitos-title">
						Otras cuentas de inversión locales o extranjeras (si es empresa extranjera) -
						<i>Opcional</i>
					</h4>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Información de las cuentas de inversión</label>
							<input
								type="text"
								maxLength={120}
								className="global-input"
								placeholder="Información de las cuentas de inversión"
								{...register(`${baseName}.Information`, {
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name={`${baseName}.Information`}
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
				</div>
			);
      })}
      <div className="mt-6 mb-6 text-center create__requisitos">
        <button
          type="button"
          onClick={() => {
            append({});
          }}
          className="mb-4"
        >
          <div className="mx-auto text-[#012169] opacity-50 text-[40px]">+</div>
          <span className="text-[#012169] opacity-50 font-bold mb-3">
            Agregar otra Cuenta de inversión
          </span>
        </button>
      </div>
    </React.Fragment>
  );
}

export default OtherInvestmentAccounts;
