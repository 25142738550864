import React, { useEffect, useState } from "react";
import AboutUsBannerReadDto from "../../dtos/AboutUsBannerReadDto";
import AboutUsMvvReadDto from "../../dtos/AboutUsMvvReadDto";
import AboutUsVideoReadDto from "../../dtos/AboutUsVideoReadDto";
import AcknowledgmentReadDto from "../../dtos/AcknowledgmentReadDto";
import ExecutivesReadDto from "../../dtos/ExecutivesReadDto";
import PromotorReadDto from "../../dtos/PromotorReadDto";
import TimelineReadDto from "../../dtos/TimelineReadDto";
import {
  getActiveAcknowledgments,
  getActiveAcknowledgmentsByYear,
  getActiveAcknowledgmentsYears,
  getActiveAndOrderTimeline,
  getActiveBanner,
  getActiveExecutives,
  getActiveMvv,
  getActivePromotors,
  getActiveVideo,
} from "../../services/AboutUsServices";
import { getSectionStatus } from "../../services/GenericServices";
import AboutUsBanner from "./AboutUsBanner";
import Acknowledgment from "./Acknowledgment";
import MvvSection from "./MvvSection";
import Team from "./Team";
import TimelineContainer from "./TimelineContainer";
import VideoSection from "./VideoSection";

function MainAboutUs() {
  const [bannerInfo, setBannerInfo] = useState<AboutUsBannerReadDto>(
    new AboutUsBannerReadDto()
  );
  const [timeline, setTimeline] = useState<TimelineReadDto[]>([]);
  const [mvv, setMvv] = useState<AboutUsMvvReadDto>(new AboutUsMvvReadDto());
  const [video, setVideo] = useState<AboutUsVideoReadDto>(
    new AboutUsVideoReadDto()
  );
  const [acknowledgmentYear, setAcknowledgmentYear] = useState("");
  const [years, setYears] = useState<string[]>([]);
  const [acknowledgments, setAcknowledgments] = useState<
    AcknowledgmentReadDto[]
  >([]);
  const [executives, setExecutives] = useState<ExecutivesReadDto[]>([]);
  const [promotors, setPromotors] = useState<PromotorReadDto[]>([]);

  const [acknowledgmentSectionStatus, setAcknowledgmentSectionStatus] = useState<boolean>();

  const setSectionStatus = async () => {
		const Status = await getSectionStatus('Sobre Nosotros - Reconocimientos');
		setAcknowledgmentSectionStatus(Status.visibility);
  };

  useEffect(() => {
		setSectionStatus();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const bannerINFO = await getActiveBanner();
      setBannerInfo(bannerINFO);

      const timelineItems = await getActiveAndOrderTimeline();
      setTimeline(timelineItems);

      const _mvv = await getActiveMvv();
      setMvv(_mvv);

      const _video = await getActiveVideo();
      setVideo(_video);

      const _years = await getActiveAcknowledgmentsYears();
      setYears(_years);

      const _acknowledgments = await getActiveAcknowledgments();
      setAcknowledgments(_acknowledgments);

      const _executives = await getActiveExecutives();
      setExecutives(_executives);

      const _promotors = await getActivePromotors();
      setPromotors(_promotors);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const _acknowledgments = await getActiveAcknowledgmentsByYear(
        acknowledgmentYear
      );
      setAcknowledgments(_acknowledgments);
    };

    fetchData();
  }, [acknowledgmentYear]);

  return (
		<React.Fragment>
			<AboutUsBanner BannerInfo={bannerInfo} />
			<TimelineContainer items={timeline} />
			<MvvSection mvvInfo={mvv} />
			<VideoSection video={video} />
			{acknowledgmentSectionStatus === true ? (
				<Acknowledgment years={years} ack={acknowledgments} setYear={setAcknowledgmentYear} />
			) : (
				<div className="reconocimiento-section">
					
				</div>
			)}

			<Team executives={executives} promotors={promotors} />
		</React.Fragment>
  );
}

export default MainAboutUs;
