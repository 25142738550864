import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import locationIcon from '../../img/footer/location-icon.svg';
import phoneIcon from '../../img/footer/phone-icon.svg';
import mailIcon from '../../img/footer/mail-icon.svg';
import instagramIcon from '../../img/general/instagram-icon.svg';
import linkedinIcon from '../../img/general/linkedin-icon.svg';
import calendar from '../../img/general/calendar-white.svg';
import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';
import { useEffect, useState } from 'react';
import AfiContactoDto from '../../dtos/AfiContactosDto';
import { getAfiContactosInfo, getSectionStatus } from '../../services/GenericServices';

function Footer() {
	const [contactos, setContactos] = useState<AfiContactoDto>(new AfiContactoDto());
	const [ebookSectionStatus, setEbookSectionStatus] = useState<boolean>();

	const navigate = useNavigate();

	const setSectionStatus = async () => {
		const EbookStatus = await getSectionStatus('E-book');
		setEbookSectionStatus(EbookStatus.visibility);
	};

	const getContactos = async () => {
		const fetchedContactos = await getAfiContactosInfo();
		setContactos(fetchedContactos);
	};

	function formatPhoneNumber(phoneNumberString: string) {
		const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return '(' + match[1] + ') ' + match[2] + '-' + match[3];
		}
		return null;
	}

	useEffect(() => {
		setSectionStatus();
		getContactos();
	}, []);

	return (
		<footer className="footer">
			<div className="top-footer">
				<div className="top-footer__contactanos">
					<h2 className="top-footer__title">Contáctanos</h2>
					<div className="top-footer__contact-info">
						<div className="generic-info">
							<img src={locationIcon} alt="location-icon" className="generic-info__icon" />
							<a
								className="generic-info__content visited:!text-white"
								href={contactos.direccionUrl}
							>
								{contactos.direccion}
							</a>
						</div>
						<a href={`tel:${contactos.telefono}`}>
							<div className="generic-info">
								<img src={phoneIcon} alt="phone-icon" className="generic-info__icon" />
								<p className="generic-info__content">
									{formatPhoneNumber(contactos.telefono)}
								</p>
							</div>
						</a>
						<div className="generic-info">
							<img src={mailIcon} alt="mail-icon" className="generic-info__icon" />
							<a
								href={`mailto:${contactos.escribenosMail}`}
								className="generic-info__content"
							>
								Escríbenos
							</a>
						</div>
						<div className="generic-info">
							<img src={mailIcon} alt="mail-icon" className="generic-info__icon" />
							<a
								href={`mailto:${contactos.reclamosMail}`}
								className="generic-info__content"
							>
								Reclamos
							</a>
						</div>
						<div className="generic-info">
							<img src={mailIcon} alt="mail-icon" className="generic-info__icon" />
							<a
								href={`mailto:${contactos.denunciasPlaft}`}
								className="generic-info__content"
							>
								Denuncias PLAFT
							</a>
						</div>
						<div className="generic-info">
							<img src={calendar} alt="calendar" className="generic-info__icon" />
							<p className="generic-info__content">Lunes a Viernes 8:00 AM a 5:00 PM</p>
						</div>
					</div>
					<div className="social-media">
						<a href={contactos.instagramUrl} target="blank">
							<img src={instagramIcon} alt="" className="social-media__icon" />
						</a>
						{/* <a href="https://twitter.com/Popularenlinea" target="blank">
							<img src={twitterIcon} alt="" className="social-media__icon" />
						</a>
						<a href="https://www.facebook.com/AFIPopular" target="blank">
							<img src={facebookIcon} alt="" className="social-media__icon" />
						</a> */}
						<a href={contactos.linkedInUrl} target="blank">
							<img src={linkedinIcon} alt="" className="social-media__icon" />
						</a>
					</div>
				</div>
				<div className="top-footer__afi-popular">
					<h1 className="top-footer__title">AFI Popular</h1>
					<ul className="top-footer__generic-list">
						<li
							className="cursor-pointer"
							onClick={() => {
								navigate(`${AppVariables.REACT_ROUTER_URL}/sobre-nosotros`);
							}}
						>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>{' '}
							Sobre nosotros
						</li>
						<li
							className="cursor-pointer"
							onClick={() => {
								navigate(`${AppVariables.REACT_ROUTER_URL}/sala-de-prensa`);
							}}
						>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>{' '}
							Noticias y prensa
						</li>
						<li
							className="cursor-pointer"
							onClick={() => {
								navigate(`${AppVariables.REACT_ROUTER_URL}/fondos-de-inversion`);
							}}
						>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>{' '}
							Fondos de inversión
						</li>
						<li
							className="cursor-pointer"
							onClick={() => {
								navigate(`${AppVariables.REACT_ROUTER_URL}/gobierno-corporativo`);
							}}
						>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>{' '}
							Gobierno corporativo
						</li>
						<li
							className="cursor-pointer"
							onClick={() => {
								navigate(`${AppVariables.REACT_ROUTER_URL}/publicaciones`);
							}}
						>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>{' '}
							Publicaciones institucionales
						</li>
					</ul>
				</div>
				<div className="top-footer__informaciones">
					<h1 className="top-footer__title">Informaciones</h1>
					<ul className="top-footer__generic-list">
						{ebookSectionStatus === true ? (
							<li
								className="cursor-pointer"
								onClick={() => {
									navigate(`${AppVariables.REACT_ROUTER_URL}/ebooks`);
								}}
							>
								<i>
									<FontAwesomeIcon icon={faChevronRight} />
								</i>{' '}
								Centro de información
							</li>
						) : (
							<></>
						)}

						<li
							className="cursor-pointer"
							onClick={() => {
								navigate(`${AppVariables.REACT_ROUTER_URL}/FATCA`);
							}}
						>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>{' '}
							FATCA
						</li>
						<li
							className="cursor-pointer"
							onClick={() => {
								navigate(`${AppVariables.REACT_ROUTER_URL}/publicaciones`);
							}}
						>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>{' '}
							Hechos relevantes
						</li>
						<li
							className="cursor-pointer"
							onClick={() => {
								navigate(`${AppVariables.REACT_ROUTER_URL}/sobre-nosotros`);
							}}
						>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>{' '}
							Promotores autorizados
						</li>
					</ul>
				</div>
				<div className="top-footer__herramientas">
					<h1 className="top-footer__title">Herramientas</h1>
					<ul className="top-footer__generic-list">
						<li
							className="cursor-pointer"
							onClick={() => {
								navigate(`${AppVariables.REACT_ROUTER_URL}/recomendador`);
							}}
						>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>{' '}
							Recomendador de fondos
						</li>
						<li
							className="cursor-pointer"
							onClick={() => {
								navigate(`${AppVariables.REACT_ROUTER_URL}/valor-cuota`);
							}}
						>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>{' '}
							Valor de las cuotas
						</li>
						<li
							className="cursor-pointer"
							onClick={() => {
								navigate(`${AppVariables.REACT_ROUTER_URL}/calculadora`);
							}}
						>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>{' '}
							Calculadora de rendimiento
						</li>
						<li
							className="cursor-pointer"
							onClick={() => {
								navigate(`${AppVariables.REACT_ROUTER_URL}/perfil-de-riesgo`);
							}}
						>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>{' '}
							Perfil de riesgo del Inversionista
						</li>
						<li
							className="cursor-pointer"
							onClick={() => {
								navigate(`${AppVariables.REACT_ROUTER_URL}/comparador-de-fondos`);
							}}
						>
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>{' '}
							Comparador de Fondos
						</li>
					</ul>
				</div>
			</div>
			<div className="bottom-footer">
				<p className="bottom-footer__policies cursor-pointer">
					<span
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/politicas-privacidad`);
						}}
					>
						Políticas de Privacidad y Seguridad
					</span>
					<br></br>
					<span
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/terminos-condiciones`);
						}}
					>
						Aviso Legal y Condiciones de Uso
					</span>
					<br></br>
					<span
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/politicas-cookies`);
						}}
					>
						Políticas de Cookies
					</span>
				</p>
				<hr className="bottom-footer__division" />
				<p
					className="bottom-footer__text"
					onClick={() => {
						navigate(`${AppVariables.REACT_ROUTER_URL}/fondos-de-inversion`);
					}}
				>
					Administradora de Fondos de Inversión Popular Filial del Grupo Popular
				</p>
				<p
					className="bottom-fotter__policies-des"
					onClick={() => {
						navigate(`${AppVariables.REACT_ROUTER_URL}/`);
					}}
				>
					Administradora de Fondos de Inversión Popular | Filial del Grupo Popular
				</p>
				<p className="bottom-footer__text-des cursor-pointer">
					<span
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/politicas-privacidad`);
						}}
					>
						Políticas de Privacidad y Seguridad
					</span>{' '}
					|{' '}
					<span
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/terminos-condiciones`);
						}}
					>
						Aviso Legal y Condiciones de Uso
					</span>{' '}
					|{' '}
					<span
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/politicas-cookies`);
						}}
					>
						Políticas de Cookies
					</span>
				</p>
			</div>
		</footer>
	);
}

export default Footer;
