import React, { useEffect, useState } from "react";
import PublicacionReadDto from "../../dtos/PublicacionReadDto";
import { getAllMarcoRegulatorio } from "../../services/MarcoRegulatorioServices";
import MarcoRegulatorioBanner from "./MarcoRegulatorioBanner";
import MarcoRegulatorioDocSection from "./MarcoRegulatorioDocSection";

function MainMarcoRegulatorio() {
  const [docs, setDocs] = useState<PublicacionReadDto[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const docs1 = await getAllMarcoRegulatorio();
      setDocs(docs1);
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <MarcoRegulatorioBanner />
      <MarcoRegulatorioDocSection docs={docs} />
    </React.Fragment>
  );
}

export default MainMarcoRegulatorio;
