import { Link } from "react-router-dom";
import notFoundIcon from "../../img/not-found/not-found-icon.svg";

export default function NotFoundPage({ link }: any) {
  return (
    <div className="bg-[#F0F2F7] w-full h-screen flex flex-col items-center justify-center">
      <img src={notFoundIcon} alt="" className="w-32" />
      <div className="text-[#012169] mt-12 font-bold text-5xl">
        404
      </div>
      <span className="mt-4 text-2xl text-center text-black font-semibol">
        Esta página no pudo ser encontrada
      </span>

      <Link
        to={link}
        className=" bg-[#00A5C2]  h-10 w-52 mt-10 flex rounded-full items-center  justify-center hover:bg-[#00709E] visited:text-black"
      >
        <span className="text-base text-white ">
          Volver al inicio
        </span>
      </Link>
    </div>
  );
}
