import { useNavigate } from 'react-router-dom';
import blueBlackArrow from '../../img/sala-de-prensa-details/blue-back-arrow.svg';
import whiteBlackArrow from '../../img/sala-de-prensa-details/white-back-arrow.png';

interface IProps {
	date: string;
	title: string;
	category: string;
}

function PrensaDetailTop(props:IProps) {

	const navigate = useNavigate();

	return (
		<div className="prensa-detail-top flex flex-col md:mx-12 xl:mx-[150px] 2xl:mx-[200px] 3xl:mx-[300px] mx-[18px] lg:mx-24 mb-6">
			<div className="prensa-detail-header flex items-center mb-4">
				<a
					className="prensa-detail-header__button-back mr-4 rounded-full border-2 border-[#012169] w-14 h-9 flex justify-center items-center hover:bg-ultramar cursor-pointer"
					onClick={() => {
						navigate(-1);
					}}
				>
					<img
						src={blueBlackArrow}
						alt="arrow back"
						className="prensa-detail-header-button__img w-4 h-4"
					/>
					<img
						src={whiteBlackArrow}
						alt="arrow back"
						className="prensa-detail-header-button__img-hover w-4 h-4 hidden"
					/>
				</a>
				<span className="prensa-detail-header__text text-xs text-[#414652] lg:text-base 3xl:text-xl font-bold">
					Publicado el {props.date}
				</span>
			</div>
			<div className="prensa-detail__title text-entremar1 text-2xl font-bold mb-4 xl:text-3xl 3xl:text-5xl">
				{props.title}
			</div>
			<div className="prensa-detail-subtitle text-dark-gray text-xs lg:text-base xl:text-lg 3xl:text-xl font-bold">
				{props.category}
			</div>
		</div>
	);
}

export default PrensaDetailTop;
