import AppVariables from '../Variables.json';

class StartInvestReadDto {
	public id: number;

	public title: string;
	public description: string;

	private _imgUrl: string;
	public get imgUrl(): string {
		return this._imgUrl;
	}
	public set imgUrl(value: string) {
		this._imgUrl = AppVariables.BASE_URL + value;
	}

	public url: string;
	public textUrl: string;
}

export default StartInvestReadDto;
