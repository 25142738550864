import pdf from '../../img/general/pdf-icon.svg';
import {saveAs} from "file-saver";

interface IProps {
	title: string;
	date: string;
	size: string;
	url: string;
    filename:string;
}

function MobileFileCard(props:IProps) {

    function saveFile(url:string,filename:string){
        saveAs(url,filename);
    }

    return (
			<div className="file-section__file-container--card shadow-lg" onClick={() => saveFile(props.url,props.filename)}>
				<img src={pdf} alt="" className="file-section__file-container--card--img" />
				<div className="file-section__file-container--card--content">
					<p className="file-section__file-container--card--content--text">{props.title}</p>
					<h4 className="file-section__file-container--card--content--meta-data">
						Publicado:<b>{props.date}</b> - Tamaño:<b>{props.size} Kb</b>
					</h4>
				</div>
			</div>
		);
}

export default MobileFileCard;