import { useEffect, useState } from 'react';
import NewsReadDto from '../../dtos/NewsReadDto';
import {
	getActiveNews,
	getNewsByCategory,
	getNewsByCategoryAndDate,
	getNewsByDate,
	getNewsCategories,
	getTop10News,
} from '../../services/NewsServices';
import SalaDePrensaBigCard from './SalaDePrensaBigCard';
import SalaDePrensaCard from './SalaDePrensaCard';
import SalaDePrensaSelector from './SalaDePrensaSelector';

function MainSalaDePrensa() {
	const [news, setNews] = useState<NewsReadDto[]>([]);
	const [mainNews, setMainNews] = useState<NewsReadDto>();
	const [secundaryNews, setSecNews] = useState<NewsReadDto[]>([]);
	const [categories, setCategories] = useState([]);
	const [currentCategory, setCurrentCategory] = useState('Todas');
	const [dates, setDates] = useState([]);
	const [currentDate, setCurrentDate] = useState('');
	const [loadMore, setLoadMore] = useState<boolean>(true);

	const fetchData = async () => {
		let fetchedNews: NewsReadDto[];
		let fetchedMainNews: NewsReadDto;
		let fetchedSecNews: NewsReadDto[];

		if (currentCategory === 'Todas' && currentDate === '') {
			fetchedNews = await getTop10News();
			setNews(fetchedNews);
			fetchedMainNews = fetchedNews.shift();
			fetchedSecNews = fetchedNews;
		} else if (currentCategory !== 'Todas' && currentDate === '') {
			fetchedNews = await getNewsByCategory(currentCategory);
			setNews(fetchedNews);
			fetchedMainNews = fetchedNews.shift();
			fetchedSecNews = fetchedNews;
		} else if (currentCategory === 'Todas' && currentDate !== '') {
			fetchedNews = await getNewsByDate(currentDate);
			setNews(fetchedNews);
			fetchedMainNews = fetchedNews.shift();
			fetchedSecNews = fetchedNews;
		} else if (currentCategory !== 'Todas' && currentDate !== '') {
			fetchedNews = await getNewsByCategoryAndDate(currentCategory, currentDate);
			setNews(fetchedNews);
			fetchedMainNews = fetchedNews.shift();
			fetchedSecNews = fetchedNews;
		}

		setMainNews(fetchedMainNews);
		setSecNews(fetchedSecNews);
	};

	const fetchCategories = async () => {
		const fetchedCategories = await getNewsCategories();
		setCategories(fetchedCategories);
	};

	const fetchDates = async () => {
		const fetchedNews = await getActiveNews();
		const uniqueDates = Array.from(new Set(fetchedNews.map((n) => n.date)));
		setDates(uniqueDates);
	};

	useEffect(() => {
		fetchCategories();
		fetchDates();
	}, []);

	useEffect(() => {
		fetchData();
	}, [currentCategory, currentDate]);

	const loadAllNews = async () => {
		const allnews = await getActiveNews();
		setNews(allnews);
		setMainNews(allnews.shift());
		setSecNews(allnews);
	};

	const renderSecNews = () => {
		return secundaryNews.map((n) => <SalaDePrensaCard news={n} />);
	};

	return (
		<section className="main-prensa">
			<div className="main-prensa-container">
				<div className="main-prensa-header">
					<h1 className="main-prensa__title">Sala de prensa</h1>
					<SalaDePrensaSelector
						categories={categories}
						setCategory={setCurrentCategory}
						setDate={setCurrentDate}
						dates={dates}
						currentDate={currentDate}
					/>
				</div>
				<div className="main-prensa-big-card-container">
					{/* <SalaDePrensaBigCard category={news[0].category} title={news[0].title} content={news[0].principalContent} date={news[0].date} imgUrl={news[0].imgUrl}/> */}
					{mainNews ? <SalaDePrensaBigCard news={mainNews} /> : <></>}
				</div>
				<div className="main-prensa-cards-container">{renderSecNews()}</div>

				{loadMore === true ? (
					<div
						className="main-prensa-load__link"
						onClick={() => {
							loadAllNews();
							setLoadMore(false);
						}}
					>
						<button>Cargar más</button>
					</div>
				) : (
					<></>
				)}
			</div>
		</section>
	);
}

export default MainSalaDePrensa;
