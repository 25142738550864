import { ErrorMessage } from "@hookform/error-message";
import { useFormContext, Controller } from "react-hook-form";
import SelectComponent from "../../Select/SelectComponent";

import {
  fe_s7_beneficios_anuales,
  fe_s7_nivel_experiencia,
  fe_s7_operaciones_bursatiles,
  fe_s7_plazo_inversion,
  fe_s7_tiempo_operacion,
  fe_s7_total_activos_liquidos,
  fe_s7_variacion_patrimonio,
} from "../../Utility/SelectOptions";
function StartInvestCompanyStep7() {
  const emptyFieldErrorMessage = "Campo obligatorio";
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
		<div className="create__requisitos">
			<h4 className="create__requisitos-title">Informaciones relativas al inversionista</h4>
			<div className="lg:items-end lg:flex lg:space-x-9">
				<div className="mt-6 group-form lg:w-1/2">
					<label className="global-label">Tiempo de operación de la empresa</label>
					<Controller
						control={control}
						name="investorInfoOperationTime"
						rules={{
							required: { value: true, message: emptyFieldErrorMessage },
						}}
						render={({ field: { onChange, value } }) => (
							<SelectComponent
								options={fe_s7_tiempo_operacion}
								value={value}
								onChange={onChange}
								isMulti={false}
								placeHolder="Seleccione una opción"
							/>
						)}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name="investorInfoOperationTime"
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
				<div className="mt-6 group-form lg:w-1/2">
					<label className="global-label">
						¿A qué plazo le gustaría invertir? En otras palabras, ¿Cuánto tiempo está
						dispuesto a esperar para obtener una ganancia de su inversión?
					</label>
					<Controller
						control={control}
						name="investorInfoHoldTime"
						rules={{
							required: { value: true, message: emptyFieldErrorMessage },
						}}
						render={({ field: { onChange, value } }) => (
							<SelectComponent
								options={fe_s7_plazo_inversion}
								value={value}
								onChange={onChange}
								isMulti={false}
								placeHolder="Seleccione una opción"
							/>
						)}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name="investorInfoHoldTime"
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
			</div>
			<div className="lg:items-end lg:flex lg:space-x-9">
				<div className="mt-6 group-form lg:w-1/2">
					<label className="global-label">
						Nivel de Experiencia y Conocimiento en el mercado de valores
					</label>
					<Controller
						control={control}
						name="investorInfoExperienceLevel"
						rules={{
							required: { value: true, message: emptyFieldErrorMessage },
						}}
						render={({ field: { onChange, value } }) => (
							<SelectComponent
								options={fe_s7_nivel_experiencia}
								value={value}
								onChange={onChange}
								isMulti={false}
								placeHolder="Seleccione una opción"
							/>
						)}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name="investorInfoExperienceLevel"
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
				<div className="mt-6 group-form lg:w-1/2">
					<label className="global-label">
						Operaciones Bursátiles y Financieras que ha realizado como inversionista
					</label>
					<Controller
						control={control}
						name="investorInfoStockMarket"
						rules={{
							required: { value: true, message: emptyFieldErrorMessage },
						}}
						render={({ field: { onChange, value } }) => (
							<SelectComponent
								options={fe_s7_operaciones_bursatiles}
								value={value}
								onChange={onChange}
								isMulti={true}
								placeHolder="Seleccione las opciones"
							/>
						)}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name="investorInfoStockMarket"
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
			</div>
			<div className="lg:flex lg:space-x-9 lg:items-end">
				<div className="mt-6 group-form lg:w-1/2">
					<label className="global-label">Beneficios anuales</label>
					<Controller
						control={control}
						name="investorInfoAnnualBenefits"
						rules={{
							required: { value: true, message: emptyFieldErrorMessage },
						}}
						render={({ field: { onChange, value } }) => (
							<SelectComponent
								options={fe_s7_beneficios_anuales}
								value={value}
								onChange={onChange}
								isMulti={false}
								placeHolder="Seleccione una opción"
							/>
						)}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name="investorInfoAnnualBenefits"
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
				<div className="mt-6 group-form lg:w-1/2">
					<label className="global-label">
						Total de activos líquidos (certificados financieros, cuentas corrientes, ahorros,
						entre otros)
					</label>
					<Controller
						control={control}
						name="investorInfoLiquidAssetsTotal"
						rules={{
							required: { value: true, message: emptyFieldErrorMessage },
						}}
						render={({ field: { onChange, value } }) => (
							<SelectComponent
								options={fe_s7_total_activos_liquidos}
								value={value}
								onChange={onChange}
								isMulti={false}
								placeHolder="Seleccione una opción"
							/>
						)}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name="investorInfoLiquidAssetsTotal"
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
			</div>
			<div className="lg:items-end lg:flex lg:space-x-9 lg:pr-9">
				<div className="mt-6 group-form lg:w-1/2">
					<label className="global-label">
						Variación de patrimonio (Activos y Pasivos) en los últimos dos (2) años
					</label>
					<Controller
						control={control}
						name="investorInfoEquityVariation"
						rules={{
							required: { value: true, message: emptyFieldErrorMessage },
						}}
						render={({ field: { onChange, value } }) => (
							<SelectComponent
								options={fe_s7_variacion_patrimonio}
								value={value}
								onChange={onChange}
								isMulti={false}
								placeHolder="Seleccione una opción"
							/>
						)}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name="investorInfoEquityVariation"
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
			</div>
		</div>
  );
}

export default StartInvestCompanyStep7;
