import React from "react";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import AppVariables from "../../../Variables.json";
import RenaudarFormularioEmpresa from "../../pop-up-messages/ReanudarFormularioEmpresa";

function StartInvestCompanyRequirements() {
  const navigate = useNavigate();
  const overlayStyle = { background: "rgba(0,0,0,0.7)" };
  return (
    <React.Fragment>
      <div className="create">
        <div className="container mx-auto">
          <h2 className="create__title create__title--margin">
            Invierte con nosotros
          </h2>
          <h3 className="create__subtitle">Empresa o institución</h3>
        </div>
      </div>
      <div className="create__requisitos">
        <h6 className="create__requisitos-subTitle italic font-base">
          Administradora De Fondos De Inversión
        </h6>
        <h4 className="create__requisitos-title">Requisitos</h4>
        <div className="create__list">
          <p className="list-top">
            <span />
            Estatutos Sociales (Registrados en la Cámara de Comercio y
            Producción correspondiente y Certificados por el Presidente y el
            Secretario).
          </p>
          <p className="list-top">
            <span />
            Documentos constitutivos.
          </p>
          <p className="list-top">
            <span />
            Registro Mercantil Actualizado (Certificado por el Presidente y el
            Secretario).
          </p>
          <p className="list-top">
            <span />
            Certificación emitida por la Oficina Virtual de la DGII que
            certifica que en sus archivos se encuentra registrada la empresa con
            su RNC y con la actividad económica específica.
          </p>
          <p className="list-top">
            <span />
            Acta que designa las personas con capacidad para abrir cuentas de
            corretajeEstados Financieros Auditados en los que se refleje la
            información de los últimos dos años de ejercicio fiscal (Certificada
            por el Presidente y el Secretario).
          </p>
          <p className="list-top">
            <span />
            Última Asamblea General Ordinaria Anual de Accionistas (Registrada
            en la Cámara de Comercio y Producción correspondiente y Certificada
            por el Presidente y el Secretario).
          </p>
          <p className="list-top">
            <span />
            Autorización de la Superintendencia de Valores para operar como
            sociedad administradora de fondos de inversión.
          </p>
          <p className="list-top">
            <span />
            Acta que designa las personas con capacidad para abrir cuentas de
            corretaje (Registrada en la Cámara de Comercio y Producción
            correspondiente y Certificada por el Presidente y el Secretario).
          </p>
          <p className="list-top">
            <span />
            Dos documentos de identidad del representante legal, accionistas y
            de cada firmante.
          </p>
          <p className="list-top">
            <span />
            Copia del Listado de accionistas y composición accionaria.
          </p>
          <p className="list-top">
            <span />
            Autorización de la Superintendencia de Valores para operar como
            sociedad administradora de fondos de inversión.
          </p>
          <p className="list-top">
            <span />
            Acta de Asamblea General Ordinaria de Accionistas que designa el
            último Consejo de Administración (Registrada en la Cámara de
            Comercio y Producción correspondiente y Certificada por el
            Presidente y el Secretario)omo sociedad administradora de fondos de
            inversión.
          </p>
          <p className="list-top">
            <span />
            Carta de Referencia Bancaria (En caso de que NO sea cliente de BPD)
          </p>
        </div>
        <div className="create__note">
          <h5>
            <b>Nota adicional</b>
          </h5>
          <p>
            Todos los documentos constitutivos deben estar vigentes, sellados
            por Cámara de Comercio y Producción correspondiente y Certificados
            por el Presidente y el Secretario.
          </p>
          <p>
            Los documentos podrán estar en original, en copia o por algún medio
            electrónico. Si provienen del extranjero pueden aceptarse siempre
            que estén apostillados, es decir legalizados a nivel internacional;
            y si están en un idioma distinto al español deben estar traducidos
            por un intérprete judicial.
          </p>
          <p>
            Cuando exista diﬁcultad para obtener la documentación requerida para
            identiﬁcar el Beneﬁciario Final, la institución puede proveerle un
            modelo de Declaración de Beneﬁciario Final, que debera ser firmado
            por la persona autorizada por la entidad.
          </p>
          <p>
            Para personas que tengan doble nacionalidad, incluyendo la
            nacionalidad dominicana, se deberan requerir los documentos de
            identidad de cada nacionalidad.
          </p>
        </div>
      </div>
      <div className="px-4 pb-10">
        <div className="grid lg:grid-cols-2 lg:gap-6 create__actions gap-y-6 lg:gap-y-0">
          <a
            className="global-button global-button--full lg:order-last"
            onClick={() =>
              navigate(`${AppVariables.REACT_ROUTER_URL}/formulario-empresa`)
            }
          >
            Nueva solicitud
          </a>
          <Popup
            trigger={
              <a className="global-button global-button--orange global-button--full">
                Reanudar solicitud
              </a>
            }
            position="right center"
            modal
            nested
            {...{ overlayStyle }}
          >
            {/* Call to new ReanudarFormularioEmpresa -> */}
            {(close: undefined) => <RenaudarFormularioEmpresa close={close} />}
          </Popup>
        </div>
      </div>
    </React.Fragment>
  );
}

export default StartInvestCompanyRequirements;
