import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import StartInvestCompanyForm from "../components/forms/start-invest-company-form/StartInvestCompanyForm";
import { getFormProgress } from "../services/FormularioEmpresaServices";

interface StateType {
  code: string;
  lastStep:number;
}

const INITIAL_STATE: any = {};

function CompanyForm() {
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: INITIAL_STATE,
    delayError: 1500,
  });
  const location = useLocation();
  const state = location.state as StateType;

  useEffect(() => {
    state
      ? getFormProgress(state.code).then((value) => {
          methods.reset(value);
        })
      : methods.reset(INITIAL_STATE);
  }, []);

  return (
		<FormProvider {...methods}>
			<StartInvestCompanyForm lastStep={state ? state.lastStep : 1} />
		</FormProvider>
  );
}

export default CompanyForm;
