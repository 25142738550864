import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';

interface IProps {
	year: string;
	rendimientoUltimos30dias: number;
	rendimientoUltimos90dias: number;
	rendimientoUltimos180dias: number;
	rendimientoUltimos365dias: number;
	benchmark: number;
}

function FundMainRendimiento(props: IProps) {
	const navigate = useNavigate();

	return (
		<section className="main-rendimiento">
			<div className="main-rendimiento-container">
				<div className="main-rendimiento-header">
					<h3 className="main-rendimiento-header__title">Rendimiento del fondo</h3>
					<span className="main-rendimiento-header__date">
						<i className="calendar-icon-blue" />
						<b>Año</b> : {props.year}
					</span>
					<a
						className="main-rendimiento__link large"
						onClick={() => navigate(`${AppVariables.REACT_ROUTER_URL}/calculadora`)}
					>
						<i>
							<svg
								aria-hidden="true"
								focusable="false"
								data-prefix="fas"
								data-icon="chevron-right"
								className="svg-inline--fa fa-chevron-right "
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 320 512"
							>
								<path
									fill="currentColor"
									d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
								></path>
							</svg>
						</i>{' '}
						Ir a la calculadora de rendimiento
					</a>
				</div>
				<div className="main-rendimiento-content">
					<div className="main-rendimiento-content-container">
						<div className="main-rendimiento-card">
							<span className="main-rendimiento-card__title">Últimos 30 días</span>
							<span className="main-rendimiento-card__percentage">
								{props.rendimientoUltimos30dias}%
							</span>
						</div>
					</div>
					<div className="main-rendimiento-content-container">
						<div className="main-rendimiento-card">
							<span className="main-rendimiento-card__title">Últimos 90 días</span>
							<span className="main-rendimiento-card__percentage">
								{props.rendimientoUltimos90dias}%
							</span>
						</div>
					</div>
					<div className="main-rendimiento-content-container">
						<div className="main-rendimiento-card">
							<span className="main-rendimiento-card__title">Últimos 180 días</span>
							<span className="main-rendimiento-card__percentage">
								{props.rendimientoUltimos180dias}%
							</span>
						</div>
					</div>
					<div className="main-rendimiento-content-container">
						<div className="main-rendimiento-card">
							<span className="main-rendimiento-card__title">Últimos 365 días</span>
							<span className="main-rendimiento-card__percentage">
								{props.rendimientoUltimos365dias}%
							</span>
						</div>
					</div>
					<div className="main-rendimiento-content-container">
						<div className="main-rendimiento-card">
							<span className="main-rendimiento-card__title">Benchmark</span>
							<span className="main-rendimiento-card__percentage">{props.benchmark}%</span>
						</div>
					</div>
				</div>
				<a className="main-rendimiento__link small">
					<svg
						aria-hidden="true"
						focusable="false"
						data-prefix="fas"
						data-icon="chevron-right"
						className="svg-inline--fa fa-chevron-right "
						role="img"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 320 512"
					>
						<path
							fill="currentColor"
							d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
						></path>
					</svg>{' '}
					Ir a la calculadora de rendimiento
				</a>
			</div>
		</section>
	);
}

export default FundMainRendimiento;
