import BlogReadDto from '../../dtos/BlogReadDto';
import BlogBigCard from './BlogBigCard';
import BlogCard from './BlogCard';

interface IProps {
	principalBlog: BlogReadDto;
	secundaryBlogs: BlogReadDto[];
	loadAllBlogs: () => Promise<void>;
	Blogs: BlogReadDto[];
	loadMore: boolean;
	setLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
}

function BlogContainer({
	principalBlog,
	secundaryBlogs,
	loadAllBlogs,
	Blogs,
	loadMore,
	setLoadMore,
}: IProps) {
	const renderSecundaryBlogs = () => {
		return secundaryBlogs.map((blog) => <BlogCard key={blog.id} blog={blog} />);
	};

	return (
		<section className="main-blog-cards lg:mt-10 mx-[18px] font-['Roboto']">
			<div className="container mx-auto">
				<div className="grid grid-cols-1 gap-8 mt-10 main-blog-cards-container md:grid-cols-2 lg:grid-cols-3">
					{principalBlog ? <BlogBigCard blog={principalBlog} /> : <></>}
					{renderSecundaryBlogs()}
				</div>
				{/* load more start */}
				<div className="load-more-box">
					{loadMore === true ? (
						<div className="container mx-auto">
							<a onClick={() => { loadAllBlogs(); setLoadMore(false) }} className={'global-button'}>
								Cargar Más
							</a>
						</div>
					) : (
						<></>
					)}
				</div>
				{/* load more end */}
			</div>
		</section>
	);
}

export default BlogContainer;
