import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import NewsReadDto from '../../dtos/NewsReadDto';
import { getActiveNews, getTopNNews } from '../../services/NewsServices';
import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';
import HomeNewsCard from './HomeNewsCard';

function News() {
	const [news, setNews] = useState<NewsReadDto[]>([]);
	const navigate = useNavigate();

	const fetchData = async () => {
		const data = await getTopNNews(4);
		setNews(data);
	};

	useEffect(() => {
		fetchData();
	}, []);

	function renderCards() {
		return news.map((c) => <HomeNewsCard news={c} />);
	}

	return (
		<div className="last-news">
			<h1 className="generic-title pb-2">Noticias</h1>
			<div className="last-news-container">{renderCards()}</div>
			<div className="watch-all-news">
				{/*dejar un anchor tag o poner un h4*/}
				<a
					className="generic-watch-all"
					onClick={() => {
						navigate(`${AppVariables.REACT_ROUTER_URL}/sala-de-prensa`);
					}}
				>
					<i>
						<FontAwesomeIcon icon={faChevronRight} />
					</i>{' '}
					<span>Ver todas las noticias</span>
				</a>
			</div>
		</div>
	);
}

export default News;
