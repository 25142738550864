import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDraggable } from 'react-use-draggable-scroll';
import OrganizationalChart from './OrganizationalChart';
import OrgChartDto from './OrgChartDto';
import { getOrgChartNodes, getOrgChartRoot } from './OrgChartServices';

function MainOrgChart() {
	const [rootNode, setRootNode] = useState<OrgChartDto>(new OrgChartDto());
	const [nodes, setNodes] = useState<OrgChartDto[]>([]);

	const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
	const { events } = useDraggable(ref);

	const fetchData = async () => {
		const root = await getOrgChartRoot();
		setRootNode(root);
		const childnodes = await getOrgChartNodes();
		setNodes(childnodes);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<section className="main-structure">
			<h2 className="main-structure_diagram__title">Estructura Organizacional</h2>

			<div className="main-structure__diagram" {...events} ref={ref}>
				<OrganizationalChart rootNode={rootNode} nodes={nodes} />
			</div>
		</section>
	);
}

export default MainOrgChart;
