import Scrollbars from "react-custom-scrollbars-2";
import ExecutivesReadDto from "../../dtos/ExecutivesReadDto";

interface IProps{
    executive:ExecutivesReadDto;
}

function AboutUsExecutiveCard(props:IProps) {
    return (
			<div className="ejecutivo__card">
				<div>
					<img src={props.executive.imgUrl} alt="" className="ejecutivo__card--img" />
				</div>
				<div>
					<h3 className="ejecutivo__card--title">{props.executive.name}</h3>
					<h4 className="ejecutivo__card--position">{props.executive.rol}</h4>
					<div className="ejecutivo__card--content">
						<Scrollbars>
							<p className="pr-4">{props.executive.description}</p>
						</Scrollbars>
					</div>
				</div>
			</div>
		);
}

export default AboutUsExecutiveCard;