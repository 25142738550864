import blueShare from '../../img/sala-de-prensa-details/blue-share.svg';
import orangeShare from '../../img/sala-de-prensa-details/orange-share.svg';
import blueTwitter from '../../img/sala-de-prensa-details/blue-twitter.svg';
import orangeTwitter from '../../img/sala-de-prensa-details/orange-twitter.svg';
import blueFacebook from '../../img/sala-de-prensa-details/blue-facebook.svg';
import orangeFacebook from '../../img/sala-de-prensa-details/orange-facebook.svg';
import blueLinkedIn from '../../img/sala-de-prensa-details/blue-linkedin.svg';
import orangeLinkedIn from '../../img/sala-de-prensa-details/orange-linkedin.svg';
import blueWhatsapp from '../../img/sala-de-prensa-details/blue-whatsapp.svg';
import orangeWhatsapp from '../../img/sala-de-prensa-details/orange-whatsapp.svg';
import blueEmail from '../../img/sala-de-prensa-details/blue-email.svg';
import orangeEmail from '../../img/sala-de-prensa-details/orange-email.svg';
import blueDoubleT from '../../img/sala-de-prensa-details/blue-doubleT.svg';
import orangeDoubleT from '../../img/sala-de-prensa-details/orange-doubleT.svg';
import {
	FacebookShareButton,
	LinkedinShareButton,
	WhatsappShareButton,
	TwitterShareButton,
	EmailShareButton,
} from 'react-share';

interface IProps {
	imgUrl: string;
	setZoom: React.Dispatch<React.SetStateAction<boolean>>;
	zoom: boolean;
}

function PrensaDetailImage(props: IProps) {
	return (
		<div className="prensa-detail-image-container flex h-[400px] xl:h-[450px] 2xl:h-[500px] 3xl:h-[550px] md:mr-12 xl:mr-[150px] 2xl:mr-[200px] 3xl:mr-[300px] mr-[18px] lg:mr-24">
			<img
				src={props.imgUrl}
				alt="prensa detail image"
				className="object-cover w-full prensa-detail__img "
			/>
			<div className="flex flex-col justify-between w-10 ml-6 prensa-detail-icons-container 3xl:ml-8 3xl:w-12">
				<div className="flex flex-col items-center justify-between w-full gap-5 py-4 bg-white prensa-detail-icons-top 3xl:gap-6">
					<div className="prensa-detail__icon-container">
						<a
							href="#"
							onClick={() => {
								navigator.clipboard.writeText(window.location.href);
								window.alert('url copied!!');
							}}
						>
							<img src={blueShare} alt="icon share" className="prensa-detail__icon" />
							<img
								src={orangeShare}
								alt="icon share"
								className="prensa-detail__icon-hover"
							/>
						</a>
					</div>
					<div className="prensa-detail__icon-container">
						<a href="#">
							<TwitterShareButton url={window.location.href} className="!min-w-0">
								<img src={blueTwitter} alt="icon twitter" className="prensa-detail__icon" />
								<img
									src={orangeTwitter}
									alt="icon twitter"
									className="prensa-detail__icon-hover"
								/>
							</TwitterShareButton>
						</a>
					</div>
					<div className="prensa-detail__icon-container">
						<a href="#">
							<FacebookShareButton className="!min-w-0" url={window.location.href}>
								<img
									src={blueFacebook}
									alt="icon facebook"
									className="prensa-detail__icon"
								/>
								<img
									src={orangeFacebook}
									alt="icon facebook"
									className="prensa-detail__icon-hover"
								/>
							</FacebookShareButton>
						</a>
					</div>
					<div className="prensa-detail__icon-container">
						<a href="#">
							<LinkedinShareButton className="!min-w-0" url={window.location.href}>
								<img
									src={blueLinkedIn}
									alt="icon linkedin"
									className="prensa-detail__icon"
								/>
								<img
									src={orangeLinkedIn}
									alt="icon linkedin"
									className="prensa-detail__icon-hover"
								/>
							</LinkedinShareButton>
						</a>
					</div>
					<div className="prensa-detail__icon-container">
						<a href="#">
							<WhatsappShareButton className="!min-w-0" url={window.location.href}>
								<img
									src={blueWhatsapp}
									alt="icon whatsapp"
									className="prensa-detail__icon"
								/>
								<img
									src={orangeWhatsapp}
									alt="icon whatsapp"
									className="prensa-detail__icon-hover"
								/>
							</WhatsappShareButton>
						</a>
					</div>
					<div className="prensa-detail__icon-container">
						<a href="#">
							<EmailShareButton className="!min-w-0" url={window.location.href}>
								<img src={blueEmail} alt="icon email" className="prensa-detail__icon" />
								<img
									src={orangeEmail}
									alt="icon email"
									className="prensa-detail__icon-hover"
								/>
							</EmailShareButton>
						</a>
					</div>
				</div>
				<div className="flex items-center justify-center h-10 bg-white prensa-detail-icon-bottom">
					<div className="prensa-detail__icon-container" onClick={() => props.setZoom(props.zoom === false? true:false)}>
						<a href="#">
							<img src={blueDoubleT} alt="icon double T" className="prensa-detail__icon" />
							<img
								src={orangeDoubleT}
								alt="icon double T"
								className="prensa-detail__icon-hover"
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PrensaDetailImage;
