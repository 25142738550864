import axios from 'axios';
import AppVariables from '../Variables.json';
import PodcastParticipans from '../dtos/PodcastParticipans';
import PodcastReadDto from '../dtos/PodcastReadDto';
import { getItemAttachmentFileById } from './GenericServices';
import { getRand } from '../util/util';
import { AxiosError, AxiosResponse } from '../dtos/types/Axios';
import { SharePointConsts } from '../util/constants';

// Refactored to use a single function for getting podcasts with customizable filters
async function fetchPodcasts(filter: string, topN: number = 0): Promise<PodcastReadDto[]> {
	let url = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Podcast')/items?$expand=AttachmentFiles&$filter=(${filter})&$orderby=Orden desc,Fechadepublicacion desc`;
	if (topN > 0) {
		url += `&$top=${topN}`;
	}
	try {
		const response = await axios.get(url, {
			headers: {
				'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
					.value,
				Accept: SharePointConsts.acceptVerbose,
			},
		});
		return await Promise.all(
			response.data.d.results.map(async (item:any) => await constructPodcastReadDto(item))
		);
	} catch (error) {
		console.error(error);
		return [];
	}
}

// New function to create a PodcastReadDto from an item
async function constructPodcastReadDto(item:any): Promise<PodcastReadDto> {
	const podcast = new PodcastReadDto();
	podcast.id = item.Id;
	podcast.title = item.Title;
	podcast.content = item.Contenido;
	podcast.date = item.Fechadepublicacion;
	podcast.duration = item.Duracion;
	podcast.author = item.Autor;
	podcast.episode = item.Episodio;
	podcast.imgUrl = item.AttachmentFiles?.results[0]?.ServerRelativeUrl || '';
	podcast.googleUrl = item.Google_x0020_podcast?.Url;
	podcast.appleUrl = item.Apple_x0020_podcast?.Url;
	podcast.spotifyUrl = item.Spotify_x0020_podcast?.Url;
	podcast.participans = await getPodcastParticipans(podcast.id);
	return podcast;
}

export async function getActivePodcast(): Promise<PodcastReadDto[]> {
	return fetchPodcasts("Estado eq 'Activo'");
}

export async function getValidPodcast(id: number): Promise<PodcastReadDto[]> {
	return fetchPodcasts(`Estado eq 'Activo' and ID ne ${id}`);
}

export async function getActivePodcasttopN(n: number): Promise<PodcastReadDto[]> {
	return fetchPodcasts("Estado eq 'Activo'", n);
}

export async function getPodcastById(id: number): Promise<PodcastReadDto> {
	const podcasts = await fetchPodcasts(`Id eq ${id}`);
	return podcasts.length > 0 ? podcasts[0] : new PodcastReadDto();
}

export async function getPodcastByTitle(title: string): Promise<PodcastReadDto> {
	const podcasts = await fetchPodcasts(`Title eq '${title}'`);
	return podcasts.length > 0 ? podcasts[0] : new PodcastReadDto();
}

export async function getPodcastParticipans(podcastId: number): Promise<PodcastParticipans[]> {
	try {
		const response = await axios.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Podcast Integrantes')/items?$filter=(PodcastId eq ${podcastId})`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		);
		return await Promise.all(
			response.data.d.results.map(async (item:any) => await constructPodcastParticipans(item))
		);
	} catch (error) {
		console.error(error);
		return [];
	}
}

// New function to create a PodcastParticipans from an item
async function constructPodcastParticipans(item:any): Promise<PodcastParticipans> {
	const participan = new PodcastParticipans();
	participan.id = item.Id;
	participan.name = item.Nombre;
	participan.category = item.Categoria;
	participan.biography = item.Biografia;
	const file = await getItemAttachmentFileById('Podcast Integrantes', participan.id);
	participan.imgUrl = file.url;
	return participan;
}

export async function getRandomPodcast(podcastId: number): Promise<PodcastReadDto[]> {
	const podcast = await getValidPodcast(podcastId);
	return [...podcast].sort(() => 0.5 - getRand()).slice(0, 4);
}

export async function getPodcastsByDate(date: string): Promise<PodcastReadDto[]> {
	return fetchPodcasts(`Estado eq 'Activo' and Fechadepublicacion eq '${date}'`);
}
