import { useDraggable } from 'react-use-draggable-scroll';
import ExecutiveOption from './ExecutiveOption';
import { useRef } from 'react';

interface IProps {
	areas: string[];
	setCurrentArea: React.Dispatch<React.SetStateAction<string>>;
}

function ExecutivesFilter(props: IProps) {

	const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
	const { events } = useDraggable(ref);

	function renderOptions() {
		return props.areas.map((area, i) => (
			<ExecutiveOption
				key={area}
				id={(i + 1).toString()}
				area={area}
				setCurrentArea={props.setCurrentArea}
			/>
		));
	}

	return (
		<div id="GCOptionsContainer" className="main-executives-options" {...events} ref={ref}>
			{renderOptions()}
		</div>
	);
}

export default ExecutivesFilter;
