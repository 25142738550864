import React from "react";
import RecomendadorOption from "./RecomendadorOption";

interface IProps {
  currentCategory: string;
  setCurrentCategory: React.Dispatch<React.SetStateAction<string>>;
  tiposDeActivo: string[];
  Niveles: string[];
  Plazos: string[];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function RecomendadorFilter({
  handleChange,
  tiposDeActivo,
  Niveles,
  Plazos,
  currentCategory,
  setCurrentCategory,
}: IProps) {
  const TipoDeActivoCategory = "Tipodeactivo";
  const NivelDeRiesgoCategory = "Nivelderiesgo";
  const PlazoInverisonCategory = "Plazodeinversion";

  const fileSectionActive =
    "file-section__file-choser--button-active cursor-pointer";
  const fileSectionInactive = "file-section__file-choser--button ";

  const renderTiposDeActivos = () => {
    return tiposDeActivo.map((o) => (
      <RecomendadorOption
        currentCategory="Tipodeactivo"
        option={o}
        handleChange={handleChange}
      />
    ));
  };

  const renderNiveles = () => {
    return Niveles.map((o) => (
      <RecomendadorOption
        currentCategory="Nivelderiesgo"
        option={o}
        handleChange={handleChange}
      />
    ));
  };

  const renderPlazos = () => {
    return Plazos.map((o) => (
      <RecomendadorOption
        currentCategory="Plazodeinversion"
        option={o}
        handleChange={handleChange}
      />
    ));
  };

  return (
    <React.Fragment>
      <div className="flex bg-[#00235c29] p-2.5 rounded-[31px] comparador__box--container">
        <div
          className={
            currentCategory === TipoDeActivoCategory
              ? fileSectionActive
              : fileSectionInactive
          }
          onClick={() => {
            setCurrentCategory(TipoDeActivoCategory);
          }}
        >
          Tipo de Activos
        </div>
        <div
          className={
            currentCategory === NivelDeRiesgoCategory
              ? fileSectionActive
              : fileSectionInactive
          }
          onClick={() => {
            setCurrentCategory(NivelDeRiesgoCategory);
          }}
        >
          Nivel de riesgo
        </div>
        <div
          className={
            currentCategory === PlazoInverisonCategory
              ? fileSectionActive
              : fileSectionInactive
          }
          onClick={() => {
            setCurrentCategory(PlazoInverisonCategory);
          }}
        >
          Plazo de la inversión
        </div>
      </div>

      {currentCategory === TipoDeActivoCategory && (
        <form className="checkbox-list">{renderTiposDeActivos()}</form>
      )}
      {currentCategory === NivelDeRiesgoCategory && (
        <form className="checkbox-list">{renderNiveles()}</form>
      )}
      {currentCategory === PlazoInverisonCategory && (
        <form className="checkbox-list">{renderPlazos()}</form>
      )}
    </React.Fragment>
  );
}

export default RecomendadorFilter;
