import axios from 'axios';
import AppVariables from '../Variables.json';
import EbookReadDto from '../dtos/EbookReadDto';

export async function getLastEbooks(numOfEbooks: number): Promise<EbookReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Ebooks')/items?$expand=AttachmentFiles&$orderby=Orden desc&$top=${numOfEbooks}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: 'application/json; odata=verbose',
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const ebooks: EbookReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const ebook = new EbookReadDto();

		ebook.id = data.d.results[index].ID;
		ebook.autor = data.d.results[index].Autor;
		ebook.title = data.d.results[index].Title;
		ebook.descripcion = data.d.results[index].Descripcion;

		const filename: string = data.d.results[index].AttachmentFiles.results[0].FileName;
		const splitFilename: string[] = filename.split('.');
		const fileExtension = splitFilename[splitFilename.length - 1];

		if (
			fileExtension === 'pdf' ||
			fileExtension === 'PDF' ||
			fileExtension === 'EPUB' ||
			fileExtension === 'epub' ||
			fileExtension === 'MOBI' ||
			fileExtension === 'mobi'
		) {
			if (data.d.results[index].AttachmentFiles.results.length > 1) {
				ebook.pdfUrl = data.d.results[index].AttachmentFiles.results[0].ServerRelativeUrl;
				ebook.portadaUrl = data.d.results[index].AttachmentFiles.results[1].ServerRelativeUrl;
			} else {
				ebook.pdfUrl = '';
				ebook.portadaUrl = '';
			}
		} else {
			if (data.d.results[index].AttachmentFiles.results.length > 1) {
				ebook.pdfUrl = data.d.results[index].AttachmentFiles.results[1].ServerRelativeUrl;
				ebook.portadaUrl = data.d.results[index].AttachmentFiles.results[0].ServerRelativeUrl;
			} else {
				ebook.pdfUrl = '';
				ebook.portadaUrl = '';
			}
		}

		ebooks[index] = ebook;
	}

	return ebooks;
}

export async function getAllEbooks(): Promise<EbookReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Ebooks')/items?$expand=AttachmentFiles&$orderby=Orden desc`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: 'application/json; odata=verbose',
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const ebooks: EbookReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const ebook = new EbookReadDto();

		ebook.id = data.d.results[index].ID;
		ebook.autor = data.d.results[index].Autor;
		ebook.title = data.d.results[index].Title;
		ebook.descripcion = data.d.results[index].Descripcion;

		const filename: string = data.d.results[index].AttachmentFiles.results[0].FileName;
		const splitFilename: string[] = filename.split('.');
		const fileExtension = splitFilename[splitFilename.length - 1];

		if (
			fileExtension === 'pdf' ||
			fileExtension === 'PDF' ||
			fileExtension === 'EPUB' ||
			fileExtension === 'epub' ||
			fileExtension === 'MOBI' ||
			fileExtension === 'mobi'
		) {
			if (data.d.results[index].AttachmentFiles.results.length > 1) {
				ebook.pdfUrl = data.d.results[index].AttachmentFiles.results[0].ServerRelativeUrl;
				ebook.portadaUrl = data.d.results[index].AttachmentFiles.results[1].ServerRelativeUrl;
			} else {
				ebook.pdfUrl = '';
				ebook.portadaUrl = '';
			}
		} else {
			if (data.d.results[index].AttachmentFiles.results.length > 1) {
				ebook.pdfUrl = data.d.results[index].AttachmentFiles.results[1].ServerRelativeUrl;
				ebook.portadaUrl = data.d.results[index].AttachmentFiles.results[0].ServerRelativeUrl;
			} else {
				ebook.pdfUrl = '';
				ebook.portadaUrl = '';
			}
		}

		ebooks[index] = ebook;
	}

	return ebooks;
}
