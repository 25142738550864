import AppVariables from '../Variables.json';

class CarouselReadDto {
	public id: number;
	public category: string;
	public order: number;

	private _imgUrl: string;
	public get imgUrl(): string {
		return this._imgUrl;
	}
	public set imgUrl(value: string) {
		this._imgUrl = AppVariables.BASE_URL + value;
	}


    public subtitle:string;
    public title:string;
    public content:string;
    public buttonText:string;
    public buttonUrl:string;
    public icon:string;
}

export default CarouselReadDto;
