import AppVariables from '../Variables.json';

class AboutUsVideoReadDto {
	public id: number;

	private _videoUrl: string;
	public get videoUrl(): string {
		return this._videoUrl;
	}
	public set videoUrl(value: string) {
		this._videoUrl = AppVariables.BASE_URL + value;
	}

	public posterUrl: string;
}

export default AboutUsVideoReadDto;
