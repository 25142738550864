import axios from 'axios';
import AppVariables from '../Variables.json';
import PreguntaReadDto from '../dtos/PreguntaReadDto';
import RespuestaReadDto from '../dtos/RespuestaReadDto';

const headersAccept = 'application/json; odata=verbose';

export async function getAllQuestions(): Promise<PreguntaReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Perfil de riesgo preguntas')/items?$filter=Estado eq 'Activo'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const questions: PreguntaReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const question = new PreguntaReadDto();

		question.id = data.d.results[index].ID;
		question.question = data.d.results[index].Pregunta;
		question.answers = await getAnswersByQuestion(question.question);

		questions[index] = question;
	}
	return questions;
}

export async function getAnswersByQuestion(question: string): Promise<RespuestaReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Perfil de riesgo opciones')/items?$select=*,Pregunta/Pregunta&$expand=Pregunta&$filter=Pregunta/Pregunta eq '${question}'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const Answers: RespuestaReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const Answer = new RespuestaReadDto();

		Answer.id = data.d.results[index].ID;
		Answer.option = data.d.results[index].opcion;
		Answer.value = data.d.results[index].Valor;

		Answers[index] = Answer;
	}
	return Answers;
}
