import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import DatePickerComponent from "../../datepicker/DatePickerComponent";
import SelectComponent from "../../Select/SelectComponent";
import {
  fe_s5_asociacion_sin_fines_lucro,
  fe_s5_formularios,
  fe_s5_pais_residencia,
  fe_s5_societario,
} from "../../Utility/SelectOptions";
import { validations } from "../Validations/regexValidations";

function StartInvestCompanyStep5() {
  const emptyFieldErrorMessage = "Campo obligatorio";
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const politicallyExposed = useWatch({ control, name: "politicallyExposed" });
  const statusFATCALessThan24Operation = useWatch({
    control,
    name: "statusFATCALessThan24Operation",
  });

  return (
		<React.Fragment>
			<div className="mb-6 create__requisitos">
				<h4 className="create__requisitos-title">
					Persona políticamente expuesta
					<span className="text-xl fa fa-question-circle"></span>
				</h4>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">
							¿Es alguno de los accionistas, firmantes, socios, gerentes o miembros de
							Consejo una persona políticamente expuesta, según la definición de normativa
							vigente?
						</label>
						<div className="mb-4 document-box">
							<input
								id="toggle-on-politica-si-4"
								className="toggle toggle-left"
								type="radio"
								value="Si"
								{...register('politicallyExposed', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="toggle-on-politica-si-4" className="btn">
								Si
							</label>
							<input
								id="toggle-off-politica-no-4"
								className="toggle toggle-right"
								type="radio"
								value="No"
								{...register('politicallyExposed', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="toggle-off-politica-no-4" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				{politicallyExposed === 'Si' && (
					<>
						<div className="lg:items-end lg:flex lg:space-x-9">
							<div className="mb-4 group-form lg:w-1/2">
								<label className="global-label">Nombre</label>
								<input
									type="text"
									maxLength={40}
									className="global-input"
									placeholder="Nombre"
									{...register('politicallyExposedName', {
										required: {
											value: politicallyExposed === 'Si' ? true : false,
											message: emptyFieldErrorMessage,
										},
										pattern: validations.letrasYespacios,
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="politicallyExposedName"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
							<div className="mb-4 group-form lg:w-1/2 lg:pr-4">
								<label className="global-label">Cargo desempeñado</label>
								<input
									type="text"
									maxLength={20}
									className="global-input"
									placeholder="Cargo desempeñado"
									{...register('politicallyExposedPositionHeld', {
										required: {
											value: politicallyExposed === 'Si' ? true : false,
											message: emptyFieldErrorMessage,
										},
										pattern: validations.letrasYespacios,
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="politicallyExposedPositionHeld"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
						</div>
						<div className="lg:items-end lg:flex lg:space-x-9">
							<div className="mb-4 group-form lg:w-1/2">
								<label className="global-label">Vinculación</label>
								<input
									type="text"
									maxLength={40}
									className="global-input"
									placeholder="Vinculación"
									{...register('politicallyExposedBonding', {
										required: {
											value: politicallyExposed === 'Si' ? true : false,
											message: emptyFieldErrorMessage,
										},
										pattern: validations.letrasYespacios,
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="politicallyExposedBonding"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
							<div className="mb-4 group-form lg:w-1/2 lg:pr-4">
								<label className="global-label">País</label>
								<input
									type="text"
									maxLength={20}
									className="global-input"
									placeholder="Cargo desempeñado"
									{...register('politicallyExposedCountry', {
										required: {
											value: politicallyExposed === 'Si' ? true : false,
											message: emptyFieldErrorMessage,
										},
										pattern: validations.letrasYespacios,
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="politicallyExposedCountry"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
						</div>
						<div className="lg:items-end lg:flex lg:space-x-9">
							<div className="mb-4 comparador__select-block md:w-1/2">
								<label className="global-label">Indique el periodo (desde - hasta)</label>
								<div className="flex flex-row gap-2">
									<>
										<Controller
											control={control}
											name="politicallyExposedPeriodStart"
											rules={{
												required: {
													value: politicallyExposed === 'Si',
													message: emptyFieldErrorMessage,
												},
											}}
											render={({ field: { onChange, value } }) => (
												<DatePickerComponent onChange={onChange} currValue={value} />
											)}
										/>
										<div className="h-6 w-full">
											<ErrorMessage
												errors={errors}
												name="politicallyExposedPeriodStart"
												render={({ message }) => (
													<p className="text-red-600 ml-1">{message}</p>
												)}
											/>
										</div>
									</>

									<span className="flex justify-center items-center text-base"> - </span>
									<>
										<Controller
											control={control}
											name="politicallyExposedPeriodEnd"
											rules={{
												required: {
													value: politicallyExposed === 'Si',
													message: emptyFieldErrorMessage,
												},
											}}
											render={({ field: { onChange, value } }) => (
												<DatePickerComponent onChange={onChange} currValue={value} />
											)}
										/>
										<div className="h-6 w-full">
											<ErrorMessage
												errors={errors}
												name="politicallyExposedPeriodEnd"
												render={({ message }) => (
													<p className="text-red-600 ml-1">{message}</p>
												)}
											/>
										</div>
									</>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
			<div className="mb-6 create__requisitos">
				<h4 className="create__requisitos-title">
					Identificación de estatus FATCA{' '}
					<span className="text-xl fa fa-question-circle"></span>
				</h4>
				<div className="lg:items-start lg:flex lg:space-x-9">
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿Tiene la empresa teléfono en Estados Unidos?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-empresa-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCAHasUSPhone', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-empresa-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-empresa-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCAHasUSPhone', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-empresa-no" className="btn">
								No
							</label>
						</div>
					</div>
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿Mantiene instrucciones para realizar transferencias recurrentes a Estados
							Unidos o una cuenta en el referido país?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-transferencias-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCACanMakeUSTransfer', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-transferencias-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-transferencias-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCACanMakeUSTransfer', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-transferencias-no" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				<div className="lg:items-start lg:flex lg:space-x-9">
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿Tiene un apoderado o firmante en la cuenta con dirección en los Estados
							Unidos?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-politica-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCAHasAccountPerson', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-politica-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-politica-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCAHasAccountPerson', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-politica-no" className="btn">
								No
							</label>
						</div>
					</div>
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿Tiene la empresa domicilio registrado en Estados Unidos?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-domicio-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCACompanyUSAddress', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-domicio-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-domicio-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCACompanyUSAddress', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-domicio-no" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				<div className="lg:items-start lg:flex lg:space-x-9">
					<div className="lg:w-1/2 ">
						<label className="global-label">
							La dirección que ha registrado en el banco corresponde a una dirección ¿Retener
							correspondencia / Hold mail, o Por cuenta de / In care of en Estados Unidos?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-direccion-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCABankHoldMail', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-direccion-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-direccion-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCABankHoldMail', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-direccion-no" className="btn">
								No
							</label>
						</div>
					</div>
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿Registrará en el banco un teléfono de Estados Unidos en adición a uno fuera de
							los Estados Unidos?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-banco-no"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCAUSPhoneRegistration', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-banco-no" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-banco-si"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCAUSPhoneRegistration', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-banco-si" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				<div className="lg:items-start lg:flex lg:space-x-9">
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿Tiene la empresa residencia o dirección de correo en Estados Unidos?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-correo-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCACompanyHasUSMailAddress', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-correo-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-correo-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCACompanyHasUSMailAddress', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-correo-no" className="btn">
								No
							</label>
						</div>
					</div>
					<div className="lg:w-1/2 ">
						<label className="global-label">¿Es usted un condominio?</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-condominio-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCAIsCondominium', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-condominio-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-condominio-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCAIsCondominium', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-condominio-no" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-6 group-form lg:w-1/2 lg:mb-0 ">
						<label className="global-label">
							¿Bajo qué tipo de societario está constituida su empresa?
						</label>
						<Controller
							control={control}
							name="statusFATCACorporateType"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fe_s5_societario}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="statusFATCACorporateType"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿Es usted una entidad financiera conforme a la Legislación FATCA ? (ej.: Banco,
							Asociación de Ahorros y Préstamos, Puesto de Bolsa, Fiduciaria, Administradoras
							de Fondos de Inversión, compañías de seguro, vehículos de inversión, entidad
							que recibe depósitos como parte de su actividad ordinaria,entre otras.)
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-fatca-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCAIsFinancialEntity', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-fatca-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-fatca-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCAIsFinancialEntity', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-fatca-no" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-6 group-form lg:w-1/2 lg:mb-0 lg:items-end">
						<label className="global-label">
							¿Es usted una Asociación sin Fines de Lucro constituida fuera de los Estados
							Unidos, Una Asociación Sin Fines de Lucro exenta en los Estados Unidos de
							América o una Organización Internacional? (Debe tener carta de determinación de
							la autoridad fiscal estadounidense, IRS)
						</label>
						<Controller
							control={control}
							name="statusFATCAIsNonProfit"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fe_s5_asociacion_sin_fines_lucro}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="statusFATCAIsNonProfit"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿Su empresa tiene menos de 24 meses de operaciones?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-operaciones-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCALessThan24Operation', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-operaciones-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-operaciones-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCALessThan24Operation', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-operaciones-no" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">¿Cúando inició sus operaciones?</label>
						<input
							type="text"
							maxLength={20}
							className="global-input"
							placeholder="¿Cúando inició sus operaciones?"
							{...register('statusFATCAOperationStart', {
								required: {
									value: statusFATCALessThan24Operation === 'Si' ? true : false,
									message: emptyFieldErrorMessage,
								},
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="statusFATCAOperationStart"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿Cotiza su empresa acciones en un mercado de valores regulado o es afiliada a
							una sociedad que cotiza acciones en un mercado de valores regulado?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-cotiza-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCAListedOnRegulatedMarket', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-cotiza-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-cotiza-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCAListedOnRegulatedMarket', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-cotiza-no" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿Es su empresa una Holding No Financiero (Casa Matriz, Tenedora, etc.)?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-Holding-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCAIsCompanyAHolding', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-Holding-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-holding-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCAIsCompanyAHolding', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-holding-no" className="btn">
								No
							</label>
						</div>
					</div>
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿Lleva a cabo su empresa una actividad comercial consistente en la venta de
							bienes, productos o servicios, generando esta actividad más del 50% de sus
							ingresos brutos anuales?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-ventas-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCAConsistentProductSales', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-ventas-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-ventas-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCAConsistentProductSales', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-ventas-no" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿La actividad principal de su empresa es generar ingresos por inversiones,
							intereses, dividendos, anualidades, regalías u otros instrumentos financieros,
							generando estos más del 50% de los ingresos brutos anuales?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-brutos-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCAMainActivity', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-brutos-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-brutos-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCAMainActivity', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-brutos-no" className="btn">
								No
							</label>
						</div>
					</div>
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿Es su empresa una entidad no financiera Patrocinada o no que reporta
							directamente al Servicio de Rentas Internas de los Estados Unidos de América?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-america-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCAIsNonSponsored', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-america-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-america-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCAIsNonSponsored', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-america-no" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿Es su empresa una entidad de Planes de Retiro o Jubilación exento de
							conformidad al Código de Rentas Internas de los Estados Unidos de América?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-plan-retiro-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCAIsRetirementPlanCompany', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-plan-retiro-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-plan-retiro-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCAIsRetirementPlanCompany', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-plan-retiro-no" className="btn">
								No
							</label>
						</div>
					</div>
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿Es su entidad una sociedad no financiera exenta por quiebra o liquidación
							conforme al Código de Rentas Internas de los Estados Unidos de América?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-quiebra-si"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCAIsExemptedDueToBankruptcy', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-quiebra-si" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-quiebra-no"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCAIsExemptedDueToBankruptcy', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-quiebra-no" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="lg:w-1/2 ">
						<label className="global-label">
							¿Es su entidad una sociedad no financiera constituida en un territorio
							considerado exceptuado por el Código de Rentas Internas de los Estados Unidos
							de América (Puerto Rico, Islas Vírgenes Americanas, Islas Marianas del Norte,
							Guam, Samoa Americana)?
						</label>
						<div className="mb-4 document-box">
							<input
								id="statusFATCA-toggle-on-plotica-si-3"
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register('statusFATCAIsExemptedByUSRevenueCode', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-toggle-on-politica-si-3" className="btn">
								Si
							</label>
							<input
								id="statusFATCA-toggle-off-politica-no-3"
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register('statusFATCAIsExemptedByUSRevenueCode', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor="statusFATCA-toggle-off-politica-no-3" className="btn">
								No
							</label>
						</div>
					</div>
					<div className="mb-6 group-form lg:w-1/2 lg:mb-0 ">
						<label className="global-label">
							¿Le corresponde a usted alguno de los formularios detallados a continuación
							puestos a disposición por el Servicio de Rentas Internas de los Estados Unidos?
						</label>
						<Controller
							control={control}
							name="statusFATCAFormsApply"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fe_s5_formularios}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="statusFATCAFormsApply"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:flex lg:space-x-9 lg:items-end">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label">Clasificación Estatus CRS</label>
						<input
							type="text"
							maxLength={20}
							className="global-input"
							placeholder="Clasificación Estatus CRS"
							{...register('statusFATCAClassificationCRS', {
								required: { value: true, message: emptyFieldErrorMessage },
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="statusFATCAClassificationCRS"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mb-6 group-form lg:w-1/2 lg:mb-0 ">
						<label className="global-label">País de residencia fiscal</label>
						<Controller
							control={control}
							name="statusFATCATaxResidenceCountry"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fe_s5_pais_residencia}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="statusFATCATaxResidenceCountry"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
  );
}

export default StartInvestCompanyStep5;
