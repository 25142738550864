import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import SelectComponent from '../../Select/SelectComponent';
import {
	fp_s7_capacidad_absorber_perdidas,
	fp_s7_destino_ingresos_generados,
	fp_s7_distribucion_activos_liquidos,
	fp_s7_fuente_ingresos,
	fp_s7_porcentaje_patrimonio_total,
	fp_s7_principal_objetivo,
	fp_s7_total_activos_liquidos,
	fp_s7_total_variacion_patrimonio,
} from '../../Utility/SelectOptions';

function StartInvestPersonStep7() {
	const emptyFieldErrorMessage = 'Campo obligatorio';
	const {
		control,
		formState: { errors },
	} = useFormContext();
	return (
		<React.Fragment>
			<div className="create__requisitos">
				<h4 className="create__requisitos-title">Información relativa al Cliente</h4>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">
							¿Cómo genera el capital que desea invertir? (Fuente de ingresos u origen de los
							fondos)
						</label>
						<Controller
							control={control}
							name="incomeSource"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s7_fuente_ingresos}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>

						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="incomeSource"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">
							Total de activos Líquidos (certificados financieros, cuentas corrientes,
							ahorros, entre otros)
						</label>
						<Controller
							control={control}
							name="totalOfLiquidAssets"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s7_total_activos_liquidos}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>

						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="totalOfLiquidAssets"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mt-6 group-form lg:w-1/2 xl:pr-4">
						<label className="global-label">
							Variación de Patrimonio (Activos y Pasivos) en los últimos dos (2) años
						</label>
						<Controller
							control={control}
							name="equityVariation"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s7_total_variacion_patrimonio}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>

						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="equityVariation"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-6 create__requisitos">
				<h4 className="create__requisitos-title">
					Objetivos Financieros Inversionista y tolerancia de riesgo
				</h4>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">Distribución activos líquidos</label>
						<Controller
							control={control}
							name="liquidAssetsDistribution"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s7_distribucion_activos_liquidos}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>

						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="liquidAssetsDistribution"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">
							Destino de los ingresos generados por sus inversiones
						</label>
						<Controller
							control={control}
							name="revenueDestination"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s7_destino_ingresos_generados}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>

						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="revenueDestination"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9 xl:items-end">
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">
							¿Qué porcentaje de su patrimonio total tiene previsto invertir en este momento?
						</label>
						<Controller
							control={control}
							name="investmentEquityPercentage"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s7_porcentaje_patrimonio_total}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>

						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="investmentEquityPercentage"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">
							¿Cuál es su principal objetivo de inversión?
						</label>
						<Controller
							control={control}
							name="investmentObjective"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s7_principal_objetivo}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>

						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="investmentObjective"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
				<div className="lg:items-end lg:flex lg:space-x-9 lg:pr-9">
					<div className="mt-6 group-form lg:w-1/2">
						<label className="global-label">
							De acuerdo con la respuesta anterior, ¿Qué capacidad de absorber pérdidas de
							capital usted entiende tener?
						</label>
						<Controller
							control={control}
							name="capitalLossesAbility"
							rules={{
								required: { value: true, message: emptyFieldErrorMessage },
							}}
							render={({ field: { onChange, value } }) => (
								<SelectComponent
									options={fp_s7_capacidad_absorber_perdidas}
									value={value}
									onChange={onChange}
									isMulti={false}
									placeHolder="Seleccione una opción"
								/>
							)}
						/>

						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="capitalLossesAbility"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default StartInvestPersonStep7;
