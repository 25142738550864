import { ErrorMessage } from "@hookform/error-message";
import { useFormContext, useWatch } from "react-hook-form";
import { validations } from "../Validations/regexValidations";

export default function BankReferencesFinalBeneficiariesContent({
  index,
}: any) {
  const emptyFieldErrorMessage = "Campo obligatorio";
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const baseName = `bankReferencesFinalBeneficiaries[${index}]`;
  const IsSubscribed = useWatch({ control, name: `${baseName}.IsSubscribed` });
  return (
		<div className="mt-6 create__requisitos">
			<h4 className="create__requisitos-title">Referencias Bancarias Beneficiarios Finales</h4>
			<div className="lg:items-end lg:flex lg:space-x-9">
				<div className="mb-4 group-form lg:w-1/2">
					<label className="global-label">Nombre</label>
					<input
						type="text"
						maxLength={40}
						className="global-input"
						placeholder="Nombre"
						{...register(`${baseName}.Name`, {
							required: { value: true, message: emptyFieldErrorMessage },
							pattern: validations.letrasYespacios,
						})}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name={`${baseName}.Name`}
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
				<div className="mb-4 group-form lg:w-1/2">
					<label className="global-label">Banco</label>
					<input
						type="text"
						maxLength={60}
						className="global-input"
						placeholder="Banco"
						{...register(`${baseName}.Bank`, {
							required: { value: true, message: emptyFieldErrorMessage },
							pattern: validations.letrasYespacios,
						})}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name={`${baseName}.Bank`}
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
			</div>
			<div className="lg:items-end lg:flex lg:space-x-9">
				<div className="mb-4 group-form lg:w-1/2">
					<label className="global-label">Tipo de Cuenta</label>
					<input
						type="text"
						maxLength={60}
						className="global-input"
						placeholder="Tipo de Cuenta"
						{...register(`${baseName}.AccountType`, {
							required: { value: true, message: emptyFieldErrorMessage },
							pattern: validations.letrasYespacios,
						})}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name={`${baseName}.AccountType`}
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
				<div className="mb-4 group-form lg:w-1/2">
					<label className="global-label">Número de Cuenta</label>
					<input
						type="text"
						maxLength={60}
						className="global-input"
						placeholder="Número de Cuenta"
						{...register(`${baseName}.AccountNumber`, {
							required: { value: true, message: emptyFieldErrorMessage },
							pattern: validations.letrasNumerosEspacios,
						})}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name={`${baseName}.AccountNumber`}
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
			</div>
			<div className="lg:items-start lg:flex lg:space-x-9">
				<div className="mb-4 group-form lg:w-1/2">
					<div className="">
						<label className="global-label">
							¿Está inscrito en algún gremio comercial, industrial o profesional?
						</label>
						<div className="document-box">
							<input
								id={`${baseName}.-provedor-estado-si`}
								className="toggle toggle-left"
								value="Si"
								type="radio"
								{...register(`${baseName}.IsSubscribed`, {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor={`${baseName}.-provedor-estado-si`} className="btn">
								Si
							</label>
							<input
								id={`${baseName}.-provedor-estado-no`}
								className="toggle toggle-right"
								value="No"
								type="radio"
								{...register(`${baseName}.IsSubscribed`, {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
							/>

							<label htmlFor={`${baseName}.-provedor-estado-no`} className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				<div className="mb-4 group-form lg:w-1/2">
					<label className="global-label">¿Cuál?</label>
					<input
						type="text"
						maxLength={20}
						className="global-input"
						placeholder="¿Cuál?"
						{...register(`${baseName}.Which`, {
							required: {
								value: IsSubscribed === 'Si' ? true : false,
								message: emptyFieldErrorMessage,
							},
							pattern: validations.letrasYespacios,
						})}
						disabled={IsSubscribed === 'No'}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={errors}
							name={`${baseName}.Which`}
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
			</div>
		</div>
  );
}
