import React,{ useEffect, useState } from 'react';
import { getAllTiposSolicitud, postNewContact } from '../../services/ContactanosServices';
import { v4 as uuid } from 'uuid';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SelectComponent from '../Select/SelectComponent';
import Popup from 'reactjs-popup';
import FormSent from '../pop-up-messages/FormSent';
import { ValidationMessages } from '../Utility/ValidationMessages';

function MainContactanos() {
	const emptyFieldErrorMessage = 'Campo obligatorio!';
	const [captcha, setCaptcha] = useState('');
	const [code, setCode] = useState('');
	const [openFS, setOpenFS] = useState(false);
	const [captchaErrorMessage, setCaptchaErrorMessage] = useState(false);
	const showCaptchaErrorMessage = () => setCaptchaErrorMessage(true);
	const [options, setOptions] = useState([]);
	const [numIdPattern, setNumIdPattern] = useState<RegExp>(/^\d{11}/);

	const closeModalFS = () => setOpenFS(false);
	const overlayStyle = { background: 'rgba(0,0,0,0.7)' };
	const fetchData = async () => {
		const categories = await getAllTiposSolicitud();

		setOptions(
			categories.map((category) => ({
				value: category,
				label: category,
			}))
		);
	};

	useEffect(() => {
		setCaptcha(uuid().slice(0, 8).toString());
		fetchData();
	}, []);

	const updateCaptcha = () => {
		setCaptcha(uuid().slice(0, 8).toString());
	};

	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
		getValues,
	} = useForm({
		defaultValues: {
			name: '',
			idNo: '',
			idType: 'Cedula',
			message: '',
			email: '',
			recieveInfo: [],
			requestType: '',
		},
	});

	const getNumIdPattern = (idType: string): RegExp => {
		if (idType === 'Cedula') {
			return /^\d{11}/;
		} else {
			return /^[A-Za-z0-9? ,-]+$/i;
		}
	};

	const saveForm = async () => {
		await postNewContact(
			getValues('name'),
			getValues('email'),
			getValues('idType'),
			getValues('idNo'),
			getValues('requestType'),
			getValues('message'),
			getValues('recieveInfo').length === 0 ? 'No' : 'Si'
		);
	};

	return (
		<React.Fragment>
			<div className="decal" />
			<div className="podcast podcast--fix">
				<div className="container mx-auto">
					<h1 className="generic-title generic-title--mb generic-title--left mt-4">Contáctanos</h1>
					<div className="grid-cols-2 lg:grid lg:gap-24 contact-box">
						<div className="map-box xl:h-[540px]">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16583.377777316273!2d-69.9340447953201!3d18.473295268206417!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xead6565a496c72bc!2sAFI%20Popular!5e0!3m2!1ses!2sdo!4v1650910541092!5m2!1ses!2sdo"
								allowFullScreen
								loading="lazy"
								referrerPolicy="no-referrer-when-downgrade"
							/>
						</div>
						<form className="mt-10 lg:mt-0 form-box xl:-translate-y-20">
							<div className="lg:items-end lg:flex lg:space-x-9">
								<div className="group-form lg:w-1/2">
									<label className="global-label">Nombre</label>
									<input
										type="text"
										maxLength={40}
										className="global-input"
										placeholder="Nombre"
										{...register('name', {
											required: {
												value: true,
												message: emptyFieldErrorMessage,
											},
											pattern: {
												value: /^[A-Za-z ]+$/i,
												message: ValidationMessages.invalidCharacters,
											},
										})}
									/>

									<div className="h-6 w-full">
										<ErrorMessage
											errors={errors}
											name="name"
											render={({ message }) => (
												<p className="text-red-600 ml-1">{message}</p>
											)}
										/>
									</div>
								</div>
								<div className="mt-6 lg:mt-0 group-form lg:w-1/2">
									<label className="global-label">Email</label>
									<input
										type="text"
										className="global-input"
										placeholder="ejemplo@correo.com"
										{...register('email', {
											required: {
												value: true,
												message: emptyFieldErrorMessage,
											},
											pattern: {
												value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
												message: 'Correo inválido!',
											},
										})}
									/>

									<div className="h-6 w-full">
										<ErrorMessage
											errors={errors}
											name="email"
											render={({ message }) => (
												<p className="text-red-600 ml-1">{message}</p>
											)}
										/>
									</div>
								</div>{' '}
							</div>
							<div className="mt-6 group-form lg:items-start lg:flex lg:space-x-9">
								<div className="lg:w-1/2">
									<label className="global-label">Tipo de identificación</label>
									<div className="document-box">
										<input
											id="toggle-on"
											className="toggle toggle-left"
											value="Cedula"
											type="radio"
											{...register('idType', {
												onChange: () =>
													setNumIdPattern(getNumIdPattern(getValues('idType'))),
											})}
											defaultChecked
										/>
										<label htmlFor="toggle-on" className="btn">
											Cédula
										</label>
										<input
											id="toggle-off"
											className="toggle toggle-right"
											value="Pasaporte"
											type="radio"
											{...register('idType', {
												onChange: () =>
													setNumIdPattern(getNumIdPattern(getValues('idType'))),
											})}
										/>
										<label htmlFor="toggle-off" className="btn">
											Pasaporte
										</label>
									</div>
								</div>
								<div className="mt-6 lg:w-1/2 lg:m-0">
									<label className="global-label">Número de identificación</label>
									<input
										type="text"
										maxLength={11}
										className="global-input"
										placeholder="000-00000000-0"
										{...register('idNo', {
											required: {
												value: true,
												message: emptyFieldErrorMessage,
											},
											pattern: {
												value: numIdPattern,
												message: ValidationMessages.invalidCharacters,
											},
										})}
									/>

									<div className="h-6 w-full">
										<ErrorMessage
											errors={errors}
											name="idNo"
											render={({ message }) => (
												<p className="text-red-600 ml-1">{message}</p>
											)}
										/>
									</div>
								</div>
							</div>
							<div className="mt-6 group-form">
								<label className="global-label">Tipo de solicitud</label>
								<Controller
									control={control}
									name="requestType"
									rules={{ required: true }}
									render={({ field: { onChange, value } }) => (
										<SelectComponent
											options={options}
											value={value}
											onChange={onChange}
											isMulti={false}
											placeHolder="Seleccione un campo"
										/>
									)}
								/>

								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="requestType"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
							<div className="mt-6 group-form">
								<label className="global-label">Mensaje</label>
								<textarea
									maxLength={256}
									className="global-textarea"
									cols={30}
									rows={10}
									placeholder="Mensaje"
									{...register('message')}
								/>
							</div>
							<div className="lg:items-end lg:flex lg:space-x-9">
								<div className="flex items-center justify-center mt-6 capchat-box lg:w-1/2">
									<h3
										className="capchat-box__text"
										onCopy={(e) => {
											e.preventDefault();
											return false;
										}}
									>
										{captcha}
									</h3>
								</div>
								<div className="items-center hidden mt-6 check-container lg:flex lg:w-1/2">
									<label>
										<input
											className="accent-[#012169]"
											type="checkbox"
											{...register('recieveInfo')}
										/>
										Deseo recibir información de AFI Popular por correo electrónico.
									</label>
									<div className="mt-6 group-form lg:hidden lg:w-1/2">
										<label className="global-label">
											Ingrese los caracteres anteriores
										</label>
										<input
											type="text"
											className="global-input"
											placeholder="Captcha"
											onPaste={(e) => {
												e.preventDefault();
												return false;
											}}
										/>
										<div className="h-6 w-full">
											{captchaErrorMessage && (
												<p className="text-red-600 ml-1">
													Incorrect captcha, try again
												</p>
											)}
										</div>
									</div>
									<div className=" form-group lg:hidden">
										<div className="mt-6 check-container lg:hidden">
											<label>
												<input
													className="accent-[#012169]"
													type="checkbox"
													{...register('recieveInfo')}
												/>
												Deseo recibir información de AFI Popular por correo electrónico.
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="lg:items-start lg:flex lg:space-x-9">
								<div className="mt-6 group-form lg:w-1/2">
									<label className="global-label">Ingrese los caracteres anteriores</label>
									<input
										type="text"
										className="global-input"
										placeholder="Captcha"
										onChange={(e) => setCode(e.target.value)}
									/>
									<div className="h-6 w-full">
										{captchaErrorMessage && (
											<p className="text-red-600 ml-1">Incorrect captcha, try again</p>
										)}
									</div>
								</div>

								<div className=" form-group lg:hidden">
									<div className="mt-6 check-container lg:hidden">
										<label>
											<input
												className="accent-[#012169]"
												type="checkbox"
												{...register('recieveInfo')}
											/>
											Deseo recibir información de AFI Popular por correo electrónico.
										</label>
									</div>
								</div>
								<div className="mt-6 lg:w-1/2 lg:mt-12 enviarFix">
									<>
										<a
											className="global-button"
											onClick={handleSubmit((data) => {
												// console.log(data);
												if (code === captcha) {
													saveForm().then(() => {
														reset();
														updateCaptcha();
														setOpenFS(true);
													});
												} else {
													showCaptchaErrorMessage();
													updateCaptcha();
													window.setTimeout(() => setCaptchaErrorMessage(false), 1500);
												}
											})}
										>
											Enviar
										</a>
										<Popup
											open={openFS}
											position="right center"
											modal
											nested
											onClose={closeModalFS}
											{...{ overlayStyle }}
										>
											<FormSent
												title="Formulario enviado"
												link="contactanos"
												close={closeModalFS}
											/>
										</Popup>
									</>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default MainContactanos;
