import AboutUsVideoReadDto from "../../dtos/AboutUsVideoReadDto";

interface IProps {
  video: AboutUsVideoReadDto;
}

function VideoSection(props: IProps) {
  return (
    <div className="video-section">
      <div className="relative w-[90%]">
        {/* <img
						src={playVideoIcon}
						alt=""
						className="overlay flex items-center justify-center z-20 absolute video-section-playIcon"
					/> */}
        <video
          className="video-section__src object-cover absolute z-10"
          poster={props.video.posterUrl}
          src={props.video.videoUrl}
          controls
        ></video>
      </div>
      {/* <img src="./img/general_img/video-test-img.PNG" class="video-section__bg-img"> */}
    </div>
  );
}

export default VideoSection;
