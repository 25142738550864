import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import BlogDetails from '../components/blog-details/BlogDetails';
import BlogDetailAnotherBlogs from '../components/blog-details/BlogDetailAnotherBlogs';
import BlogReadDto from '../dtos/BlogReadDto';
import { getBlogByTitle, getRandomBlogs } from '../services/BlogServices';

function BlogDetalle() {
	const [blog, setBlog] = useState<BlogReadDto>();
	const [blogs, setBlogs] = useState<BlogReadDto[]>();
	const [searchParams] = useSearchParams();

	const fetchData = async () => {
		const blog1 = await getBlogByTitle(searchParams.get('titulo'));
		const otherBlogs = await getRandomBlogs(blog1.id);
		setBlog(blog1);
		setBlogs(otherBlogs);
	};

	useEffect(() => {
		fetchData();
	}, [searchParams]);

	return (
		<React.Fragment>
			{blog ? <BlogDetails blog={blog} /> : <></>}
			{blogs ? <BlogDetailAnotherBlogs blogs={blogs} /> : <></>}
		</React.Fragment>
	);
}

export default BlogDetalle;
