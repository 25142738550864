import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { validations } from '../Validations/regexValidations';
import { ValidationMessages } from '../../Utility/ValidationMessages';

function FinalCompanyBeneficiaries() {
	const emptyFieldErrorMessage = 'Campo obligatorio';
	const {
		register,
		control,
		getValues,
		formState: { errors },
	} = useFormContext();
	const { append, fields, prepend } = useFieldArray({
		control,
		name: 'finalCompanyBeneficiaries',
	});

	useEffect(() => {
		!fields.length && prepend({});
	}, []);

	const [numIdPattern, setNumIdPattern] = useState<RegExp>();

	const getNumIdPattern = (idType: string): RegExp => {
		if (idType === 'Cedula') {
			return /^\d{11}/;
		} else {
			return /^[A-Za-z0-9? ,-]+$/i;
		}
	};

	return (
		<React.Fragment>
			{fields.map((field, index) => {
				const baseName = `finalCompanyBeneficiaries[${index}]`;
				return (
					<div key={field.id} className="mb-6 create__requisitos">
						<h4 className="create__requisitos-title">Beneficiarios finales de la empresa</h4>
						<div className="group-form lg:items-end lg:flex lg:space-x-9">
							<div className="mb-6 lg:w-1/2 lg:pr-4">
								<label className="global-label">Nombre</label>
								<input
									type="text"
									maxLength={40}
									className="global-input"
									placeholder="Nombre"
									{...register(`${baseName}.Name`, {
										required: { value: true, message: emptyFieldErrorMessage },
										pattern: validations.letrasYespacios,
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name={`${baseName}.Name`}
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
						</div>
						<div className="lg:items-start lg:flex lg:space-x-9">
							<div className="lg:w-1/2 ">
								<label className="global-label">Tipo de identificación</label>
								<div className="mb-4 document-box">
									<input
										id={`indentificacion-si-4-${index}`}
										className="toggle toggle-left"
										name="identificacion"
										value="Cedula"
										type="radio"
										{...register(`${baseName}.IdType`, {
											required: {
												value: true,
												message: emptyFieldErrorMessage,
											},
											onChange: () =>
												setNumIdPattern(
													getNumIdPattern(getValues(`${baseName}.IdType`))
												),
										})}
									/>

									<label htmlFor={`indentificacion-si-4-${index}`} className="btn">
										Cédula
									</label>
									<input
										id={`identificación-no-4-${index}`}
										className="toggle toggle-right"
										name="identificacion"
										value="Pasaporte"
										type="radio"
										{...register(`${baseName}.IdType`, {
											required: {
												value: true,
												message: emptyFieldErrorMessage,
											},
											onChange: () =>
												setNumIdPattern(
													getNumIdPattern(getValues(`${baseName}.IdType`))
												),
										})}
									/>

									<label htmlFor={`identificación-no-4-${index}`} className="btn">
										Pasaporte
									</label>
								</div>
							</div>
							<div className="mb-6 lg:w-1/2 lg:pr-4">
								<label className="global-label">Número de identificación</label>
								<input
									type="text"
									maxLength={11}
									className="global-input"
									placeholder="000-000000-0"
									{...register(`${baseName}.IdNo`, {
										required: { value: true, message: emptyFieldErrorMessage },
										pattern: {
											value: numIdPattern,
											message: ValidationMessages.invalidCharacters,
										},
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name={`${baseName}.IdNo`}
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
						</div>
						<div className="group-form lg:items-end lg:flex lg:space-x-9">
							<div className="mb-6 lg:w-1/2 lg:pr-4">
								<label className="global-label">Comentario</label>
								<input
									type="text"
									maxLength={256}
									className="global-input"
									placeholder="Comentario"
									{...register(`${baseName}.Comment`, {
										required: { value: true, message: emptyFieldErrorMessage },
										pattern: validations.letrasYespacios,
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name={`${baseName}.Comment`}
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
						</div>
					</div>
				);
			})}
			<div className="mt-6 mb-6 text-center create__requisitos">
				<button
					type="button"
					onClick={() => {
						append({});
					}}
					className="mb-4"
				>
					<div className="mx-auto text-[#012169] opacity-50 text-[40px]">+</div>
					<span className="text-[#012169] opacity-50 font-bold mb-3">
						Agregar otro Beneficiario final de la empresa
					</span>
				</button>
			</div>
		</React.Fragment>
	);
}

export default FinalCompanyBeneficiaries;
