import AppVariables from '../Variables.json';

class FundReadDto {
	public id: number;
	public title: string;
	public definicion: string;
	public tipoDeFodo: string;
	public moneda: string;
	public plazoDeInversion?: string;

	private _valorCuota?: string;

	public get valorCuota(): string {
		return this._valorCuota;
	}
	public setValorCuota(value: number) {
		this._valorCuota = value.toLocaleString('en-Us', {
			minimumFractionDigits: 6,
			maximumFractionDigits: 6,
		});
	}

	private _valorCuotaDiaAnterior?: string;
	public get valorCuotaDiaAnterior(): string {
		return this._valorCuotaDiaAnterior;
	}
	public setValorCuotaDiaAnterior(value: number) {
		this._valorCuotaDiaAnterior = value.toLocaleString('en-Us', {
			minimumFractionDigits: 6,
			maximumFractionDigits: 6,
		});
	}

	private _totalCuotas?: string;
	public get totalCuotas(): string {
		return this._totalCuotas;
	}
	public setTotalCuotas(value: number) {
		this._totalCuotas = value.toLocaleString('en-Us', {
			minimumFractionDigits: 6,
			maximumFractionDigits: 6,
		});
	}

	private _patrimonioEnCirculacion?: string;
	public get patrimonioEnCirculacion(): string {
		return this._patrimonioEnCirculacion;
	}
	public setPatrimonioEnCirculacion(value: number) {
		this._patrimonioEnCirculacion = value.toLocaleString('en-Us');
	}

	private _rendimientoUltimos30dias: number;
	public get rendimientoUltimos30dias(): number {
		return this._rendimientoUltimos30dias;
	}
	public set rendimientoUltimos30dias(value: number) {
		this._rendimientoUltimos30dias = Math.round(value * 100) / 100;
	}

	private _rendimientoUltimos90dias?: number;
	public get rendimientoUltimos90dias(): number {
		return this._rendimientoUltimos90dias;
	}
	public set rendimientoUltimos90dias(value: number) {
		this._rendimientoUltimos90dias = Math.round(value * 100) / 100;
	}

	private _rendimientoUltimos180dias?: number;
	public get rendimientoUltimos180dias(): number {
		return this._rendimientoUltimos180dias;
	}
	public set rendimientoUltimos180dias(value: number) {
		this._rendimientoUltimos180dias = Math.round(value * 100) / 100;
	}

	private _rendimientoUltimos365dias?: number;
	public get rendimientoUltimos365dias(): number {
		return this._rendimientoUltimos365dias;
	}
	public set rendimientoUltimos365dias(value: number) {
		this._rendimientoUltimos365dias = Math.round(value * 100) / 100;
	}

	private _benchmark?: number;
	public get benchmark(): number {
		return this._benchmark;
	}
	public set benchmark(value: number) {
		this._benchmark = Math.round(value * 100) / 100;
	}

	private _fecha?: string;
	public get fecha(): string {
		return this._fecha;
	}

	public set fecha(value: string) {
		const newDate = new Intl.DateTimeFormat(['ban', 'id']).format(new Date(value));
		this._fecha = newDate;
	}

	private _year: string;
	public get year(): string {
		return this._year;
	}
	public set year(value: string) {
		const date = new Date(value);
		const year = date.getFullYear();
		this._year = year.toString();
	}

	private _mainImage: string;
	public get mainImage(): string {
		return this._mainImage;
	}
	public set mainImage(value: string) {
		this._mainImage = AppVariables.BASE_URL + value;
	}

	public pagoDividendos: string;
}

export default FundReadDto;
