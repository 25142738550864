import PodcastDetail from '../components/podcast-detalle/PodcastDetail';
import PodcastReadDto from '../dtos/PodcastReadDto';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPodcastByTitle, getRandomPodcast } from '../services/PodcastServices';
import OtherPodcasts from '../components/podcast-detalle/OtherPodcasts';

function PodcastDetalle() {
	const [podcast, setPodcast] = useState<PodcastReadDto>();
	const [othersPodcasts, setOthersPodacts] = useState<PodcastReadDto[]>();
	const [searchParams] = useSearchParams();

	const fetchPodcasts = async () => {
		const fetchedPodcast = await getPodcastByTitle(searchParams.get('titulo'));
		const fetchedPodcasts = await getRandomPodcast(fetchedPodcast.id);
		console.log(fetchedPodcasts);
		setPodcast(fetchedPodcast);
		setOthersPodacts(fetchedPodcasts);
	};

	useEffect(() => {
		fetchPodcasts();
	}, [searchParams]);

	return (
		<React.Fragment>
			{podcast ? <PodcastDetail podcast={podcast} /> : <></>}
			{othersPodcasts ? <OtherPodcasts podcasts={othersPodcasts} /> : <></>}
		</React.Fragment>
	);
}

export default PodcastDetalle;
