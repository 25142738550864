import axios from 'axios';
import AppVariables from '../Variables.json';
import FundReadDto from '../dtos/FundReadDto';
import { getFundsDetailsByID } from './FundServices';

const headersAccept = 'application/json; odata=verbose';

export async function getFundsByType(type: string): Promise<FundReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Recomendador de Fondos')/items?$select=*,Fondo/ID&$expand=Fondo&$filter=Tipodeactivo eq '${type}'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const responseData: FundReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const fund = await getFundsDetailsByID(data.d.results[index].Fondo.ID);
		responseData[index] = fund;
	}

	return responseData;
}

export async function getAllFundsTypes(): Promise<string[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Recomendador de Fondos')/fields?$filter=EntityPropertyName eq 'Tipodeactivo'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	let categories: string[] = [];

	categories = data.d.results[0].Choices.results;

	return categories;
}

export async function getFundsByRiskLEvel(level: string): Promise<FundReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Recomendador de Fondos')/items?$select=*,Fondo/ID&$expand=Fondo&$filter=Nivelderiesgo eq '${level}'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const responseData: FundReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const fund = await getFundsDetailsByID(data.d.results[index].Fondo.ID);
		responseData[index] = fund;
	}

	return responseData;
}

export async function getAllFundsLevels(): Promise<string[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Recomendador de Fondos')/fields?$filter=EntityPropertyName eq 'Nivelderiesgo'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	let categories: string[] = [];

	categories = data.d.results[0].Choices.results;

	return categories;
}

export async function getFundsByInvestmentTerm(term: string): Promise<FundReadDto[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Recomendador de Fondos')/items?$select=*,Fondo/ID&$expand=Fondo&$filter=Plazodeinversion eq '${term}'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const responseData: FundReadDto[] = [];

	for (let index = 0; index < data.d.results.length; index++) {
		const fund = await getFundsDetailsByID(data.d.results[index].Fondo.ID);
		responseData[index] = fund;
	}

	return responseData;
}

export async function getAllFundsTerms(): Promise<string[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Recomendador de Fondos')/fields?$filter=EntityPropertyName eq 'Plazodeinversion'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: headersAccept,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	let categories: string[] = [];

	categories = data.d.results[0].Choices.results;

	return categories;
}
