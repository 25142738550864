import axios from 'axios';
import AppVariables from '../Variables.json';
import { getListMetadata } from './GenericServices';
import { v4 as uuidv4 } from 'uuid';
import FormularioEmpresaReadDto from '../dtos/FormularioEmpresaReadDto';
import {
	fe_s5_asociacion_sin_fines_lucro,
	fe_s5_pais_residencia,
	fe_s7_variacion_patrimonio,
	fe_s8_distribucion_activos,
	fe_s8_frases_identificacion,
	fe_s8_principal_objetivo,
} from '../components/Utility/SelectOptions';
import { SharePointConsts } from '../util/constants';

//generic formulario services
export async function getFormularioEmpresaByCode(
	code: string
): Promise<FormularioEmpresaReadDto | null> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Formulario empresa')/items?$filter=Codigo eq '${code}'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const results = data.d.results[0];
	if (results === undefined || results === null) {
		return null;
	}
	const formulario = new FormularioEmpresaReadDto();

	formulario.id = results?.ID;
	formulario.mail = results?.Correoelectronico;
	formulario.code = results?.Codigo;
	formulario.lastStep = results?.Ultimopaso;
	formulario.status = results?.Estado;

	return formulario;
}

let FORM_ID: any;
let EMAIL: any;

export async function getFormProgress(code: string) {
	return getFormularioEmpresaByCode(code)
		.then(({ id, mail }) => {
			FORM_ID = id;
			EMAIL = mail;
			return Promise.allSettled([
				getFEDatosInformacionDeLaEmpresa(id),
				getFEDatosDomicilioPrincipal(id),
				getFEDatosDomicilioCasaMatriz(id),

				getFEDatosEmpresasAfiliadas(id),
				getFEDatosPrincipalesProveedores(id),
				getFEDatosPrincipalesAccionistas(id),
				getFEDatosDirectores(id),
				getFEDatosInformacionFinanciera(id),
				getFEDatosReferenciasBancariasYComerciales(id),
				getFEDatosSociedadesSinFinesDeLucro(id),

				getFEDatosPersonalDirectivoPersonaJuridica(id),
				getFEDatosSociosYDirectores(id),
				getFEDatosRepresentanteLegal(id),
				getFEDatosReferenciasBancariasRepresentanteLegal(id),

				getFEDatosClientesDeMayorRiesgoDireccionDirectores(id),
				getFEDatosReferenciasBancarias(id),
				getFEDatosReferenciasBancariasBeneficiariosFinales(id),
				getFEDatosOtrosServiciosDeInversion(id),
				getFEDatosOtrasCuentasDeInversion(id),
				getFEDatosZonaGeografica(id),

				getFEDatosPersonaPoliticamenteExpuesta(id),
				getFEDatosIdentificacionEstatusFATCA(id),

				getFEDatosBeneficiarioDeLaInversion(id),
				getFEDatosOrigenDelPatrimonio(id),
				getFEDatosBeneficiariosFinalesDeLaEmpresa(id),
				getFEDatosPersonasRelacionadas(id),
				getFEDatosPersonasAutorizadas(id),

				getFEDatosInformacionesDelInversionista(id),

				getFEDatosObjetivosFinancieros(id),
			]);
		})
		.then((forms) => {
			const combinedForm = forms.reduce((acc, current: any) => {
				return { ...acc, ...current?.value };
			}, {});
			return combinedForm;
		});
}

export async function saveFormProgress(
	progress: any,
	step: number,
	status: string,
	code?: string,
	email?: string
): Promise<any> {
	const stepMethods = [
		[
			postOrUpdateFEDatosInformacionDeLaEmpresa,
			postOrUpdateFEDatosDomicilioPrincipal,
			postOrUpdateFEDatosDomicilioCasaMatriz,
		],
		[
			postOrUpdateFEDatosEmpresasAfiliadas,
			postOrUpdateFEDatosPrincipalesProveedores,
			postOrUpdateFEDatosPrincipalesAccionistas,
			postOrUpdateFEDatosDirectores,
			postOrUpdateFEDatosInformacionFinanciera,
			postOrUpdateFEDatosReferenciasBancariasYComerciales,
			postOrUpdateFEDatosSociedadesSinFinesDeLucro,
		],
		[
			postOrUpdateFEDatosPersonalDirectivoPersonaJuridica,
			postOrUpdateFEDatosSociosYDirectores,
			postOrUpdateFEDatosRepresentanteLegal,
			postOrUpdateFEDatosReferenciasBancariasRepresentanteLegal,
		],
		[
			postOrUpdateFEDatosClientesDeMayorRiesgoDireccionDirectores,
			postOrUpdateFEDatosReferenciasBancarias,
			postOrUpdateFEDatosReferenciasBancariasBeneficiariosFinales,
			postOrUpdateFEDatosOtrosServiciosDeInversion,
			postOrUpdateFEDatosOtrasCuentasDeInversion,
			postOrUpdateFEDatosZonaGeografica,
		],
		[
			postOrUpdateFEDatosPersonaPoliticamenteExpuesta,
			postOrUpdateFEDatosIdentificacionEstatusFATCA,
		],
		[
			postOrUpdateFEDatosBeneficiarioDeLaInversion,
			postOrUpdateFEDatosOrigenDelPatrimonio,
			postOrUpdateFEDatosBeneficiariosFinalesDeLaEmpresa,
			postOrUpdateFEDatosPersonasRelacionadas,
			postOrUpdateFEDatosPersonasAutorizadas,
		],
		[postOrUpdateFEDatosInformacionesDelInversionista],

		[postOrUpdateFEDatosObjetivosFinancieros],
	];

	const selectedMethods = stepMethods.flat();

	if (FORM_ID) {
		updateFormularioEmpresa(FORM_ID, step, status, EMAIL, code).then(() => {
			const savedProgress = selectedMethods.map((method: any) =>
				method({ ...progress, formId: FORM_ID })
			);
			return Promise.all(savedProgress);
		});
	}

	return postFormularioEmpresa(step, status, email, code)
		.then((data) => {
			return data.d.ID;
		})
		.then((formId) => {
			const savedProgress = selectedMethods.map((method: any) =>
				method({ ...progress, formId })
			);
			return Promise.all(savedProgress);
		});
}

export async function saveForm(progress: any, step: number, status: string, code?: string) {
	const stepMethods = [
		postOrUpdateFEDatosInformacionDeLaEmpresa,
		postOrUpdateFEDatosDomicilioPrincipal,
		postOrUpdateFEDatosDomicilioCasaMatriz,
		postOrUpdateFEDatosEmpresasAfiliadas,
		postOrUpdateFEDatosPrincipalesProveedores,
		postOrUpdateFEDatosPrincipalesAccionistas,
		postOrUpdateFEDatosDirectores,
		postOrUpdateFEDatosInformacionFinanciera,
		postOrUpdateFEDatosReferenciasBancariasYComerciales,
		postOrUpdateFEDatosSociedadesSinFinesDeLucro,
		postOrUpdateFEDatosPersonalDirectivoPersonaJuridica,
		postOrUpdateFEDatosSociosYDirectores,
		postOrUpdateFEDatosRepresentanteLegal,
		postOrUpdateFEDatosReferenciasBancariasRepresentanteLegal,
		postOrUpdateFEDatosClientesDeMayorRiesgoDireccionDirectores,
		postOrUpdateFEDatosReferenciasBancarias,
		postOrUpdateFEDatosReferenciasBancariasBeneficiariosFinales,
		postOrUpdateFEDatosOtrosServiciosDeInversion,
		postOrUpdateFEDatosOtrasCuentasDeInversion,
		postOrUpdateFEDatosZonaGeografica,
		postOrUpdateFEDatosPersonaPoliticamenteExpuesta,
		postOrUpdateFEDatosIdentificacionEstatusFATCA,
		postOrUpdateFEDatosBeneficiarioDeLaInversion,
		postOrUpdateFEDatosOrigenDelPatrimonio,
		postOrUpdateFEDatosBeneficiariosFinalesDeLaEmpresa,
		postOrUpdateFEDatosPersonasRelacionadas,
		postOrUpdateFEDatosPersonasAutorizadas,
		postOrUpdateFEDatosInformacionesDelInversionista,
		postOrUpdateFEDatosObjetivosFinancieros,
	];
	const selectedMethods = stepMethods.flat();
	const useCode = code || uuidv4().slice(0, 8);

	if (FORM_ID) {
		return updateFormularioEmpresa(FORM_ID, step, status, EMAIL, code).then(() => {
			const savedProgress = selectedMethods.map((method: any) =>
				method({ ...progress, formId: FORM_ID })
			);
			return Promise.all(savedProgress);
		});
	}

	const { companyInfoEmail } = progress;

	return postFormularioEmpresa(step, status, companyInfoEmail, useCode)
		.then((data) => {
			return data.d.ID;
		})
		.then((formId) => {
			const savedProgress = selectedMethods.map((method: any) =>
				method({ ...progress, formId })
			);
			return Promise.all(savedProgress);
		});
}

//FORMULARIO PERSONA POST AND UPDATE SERVICES
export async function postFormularioEmpresa(
	lastStep: number,
	status: string,
	email?: string,
	code?: string
) {
	const formularioEmpresa = JSON.stringify({
		Correoelectronico: email,
		Codigo: code,
		Ultimopaso: lastStep,
		Estado: status,
		__metadata: { type: await getListMetadata('Formulario empresa') },
	});

	//query
	const query = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Formulario empresa')/items`;

	//config
	const axiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	//post
	return axios
		.post(query, formularioEmpresa, axiosConfig)
		.then((res) => res.data)
		.catch((error) => console.error(error));
}
export async function updateFormularioEmpresa(
	ID: number,
	lastStep: number,
	status: string,
	email?: string,
	code?: string
) {
	const formularioEmpresa = JSON.stringify({
		Correoelectronico: email,
		Codigo: code,
		Ultimopaso: lastStep,
		Estado: status,
		__metadata: { type: await getListMetadata('Formulario empresa') },
	});

	//query
	const query = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Formulario empresa')/items/getbyid(${ID})`;

	//config
	const axiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post
	return axios
		.post(query, formularioEmpresa, axiosConfig)
		.then((res) => res.data)
		.catch((error) => console.error(error));
}

//STEP 1 SERVICES
//get datos personales
export async function getFEDatosInformacionDeLaEmpresa(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Informacion de la empresa')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const results = data.d.results[0];

	const informacionEmpresa = {
		idInformacionEmpresa: results?.ID,
		companyInfoNameSocialReason: results?.NombreoRazonsocial,
		companyInfoCompanyType: results?.Tipodeempresa,
		companyInfoRncNumber: results?.NumerodeRNC,
		companyInfoEstablishmentCountry: results?.Paisdeconstitucion,
		companyInfoEstablishmentDate: new Date(results?.Fechadeconstitucion),
		companyInfoTaxIdOrigin: results?.Identificaciontributaria,
		companyInfoMainEconomicActivity: results?.Actividadeconomica,
		companyInfoCommercialRegCert: results?.Numerodecertificacion,
		companyInfoEmployeesQuantity: results?.Cantidaddeempleados,
		companyInfoOfficePhoneNumber: results?.Telefonodeoficina,
		companyInfoOfficeFaxNumber: results?.Fax,
		companyInfoEmail: results?.Correo,
		companyInfoAdditionalPhoneNumber: results?.Telefonoadicional,
		companyInfoContactPerson: results?.Personadecontacto,
		formId: results?.FormularioIDId,
	};

	return informacionEmpresa;
}
//post and update
export async function postOrUpdateFEDatosInformacionDeLaEmpresa({
	idInformacionEmpresa,
	companyInfoNameSocialReason,
	companyInfoCompanyType,
	companyInfoRncNumber,
	companyInfoEstablishmentCountry,
	companyInfoEstablishmentDate,
	companyInfoTaxIdOrigin,
	companyInfoMainEconomicActivity,
	companyInfoCommercialRegCert,
	companyInfoEmployeesQuantity,
	companyInfoOfficePhoneNumber,
	companyInfoOfficeFaxNumber,
	companyInfoEmail,
	companyInfoAdditionalPhoneNumber,
	companyInfoContactPerson,
	formId,
}: any) {
	const feInformacionEmpresa = JSON.stringify({
		ID: idInformacionEmpresa,
		NombreoRazonsocial: companyInfoNameSocialReason,
		Tipodeempresa: companyInfoCompanyType,
		NumerodeRNC: companyInfoRncNumber,
		Paisdeconstitucion: companyInfoEstablishmentCountry,
		Fechadeconstitucion: companyInfoEstablishmentDate,
		Identificaciontributaria: companyInfoTaxIdOrigin,
		Actividadeconomica: companyInfoMainEconomicActivity,
		Numerodecertificacion: companyInfoCommercialRegCert,
		Cantidaddeempleados: companyInfoEmployeesQuantity,
		Telefonodeoficina: companyInfoOfficePhoneNumber,
		Fax: companyInfoOfficeFaxNumber,
		Correo: companyInfoEmail,
		Telefonoadicional: companyInfoAdditionalPhoneNumber,
		Personadecontacto: companyInfoContactPerson,
		FormularioIDId: formId,
		__metadata: { type: await getListMetadata('FE Informacion de la empresa') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Informacion de la empresa')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Informacion de la empresa')/items/getbyid(${idInformacionEmpresa})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post
	await axios
		.post(
			idInformacionEmpresa === undefined ? postQuery : updateQuery,
			feInformacionEmpresa,
			idInformacionEmpresa === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
///////////////////////////////////////////////////////////////////////////////////
//get Domicilio Principal
export async function getFEDatosDomicilioPrincipal(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Domicilio principal')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	if (data.d.results.length === 0) {
		return null;
	}

	const datosDomicilioPrincipal = {
		idMainDomicile: data.d.results[0]?.ID,
		mainDomicile: data.d.results[0]?.Direccion,
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datosDomicilioPrincipal;
}
//post and update Domicilio Principal
export async function postOrUpdateFEDatosDomicilioPrincipal({
	mainDomicile,
	formId,
	idMainDomicile,
}: any) {
	const feDomicilioPrincipal = JSON.stringify({
		Direccion: mainDomicile,
		FormularioIDId: formId,
		__metadata: { type: await getListMetadata('FE Domicilio principal') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Domicilio principal')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Domicilio principal')/items/getbyid(${idMainDomicile})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idMainDomicile === undefined ? postQuery : updateQuery,
			feDomicilioPrincipal,
			idMainDomicile === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
///////////////////////////////////////////////////////////////////////////////////////
//get datos Domicilio Casa Matriz
export async function getFEDatosDomicilioCasaMatriz(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Domicilio casa matriz')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	if (data.d.results?.length === 0) {
		return null;
	}

	const datosDomicilioCasaMatriz = {
		idHeadquarters: data.d.results[0]?.ID,
		headquartersDomicile: data.d.results[0]?.Direccion,
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datosDomicilioCasaMatriz;
}
//post and update Domicilio Casa Matriz
export async function postOrUpdateFEDatosDomicilioCasaMatriz({
	headquartersDomicile,
	formId,
	idHeadquarters,
}: any) {
	const feDatosDomicilioCasaMatriz = JSON.stringify({
		Direccion: headquartersDomicile,
		FormularioIDId: formId,
		__metadata: { type: await getListMetadata('FE Domicilio casa matriz') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Domicilio casa matriz')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Domicilio casa matriz')/items/getbyid(${idHeadquarters})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idHeadquarters === undefined ? postQuery : updateQuery,
			feDatosDomicilioCasaMatriz,
			idHeadquarters === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
///////////////////////////////////////////////////////////////////////////////////////
//post and update Empresas afiliadas, subsidiarias y compañias relacionadas
export async function postOrUpdateFEDatosEmpresasAfiliadas({
	formId,
	afiliatedCompanies = [],
}: any) {
	const requests = afiliatedCompanies?.map(
		async ({ CompanyName, ContactPerson, ContactNumber, idEmpresasAfiliadas }: any) => {
			const datos = JSON.stringify({
				Nombredelaempresa: CompanyName,
				Personadecontacto: ContactPerson,
				Telefonodecontacto: ContactNumber,
				FormularioIDId: formId,
				__metadata: {
					type: await getListMetadata('FE Empresas afiliadas'),
				},
			});

			//query
			const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Empresas afiliadas')/items`;
			const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Empresas afiliadas')/items/getbyid(${idEmpresasAfiliadas})`;

			//config
			const postAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'IF-MATCH': '*',
				},
			};

			const updateAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'X-HTTP-Method': 'MERGE',
					'IF-MATCH': '*',
				},
			};

			//post or update
			return axios
				.post(
					idEmpresasAfiliadas === undefined ? postQuery : updateQuery,
					datos,
					idEmpresasAfiliadas === undefined ? postAxiosConfig : updateAxiosConfig
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => console.error(error));
		}
	);

	return Promise.all(requests);
}

// get Empresas afiliadas, subsidiarias y compañias relacionadas
export async function getFEDatosEmpresasAfiliadas(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Empresas afiliadas')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datos = data.d.results.map((result: any) => ({
		idEmpresasAfiliadas: result?.ID,
		CompanyName: result?.Nombredelaempresa,
		ContactPerson: result?.Personadecontacto,
		ContactNumber: result?.Telefonodecontacto,
		formId: result?.FormularioIDId,
	}));

	return { afiliatedCompanies: datos };
}

///////////////////////////////////////////////////////////////////////////////////////
//post and update Principales Proveedores o clientes
export async function postOrUpdateFEDatosPrincipalesProveedores({
	formId,
	mainProviders = [],
}: any) {
	const requests = mainProviders?.map(
		async ({ Name, OfferedProducts, IsStateProvider, idMainProviders }: any) => {
			const datos = JSON.stringify({
				Nombredeproveedor: Name,
				Productosqueofrece: OfferedProducts,
				Esproveedordelestado: IsStateProvider,
				FormularioIDId: formId,
				__metadata: {
					type: await getListMetadata('FE Principales proveedores o clientes'),
				},
			});

			//query
			const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Principales proveedores o clientes')/items`;
			const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Principales proveedores o clientes')/items/getbyid(${idMainProviders})`;

			//config
			const postAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'IF-MATCH': '*',
				},
			};

			const updateAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'X-HTTP-Method': 'MERGE',
					'IF-MATCH': '*',
				},
			};

			//post or update
			return axios
				.post(
					idMainProviders === undefined ? postQuery : updateQuery,
					datos,
					idMainProviders === undefined ? postAxiosConfig : updateAxiosConfig
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => console.error(error));
		}
	);

	return Promise.all(requests);
}

// get Principales Proveedores o clientes
export async function getFEDatosPrincipalesProveedores(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Principales proveedores o clientes')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datos = data.d.results.map((result: any) => ({
		idMainProviders: result?.ID,
		Name: result?.Nombredeproveedor,
		OfferedProducts: result?.Productosqueofrece,
		IsStateProvider: result?.Esproveedordelestado,
		formId: result?.FormularioIDId,
	}));

	return { mainProviders: datos };
}
///////////////////////////////////////////////////////////////////////////////////////
//post and update Principales Accionistas (con mas de 10% de acciones)
export async function postOrUpdateFEDatosPrincipalesAccionistas({
	formId,
	mainShareHolders = [],
}: any) {
	const requests = mainShareHolders?.map(
		async ({ Names, ParticipationPercentage, IdNo, idMainShareHolders }: any) => {
			const datos = JSON.stringify({
				Nombresyapellidos: Names,
				Porcentagedeparticipacion: ParticipationPercentage,
				Numerodeidentificacion: IdNo,
				FormularioIDId: formId,
				__metadata: {
					type: await getListMetadata('FE Principales accionistas'),
				},
			});

			//query
			const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Principales accionistas')/items`;
			const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Principales accionistas')/items/getbyid(${idMainShareHolders})`;

			//config
			const postAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'IF-MATCH': '*',
				},
			};

			const updateAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'X-HTTP-Method': 'MERGE',
					'IF-MATCH': '*',
				},
			};

			//post or update
			return axios
				.post(
					idMainShareHolders === undefined ? postQuery : updateQuery,
					datos,
					idMainShareHolders === undefined ? postAxiosConfig : updateAxiosConfig
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => console.error(error));
		}
	);

	return Promise.all(requests);
}

// get Principales Accionistas (con mas de 10% de acciones)
export async function getFEDatosPrincipalesAccionistas(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Principales accionistas')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datos = data.d.results.map((result: any) => ({
		idMainShareHolders: result?.ID,
		Names: result?.Nombresyapellidos,
		ParticipationPercentage: result?.Porcentagedeparticipacion,
		IdNo: result?.Numerodeidentificacion,
		formId: result?.FormularioIDId,
	}));

	return { mainShareHolders: datos };
}
///////////////////////////////////////////////////////////////////////////////////////
//post and update Directores
export async function postOrUpdateFEDatosDirectores({ formId, directors = [] }: any) {
	const requests = directors?.map(
		async ({ Names, ParticipationPercentage, IdNo, idDirectors }: any) => {
			const datos = JSON.stringify({
				Nombresyapellidos: Names,
				Porcentagedeparticipacion: ParticipationPercentage,
				Numerodeidentificacion: IdNo,
				FormularioIDId: formId,
				__metadata: {
					type: await getListMetadata('FE Directores'),
				},
			});

			//query
			const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Directores')/items`;
			const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Directores')/items/getbyid(${idDirectors})`;

			//config
			const postAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'IF-MATCH': '*',
				},
			};

			const updateAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'X-HTTP-Method': 'MERGE',
					'IF-MATCH': '*',
				},
			};

			//post or update
			return axios
				.post(
					idDirectors === undefined ? postQuery : updateQuery,
					datos,
					idDirectors === undefined ? postAxiosConfig : updateAxiosConfig
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => console.error(error));
		}
	);

	return Promise.all(requests);
}

// get Directores
export async function getFEDatosDirectores(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Directores')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datos = data.d.results.map((result: any) => ({
		idDirectors: result?.ID,
		Names: result?.Nombresyapellidos,
		ParticipationPercentage: result?.Porcentagedeparticipacion,
		IdNo: result?.Numerodeidentificacion,
		formId: result?.FormularioIDId,
	}));

	return { directors: datos };
}
///////////////////////////////////////////////////////////////////////////////////////
//get datos Informacion Financiera
export async function getFEDatosInformacionFinanciera(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Informacion financiera')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	if (data.d.results?.length === 0) {
		return null;
	}

	const datosDomicilioCasaMatriz = {
		idFinancialInfo: data.d.results[0]?.ID,
		financialInfoSalesIncome: data.d.results[0]?.Ventasoingresos,
		financialInfoIncomePercentage: data.d.results[0]?.Porcentajeaproximado,
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datosDomicilioCasaMatriz;
}
//post and update Informacion Financiera
export async function postOrUpdateFEDatosInformacionFinanciera({
	financialInfoSalesIncome,
	financialInfoIncomePercentage,
	formId,
	idFinancialInfo,
}: any) {
	const datos = JSON.stringify({
		Ventasoingresos: financialInfoSalesIncome,
		Porcentajeaproximado: financialInfoIncomePercentage,
		FormularioIDId: formId,
		__metadata: { type: await getListMetadata('FE Informacion financiera') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Informacion financiera')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Informacion financiera')/items/getbyid(${idFinancialInfo})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idFinancialInfo === undefined ? postQuery : updateQuery,
			datos,
			idFinancialInfo === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
///////////////////////////////////////////////////////////////////////////////////////
//post and update Referencias bancarias y/o comerciales
export async function postOrUpdateFEDatosReferenciasBancariasYComerciales({
	formId,
	comercialBankReferences = [],
}: any) {
	const requests = comercialBankReferences?.map(
		async ({
			Company,
			AccountCardNumber,
			ContactOfficialPerson,
			idComercialBankReferences,
		}: any) => {
			const datos = JSON.stringify({
				Bancooempresa: Company,
				Numerodecuenta: AccountCardNumber,
				Oficialdelacuenta: ContactOfficialPerson,
				FormularioIDId: formId,
				__metadata: {
					type: await getListMetadata('FE Referencias bancarias y/o comerciales'),
				},
			});

			//query
			const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Referencias bancarias y/o comerciales')/items`;
			const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Referencias bancarias y/o comerciales')/items/getbyid(${idComercialBankReferences})`;

			//config
			const postAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'IF-MATCH': '*',
				},
			};

			const updateAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'X-HTTP-Method': 'MERGE',
					'IF-MATCH': '*',
				},
			};

			//post or update
			return axios
				.post(
					idComercialBankReferences === undefined ? postQuery : updateQuery,
					datos,
					idComercialBankReferences === undefined ? postAxiosConfig : updateAxiosConfig
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => console.error(error));
		}
	);

	return Promise.all(requests);
}

// get Referencias bancarias y/o comerciales
export async function getFEDatosReferenciasBancariasYComerciales(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Referencias bancarias y/o comerciales')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datos = data.d.results.map((result: any) => ({
		idComercialBankReferences: result?.ID,
		Company: result?.Bancooempresa,
		AccountCardNumber: result?.Numerodecuenta,
		ContactOfficialPerson: result?.Oficialdelacuenta,
		formId: result?.FormularioIDId,
	}));

	return { comercialBankReferences: datos };
}
///////////////////////////////////////////////////////////////////////////////////////
//get datos Sociedades sin fines de lucro (ONG)
export async function getFEDatosSociedadesSinFinesDeLucro(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Sociedades sin fines de lucro')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	if (data.d.results?.length === 0) {
		return null;
	}

	const datos = {
		idNonProfit: data.d.results[0]?.ID,
		nonProfitDescriptionObjectives: data.d.results[0]?.Descripcionyobjetivos,
		nonProfitFundSource: data.d.results[0]?.Origendelosfondos,
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datos;
}
//post and update Sociedades sin fines de lucro (ONG)
export async function postOrUpdateFEDatosSociedadesSinFinesDeLucro({
	nonProfitDescriptionObjectives,
	nonProfitFundSource,
	formId,
	idNonProfit,
}: any) {
	const datos = JSON.stringify({
		Descripcionyobjetivos: nonProfitDescriptionObjectives,
		Origendelosfondos: nonProfitFundSource,
		FormularioIDId: formId,
		__metadata: {
			type: await getListMetadata('FE Sociedades sin fines de lucro'),
		},
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Sociedades sin fines de lucro')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Sociedades sin fines de lucro')/items/getbyid(${idNonProfit})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idNonProfit === undefined ? postQuery : updateQuery,
			datos,
			idNonProfit === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
///////////////////////////////////////////////////////////////////////////////////////
//get datos Si el personal directivo o responsable son personas juridicas -Opcional
export async function getFEDatosPersonalDirectivoPersonaJuridica(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Personal directivo personas juridicas')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	if (data.d.results?.length === 0) {
		return null;
	}

	const datos = {
		idLegalPerson: data.d.results[0]?.ID,
		legalPersonNamesSocialReason: data.d.results[0]?.NombreoRazonsocial,
		legalPersonEstablishmentCountry: data.d.results[0]?.Paisdeconstitucion,
		legalPersonEstablishmentDate: new Date(data.d.results[0]?.Fechadeconstitucion),
		legalPersonIdentificationNumber: data.d.results[0]?.Numerodeidentificacion,
		legalPersonOfficePhoneNumber: data.d.results[0]?.Telefonodeoficina,
		legalPersonAdditionalPhoneNumber: data.d.results[0]?.Telefonoadicional,
		legalPersonResponsibleAddress: data.d.results[0]?.Direccion,
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datos;
}
//post and update Si el personal directivo o responsable son personas juridicas -Opcional
export async function postOrUpdateFEDatosPersonalDirectivoPersonaJuridica({
	legalPersonNamesSocialReason,
	legalPersonEstablishmentCountry,
	legalPersonEstablishmentDate,
	legalPersonIdentificationNumber,
	legalPersonOfficePhoneNumber,
	legalPersonAdditionalPhoneNumber,
	legalPersonResponsibleAddress,
	formId,
	idLegalPerson,
}: any) {
	const datos = JSON.stringify({
		NombreoRazonsocial: legalPersonNamesSocialReason,
		Paisdeconstitucion: legalPersonEstablishmentCountry,
		Fechadeconstitucion: legalPersonEstablishmentDate,
		Numerodeidentificacion: legalPersonIdentificationNumber,
		Telefonodeoficina: legalPersonOfficePhoneNumber,
		Telefonoadicional: legalPersonAdditionalPhoneNumber,
		Direccion: legalPersonResponsibleAddress,
		FormularioIDId: formId,
		__metadata: {
			type: await getListMetadata('FE Personal directivo personas juridicas'),
		},
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Personal directivo personas juridicas')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Personal directivo personas juridicas')/items/getbyid(${idLegalPerson})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idLegalPerson === undefined ? postQuery : updateQuery,
			datos,
			idLegalPerson === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
///////////////////////////////////////////////////////////////////////////////////////
//post and update Datos de los Socios y directores
export async function postOrUpdateFEDatosSociosYDirectores({ formId, partnerData = [] }: any) {
	const requests = partnerData?.map(async ({ Names, Type, idPartnerData }: any) => {
		const datos = JSON.stringify({
			Nombresyapellidos: Names,
			Seleccionaeltipo: Type?.value,
			FormularioIDId: formId,
			__metadata: {
				type: await getListMetadata('FE Datos de los socios y directores'),
			},
		});

		//query
		const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Datos de los socios y directores')/items`;
		const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Datos de los socios y directores')/items/getbyid(${idPartnerData})`;

		//config
		const postAxiosConfig = {
			headers: {
				'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
					?.value,
				Accept: SharePointConsts.acceptVerbose,
				'Content-Type': SharePointConsts.contentTypeVerbose,
				'IF-MATCH': '*',
			},
		};

		const updateAxiosConfig = {
			headers: {
				'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
					?.value,
				Accept: SharePointConsts.acceptVerbose,
				'Content-Type': SharePointConsts.contentTypeVerbose,
				'X-HTTP-Method': 'MERGE',
				'IF-MATCH': '*',
			},
		};

		//post or update
		return axios
			.post(
				idPartnerData === undefined ? postQuery : updateQuery,
				datos,
				idPartnerData === undefined ? postAxiosConfig : updateAxiosConfig
			)
			.then((response) => {
				return response.data;
			})
			.catch((error) => console.error(error));
	});

	return Promise.all(requests);
}

// get Datos de los Socios y directores
export async function getFEDatosSociosYDirectores(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Datos de los socios y directores')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datos = data.d.results.map((result: any) => ({
		idPartnerData: result?.ID,
		Names: result?.Nombresyapellidos,
		Type: { value: result?.Seleccionaeltipo, label: result?.Seleccionaeltipo },
		formId: result?.FormularioIDId,
	}));

	return { partnerData: datos };
}
///////////////////////////////////////////////////////////////////////////////////////
//get datos Datos del representante legal - Opcional
export async function getFEDatosRepresentanteLegal(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Datos del representante legal')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	if (data.d.results?.length === 0) {
		return null;
	}

	const datos = {
		idLegalRepresentative: data.d.results[0]?.ID,
		legalRepresentativeNames: data.d.results[0]?.Nombres,
		legalRepresentativeLastNames: data.d.results[0]?.Apellidos,
		legalRepresentativeNationality: data.d.results[0]?.Nacionalidad,
		legalRepresentativeIdNo: data.d.results[0]?.Numerodeidentificacion,
		legalRepresentativeCompanyPosition: data.d.results[0]?.Posicionenlaempresa,
		legalRepresentativeEmail: data.d.results[0]?.Correoelectronico,
		legalRepresentativePhoneNumber: data.d.results[0]?.Telefonocelular,
		legalRepresentativeOfficePhoneNumber: data.d.results[0]?.Telefonooficina,

		formId: data.d.results[0]?.FormularioIDId,
	};

	return datos;
}
//post and update Datos del representante legal - Opcional
export async function postOrUpdateFEDatosRepresentanteLegal({
	legalRepresentativeNames,
	legalRepresentativeLastNames,
	legalRepresentativeNationality,
	legalRepresentativeIdNo,
	legalRepresentativeCompanyPosition,
	legalRepresentativeEmail,
	legalRepresentativePhoneNumber,
	legalRepresentativeOfficePhoneNumber,
	formId,
	idLegalRepresentative,
}: any) {
	const datos = JSON.stringify({
		Nombres: legalRepresentativeNames,
		Apellidos: legalRepresentativeLastNames,
		Nacionalidad: legalRepresentativeNationality,
		Numerodeidentificacion: legalRepresentativeIdNo,
		Posicionenlaempresa: legalRepresentativeCompanyPosition,
		Correoelectronico: legalRepresentativeEmail,
		Telefonocelular: legalRepresentativePhoneNumber,
		Telefonooficina: legalRepresentativeOfficePhoneNumber,
		FormularioIDId: formId,
		__metadata: {
			type: await getListMetadata('FE Datos del representante legal'),
		},
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Datos del representante legal')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Datos del representante legal')/items/getbyid(${idLegalRepresentative})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idLegalRepresentative === undefined ? postQuery : updateQuery,
			datos,
			idLegalRepresentative === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
///////////////////////////////////////////////////////////////////////////////////////
//get datos Referencias bancarias del representante legal - Opcional
export async function getFEDatosReferenciasBancariasRepresentanteLegal(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Referencias bancarias del representante legal')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	if (data.d.results?.length === 0) {
		return null;
	}

	const datos = {
		idLegalBankReferences: data.d.results[0]?.ID,

		legalBankReferencesBank: data.d.results[0]?.Banco,
		legalBankReferencesAccountType: data.d.results[0]?.Tipodecuenta,
		legalBankReferencesAccountNumber: data.d.results[0]?.Numerodecuenta,

		formId: data.d.results[0]?.FormularioIDId,
	};

	return datos;
}
//post and update Referencias bancarias del representante legal - Opcional
export async function postOrUpdateFEDatosReferenciasBancariasRepresentanteLegal({
	legalBankReferencesBank,
	legalBankReferencesAccountType,
	legalBankReferencesAccountNumber,
	formId,
	idLegalBankReferences,
}: any) {
	const datos = JSON.stringify({
		Banco: legalBankReferencesBank,
		Tipodecuenta: legalBankReferencesAccountType,
		Numerodecuenta: legalBankReferencesAccountNumber,
		FormularioIDId: formId,
		__metadata: {
			type: await getListMetadata('FE Referencias bancarias del representante legal'),
		},
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Referencias bancarias del representante legal')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Referencias bancarias del representante legal')/items/getbyid(${idLegalBankReferences})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idLegalBankReferences === undefined ? postQuery : updateQuery,
			datos,
			idLegalBankReferences === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
///////////////////////////////////////////////////////////////////////////////////////
//post and update Clientes de mayor riesgo Direccion física de los directores
export async function postOrUpdateFEDatosClientesDeMayorRiesgoDireccionDirectores({
	formId,
	highRiskDirectors = [],
}: any) {
	const requests = highRiskDirectors?.map(async ({ Name, Address, idHighRiskDirectors }: any) => {
		const datos = JSON.stringify({
			Nombre: Name,
			Direccion: Address,
			FormularioIDId: formId,
			__metadata: {
				type: await getListMetadata('FE Clientes de mayor riesgo direccion directores'),
			},
		});

		//query
		const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Clientes de mayor riesgo direccion directores')/items`;
		const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Clientes de mayor riesgo direccion directores')/items/getbyid(${idHighRiskDirectors})`;

		//config
		const postAxiosConfig = {
			headers: {
				'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
					?.value,
				Accept: SharePointConsts.acceptVerbose,
				'Content-Type': SharePointConsts.contentTypeVerbose,
				'IF-MATCH': '*',
			},
		};

		const updateAxiosConfig = {
			headers: {
				'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
					?.value,
				Accept: SharePointConsts.acceptVerbose,
				'Content-Type': SharePointConsts.contentTypeVerbose,
				'X-HTTP-Method': 'MERGE',
				'IF-MATCH': '*',
			},
		};

		//post or update
		return axios
			.post(
				idHighRiskDirectors === undefined ? postQuery : updateQuery,
				datos,
				idHighRiskDirectors === undefined ? postAxiosConfig : updateAxiosConfig
			)
			.then((response) => {
				return response.data;
			})
			.catch((error) => console.error(error));
	});

	return Promise.all(requests);
}

// get Clientes de mayor riesgo Direccion física de los directores
export async function getFEDatosClientesDeMayorRiesgoDireccionDirectores(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Clientes de mayor riesgo direccion directores')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datos = data.d.results.map((result: any) => ({
		idHighRiskDirectors: result?.ID,
		Name: result?.Nombre,
		Address: result?.Direccion,
		formId: result?.FormularioIDId,
	}));

	return { highRiskDirectors: datos };
}
///////////////////////////////////////////////////////////////////////////////////////
//post and update Referencias bancarias
export async function postOrUpdateFEDatosReferenciasBancarias({
	formId,
	highRiskBankReferences = [],
}: any) {
	const requests = highRiskBankReferences?.map(
		async ({ Name, Bank, AccountType, AccountNumber, idHighRiskBankReferences }: any) => {
			const datos = JSON.stringify({
				Nombre: Name,
				Banco: Bank,
				Tipodecuenta: AccountType,
				Numerodecuenta: AccountNumber,

				FormularioIDId: formId,
				__metadata: {
					type: await getListMetadata('FE Referencias bancarias'),
				},
			});

			//query
			const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Referencias bancarias')/items`;
			const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Referencias bancarias')/items/getbyid(${idHighRiskBankReferences})`;

			//config
			const postAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'IF-MATCH': '*',
				},
			};

			const updateAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'X-HTTP-Method': 'MERGE',
					'IF-MATCH': '*',
				},
			};

			//post or update
			return axios
				.post(
					idHighRiskBankReferences === undefined ? postQuery : updateQuery,
					datos,
					idHighRiskBankReferences === undefined ? postAxiosConfig : updateAxiosConfig
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => console.error(error));
		}
	);

	return Promise.all(requests);
}

// get Referencias bancarias
export async function getFEDatosReferenciasBancarias(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Referencias bancarias')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datos = data.d.results.map((result: any) => ({
		idHighRiskBankReferences: result?.ID,
		Name: result?.Nombre,
		Bank: result?.Banco,
		AccountType: result?.Tipodecuenta,
		AccountNumber: result?.Numerodecuenta,
		formId: result?.FormularioIDId,
	}));

	return { highRiskBankReferences: datos };
}
///////////////////////////////////////////////////////////////////////////////////////
//post and update Referencias Bancarias Beneficiarios Finales
export async function postOrUpdateFEDatosReferenciasBancariasBeneficiariosFinales({
	formId,
	bankReferencesFinalBeneficiaries = [],
}: any) {
	const requests = bankReferencesFinalBeneficiaries?.map(
		async ({
			Name,
			Bank,
			AccountType,
			AccountNumber,
			IsSubscribed,
			Which,
			idBankReferencesFinalBeneficiaries,
		}: any) => {
			const datos = JSON.stringify({
				Nombre: Name,
				Banco: Bank,
				Tipodecuenta: AccountType,
				Numerodecuenta: AccountNumber,
				Estainscritoengremio: IsSubscribed,
				Cual: Which,

				FormularioIDId: formId,
				__metadata: {
					type: await getListMetadata('FE Referencias bancarias beneficiarios finales'),
				},
			});

			//query
			const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Referencias bancarias beneficiarios finales')/items`;
			const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Referencias bancarias beneficiarios finales')/items/getbyid(${idBankReferencesFinalBeneficiaries})`;

			//config
			const postAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'IF-MATCH': '*',
				},
			};

			const updateAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'X-HTTP-Method': 'MERGE',
					'IF-MATCH': '*',
				},
			};

			//post or update
			return axios
				.post(
					idBankReferencesFinalBeneficiaries === undefined ? postQuery : updateQuery,
					datos,
					idBankReferencesFinalBeneficiaries === undefined
						? postAxiosConfig
						: updateAxiosConfig
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => console.error(error));
		}
	);

	return Promise.all(requests);
}

// get Referencias Bancarias Beneficiarios Finales
export async function getFEDatosReferenciasBancariasBeneficiariosFinales(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Referencias bancarias beneficiarios finales')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datos = data.d.results.map((result: any) => ({
		idBankReferencesFinalBeneficiaries: result?.ID,
		Name: result?.Nombre,
		Bank: result?.Banco,
		AccountType: result?.Tipodecuenta,
		AccountNumber: result?.Numerodecuenta,
		IsSubscribed: result?.Estainscritoengremio,
		Which: result?.Cual,

		formId: result?.FormularioIDId,
	}));

	return { bankReferencesFinalBeneficiaries: datos };
}
///////////////////////////////////////////////////////////////////////////////////////
//post and update Otros servicios o productos de inversión contratados o adquiridos a través de otros participantes del mercado de valores o en el extranjero - Opcional
export async function postOrUpdateFEDatosOtrosServiciosDeInversion({
	formId,
	otherInvestmentServices = [],
}: any) {
	const requests = otherInvestmentServices?.map(
		async ({ Product, Partaker, idOtherInvestmentServices }: any) => {
			const datos = JSON.stringify({
				Producto: Product,
				Participante: Partaker,
				FormularioIDId: formId,
				__metadata: {
					type: await getListMetadata('FE Otros productos de inversion contratados'),
				},
			});

			//query
			const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Otros productos de inversion contratados')/items`;
			const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Otros productos de inversion contratados')/items/getbyid(${idOtherInvestmentServices})`;

			//config
			const postAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'IF-MATCH': '*',
				},
			};

			const updateAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'X-HTTP-Method': 'MERGE',
					'IF-MATCH': '*',
				},
			};

			//post or update
			return axios
				.post(
					idOtherInvestmentServices === undefined ? postQuery : updateQuery,
					datos,
					idOtherInvestmentServices === undefined ? postAxiosConfig : updateAxiosConfig
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => console.error(error));
		}
	);

	return Promise.all(requests);
}

// get Otros servicios o productos de inversión contratados o adquiridos a través de otros participantes del mercado de valores o en el extranjero - Opcional
export async function getFEDatosOtrosServiciosDeInversion(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Otros productos de inversion contratados')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datos = data.d.results.map((result: any) => ({
		idOtherInvestmentServices: result?.ID,
		Product: result?.Producto,
		Partaker: result?.Participante,
		formId: result?.FormularioIDId,
	}));

	return { otherInvestmentServices: datos };
}
///////////////////////////////////////////////////////////////////////////////////////
//post and update Otras cuentas de inversión locales o extranjeras (si es empresa extranjera) - Opcional
export async function postOrUpdateFEDatosOtrasCuentasDeInversion({
	formId,
	otherInvestmentAccounts = [],
}: any) {
	const requests = otherInvestmentAccounts?.map(
		async ({ Information, idOtherInvestmentAccounts }: any) => {
			const datos = JSON.stringify({
				Informacion: Information,
				FormularioIDId: formId,
				__metadata: {
					type: await getListMetadata('FE Otras cuentas de inversion'),
				},
			});

			//query
			const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Otras cuentas de inversion')/items`;
			const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Otras cuentas de inversion')/items/getbyid(${idOtherInvestmentAccounts})`;

			//config
			const postAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'IF-MATCH': '*',
				},
			};

			const updateAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'X-HTTP-Method': 'MERGE',
					'IF-MATCH': '*',
				},
			};

			//post or update
			return axios
				.post(
					idOtherInvestmentAccounts === undefined ? postQuery : updateQuery,
					datos,
					idOtherInvestmentAccounts === undefined ? postAxiosConfig : updateAxiosConfig
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => console.error(error));
		}
	);

	return Promise.all(requests);
}

// get Otras cuentas de inversión locales o extranjeras (si es empresa extranjera) - Opcional
export async function getFEDatosOtrasCuentasDeInversion(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Otras cuentas de inversion')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datos = data.d.results.map((result: any) => ({
		idOtherInvestmentAccounts: result?.ID,
		Information: result?.Informacion,
		formId: result?.FormularioIDId,
	}));

	return { otherInvestmentAccounts: datos };
}
///////////////////////////////////////////////////////////////////////////////////////
//get datos Zona Geográfica
export async function getFEDatosZonaGeografica(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Zona geografica')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	if (data.d.results?.length === 0) {
		return null;
	}

	const datos = {
		idGeographicalArea: data.d.results[0]?.ID,
		geographicalArea: data.d.results[0]?.Paisyciudad,
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datos;
}
//post and update Zona Geográfica
export async function postOrUpdateFEDatosZonaGeografica({
	geographicalArea,
	formId,
	idGeographicalArea,
}: any) {
	const datos = JSON.stringify({
		Paisyciudad: geographicalArea,
		FormularioIDId: formId,
		__metadata: {
			type: await getListMetadata('FE Zona geografica'),
		},
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Zona geografica')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Zona geografica')/items/getbyid(${idGeographicalArea})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idGeographicalArea === undefined ? postQuery : updateQuery,
			datos,
			idGeographicalArea === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
///////////////////////////////////////////////////////////////////////////////////////
//get datos Persona politicamente expuesta
export async function getFEDatosPersonaPoliticamenteExpuesta(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Persona politicamente expuesta')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	if (data.d.results?.length === 0) {
		return null;
	}

	const datos = {
		idPoliticallyExposed: data.d.results[0]?.ID,
		politicallyExposed: data.d.results[0]?.Politicamenteexpuesta,
		politicallyExposedName: data.d.results[0]?.Nombre,
		politicallyExposedPositionHeld: data.d.results[0]?.Cargo,
		politicallyExposedBonding: data.d.results[0]?.Vinculacion,
		politicallyExposedCountry: data.d.results[0]?.Pais,
		politicallyExposedPeriodStart: new Date(data.d.results[0]?.Desde),
		politicallyExposedPeriodEnd: new Date(data.d.results[0]?.Hasta),
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datos;
}
//post and update Persona politicamente expuesta
export async function postOrUpdateFEDatosPersonaPoliticamenteExpuesta({
	politicallyExposed,
	politicallyExposedName,
	politicallyExposedPositionHeld,
	politicallyExposedBonding,
	politicallyExposedCountry,
	politicallyExposedPeriodStart,
	politicallyExposedPeriodEnd,
	formId,
	idPoliticallyExposed,
}: any) {
	const datos = JSON.stringify({
		Politicamenteexpuesta: politicallyExposed,
		Nombre: politicallyExposedName,
		Cargo: politicallyExposedPositionHeld,
		Vinculacion: politicallyExposedBonding,
		Pais: politicallyExposedCountry,
		Desde: politicallyExposedPeriodStart,
		Hasta: politicallyExposedPeriodEnd,
		FormularioIDId: formId,
		__metadata: {
			type: await getListMetadata('FE Persona politicamente expuesta'),
		},
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Persona politicamente expuesta')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Persona politicamente expuesta')/items/getbyid(${idPoliticallyExposed})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idPoliticallyExposed === undefined ? postQuery : updateQuery,
			datos,
			idPoliticallyExposed === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
///////////////////////////////////////////////////////////////////////////////////////
//get datos Identificacion de estatus FATCA
export async function getFEDatosIdentificacionEstatusFATCA(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Identificacion de estatus FATCA')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	if (data.d.results?.length === 0) {
		return null;
	}

	const datos = {
		idStatusFATCA: data.d.results[0]?.ID,
		statusFATCAHasUSPhone: data.d.results[0]?.TelefonoenUS,
		statusFATCACanMakeUSTransfer: data.d.results[0]?.TransferenciasaUS,
		statusFATCAHasAccountPerson: data.d.results[0]?.ApoderadocondireccionUS,
		statusFATCACompanyUSAddress: data.d.results[0]?.DomicilioenUS,
		statusFATCABankHoldMail: data.d.results[0]?.HoldmailoIncare,
		statusFATCAUSPhoneRegistration: data.d.results[0]?.TelefonodeUS,
		statusFATCACompanyHasUSMailAddress: data.d.results[0]?.MailenUS,
		statusFATCAIsCondominium: data.d.results[0]?.Condominio,
		statusFATCACorporateType: data.d.results[0]?.Societario && {
			value: data.d.results[0]?.Societario,
			label: data.d.results[0]?.Societario,
		},
		statusFATCAIsFinancialEntity: data.d.results[0]?.EntidadfinancieraFATCA,
		statusFATCAIsNonProfit: data.d.results[0]?.Asociacionsinfinesdelucro && {
			value: data.d.results[0]?.Asociacionsinfinesdelucro,
			label: fe_s5_asociacion_sin_fines_lucro.filter(
				(v) => v?.value === data.d.results[0]?.Asociacionsinfinesdelucro
			)[0]?.label,
		},
		statusFATCALessThan24Operation: data.d.results[0]?.Menosde24,
		statusFATCAOperationStart: data.d.results[0]?.Cuandoinicio,
		statusFATCAListedOnRegulatedMarket: data.d.results[0]?.Cotizaacciones,
		statusFATCAIsCompanyAHolding: data.d.results[0]?.Esholding,
		statusFATCAConsistentProductSales: data.d.results[0]?.Actividadcomercial,
		statusFATCAMainActivity: data.d.results[0]?.Generaringresos,
		statusFATCAIsNonSponsored: data.d.results[0]?.Entidadnofinanciera,
		statusFATCAIsRetirementPlanCompany: data.d.results[0]?.Entidaddeplanes,
		statusFATCAIsExemptedDueToBankruptcy: data.d.results[0]?.Entidadexenta,
		statusFATCAIsExemptedByUSRevenueCode: data.d.results[0]?.Sociedadcodigoderentas,
		statusFATCAFormsApply: data.d.results[0]?.Formularios && {
			value: data.d.results[0]?.Formularios,
			label: data.d.results[0]?.Formularios,
		},
		statusFATCAClassificationCRS: data.d.results[0]?.ClasificacionestatusCRS,
		statusFATCATaxResidenceCountry: data.d.results[0]?.Paisderesidenciafiscal && {
			value: data.d.results[0]?.Paisderesidenciafiscal,
			label: fe_s5_pais_residencia.filter(
				(v) => v?.value === data.d.results[0]?.Paisderesidenciafiscal
			)[0]?.label,
		},
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datos;
}
//post and update Identificacion de estatus FATCA
export async function postOrUpdateFEDatosIdentificacionEstatusFATCA({
	statusFATCAHasUSPhone,
	statusFATCACanMakeUSTransfer,
	statusFATCAHasAccountPerson,
	statusFATCACompanyUSAddress,
	statusFATCABankHoldMail,
	statusFATCAUSPhoneRegistration,
	statusFATCACompanyHasUSMailAddress,
	statusFATCAIsCondominium,
	statusFATCACorporateType,
	statusFATCAIsFinancialEntity,
	statusFATCAIsNonProfit,
	statusFATCALessThan24Operation,
	statusFATCAOperationStart,
	statusFATCAListedOnRegulatedMarket,
	statusFATCAIsCompanyAHolding,
	statusFATCAConsistentProductSales,
	statusFATCAMainActivity,
	statusFATCAIsNonSponsored,
	statusFATCAIsRetirementPlanCompany,
	statusFATCAIsExemptedDueToBankruptcy,
	statusFATCAIsExemptedByUSRevenueCode,
	statusFATCAFormsApply,
	statusFATCAClassificationCRS,
	statusFATCATaxResidenceCountry,
	formId,
	idStatusFATCA,
}: any) {
	const datos = JSON.stringify({
		TelefonoenUS: statusFATCAHasUSPhone,
		TransferenciasaUS: statusFATCACanMakeUSTransfer,
		ApoderadocondireccionUS: statusFATCAHasAccountPerson,
		DomicilioenUS: statusFATCACompanyUSAddress,
		HoldmailoIncare: statusFATCABankHoldMail,
		TelefonodeUS: statusFATCAUSPhoneRegistration,
		MailenUS: statusFATCACompanyHasUSMailAddress,
		Condominio: statusFATCAIsCondominium,
		Societario: statusFATCACorporateType?.value,
		EntidadfinancieraFATCA: statusFATCAIsFinancialEntity,
		Asociacionsinfinesdelucro: statusFATCAIsNonProfit?.value,
		Menosde24: statusFATCALessThan24Operation,
		Cuandoinicio: statusFATCAOperationStart,
		Cotizaacciones: statusFATCAListedOnRegulatedMarket,
		Esholding: statusFATCAIsCompanyAHolding,
		Actividadcomercial: statusFATCAConsistentProductSales,
		Generaringresos: statusFATCAMainActivity,
		Entidadnofinanciera: statusFATCAIsNonSponsored,
		Entidaddeplanes: statusFATCAIsRetirementPlanCompany,
		Entidadexenta: statusFATCAIsExemptedDueToBankruptcy,
		Sociedadcodigoderentas: statusFATCAIsExemptedByUSRevenueCode,
		Formularios: statusFATCAFormsApply?.value,
		ClasificacionestatusCRS: statusFATCAClassificationCRS,
		Paisderesidenciafiscal: statusFATCATaxResidenceCountry?.value,
		FormularioIDId: formId,
		__metadata: {
			type: await getListMetadata('FE Identificacion de estatus FATCA'),
		},
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Identificacion de estatus FATCA')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Identificacion de estatus FATCA')/items/getbyid(${idStatusFATCA})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idStatusFATCA === undefined ? postQuery : updateQuery,
			datos,
			idStatusFATCA === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
///////////////////////////////////////////////////////////////////////////////////////
//post and update Beneficiario de la inverison
export async function postOrUpdateFEDatosBeneficiarioDeLaInversion({
	formId,
	investmentBeneficiaries = [],
}: any) {
	const requests = investmentBeneficiaries?.map(
		async ({
			IsActingAsIntermediary,
			BondDescriptionSelect,
			BondDescription,
			Names,
			LastNames,
			IdType,
			IdNo,
			ContactPhoneNumber,
			Address,
			idInvestmentBeneficiaries,
		}: any) => {
			const datos = JSON.stringify({
				Intermediario: IsActingAsIntermediary,
				Relacionconcliente: BondDescriptionSelect?.value,
				Otros: BondDescription,
				Nombres: Names,
				Apellidos: LastNames,
				Tipodeidentificacion: IdType,
				Numerodeidentificacion: IdNo,
				Telefonodecontacto: ContactPhoneNumber,
				Direccion: Address,
				FormularioIDId: formId,

				__metadata: {
					type: await getListMetadata('FE Beneficiario de la inversion'),
				},
			});

			//query
			const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Beneficiario de la inversion')/items`;
			const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Beneficiario de la inversion')/items/getbyid(${idInvestmentBeneficiaries})`;

			//config
			const postAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'IF-MATCH': '*',
				},
			};

			const updateAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'X-HTTP-Method': 'MERGE',
					'IF-MATCH': '*',
				},
			};

			//post or update
			return axios
				.post(
					idInvestmentBeneficiaries === undefined ? postQuery : updateQuery,
					datos,
					idInvestmentBeneficiaries === undefined ? postAxiosConfig : updateAxiosConfig
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => console.error(error));
		}
	);

	return Promise.all(requests);
}

// get Beneficiario de la inverison
export async function getFEDatosBeneficiarioDeLaInversion(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Beneficiario de la inversion')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datos = data.d.results.map((result: any) => ({
		idInvestmentBeneficiaries: result?.ID,
		IsActingAsIntermediary: result?.Intermediario,
		BondDescriptionSelect: {
			value: result?.Relacionconcliente,
			label: result?.Relacionconcliente,
		},
		BondDescription: result?.Otros,
		Names: result?.Nombres,
		LastNames: result?.Apellidos,
		IdType: result?.Tipodeidentificacion,
		IdNo: result?.Numerodeidentificacion,
		ContactPhoneNumber: result?.Telefonodecontacto,
		Address: result?.Direccion,
		formId: result?.FormularioIDId,
	}));

	return { investmentBeneficiaries: datos };
}
///////////////////////////////////////////////////////////////////////////////////////
//get datos Origen del Patrimonio
export async function getFEDatosOrigenDelPatrimonio(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Origen del patrimonio')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	if (data.d.results?.length === 0) {
		return null;
	}

	const datos = {
		idHerigateOrigin: data.d.results[0]?.ID,
		heritageOrigin: data.d.results[0]?.Descripcion,
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datos;
}
//post and update Origen del Patrimonio
export async function postOrUpdateFEDatosOrigenDelPatrimonio({
	heritageOrigin,
	formId,
	idHerigateOrigin,
}: any) {
	const datos = JSON.stringify({
		Descripcion: heritageOrigin,
		FormularioIDId: formId,
		__metadata: {
			type: await getListMetadata('FE Origen del patrimonio'),
		},
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Origen del patrimonio')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Origen del patrimonio')/items/getbyid(${idHerigateOrigin})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idHerigateOrigin === undefined ? postQuery : updateQuery,
			datos,
			idHerigateOrigin === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
///////////////////////////////////////////////////////////////////////////////////////
//post and update Beneficiarios finales de la empresa
export async function postOrUpdateFEDatosBeneficiariosFinalesDeLaEmpresa({
	formId,
	finalCompanyBeneficiaries = [],
}: any) {
	const requests = finalCompanyBeneficiaries?.map(
		async ({ Name, IdType, IdNo, Comment, idFinalCompanyBeneficiaries }: any) => {
			const datos = JSON.stringify({
				Nombre: Name,
				Tipodeidentificacion: IdType,
				Numerodeidentificacion: IdNo,
				Comentario: Comment,
				FormularioIDId: formId,
				__metadata: {
					type: await getListMetadata('FE Beneficiarios finales de la empresa'),
				},
			});

			//query
			const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Beneficiarios finales de la empresa')/items`;
			const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Beneficiarios finales de la empresa')/items/getbyid(${idFinalCompanyBeneficiaries})`;

			//config
			const postAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'IF-MATCH': '*',
				},
			};

			const updateAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'X-HTTP-Method': 'MERGE',
					'IF-MATCH': '*',
				},
			};

			//post or update
			return axios
				.post(
					idFinalCompanyBeneficiaries === undefined ? postQuery : updateQuery,
					datos,
					idFinalCompanyBeneficiaries === undefined ? postAxiosConfig : updateAxiosConfig
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => console.error(error));
		}
	);

	return Promise.all(requests);
}

// get Beneficiarios finales de la empresa
export async function getFEDatosBeneficiariosFinalesDeLaEmpresa(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Beneficiarios finales de la empresa')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datos = data.d.results.map((result: any) => ({
		idFinalCompanyBeneficiaries: result?.ID,
		Name: result?.Nombre,
		IdType: result?.Tipodeidentificacion,
		IdNo: result?.Numerodeidentificacion,
		Comment: result?.Comentario,
		formId: result?.FormularioIDId,
	}));

	return { finalCompanyBeneficiaries: datos };
}
///////////////////////////////////////////////////////////////////////////////////////
//post and update Personas Relacionadas
export async function postOrUpdateFEDatosPersonasRelacionadas({ formId, relatedPeople = [] }: any) {
	const requests = relatedPeople?.map(async ({ Name, IdNo, Comment, idRelatedPeople }: any) => {
		const datos = JSON.stringify({
			Nombre: Name,
			Numerodeidentificacion: IdNo,
			Comentario: Comment,
			FormularioIDId: formId,
			__metadata: {
				type: await getListMetadata('FE Personas relacionadas'),
			},
		});

		//query
		const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Personas relacionadas')/items`;
		const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Personas relacionadas')/items/getbyid(${idRelatedPeople})`;

		//config
		const postAxiosConfig = {
			headers: {
				'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
					?.value,
				Accept: SharePointConsts.acceptVerbose,
				'Content-Type': SharePointConsts.contentTypeVerbose,
				'IF-MATCH': '*',
			},
		};

		const updateAxiosConfig = {
			headers: {
				'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
					?.value,
				Accept: SharePointConsts.acceptVerbose,
				'Content-Type': SharePointConsts.contentTypeVerbose,
				'X-HTTP-Method': 'MERGE',
				'IF-MATCH': '*',
			},
		};

		//post or update
		return axios
			.post(
				idRelatedPeople === undefined ? postQuery : updateQuery,
				datos,
				idRelatedPeople === undefined ? postAxiosConfig : updateAxiosConfig
			)
			.then((response) => {
				return response.data;
			})
			.catch((error) => console.error(error));
	});

	return Promise.all(requests);
}

// get Personas Relacionadas
export async function getFEDatosPersonasRelacionadas(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Personas relacionadas')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datos = data.d.results.map((result: any) => ({
		idRelatedPeople: result?.ID,
		Name: result?.Nombre,
		IdNo: result?.Numerodeidentificacion,
		Comment: result?.Comentario,
		formId: result?.FormularioIDId,
	}));

	return { relatedPeople: datos };
}
///////////////////////////////////////////////////////////////////////////////////////
//post and update Personas autorizadas para concertar, instruir o realizar las operaciones y transacciones con el intermediario de valores
export async function postOrUpdateFEDatosPersonasAutorizadas({
	formId,
	authorizedPeople = [],
}: any) {
	const requests = authorizedPeople?.map(
		async ({
			Names,
			LastNames,
			Nationality,
			IdNo,
			CompanyPosition,
			PhoneNumber,
			OfficeNumber,
			Email,
			idAuthorizedPeople,
		}: any) => {
			const datos = JSON.stringify({
				Nombres: Names,
				Apellidos: LastNames,
				Nacionalidad: Nationality,
				Numerodeidentificacion: IdNo,
				Posicionenlaempresa: CompanyPosition,
				Telefonocelular: PhoneNumber,
				Telefonooficina: OfficeNumber,
				Correoelectronico: Email,
				FormularioIDId: formId,
				__metadata: {
					type: await getListMetadata('FE Personas autorizadas'),
				},
			});

			//query
			const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Personas autorizadas')/items`;
			const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Personas autorizadas')/items/getbyid(${idAuthorizedPeople})`;

			//config
			const postAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'IF-MATCH': '*',
				},
			};

			const updateAxiosConfig = {
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
					'Content-Type': SharePointConsts.contentTypeVerbose,
					'X-HTTP-Method': 'MERGE',
					'IF-MATCH': '*',
				},
			};

			//post or update
			return axios
				.post(
					idAuthorizedPeople === undefined ? postQuery : updateQuery,
					datos,
					idAuthorizedPeople === undefined ? postAxiosConfig : updateAxiosConfig
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => console.error(error));
		}
	);

	return Promise.all(requests);
}

// get Personas autorizadas para concertar, instruir o realizar las operaciones y transacciones con el intermediario de valores
export async function getFEDatosPersonasAutorizadas(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Personas autorizadas')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	const datos = data.d.results.map((result: any) => ({
		idAuthorizedPeople: result?.ID,
		Names: result?.Nombres,
		LastNames: result?.Apellidos,
		Nationality: result?.Nacionalidad,
		IdNo: result?.Numerodeidentificacion,
		CompanyPosition: result?.Posicionenlaempresa,
		PhoneNumber: result?.Telefonocelular,
		OfficeNumber: result?.Telefonooficina,
		Email: result?.Correoelectronico,
		formId: result?.FormularioIDId,
	}));

	return { authorizedPeople: datos };
}
///////////////////////////////////////////////////////////////////////////////////////
//get datos Informaciones relativas al inversionista
export async function getFEDatosInformacionesDelInversionista(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Informaciones relativas al inversionista')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	if (data.d.results?.length === 0) {
		return null;
	}

	const datos = {
		idInvestorInfo: data.d.results[0]?.ID,
		investorInfoOperationTime: data.d.results[0]?.Tiempodeoperacion && {
			value: data.d.results[0]?.Tiempodeoperacion,
			label: data.d.results[0]?.Tiempodeoperacion,
		},
		investorInfoHoldTime: data.d.results[0]?.Plazodeinversion && {
			value: data.d.results[0]?.Plazodeinversion,
			label: data.d.results[0]?.Plazodeinversion,
		},
		investorInfoExperienceLevel: data.d.results[0]?.Niveldeexperiencia && {
			value: data.d.results[0]?.Niveldeexperiencia,
			label: data.d.results[0]?.Niveldeexperiencia,
		},
		investorInfoStockMarket:
			data.d.results[0]?.Operaciones_x0020_bursatiles &&
			data.d.results[0]?.Operaciones_x0020_bursatiles?.results.map((value: string) => ({
				value,
				label: value,
			})),
		investorInfoAnnualBenefits: data.d.results[0]?.Beneficiosanuales && {
			value: data.d.results[0]?.Beneficiosanuales,
			label: data.d.results[0]?.Beneficiosanuales,
		},
		investorInfoLiquidAssetsTotal: data.d.results[0]?.Totalactivosliquidos && {
			value: data.d.results[0]?.Totalactivosliquidos,
			label: data.d.results[0]?.Totalactivosliquidos,
		},
		investorInfoEquityVariation: data.d.results[0]?.Variaciondepatrimonio && {
			value: data.d.results[0]?.Variaciondepatrimonio,
			label: fe_s7_variacion_patrimonio.filter(
				(v) => v?.value === data.d.results[0]?.Variaciondepatrimonio
			)[0]?.label,
		},
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datos;
}
//post and update Informaciones relativas al inversionista
export async function postOrUpdateFEDatosInformacionesDelInversionista({
	investorInfoOperationTime,
	investorInfoHoldTime,
	investorInfoExperienceLevel,
	investorInfoStockMarket,
	investorInfoAnnualBenefits,
	investorInfoLiquidAssetsTotal,
	investorInfoEquityVariation,
	formId,
	idInvestorInfo,
}: any) {
	const datos = JSON.stringify({
		Tiempodeoperacion: investorInfoOperationTime?.value,
		Plazodeinversion: investorInfoHoldTime?.value,
		Niveldeexperiencia: investorInfoExperienceLevel?.value,
		Operaciones_x0020_bursatiles: {
			__metadata: {
				type: 'Collection(Edm.String)',
			},
			results: investorInfoStockMarket && investorInfoStockMarket.map(({ value }: any) => value),
		},
		Beneficiosanuales: investorInfoAnnualBenefits?.value,
		Totalactivosliquidos: investorInfoLiquidAssetsTotal?.value,
		Variaciondepatrimonio: investorInfoEquityVariation?.value,
		FormularioIDId: formId,
		__metadata: {
			type: await getListMetadata('FE Informaciones relativas al inversionista'),
		},
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Informaciones relativas al inversionista')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Informaciones relativas al inversionista')/items/getbyid(${idInvestorInfo})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idInvestorInfo === undefined ? postQuery : updateQuery,
			datos,
			idInvestorInfo === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
///////////////////////////////////////////////////////////////////////////////////////
//get datos Objetivos financieros y tolerancia de riesgo del inversionista
export async function getFEDatosObjetivosFinancieros(formId: number) {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Objetivos financieros')/items?$filter=FormularioIDId eq ${formId}`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						?.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	if (data.d.results?.length === 0) {
		return null;
	}

	const datos = {
		idFinancialObjectives: data.d.results[0]?.ID,
		financialObjectivesHasEmergyFund: data.d.results[0]?.Fondodeemergencia && {
			value: data.d.results[0]?.Fondodeemergencia,
			label: data.d.results[0]?.Fondodeemergencia,
		},
		financialObjectivesLiquidAssetsDistribution: data.d.results[0]?.Distribuciondeactivos && {
			value: data.d.results[0]?.Distribuciondeactivos,
			label: fe_s8_distribucion_activos.filter(
				(v) => v?.value === data.d.results[0]?.Distribuciondeactivos
			)[0]?.label,
		},
		financialObjectivesIncomeDestination: data.d.results[0]?.Destinodeingresos && {
			value: data.d.results[0]?.Destinodeingresos,
			label: data.d.results[0]?.Destinodeingresos,
		},
		financialObjectivesTotalAssetsPercentage: data.d.results[0]?.Porcentajedepatrimonio && {
			value: data.d.results[0]?.Porcentajedepatrimonio,
			label: data.d.results[0]?.Porcentajedepatrimonio,
		},
		financialObjectivesOfInvestment: data.d.results[0]?.Principalobjetivo && {
			value: data.d.results[0]?.Principalobjetivo,
			label: fe_s8_principal_objetivo.filter(
				(v) => v?.value === data.d.results[0]?.Principalobjetivo
			)[0]?.label,
		},
		financialObjectivesBestPhrase: data.d.results[0]?.Frasedeidentificacion && {
			value: data.d.results[0]?.Frasedeidentificacion,
			label: fe_s8_frases_identificacion.filter(
				(v) => v?.value === data.d.results[0]?.Frasedeidentificacion
			)[0]?.label,
		},
		financialObjectivesCapitalLossesAbsorption: data.d.results[0]?.Capacidaddeperdidas && {
			value: data.d.results[0]?.Capacidaddeperdidas,
			label: data.d.results[0]?.Capacidaddeperdidas,
		},
		formId: data.d.results[0]?.FormularioIDId,
	};

	return datos;
}
//post and update Objetivos financieros y tolerancia de riesgo del inversionista
export async function postOrUpdateFEDatosObjetivosFinancieros({
	financialObjectivesHasEmergyFund,
	financialObjectivesLiquidAssetsDistribution,
	financialObjectivesIncomeDestination,
	financialObjectivesTotalAssetsPercentage,
	financialObjectivesOfInvestment,
	financialObjectivesBestPhrase,
	financialObjectivesCapitalLossesAbsorption,
	formId,
	idFinancialObjectives,
}: any) {
	const datos = JSON.stringify({
		Fondodeemergencia: financialObjectivesHasEmergyFund?.value,
		Distribuciondeactivos: financialObjectivesLiquidAssetsDistribution?.value,
		Destinodeingresos: financialObjectivesIncomeDestination?.value,
		Porcentajedepatrimonio: financialObjectivesTotalAssetsPercentage?.value,
		Principalobjetivo: financialObjectivesOfInvestment?.value,
		Frasedeidentificacion: financialObjectivesBestPhrase?.value,
		Capacidaddeperdidas: financialObjectivesCapitalLossesAbsorption?.value,
		FormularioIDId: formId,
		__metadata: {
			type: await getListMetadata('FE Objetivos financieros'),
		},
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Objetivos financieros')/items`;
	const updateQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('FE Objetivos financieros')/items/getbyid(${idFinancialObjectives})`;

	//config
	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'IF-MATCH': '*',
		},
	};

	const updateAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)?.value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': SharePointConsts.contentTypeVerbose,
			'X-HTTP-Method': 'MERGE',
			'IF-MATCH': '*',
		},
	};

	//post or update
	await axios
		.post(
			idFinancialObjectives === undefined ? postQuery : updateQuery,
			datos,
			idFinancialObjectives === undefined ? postAxiosConfig : updateAxiosConfig
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
