class ValorCuotasReadDto {
  private _currentDate: string;
  public get currentDate(): string {
    return this._currentDate;
  }
  public set currentDate(value: string) {
    const newDate = new Date(value);
    this._currentDate = newDate.toLocaleDateString("en-CA");
  }
  private _prevDate: string;
  public get prevDate(): string {
    return this._prevDate;
  }
  public set prevDate(value: string) {
    const newDate = new Date(value);
    this._prevDate = newDate.toLocaleDateString("en-CA");
  }
  public currentValue: string;
  public prevValue: string;
  public totalValues: string;
  public fundId: number;
  public fundTitle: string;
}

export default ValorCuotasReadDto;
