import HeroFAQ from '../../components/FATCA_FAQ/HeroFAQ';
import Questions from '../../components/FATCA_FAQ/Questions';
import axios from 'axios';
import { useState, useEffect } from 'react';
import AppVariables from '../../Variables.json';
import { SharePointConsts } from '../../util/constants';

const DEFAULT_QUESTION = {
	order: 0,
	question: '',
	content: '',
};

const DEAFULT_HERO = {
	title: '',
	placeholder: '',
};

export default function FAQ() {
	const [heroFAQ, setHeroFAQ] = useState(DEAFULT_HERO);
	const [questions, setQuestions] = useState([]);
	const [filteredQuestions, setFilteredQuestions] = useState([DEFAULT_QUESTION]);

	const getHeroFAQ = async () => {
		const resultsArr = await axios
			.get(
				`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('S1-FATCA-FAQ')/items`,

				{
					headers: {
						'X-RequestDigest': (
							document.getElementById('__REQUESTDIGEST') as HTMLInputElement
						).value,

						Accept: SharePointConsts.acceptNoMetadata,
						'Content-Type': SharePointConsts.contentTypeNoMetadata,
					},
				}
			)
			.then((response) => response.data.value)
			.catch((e) => console.error(e));

		const result = resultsArr.find((r: any) => r.Title === 'Preguntas frecuentes');

		setHeroFAQ({
			title: result?.Title,
			placeholder: result?.Placeholder,
		});
	};
	const getQuestions = async () => {
		const resultsArr = await axios
			.get(
				`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Preguntas-FATCA-FAQ')/items?$select=*,Categoria/Title&$expand=Categoria&$filter=Categoria/Title eq 'Preguntas frecuentes'`,

				{
					headers: {
						'X-RequestDigest': (
							document.getElementById('__REQUESTDIGEST') as HTMLInputElement
						).value,

						Accept: SharePointConsts.acceptNoMetadata,
						'Content-Type': SharePointConsts.contentTypeNoMetadata,
					},
				}
			)
			.then((response) => response.data.value)
			.catch((e) => console.error(e));

		const mappedQuestions = resultsArr.map((item: any) => ({
			order: item?.Order0,
			question: item?.Pregunta,
			content: item?.Content,
		}));
		setQuestions(mappedQuestions);
		setFilteredQuestions(mappedQuestions);
	};

	useEffect(() => {
		getHeroFAQ();
		getQuestions();
	}, []);

	const handleSubmit = (userInput: string) => {
		const matchingQuestions = questions.filter(({ question }) =>
			question.toLocaleLowerCase().includes(userInput.toLocaleLowerCase())
		);
		setFilteredQuestions(matchingQuestions);
	};

	return (
		<div className="main">
			<HeroFAQ title={heroFAQ.title} onSubmit={handleSubmit} placeholder={heroFAQ.placeholder} />
			<div className="container mx-auto">
				<div className="lista-preguntas " x-data="{ openedIndex: 0 }">
					<Questions data={filteredQuestions} />
				</div>
			</div>
		</div>
	);
}
