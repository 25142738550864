import AppVariables from '../Variables.json';

class EbookReadDto {
	public id: number;
	public title: string;
	public autor: string;
	public descripcion: string;

	private _pdfUrl: string;
	public get pdfUrl(): string {
		return this._pdfUrl;
	}
	public set pdfUrl(value: string) {
		this._pdfUrl = AppVariables.BASE_URL + value;
	}

	private _portadaUrl: string;
	public get portadaUrl(): string {
		return this._portadaUrl;
	}
	public set portadaUrl(value: string) {
		this._portadaUrl = AppVariables.BASE_URL + value;
	}
}

export default EbookReadDto;
