interface IProps {
	id: string;
	category: string;
	setCurrentCategory: React.Dispatch<React.SetStateAction<string>>;
}

function DocumentCategory(props:IProps) {

	function toggleCategory(id: string) {
		const testBox = document.getElementById('document-category-filter') as HTMLDivElement;
		const choices = testBox.querySelectorAll('div');
		choices.forEach((div) => {
			if (div.id === id) {
				div.classList.add('active');
			} else {
				div.classList.remove('active');
			}
		});
	}

	return (
		<div className="main-documents-option-container">
			<div
				className={props.id === '1' ? 'cursor-pointer main-documents-option active' : 'cursor-pointer main-documents-option'}
				id={props.id}
                onClick={() => {toggleCategory(props.id);props.setCurrentCategory(props.category)}}
			>
				{props.category}
			</div>
		</div>
	);
}

export default DocumentCategory;
