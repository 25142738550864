import { useEffect, useState } from 'react';
import ValorCuotaCard from '../components/valor-cuota/ValorCuotaCard';
import { getValorCuotasFromFund } from '../services/FundServices';
import PerformanceCalc from '../img/home-tools/performance-calc-icon.svg';
import FundBuyer from '../img/home-tools/fund-buyer-icon.svg';
import FundRecommender from '../img/home-tools/fund-recommender-icon.svg';
import Riskprofile from '../img/home-tools/risk-profile-icon.svg';
import { useNavigate } from 'react-router-dom';
import AppVariables from '../Variables.json';
import ValorCuotasReadDto from '../dtos/ValorCuotasReadDto';
import PageInfoDto from '../dtos/PageInfoDto';
import { getPageInfo } from '../services/GenericServices';

interface IState {
	idFondo: number;
	title: string;
	fechaDiaActual: string;
	fechaDiaAnterior: string;
	cuotaDiaActual: number;
	cuotaDiaAnterior: number;
	totalCuotas: string;
}

function ValorCuota() {
	const [funds, setfunds] = useState<ValorCuotasReadDto[]>([]);
	const navigate = useNavigate();

	const [pageInfo, setPageInfo] = useState<PageInfoDto>(new PageInfoDto());

	const fetchInfo = async () => {
		const fetchedInfo = await getPageInfo('Valor de las cuotas');
		setPageInfo(fetchedInfo);
	};

	useEffect(() => {
		fetchInfo();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			const data = await getValorCuotasFromFund();
			setfunds(data);
		};

		const divElement = document.getElementById('viz1649085574666');
		const vizElement = divElement.getElementsByTagName('object')[0];
		vizElement.style.width = '100%';
		vizElement.style.height = '571px';
		const scriptElement = document.createElement('script');
		scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
		vizElement.parentNode.insertBefore(scriptElement, vizElement);

		fetchData();
	}, []);

	function RenderCuotasCard() {
		return funds.map((v) => (
			<ValorCuotaCard
				idFondo={v.fundId}
				title={v.fundTitle}
				fechaDiaActual={v.currentDate}
				fechaDiaAnterior={v.prevDate}
				cuotaDiaActual={v.currentValue}
				cuotaDiaAnterior={v.prevValue}
				totalCuotas={v.totalValues}
			/>
		));
	}

	return (
		<main className="main">
			<section
				className="comparador"
				style={{
					backgroundImage: `linear-gradient(
                              180deg,
                              #00235c00 0%,
                              #00235ca3 20%,
                              #00235c 100%
                            ),
                            url(${AppVariables.IMG_URL + 'bg-img-podcast1.jpg'})`,
				}}
			>
				<div className="container mx-auto">
					<div className="comparador__intro">
						<h1>{pageInfo.title}</h1>
						<p>{pageInfo.description}</p>
					</div>
					<div className="grid gap-10 lg:grid-cols-2 md:space-y-0 comparador__blue-boxes lg:justify-between">
						{RenderCuotasCard()}
					</div>

					<div className="flex items-center justify-center mt-8 table-box-value">
						<div
							className="tableauPlaceholder"
							id="viz1649085574666"
							style={{ position: 'relative' }}
						>
							<noscript>
								&lt;a
								href='https:&amp;#47;&amp;#47;www.afipopular.com.do&amp;#47;'&gt;&lt;img
								alt=' '
								src='https:&amp;#47;&amp;#47;public.tableau.com&amp;#47;static&amp;#47;images&amp;#47;In&amp;#47;IndicadoresPginaAFI&amp;#47;IndicadoresAFIPopular&amp;#47;1_rss.png'
								style='border: none' /&gt;&lt;/a&gt;
							</noscript>
							<object className="tableauViz" style={{ display: 'none' }}>
								<param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" />
								<param name="embed_code_version" value={3} />
								<param name="site_root" />
								<param name="name" value="IndicadoresPginaAFI/IndicadoresAFIPopular" />
								<param name="tabs" value="no" />
								<param name="toolbar" value="yes" />
								<param
									name="static_image"
									value="https://public.tableau.com/static/images/In/IndicadoresPginaAFI/IndicadoresAFIPopular/1.png"
								/>
								<param name="animate_transition" value="yes" />
								<param name="display_static_image" value="yes" />
								<param name="display_spinner" value="yes" />
								<param name="display_overlay" value="yes" />
								<param name="display_count" value="yes" />
							</object>
						</div>
					</div>
				</div>
			</section>
			{/*tools*/}
			<div className="tools tools--fix">
				<h1 className="generic-title">Herramientas</h1>
				<div className="tools-container">
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/perfil-de-riesgo`);
						}}
					>
						<img className="generic-tool__logo" src={Riskprofile} alt="risk-profile-icon" />
						<h4 className="generic-tool__title">Perfil de Riesgo del Inversionista</h4>
					</div>
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/recomendador`);
						}}
					>
						<img
							className="generic-tool__logo"
							src={FundRecommender}
							alt="fund-recommender-icon"
						/>
						<h4 className="generic-tool__title">Recomendador de fondos</h4>
					</div>
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/calculadora`);
						}}
					>
						<img
							className="generic-tool__logo"
							src={PerformanceCalc}
							alt="performance-calc-icon"
						/>
						<h4 className="generic-tool__title">Calculadora de rendimiento</h4>
					</div>
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/comparador-de-fondos`);
						}}
					>
						<img className="generic-tool__logo" src={FundBuyer} alt="fund-buyer-icon" />
						<h4 className="generic-tool__title">
							Comparador <br />
							de fondos
						</h4>
					</div>
				</div>
			</div>
			{/*tools end*/}
		</main>
	);
}

export default ValorCuota;
