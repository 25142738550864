import { ErrorMessage } from "@hookform/error-message";
import { validations } from "../Validations/regexValidations";

function ReferenciaBancariaForm(props: any) {
  const emptyFieldErrorMessage = "Campo obligatorio";
  const baseName = `bankReferences[${props.number}]`;
  return (
		<div className="mt-6 create__requisitos">
			<h4 className="create__requisitos-title">Referencia bancaria</h4>
			<div className="lg:items-end lg:flex lg:space-x-9">
				<div className="mb-4 group-form lg:w-1/2">
					<label className="global-label">Banco o Empresa</label>
					<input
						type="text"
						maxLength={20}
						className="global-input"
						placeholder="Banco o Empresa"
						{...props.register(`${baseName}.bank`, {
							required: { value: true, message: emptyFieldErrorMessage },
							pattern: validations.letrasYespacios,
						})}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={props.errors}
							name={`${baseName}.bank`}
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
				<div className="mb-4 group-form lg:w-1/2">
					<label className="global-label">Tipo de cuenta</label>
					<input
						type="text"
						maxLength={20}
						className="global-input"
						placeholder="Tipo de cuenta"
						{...props.register(`${baseName}.account`, {
							required: { value: true, message: emptyFieldErrorMessage },
							pattern: validations.letrasYespacios,
						})}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={props.errors}
							name={`${baseName}.account`}
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
			</div>
			<div className="lg:items-end lg:flex lg:space-x-9">
				<div className="mb-4 group-form lg:w-1/2 xl:pr-4">
					<label className="global-label">Oficial de la Cuenta o Persona de contacto</label>
					<input
						type="text"
						maxLength={20}
						className="global-input"
						placeholder="Oficial de la Cuenta o Persona de contacto"
						{...props.register(`${baseName}.official`, {
							required: { value: true, message: emptyFieldErrorMessage },
							pattern: validations.letrasYespacios,
						})}
					/>
					<div className="h-6 w-full">
						<ErrorMessage
							errors={props.errors}
							name={`${baseName}.official`}
							render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
						/>
					</div>
				</div>
			</div>
		</div>
  );
}

export default ReferenciaBancariaForm;
