import { useDraggable } from 'react-use-draggable-scroll';
import { useRef } from 'react';

interface IProps {
	years: string[];
	setYear: React.Dispatch<React.SetStateAction<string>>;
}

function AcknowledgmentFilter(props: IProps) {
	const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
	const { events } = useDraggable(ref);

	function toggleChoice(id: string) {
		const testBox = document.getElementById('filterContainer') as HTMLDivElement;
		const choices = testBox.querySelectorAll('a');
		choices.forEach((a) => {
			if (a.id === id) {
				a.classList.add('active-option');
			} else {
				a.classList.remove('active-option');
			}
		});
	}

	function renderYears() {
		return props.years.map((y, i) => (
			<a
				key={y}
				id={(i + 1).toString()}
				onClick={() => {
					props.setYear(y);
					toggleChoice((i + 1).toString());
				}}
			>
				{y}
			</a>
		));
	}

	return (
		<div id="filterContainer" className="reconocimiento-section__years" {...events} ref={ref}>
			{renderYears()}
		</div>
	);
}

export default AcknowledgmentFilter;
