import GCDocumentsReadDto from "../../dtos/GCDocumentsReadDto";
import pdf from '../../img/general/pdf-icon.svg';

interface IProps{
    document:GCDocumentsReadDto;
}

function DocumentCard(props:IProps) {
    return (
			<div className="main-documents__file-container--card">
				<img
					src={pdf}
					alt="pdf icon"
					className="main-documents__file-container--card--img"
				/>
				<div className="main-documents__file-container--card--content">
					<a 	download
						href={props.document.pdfUrl}
						className="main-documents__file-container--card--content--text"
					>
						{props.document.title}
					</a>
					<h4 className="main-documents__file-container--card--content--meta-data">
						Modificado: <b>{props.document.date}</b> - Tamaño: <b>{props.document.fileSize}</b>
					</h4>
				</div>

				<div className="colored-box cursor-pointer">
					<a href={props.document.pdfUrl} className="main-documents__file-container--card--download">
						<div className="main-documents__file-container--card--download">
							Descargar
						</div>
					</a>
				</div>
			</div>
		);
}

export default DocumentCard;