import axios from 'axios';
import AppVariables from '../Variables.json';
import { getListMetadata } from './GenericServices';
import { SharePointConsts } from '../util/constants';

export async function getAllTiposSolicitud(): Promise<string[]> {
	const data = await axios
		.get(
			`${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Contactanos')/fields?$filter=EntityPropertyName eq 'Tipodesolicitud'`,
			{
				headers: {
					'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
						.value,
					Accept: SharePointConsts.acceptVerbose,
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => console.error(error));

	let categories: string[] = [];

	categories = data.d.results[0].Choices.results;

	return categories;
}

export async function postNewContact(
	name: string,
	email: string,
	idType: string,
	idNo: string,
	requestType: any,
	message: string,
	recieveInfo: string
) {
	const contact = JSON.stringify({
		Nombre: name,
		Correoelectronico: email,
		Tipodeidentificacion: idType,
		Numerodeidentificacion: idNo,
		Tipodesolicitud: requestType?.value,
		Mensaje: message,
		Recibirinformacionporcorreo: recieveInfo,
		__metadata: { type: await getListMetadata('Contactanos') },
	});

	//query
	const postQuery = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Contactanos')/items`;

	const postAxiosConfig = {
		headers: {
			'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement).value,
			Accept: SharePointConsts.acceptVerbose,
			'Content-Type': 'application/json;odata=verbose',
			'IF-MATCH': '*',
		},
	};

	//post
	await axios
		.post(postQuery, contact, postAxiosConfig)
		.then((response) => response.data)
		.catch((error) => console.error(error));
}
