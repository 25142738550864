import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSectionStatus } from '../../services/GenericServices';
import AppVariables from '../../Variables.json';

interface IProps {
	title: string;
	definition: string;
	img: string;
}

function FundMainInfo(props: IProps) {
	const navigate = useNavigate();

	const [becomeClientSectionStatus, setBecomeClientSectionStatus] = useState<boolean>();

	const setSectionStatus = async () => {
		
		const BecomeClientStatus = await getSectionStatus('Hazte Cliente');

		setBecomeClientSectionStatus(BecomeClientStatus.visibility);
	};

	useEffect(() => {
		
		setSectionStatus();
		
	}, []);

	return (
		<section className="main-sociedades">
			<div className="main-sociedades-container">
				<div className="main-sociedades-content-container">
					<h3 className="main-sociedades-content__title">{props.title}</h3>
					<p className="main-sociedades-content__text">{props.definition}</p>
					{becomeClientSectionStatus === true ? (
						<button
							className="main-sociedades-content__link"
							onClick={() => navigate(`${AppVariables.REACT_ROUTER_URL}/empieza-invertir`)}
						>
							Invierte con nosotros
						</button>
					) : (
						<></>
					)}
				</div>
				<div
					className="main-sociedades__img"
					style={{ backgroundImage: `url(${props.img})` }}
				/>
			</div>
		</section>
	);
}

export default FundMainInfo;
