import { ErrorMessage } from '@hookform/error-message';
import React, { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import DatePickerComponent from '../../datepicker/DatePickerComponent';
import SelectComponent from '../../Select/SelectComponent';
import { fp_s1_estado_civil, fp_s1_nivel_academico } from '../../Utility/SelectOptions';
import { validations } from '../Validations/regexValidations';
import { ValidationMessages } from '../../Utility/ValidationMessages';

function StartInvestPersonStep1() {
	const [numIdPattern, setNumIdPattern] = useState<RegExp>();
	const [coHolderIdPattern, setcoHolderIdPattern] = useState<RegExp>();
	const [spouseIdPattern, setspouseIdPattern] = useState<RegExp>();

	const getNumIdPattern = (idType: string): RegExp => {
		if (idType === 'Cedula') {
			return /^\d{11}/;
		} else {
			return /^[A-Za-z0-9? ,-]+$/i;
		}
	};

	const {
		register,
		control,
		resetField,
		getValues,
		formState: { errors },
	} = useFormContext();

	const coHolderQuestion = useWatch({ control, name: 'coHolderQuestion' });
	const coHolderIdType = useWatch({ control, name: 'coHolderIdType' });
	const spouseIdType = useWatch({ control, name: 'spouseIdType' });
	const maritalStatus = useWatch({ control, name: 'maritalStatus' });
	const idType = useWatch({ control, name: 'idType' });
	return (
		<React.Fragment>
			<div className="create-box">
				<div className="mb-6 create__requisitos">
					<h4 className="create__requisitos-title">Datos Personales</h4>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Primer Nombre</label>
							<input
								name="firstName"
								id="primer-nombre-input"
								type="text"
								maxLength={40}
								className="global-input"
								placeholder="Primer Nombre"
								{...register('firstName', {
									required: { value: true, message: ValidationMessages.invalidCharacters },
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="firstName"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Segundo Nombre</label>
							<input
								name="secondName"
								id="segundo-nombre-input"
								type="text"
								maxLength={40}
								className="global-input"
								placeholder="Segundo Nombre"
								{...register('secondName', {
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="secondName"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Primer Apellido</label>
							<input
								name="firstLastName"
								type="text"
								maxLength={40}
								className="global-input"
								placeholder="Primer Apellido"
								{...register('firstLastName', {
									required: { value: true, message: ValidationMessages.invalidCharacters },
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="firstLastName"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Segundo Apellido</label>
							<input
								name="secondLastName"
								type="text"
								maxLength={40}
								className="global-input"
								placeholder="Segundo Apellido"
								{...register('secondLastName', {
									required: { value: true, message: ValidationMessages.invalidCharacters },
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="secondLastName"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="group-form lg:items-start lg:flex lg:space-x-9">
						<div className="lg:w-1/2">
							<label className="global-label">Tipo de identificación</label>
							<div className="document-box">
								<input
									id="step1-cedula"
									className="toggle toggle-left"
									type="radio"
									value="Cedula"
									{...register('idType', {
										required: { value: true, message: ValidationMessages.invalidCharacters },
										onChange: () => setNumIdPattern(getNumIdPattern(getValues('idType'))),
									})}
								/>
								<label htmlFor="step1-cedula" className="btn">
									Cédula
								</label>
								<input
									id="step1-pasaporte"
									className="toggle toggle-right"
									type="radio"
									value="Pasaporte"
									{...register('idType', {
										required: { value: true, message: ValidationMessages.invalidCharacters },
										onChange: () => setNumIdPattern(getNumIdPattern(getValues('idType'))),
									})}
								/>
								<label htmlFor="step1-pasaporte" className="btn">
									Pasaporte
								</label>
							</div>
						</div>
						<div className="mb-6 lg:w-1/2 ">
							<label className="global-label">Número de identificación</label>
							<input
								name="idNo"
								type="text"
								maxLength={11}
								className="global-input"
								placeholder="Número de identificación"
								{...register('idNo', {
									required: { value: true, message: ValidationMessages.invalidCharacters },
									pattern: {
										value: numIdPattern,
										message: ValidationMessages.invalidCharacters,
									},
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="idNo"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="group-form lg:items-start lg:flex lg:space-x-9">
						<div className="lg:w-1/2">
							<label className="global-label">Sexo</label>
							<div className="document-box">
								<input
									id="step1-femenino"
									className="toggle toggle-left"
									type="radio"
									value="Femenino"
									{...register('sex', {
										required: { value: true, message: ValidationMessages.invalidCharacters },
									})}
								/>

								<label htmlFor="step1-femenino" className="btn">
									Femenino
								</label>
								<input
									id="step1-masculino"
									className="toggle toggle-right"
									type="radio"
									value="Masculino"
									{...register('sex', {
										required: { value: true, message: ValidationMessages.invalidCharacters },
									})}
								/>

								<label htmlFor="step1-masculino" className="btn">
									Masculino
								</label>
							</div>
						</div>
						<div className="mb-6 group-form lg:w-1/2 lg:mb-0 ">
							<label className="global-label">Estado Civil</label>
							<Controller
								control={control}
								name="maritalStatus"
								rules={{
									required: { value: true, message: ValidationMessages.invalidCharacters },
								}}
								render={({ field: { onChange, value } }) => (
									<SelectComponent
										options={fp_s1_estado_civil}
										value={value}
										onChange={onChange}
										isMulti={false}
										placeHolder="Seleccione una opción"
									/>
								)}
							/>

							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="maritalStatus"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-6 comparador__select-block lg:w-1/2 ">
							<label className="global-label">Fecha de nacimiento</label>
							<Controller
								control={control}
								name="birthdate"
								rules={{
									required: { value: true, message: ValidationMessages.invalidCharacters },
								}}
								render={({ field: { onChange, value } }) => (
									<DatePickerComponent onChange={onChange} currValue={value} />
								)}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="birthdate"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-6 lg:w-1/2 ">
							<label className="global-label">Lugar de nacimiento</label>
							<input
								name="birthplace"
								type="text"
								maxLength={40}
								className="global-input"
								placeholder="Lugar de nacimiento"
								{...register('birthplace', {
									required: { value: true, message: ValidationMessages.invalidCharacters },
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="birthplace"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-6 lg:w-1/2 ">
							<label className="global-label">Nacionalidad</label>
							<input
								name="firstNationality"
								type="text"
								maxLength={20}
								className="global-input"
								placeholder="Nacionalidad"
								{...register('firstNationality', {
									required: { value: true, message: ValidationMessages.invalidCharacters },
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="firstNationality"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-6 lg:w-1/2 ">
							<label className="global-label">
								Segunda Nacionalidad - <i>Opcional</i>
							</label>
							<input
								name="secondNationality"
								type="text"
								maxLength={20}
								className="global-input"
								placeholder="Segunda Nacionalidad"
								{...register('secondNationality', {
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="secondNationality"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="lg:items-start lg:flex lg:space-x-9">
						<div className="mb-6 group-form lg:w-1/2 lg:mb-0 ">
							<label className="global-label">Nivel académico</label>
							<Controller
								control={control}
								name="academicLevel"
								rules={{
									required: { value: true, message: ValidationMessages.invalidCharacters },
								}}
								render={({ field: { onChange, value } }) => (
									<SelectComponent
										options={fp_s1_nivel_academico}
										value={value}
										onChange={onChange}
										isMulti={false}
										placeHolder="Seleccione una opción"
									/>
								)}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="academicLevel"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-6 lg:w-1/2 ">
							<label className="global-label">Profesión</label>
							<input
								name="profession"
								type="text"
								maxLength={20}
								className="global-input"
								placeholder="Profesión"
								{...register('profession', {
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="profession"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-6 lg:w-1/2 ">
							<label className="global-label">
								Teléfono residencia - <i>Opcional</i>
							</label>
							<input
								name="resTel"
								type="text"
								maxLength={14}
								className="global-input"
								placeholder="809 000 0000"
								{...register('resTel', {
									pattern: validations.numerosYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="resTel"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-6 lg:w-1/2 ">
							<label className="global-label">
								Teléfono adicional - <i>Opcional</i>
							</label>
							<input
								name="addTel"
								type="text"
								maxLength={14}
								className="global-input"
								placeholder="809 000 0000"
								{...register('addTel', {
									pattern: validations.numerosYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="addTel"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-6 lg:w-1/2 ">
							<label className="global-label">Teléfono celular</label>
							<input
								name="cel"
								type="text"
								maxLength={14}
								className="global-input"
								placeholder="809 000 0000"
								{...register('cel', {
									required: { value: true, message: ValidationMessages.invalidCharacters },
									pattern: validations.numerosYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="cel"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-6 lg:w-1/2 ">
							<label className="global-label">Correo electrónico</label>
							<input
								name="email"
								type="text"
								className="global-input"
								placeholder="ejemplo@correo.com"
								{...register('email', {
									required: { value: true, message: ValidationMessages.invalidCharacters },
									pattern: validations.emails,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="email"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-6 lg:w-1/2 ">
							<label className="global-label">Dirección residencia</label>
							<input
								name="address"
								type="text"
								maxLength={60}
								className="global-input"
								placeholder="Dirección residencia"
								{...register('address', {
									required: { value: true, message: ValidationMessages.invalidCharacters },
									pattern: validations.letrasNumerosEspaciosEspeciales,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="address"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-6 lg:w-1/2 ">
							<label className="global-label">Apartado postal</label>
							<input
								name="zipcode"
								type="text"
								maxLength={8}
								className="global-input"
								placeholder={'00000'}
								{...register('zipcode', {
									pattern: validations.soloNumeros,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="zipcode"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="mb-6 create__requisitos">
					<h4 className="create__requisitos-title">Datos de la cuenta</h4>
					<div className="lg:items-start lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Titular</label>
							<input
								name="holder"
								type="text"
								maxLength={40}
								className="global-input"
								placeholder="Titular"
								{...register('holder', {
									required: { value: false, message: ValidationMessages.invalidCharacters },
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="holder"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<div>
								<label className="global-label">¿Tendrá un cotitular?</label>
								<div className="document-box" onClick={() => resetField('coHolder')}>
									<input
										id="step1-coHolder-yes"
										className="toggle toggle-left"
										type="radio"
										value="Si"
										{...register('coHolderQuestion', {
											required: {
												value: true,
												message: ValidationMessages.invalidCharacters,
											},
										})}
									/>

									<label htmlFor="step1-coHolder-yes" className="btn">
										Si
									</label>
									<input
										id="step1-coHolder-no"
										className="toggle toggle-right"
										type="radio"
										value="No"
										{...register('coHolderQuestion', {
											required: {
												value: true,
												message: ValidationMessages.invalidCharacters,
											},
										})}
									/>

									<label htmlFor="step1-coHolder-no" className="btn">
										No
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				{coHolderQuestion === 'Si' && (
					<div className="mb-6 create__requisitos">
						<h4 className="create__requisitos-title">Datos del cotitular</h4>
						<div className="lg:items-end lg:flex lg:space-x-9">
							<div className="mb-4 group-form lg:w-1/2">
								<label className="global-label">Nombre</label>
								<input
									name="coHolder"
									type="text"
									maxLength={40}
									className="global-input"
									placeholder="Nombre"
									{...register('coHolder', {
										required: { value: true, message: ValidationMessages.invalidCharacters },
										pattern: validations.letrasYespacios,
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="coHolder"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
						</div>
						<div className="lg:items-start lg:flex lg:space-x-9">
							<div className="mb-4 group-form lg:w-1/2">
								<div>
									<label className="global-label">Tipo de identificación</label>
									<div className="document-box">
										<input
											id="step1-coHolder-cedula"
											className="toggle toggle-left"
											type="radio"
											value="Cedula"
											{...register('coHolderIdType', {
												required: {
													value: true,
													message: ValidationMessages.invalidCharacters,
												},
												onChange: () =>
													setcoHolderIdPattern(
														getNumIdPattern(getValues('coHolderIdType'))
													),
											})}
										/>

										<label htmlFor="step1-coHolder-cedula" className="btn">
											Cédula
										</label>
										<input
											id="step1-coHolder-pasaporte"
											className="toggle toggle-right"
											type="radio"
											value="Pasaporte"
											{...register('coHolderIdType', {
												required: {
													value: true,
													message: ValidationMessages.invalidCharacters,
												},
												onChange: () =>
													setcoHolderIdPattern(
														getNumIdPattern(getValues('coHolderIdType'))
													),
											})}
										/>

										<label htmlFor="step1-coHolder-pasaporte" className="btn">
											Pasaporte
										</label>
									</div>
								</div>
							</div>
							<div className="mb-4 group-form lg:w-1/2">
								<label className="global-label">Número de identificación</label>
								<input
									type="text"
									maxLength={11}
									className="global-input"
									placeholder="Número de identificación"
									{...register('coHolderId', {
										required: { value: true, message: ValidationMessages.invalidCharacters },
										pattern: {
											value: coHolderIdPattern,
											message: ValidationMessages.invalidCharacters,
										},
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="coHolderId"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
						</div>
						<div className="lg:items-end lg:flex lg:space-x-9">
							<div className="mb-4 group-form lg:w-1/2">
								<label className="global-label">Profesión</label>
								<input
									type="text"
									maxLength={20}
									className="global-input"
									placeholder="Profesión"
									{...register('coHolderProfesion', {
										required: { value: true, message: ValidationMessages.invalidCharacters },
										pattern: validations.letrasYespacios,
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="coHolderProfesion"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
							<div className="mb-4 group-form lg:w-1/2">
								<label className="global-label">Lugar de trabajo</label>
								<input
									name="coHolderWork"
									type="text"
									maxLength={20}
									className="global-input"
									placeholder="Lugar de trabajo"
									{...register('coHolderWork', {
										required: { value: true, message: ValidationMessages.invalidCharacters },
										pattern: validations.letrasNumerosEspacios,
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="coHolderWork"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
				{maritalStatus?.value === 'Casado' && (
					<div className="mb-6 create__requisitos">
						<h4 className="create__requisitos-title"> Datos del cónyuge</h4>
						<div className="lg:items-end lg:flex lg:space-x-9">
							<div className="mb-4 group-form lg:w-1/2">
								<label className="global-label">Nombre</label>
								<input
									name="spouseName"
									type="text"
									maxLength={40}
									className="global-input"
									placeholder="Nombre"
									{...register('spouseName', {
										pattern: validations.letrasYespacios,
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="spouseName"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
						</div>
						<div className="lg:items-start lg:flex lg:space-x-9">
							<div className="mb-4 group-form lg:w-1/2">
								<label className="global-label">Tipo de identificación</label>
								<div className="document-box">
									<input
										id="step1-spoude-cedula"
										className="toggle toggle-left"
										type="radio"
										value="Cedula"
										{...register('spouseIdType', {
											onChange: () =>
												setspouseIdPattern(getNumIdPattern(getValues('spouseIdType'))),
										})}
									/>

									<label htmlFor="step1-spoude-cedula" className="btn">
										Cédula
									</label>
									<input
										id="step1-spoude-pasaporte"
										className="toggle toggle-right"
										type="radio"
										value="Pasaporte"
										{...register('spouseIdType', {
											onChange: () =>
												setspouseIdPattern(getNumIdPattern(getValues('spouseIdType'))),
										})}
									/>

									<label htmlFor="step1-spoude-pasaporte" className="btn">
										Pasaporte
									</label>
								</div>
							</div>
							<div className="mb-4 group-form lg:w-1/2">
								<label className="global-label">Número de identificación</label>
								<input
									name="spouseId"
									type="text"
									maxLength={11}
									className="global-input"
									placeholder="Número de identificación"
									{...register('spouseId', {
										pattern: {
											value: spouseIdPattern,
											message: ValidationMessages.invalidCharacters,
										},
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="spouseId"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
						</div>
						<div className="lg:items-end lg:flex lg:space-x-9">
							<div className="mb-4 group-form lg:w-1/2">
								<label className="global-label">Profesión</label>
								<input
									name="spouseProfession"
									type="text"
									maxLength={20}
									className="global-input"
									placeholder="Profesion"
									{...register('spouseProfession', {
										pattern: validations.letrasYespacios,
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="spouseProfession"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
							<div className="mb-4 group-form lg:w-1/2">
								<label className="global-label">Lugar de trabajo</label>
								<input
									name="spouseWork"
									type="text"
									maxLength={20}
									className="global-input"
									placeholder="Lugar de trabajo"
									{...register('spouseWork', {
										pattern: validations.letrasNumerosEspacios,
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="spouseWork"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</React.Fragment>
	);
}

export default StartInvestPersonStep1;
