import React, { useEffect } from "react";
import RequisitosBeneficiariosForm from "./RequisitosBeneficiariosForm";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { validations } from "../Validations/regexValidations";
import { ErrorMessage } from "@hookform/error-message";
import DatePickerComponent from "../../datepicker/DatePickerComponent";

function StartInvestPersonStep3() {
  const emptyFieldErrorMessage = "Campo obligatorio";
  const {
    register,
    control,
    resetField,
	getValues,
    formState: { errors, isValid },
  } = useFormContext();

  const { append, fields, prepend } = useFieldArray({
    control,
    name: "beneficiaries",
  });

  const politicallyExposed = useWatch({ control, name: "politicallyExposed" });

  useEffect(() => {
    !fields.length && prepend({});
  }, [fields]);

  return (
		<React.Fragment>
			<div className="create__requisitos">
				<h4 className="create__requisitos-title">
					Persona políticamente expuesta <span className="text-xl fa fa-question-circle" />
				</h4>
				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="lg:w-1/2 lg:pr-4">
						<label className="global-label">¿Es una persona políticamente expuesta?</label>
						<div className="mb-4 document-box">
							<input
								id="step3-politically-exposed-yes"
								className="toggle toggle-left"
								type="radio"
								value="Si"
								{...register('politicallyExposed', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
								onClick={() => {
									resetField('bond');
									resetField('beneficiaries[0].intermediaryRelation');
								}}
							/>

							<label htmlFor="step3-politically-exposed-yes" className="btn">
								Si
							</label>
							<input
								id="step3-politically-exposed-no"
								className="toggle toggle-right"
								type="radio"
								value="No"
								{...register('politicallyExposed', {
									required: { value: true, message: emptyFieldErrorMessage },
								})}
								onClick={() => {
									resetField('bond');
									resetField('beneficiaries[0].intermediaryRelation');
								}}
							/>

							<label htmlFor="step3-politically-exposed-no" className="btn">
								No
							</label>
						</div>
					</div>
				</div>
				{politicallyExposed === 'Si' && (
					<>
						<div className="lg:items-end lg:flex lg:space-x-9">
							<div className="mb-4 group-form lg:w-1/2">
								<label className="global-label">Vinculación</label>
								<input
									type="text"
									className="global-input"
									placeholder="Vinculación"
									{...register('bond', {
										required: {
											value: politicallyExposed === 'Si',
											message: emptyFieldErrorMessage,
										},
										pattern: validations.letrasYespacios,
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="bond"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
							<div className="mb-4 group-form lg:w-1/2">
								<label className="global-label">Cargo desempeñado y lugar</label>
								<input
									type="text"
									className="global-input"
									placeholder="Cargo desempeñado y lugar"
									{...register('heldPosition', {
										required: {
											value: politicallyExposed === 'Si',
											message: emptyFieldErrorMessage,
										},
										pattern: validations.letrasYespacios,
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="heldPosition"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
						</div>
						<div className="lg:items-start lg:flex lg:space-x-9">
							<div className="mb-4 comparador__select-block md:w-1/2">
								<label className="global-label">Indique el periodo (desde - hasta)</label>
								<div className="flex flex-row gap-2">
									<>
										<Controller
											control={control}
											name="termStart"
											rules={{
												required: {
													value: politicallyExposed === 'Si',
													message: emptyFieldErrorMessage,
												},
											}}
											render={({ field: { onChange, value } }) => (
												<DatePickerComponent onChange={onChange} currValue={value} />
											)}
										/>
									</>
									<span className="flex justify-center items-center text-base"> - </span>
									<>
										<Controller
											control={control}
											name="termEnd"
											rules={{
												required: {
													value: politicallyExposed === 'Si',
													message: emptyFieldErrorMessage,
												},
											}}
											render={({ field: { onChange, value } }) => (
												<DatePickerComponent onChange={onChange} currValue={value} />
											)}
										/>
									</>
								</div>
							</div>
							<div className="mb-4 group-form lg:w-1/2">
								<label className="global-label">País</label>
								<input
									type="text"
									maxLength={20}
									className="global-input"
									placeholder="País"
									{...register('country', {
										required: {
											value: politicallyExposed === 'Si',
											message: emptyFieldErrorMessage,
										},
										pattern: validations.letrasYespacios,
									})}
								/>
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="country"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
						</div>
					</>
				)}
			</div>

			{fields.map((field, index) => (
				<RequisitosBeneficiariosForm
					key={field.id}
					number={index}
					errors={errors}
					register={register}
					useWatch={useWatch}
					control={control}
					resetField={resetField}
					isValid={isValid}
					getValues={getValues}
				/>
			))}

			<div className="mt-6 text-center create__requisitos">
				<button
					type="button"
					onClick={() => {
						append({});
					}}
					className="mb-4"
				>
					<div className="mx-auto text-[#012169] opacity-50 text-[40px]">+</div>
					<span className="text-[#012169] opacity-50 font-bold mb-3">
						Agregar otra Declaración de beneficiarios
					</span>
				</button>
			</div>
		</React.Fragment>
  );
}

export default StartInvestPersonStep3;
