import { Helmet } from 'react-helmet';

export interface PageHeadersProps {
	title?: string;
	description?: string;
}

export function PageHeaders(props: PageHeadersProps) {
	return (
		<Helmet>
			<title>{(props.title ? props.title + ' | ' : '') + 'AFI Popular'}</title>
			{props.description === undefined ? (
				<meta name="robots" content="noindex" />
			) : (
				<meta name="description" content={props.description} />
			)}
		</Helmet>
	);
}
