import AppVariables from '../Variables.json';

class BlogReadDto {
	public id: number;
	public title: string;
	public category: string;
	public shortDescription: string;
	public description: string;
	public _publicationDate: string;
	public get publicationDate(): string {
		return this._publicationDate;
	}
	public set publicationDate(value: string) {
		const newDate = new Intl.DateTimeFormat(['ban', 'id']).format(new Date(value));
		this._publicationDate = newDate;
	}
	private _imgUrl: string;
	public get imgUrl(): string {
		return this._imgUrl;
	}
	public set imgUrl(value: string) {
		this._imgUrl = value === '' ? '' : AppVariables.BASE_URL + value;
	}
}

export default BlogReadDto;
