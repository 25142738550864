
interface IProps {
	title: string;
	definition: string;
	members: Member[];
}

type Member = {
	nombre: string;
	rol: string;
};

function FundMainComite(props: IProps) {

	function renderMembers() {
		return props.members.map((member) => (
			<div key={member.nombre} className="main-comite-card-container">
				<div className="main-comite-card">
					<span className="main-comite-card__name">{member.nombre}</span>
					<span className="main-comite-card__position">{member.rol}</span>
				</div>
			</div>
		));
	}

	return (
		<section className="main-comite">
			<div className="main-comite-container">
				<h3 className="main-comite__title">Comité de Inversión</h3>
				<div className="main-comite__text">
					<p>
						{props.definition}
					</p>
					<p>
						El Comité de Inversiones del {props.title} estará
						conformado por las siguientes personas:
					</p>
				</div>
				<div className="main-comite-cards-container">
					{renderMembers()}
				</div>
			</div>
		</section>
	);
}

export default FundMainComite;