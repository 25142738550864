import AppVariables from '../Variables.json';

class CourseReadDto {
	public id: number;
	public title: string;

	public description: string;

	private _fecha: string;
	public get fecha(): string {
		return this._fecha;
	}
	public set fecha(value: string) {
		const newDate = new Intl.DateTimeFormat(['ban', 'id']).format(new Date(value));
		this._fecha = newDate;
	}

	public categoty: string;

	private _videoUrl: string;
	public get videoUrl(): string {
		return this._videoUrl;
	}
	public set videoUrl(value: string) {
		this._videoUrl = AppVariables.BASE_URL + value;
	}

	private _imgUrl: string;
	public get imgUrl(): string {
		return this._imgUrl;
	}
	public set imgUrl(value: string) {
		this._imgUrl = AppVariables.BASE_URL + value;
	}
}

export default CourseReadDto;
