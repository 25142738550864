import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DatePickerComponent from '../../datepicker/DatePickerComponent';
import { validations } from '../Validations/regexValidations';

function StartInvestCompanyStep1() {
	const emptyFieldErrorMessage = 'Campo obligatorio';
	const {
		register,
		control,
		formState: { errors },
	} = useFormContext();

	return (
		<React.Fragment>
			<div className="create-box">
				<div className="mb-6 create__requisitos">
					<h4 className="create__requisitos-title">Información de la empresa</h4>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Nombre/Razón social</label>
							<input
								type="text"
								maxLength={40}
								className="global-input"
								placeholder="Nombre/Razón social"
								{...register('companyInfoNameSocialReason', {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.letrasNumerosEspaciosEspeciales,
								})}
							/>
							<div className="h-6 w-full">
								<div className="h-6 w-full">
									<ErrorMessage
										errors={errors}
										name="companyInfoNameSocialReason"
										render={({ message }) => (
											<p className="text-red-600 ml-1">{message}</p>
										)}
									/>
								</div>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Tipo de empresa</label>
							<input
								type="text"
								className="global-input"
								placeholder="SRL, CxA, SA, etc…"
								{...register('companyInfoCompanyType', {
									required: { value: false, message: emptyFieldErrorMessage },
									pattern: validations.letrasNumerosEspaciosEspeciales,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="companyInfoCompanyType"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Número de RNC</label>
							<input
								type="text"
								maxLength={11}
								className="global-input"
								placeholder="Número de RNC"
								{...register('companyInfoRncNumber', {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.letrasNumerosEspaciosEspeciales,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="companyInfoRncNumber"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">País de constitución</label>
							<input
								type="text"
								maxLength={20}
								className="global-input"
								placeholder="País de constitución"
								{...register('companyInfoEstablishmentCountry', {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="companyInfoEstablishmentCountry"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="group-form lg:items-end lg:flex lg:space-x-9">
						<div className="mb-6 comparador__select-block lg:w-1/2 ">
							<label className="global-label">Fecha de constitución</label>
							<Controller
								control={control}
								name="companyInfoEstablishmentDate"
								rules={{
									required: { value: true, message: emptyFieldErrorMessage },
								}}
								render={({ field: { onChange, value } }) => (
									<DatePickerComponent onChange={onChange} currValue={value} />
								)}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="companyInfoEstablishmentDate"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-6 lg:w-1/2 ">
							<label className="global-label">
								Identificación tributaria del País de origen
							</label>
							<input
								type="text"
								className="global-input"
								placeholder="Identificación tributaria del País de origen"
								{...register('companyInfoTaxIdOrigin', {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.letrasNumerosEspaciosEspeciales,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="companyInfoTaxIdOrigin"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Actividad económica principal</label>
							<input
								type="text"
								maxLength={60}
								className="global-input"
								placeholder="Actividad económica principal"
								{...register('companyInfoMainEconomicActivity', {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="companyInfoMainEconomicActivity"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">
								No. de certificación de registro mercantil
							</label>
							<input
								type="text"
								maxLength={8}
								className="global-input"
								placeholder="No. de certificación de registro mercantil"
								{...register('companyInfoCommercialRegCert', {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.letrasNumerosEspacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="companyInfoCommercialRegCert"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="group-form lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Cantidad de empleados</label>
							<input
								type="text"
								maxLength={10}
								className="global-input"
								placeholder="0"
								{...register('companyInfoEmployeesQuantity', {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.soloNumeros,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="companyInfoEmployeesQuantity"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Teléfono de oficina</label>
							<input
								type="text"
								maxLength={14}
								className="global-input"
								placeholder="(809) 000 - 0000"
								{...register('companyInfoOfficePhoneNumber', {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.soloNumeros,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="companyInfoOfficePhoneNumber"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="group-form lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">
								Fax oficina <i>Opcional</i>
							</label>
							<input
								type="text"
								maxLength={14}
								className="global-input"
								placeholder="809) 000 - 0000"
								{...register('companyInfoOfficeFaxNumber', {
									pattern: validations.soloNumeros,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="companyInfoOfficeFaxNumber"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Correo electrónico</label>
							<input
								type="text"
								className="global-input"
								placeholder="ejemplo@correo.com"
								{...register('companyInfoEmail', {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.emails,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="companyInfoEmail"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
					<div className="group-form lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">
								Telefono adicional<i>Opcional</i>
							</label>
							<input
								type="text"
								maxLength={14}
								className="global-input"
								placeholder="(809) 000 - 0000"
								{...register('companyInfoAdditionalPhoneNumber', {
									pattern: validations.soloNumeros,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="companyInfoAdditionalPhoneNumber"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
						<div className="mb-4 group-form lg:w-1/2">
							<label className="global-label">Persona de contacto</label>
							<input
								type="text"
								maxLength={20}
								className="global-input"
								placeholder="Persona de contacto"
								{...register('companyInfoContactPerson', {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.letrasYespacios,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="companyInfoContactPerson"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="mb-6 create__requisitos">
					<h4 className="create__requisitos-title">Domicilio Principal</h4>
					<div className="lg:items-end lg:flex lg:space-x-9 ">
						<div className="mb-4 group-form lg:w-1/2 lg:pr-4">
							<label className="global-label">Dirección</label>
							<input
								type="text"
								maxLength={40}
								className="global-input"
								placeholder="Dirección"
								{...register('mainDomicile', {
									required: { value: true, message: emptyFieldErrorMessage },
									pattern: validations.letrasNumerosEspaciosEspeciales,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="mainDomicile"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="mb-6 create__requisitos">
					<h4 className="create__requisitos-title">Domicilio casa matriz</h4>
					<div className="lg:items-end lg:flex lg:space-x-9">
						<div className="mb-4 group-form lg:w-1/2 lg:pr-4">
							<label className="global-label">Dirección</label>
							<input
								type="text"
								maxLength={40}
								className="global-input"
								placeholder="Dirección"
								{...register('headquartersDomicile', {
									pattern: validations.letrasNumerosEspaciosEspeciales,
								})}
							/>
							<div className="h-6 w-full">
								<ErrorMessage
									errors={errors}
									name="headquartersDomicile"
									render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default StartInvestCompanyStep1;
