import pdf from '../../img/general/pdf-icon.svg';
import { saveAs } from 'file-saver';

interface IProps {
	title: string;
	date: string;
	size: string;
	url: string;
	filename: string;
}

function PdfMobileCard(props: IProps) {
	function saveFile(url: string, filename: string) {
		saveAs(url, filename);
	}

	return (
		<div
			className="main-documents__file-container--card"
			onClick={() => saveFile(props.url, props.filename)}
		>
			<img src={pdf} alt="" className="main-documents__file-container--card--img" />
			<div className="main-documents__file-container--card--content">
				<h4 className="main-documents__file-container--card--content--text">{props.title}</h4>
				<p className="main-documents__file-container--card--content--meta-data">
					Modificado: <b>{props.date}</b> - Tamaño: <b>{props.size}Kb</b>
				</p>
			</div>
			<div className="colored-box">
				<div className="main-documents__file-container--card--download">Descargar</div>
			</div>
		</div>
	);
}

export default PdfMobileCard;
