import React from "react";

interface IProps {
  VCFC: string; //valor de la cuota a la fecha de compra
  VCFH: string; //valor de la cuota a la fecha de hoy
  rendimiento: string;
  diasTranscurridos: string;
  gananciasPerdidas: string;
}

function CalculadoraResult(props: IProps) {
  return (
    <React.Fragment>
      <div className="comparador__table">
        <div className="justify-between md:space-x-4 md:flex comparador__table-top">
          <div className="flex-1 w-full">
            <h1>Resultado</h1>
          </div>
        </div>
      </div>
      <div className="flex mb-4 space-x-4 md:space-y-0 comparador__blue-boxes lg:justify-between">
        <div className="w-1/2 comparador__bg">
          <h2 className="comparador__desc">
            Valor de la cuota a la fecha de compra
          </h2>
          <h4 className="comparador__number">$ {props.VCFC}</h4>
        </div>
        <div className="w-1/2 comparador__bg">
          <h2 className="comparador__desc">
            Valor de la cuota a la fecha de hoy
          </h2>
          <h4 className="comparador__number">$ {props.VCFH}</h4>
        </div>
      </div>
      <div className="flex mb-4 space-x-4 md:space-y-0 comparador__blue-boxes lg:justify-between">
        <div className="w-1/2 comparador__bg">
          <h2 className="comparador__desc">Rendimiento</h2>
          <h4 className="comparador__number">{props.rendimiento}%</h4>
        </div>
        <div className="w-1/2 comparador__bg">
          <h2 className="comparador__desc">Dias transcurridos</h2>
          <h4 className="comparador__number">{props.diasTranscurridos}</h4>
        </div>
      </div>
      <div className="flex space-x-4 md:space-y-0 comparador__blue-boxes lg:justify-between">
        <div className="w-1/2 comparador__bg">
          <h2 className="comparador__desc">Ganancia / pérdida</h2>
          <h4 className="comparador__number">$ {props.gananciasPerdidas}</h4>
        </div>
        <div className="w-1/2"></div>
      </div>
    </React.Fragment>
  );
}

export default CalculadoraResult;
