import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';
import FundReadDto from '../../dtos/FundReadDto';

interface IProps {
	fondo: FundReadDto;
}

function RecomendadorCard({ fondo }: IProps) {
	const navigate = useNavigate();

	return (
		<div className="comparador__bg">
			<h1>{fondo.title}</h1>
			<p>{fondo.definicion}</p>
			<a
				className="hover:bg-[#CC6A25]"
				onClick={() =>
					navigate({
						pathname: `${AppVariables.REACT_ROUTER_URL}/generic-fund`,
						search: `?titulo=${fondo.title}`,
					})
				}
			>
				Más detalles
			</a>
		</div>
	);
}

export default RecomendadorCard;
