import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import StartInvestCompanyStep1 from './StartInvestCompanyStep1';
import StartInvestCompanyStep2 from './StartInvestCompanyStep2';
import StartInvestCompanyStep3 from './StartInvestCompanyStep3';
import StartInvestCompanyStep4 from './StartInvestCompanyStep4';
import StartInvestCompanyStep5 from './StartInvestCompanyStep5';
import StartInvestCompanyStep6 from './StartInvestCompanyStep6';
import StartInvestCompanyStep7 from './StartInvestCompanyStep7';
import StartInvestCompanyStep8 from './StartInvestCompanyStep8';
import { useFormContext } from 'react-hook-form';
import Popup from 'reactjs-popup';
import ContinuarMasTarde from '../../pop-up-messages/ContinuarMasTarde';
import ProgressBar from '../components/ProgressBar';
import { saveForm, saveFormProgress } from '../../../services/FormularioEmpresaServices';
import Captcha from '../components/Captcha';
import FormSent from '../../pop-up-messages/FormSent';

type FormProps = {
	lastStep?: number;
};

function StartInvestCompanyForm({ lastStep }: FormProps) {
	const {
		getValues,
		formState: { isValid },
	} = useFormContext();
	const overlayStyle = { background: 'rgba(0,0,0,0.7)' };

	const { state }: any = useLocation();
	const [formStep, setFormStep] = useState(1);
	const [isCaptcha, setIsCaptcha] = useState(false);
	const [openCL, setOpenCL] = useState(false);
	const [openFS, setOpenFS] = useState(false);

	const closeModalCL = () => setOpenCL(false);
	const closeModalFS = () => setOpenFS(false);

	useEffect(() => {
		setFormStep(lastStep);
	}, [lastStep]);

	const handleContinuesLater = (email: string, code: string) => {
		const status = 'Incompleto';
		const useCode = state?.code || code;
		const progress = { ...getValues() };
		saveFormProgress(progress, formStep, status, useCode, email).then(() => {
			setOpenCL(true);
		});
	};

	const handleNextStep = () => {
		if (!isValid) {
			return;
		}
		const nextStep = formStep + 1;
		if (nextStep < 9) {
			setFormStep(nextStep);
			scrollTop();
		} else {
			if (!isCaptcha) {
				return;
			}
			const formValue = getValues();
			saveForm(formValue, formStep, 'Completo', state?.code).then(() => {
				setOpenFS(true);
			});
		}
	};

	const handleContinuesLaterAgain = () => {
		const status = 'Incompleto';
		const progress = { ...getValues() };
		saveForm(progress, formStep, status, state?.code).then(() => {
			setOpenCL(true);
		});
	};

	const scrollTop = () => {
		const topDiv = document.getElementById('s4-workspace') as HTMLDivElement;
		topDiv.scrollTop = 0;
	};
	return (
		<React.Fragment>
			<ProgressBar progress={formStep} numOfSteps={8} title="Empresa o institución" />

			{formStep === 1 && <StartInvestCompanyStep1 />}
			{formStep === 2 && <StartInvestCompanyStep2 />}
			{formStep === 3 && <StartInvestCompanyStep3 />}
			{formStep === 4 && <StartInvestCompanyStep4 />}
			{formStep === 5 && <StartInvestCompanyStep5 />}
			{formStep === 6 && <StartInvestCompanyStep6 />}
			{formStep === 7 && <StartInvestCompanyStep7 />}
			{formStep === 8 && (
				<>
					<StartInvestCompanyStep8 />
					<Captcha setIsCaptcha={setIsCaptcha} />
				</>
			)}

			<div className="px-4 pb-10 lg:px-0 lg:flex create__actions lg:items-center lg:justify-between">
				<div
					className={`grid grid-cols-${
						formStep === 1 ? '1' : '2'
					} gap-6 gap-y-6 lg:gap-y-0 lg:w-[300px] mb-4 lg:mb-0`}
				>
					{formStep > 1 && (
						<button
							type="button"
							className="global-button global-button--full global-button--block boton-anterior"
							onClick={() => setFormStep((step) => step - 1)}
						>
							Anterior
						</button>
					)}
					{formStep < 8 ? (
						<button
							type="button"
							className={
								isValid === true
									? 'global-button global-button--full global-button global-button--full boton-siguiente-enviar'
									: 'isDisabled global-button--full'
							}
							onClick={handleNextStep}
						>
							Siguiente
						</button>
					) : (
						<>
							<button
								type="button"
								className={
									isValid === true && isCaptcha === true
										? 'global-button global-button--full global-button global-button--full boton-siguiente-enviar'
										: 'isDisabled global-button--full'
								}
								onClick={handleNextStep}
							>
								Enviar
							</button>
							<Popup
								open={openFS}
								position="right center"
								modal
								nested
								onClose={closeModalFS}
								{...{ overlayStyle }}
							>
								<FormSent
									title="Formulario enviado"
									link="requisitos-empresa"
									close={closeModalFS}
								/>
							</Popup>
						</>
					)}
				</div>
				<div className="mb-6 lg:m-0 lg:order-first lg:w-[200px]">
					{state?.code ? (
						<>
							<button
								type="button"
								className="global-button global-button--orange global-button--full"
								onClick={() => handleContinuesLaterAgain()}
							>
								Continuar más tarde
							</button>
							<Popup
								open={openCL}
								position="right center"
								modal
								nested
								onClose={closeModalCL}
								{...{ overlayStyle }}
							>
								<FormSent
									title="Formulario guardado con éxito"
									link="requisitos-empresa"
									close={closeModalCL}
								/>
							</Popup>
						</>
					) : (
						<>
							<Popup
								trigger={
									<button
										type="button"
										className="global-button global-button--orange global-button--full"
									>
										Continuar más tarde
									</button>
								}
								position="right center"
								modal
								nested
								{...{ overlayStyle }}
							>
								{(close: undefined) => (
									<ContinuarMasTarde
										close={close}
										ultimoPAso={1}
										handleSend={handleContinuesLater as any}
									/>
								)}
							</Popup>
							<Popup
								open={openCL}
								position="right center"
								modal
								nested
								onClose={closeModalCL}
								{...{ overlayStyle }}
							>
								<FormSent
									title="Formulario guardado"
									message="Se ha enviado un correo con su código de reanudación"
									link="requisitos-empresa"
									close={closeModalCL}
								/>
							</Popup>
						</>
					)}
				</div>
			</div>
		</React.Fragment>
	);
}

export default StartInvestCompanyForm;
