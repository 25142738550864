import PodcastReadDto from '../../dtos/PodcastReadDto';
import HomePodcastCard from '../podcast/HomePodcastCard';

interface IProps {
	podcasts: PodcastReadDto[];
}

function OtherPodcasts(props: IProps) {

	return (

		<div className="podcast podcast--white">
			<div className="container mx-auto">
				<h1 className="generic-title">También te puede interesar</h1>
				<div className="podcast-container">
					{props.podcasts.map((p) => (
						<HomePodcastCard podcast={p}/>
					))}
				</div>
			</div>
		</div>
	);
}

export default OtherPodcasts;
