import AppVariables from "../Variables.json";

class AboutUsBannerReadDto {
  public id: number;
  public title: string;
  public content: string;

  private _imgUrl: string;
  
  public get imgUrl(): string {
    return this._imgUrl;
  }
  public set imgUrl(value: string) {
    this._imgUrl = AppVariables.BASE_URL + value;
  }
}

export default AboutUsBannerReadDto;
