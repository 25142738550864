import React, { useEffect, useRef, useState } from 'react';
import Media from 'react-media';
import { useDraggable } from 'react-use-draggable-scroll';
import PdfReadDto from '../../dtos/PdfReadDto';
import {
	getAllFundDocsCategories,
	getDocsByFundAndCategory,
	getFundAttachmentFileById,
} from '../../services/FundServices';
import { getDocSizeByUrl } from '../../services/PublicacionesServices';
import PdfCard from './PdfCard';
import PdfMobileCard from './PdfMobileCard';

interface IProps {
	fondo: string;
}

function FundDocuments(props: IProps) {

	const [option, setOption] = useState('');
	const [categories, setCategories] = useState<string[]>([]);
	const [optionData, setOptionData] = useState<PdfReadDto[]>([]);

	const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
	const { events } = useDraggable(ref);

	const fetchCategories = async () => {
		const fetchedCategories = await getAllFundDocsCategories();
		setCategories(fetchedCategories);
		return categories;
	};

	const fetchData = async () => {
		const data = await getDocsByFundAndCategory(props.fondo, option);

		for (let index = 0; index < data.length; index++) {
			const fileInfo = await getFundAttachmentFileById(data[index].getId);
			data[index].setUrl = fileInfo.url;
			data[index].setFileName = fileInfo.filename;
		}

		for (let index = 0; index < data.length; index++) {
			const size = await getDocSizeByUrl(data[index].getUrl);
			data[index].setSize = size;
		}

		setOptionData(data);
	};

	useEffect(() => {
		const fetchDataAndSetOption = async () => {
			const fetchedCategories = await fetchCategories();
			setOption(fetchedCategories[0]);
		};

		fetchDataAndSetOption();
	}, []);

	useEffect(() => {
		fetchData();
	}, [option]);

	const renderDocs = () => {
		return optionData.map((c) => (
			<Media key={c.getId} queries={{ mobile: '(max-width:768px)', desktop: '(min-width:769px)' }}>
				{(matches) => (
					<React.Fragment>
						{matches.mobile && (
							<PdfMobileCard
								title={c.getTitle}
								date={c.getDate}
								size={c.getSize}
								url={c.getUrl}
								filename={c.getFileName}
							/>
						)}
						{matches.desktop && (
							<PdfCard
								title={c.getTitle}
								date={c.getDate}
								size={c.getSize}
								url={c.getUrl}
								filename={c.getFileName}
							/>
						)}
					</React.Fragment>
				)}
			</Media>
		));
	};

	const renderCategories = () => {
		return categories.map((category) => (
			<div key={category} className="main-fc-documents-option-container">
				<div
					onClick={() => {
						setOption(category);
					}}
					className={
						option === category
							? 'main-fc-documents-option-active'
							: 'main-fc-documents-option'
					}
				>
					{category}
				</div>
			</div>
		));
	};

	return (
		<section className="main-fc-documents">
			<h3 className="main-fc-documents__title">Documentos</h3>

			<div className="main-fc-documents-options" {...events} ref={ref}>
				{renderCategories()}
			</div>

			<div className="main-documents__file-container">{renderDocs()}</div>
		</section>
	);
}

export default FundDocuments;
