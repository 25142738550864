import "./preguntas.css";
import Question, {QuestionProps } from "./Question";

type QuestionsProps = {
  data: Array<QuestionProps>;
};

export default function Questions({ data }: QuestionsProps) {
  const renderQuestions = function () {
    data.sort((a, b) => (a.order > b.order ? 1 : -1));
    return data.map((q: any, i: number) => (
      <Question
        question={q.question}
        content={q.content}
        key={i}
        order={q.order}
      />
    ));
  };

  return (
    <div className="container mx-auto">
      <div className="lista-preguntas">{renderQuestions()}</div>
    </div>
  );
}
