import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { useFormContext } from "react-hook-form";
import { validations } from "../Validations/regexValidations";
import BankReferencesFinalBeneficiaries from "./BankReferencesFinalBeneficiaries";
import HighRiskBankReferences from "./HighRiskBankReferences";
import HighRiskDirectorsAddress from "./HighRiskDirectorsAddress";
import OtherInvestmentAccounts from "./OtherInvestmentAccounts";
import OtherInvestmentServices from "./OtherInvestmentServices";

function StartInvestCompanyStep4() {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
		<React.Fragment>
			<HighRiskDirectorsAddress />
			<HighRiskBankReferences />
			<BankReferencesFinalBeneficiaries />
			<OtherInvestmentServices />
			<OtherInvestmentAccounts />
			<div className="mb-6 create__requisitos">
				<h4 className="create__requisitos-title">Zona Geográfica</h4>

				<div className="lg:items-end lg:flex lg:space-x-9">
					<div className="mb-4 group-form lg:w-1/2">
						<label className="global-label" htmlFor="">
							País y ciudad
						</label>
						<input
							type="text"
							maxLength={60}
							className="global-input"
							placeholder="País y ciudad"
							{...register('geographicalArea', {
								required: { value: true, message: 'Campo obligatorio' },
								pattern: validations.letrasYespacios,
							})}
						/>
						<div className="h-6 w-full">
							<ErrorMessage
								errors={errors}
								name="geographicalArea"
								render={({ message }) => <p className="text-red-600 ml-1">{message}</p>}
							/>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
  );
}

export default StartInvestCompanyStep4;
