import React, { useEffect, useState } from 'react';
import CourseReadDto from '../../dtos/CourseReadDto';
import {
	getAllCourses,
	getAllCoursesCategories,
	getCoursesByCategory,
	getTopCourses,
} from '../../services/CourseServices';
import CategoryFilter from '../filters/CategoryFilter';
import CourseCard from './CourseCard';

function MainCourses() {
	const [category, setCategory] = useState('Todas');
	const [categories, setCategories] = useState([]);
	const [courses, setCourses] = useState<CourseReadDto[]>([]);
	const [loadMore, setLoadMore] = useState<boolean>(true);

	useEffect(() => {
		const fetchData = async () => {
			const fetchedCategories = await getAllCoursesCategories();
			setCategories(fetchedCategories);
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			let courses1: CourseReadDto[] = [];
			if (category === 'Todas') {
				courses1 = await getTopCourses(10);
			} else {
				courses1 = await getCoursesByCategory(category);
			}
			setCourses(courses1);
		};
		fetchData();
	}, [category]);

	function renderCards() {
		return courses.map((course) => <CourseCard key={course.id} course={course} />);
	}

	async function loadAllCourses() {
		const courses2 = await getAllCourses();
		setCategory('Todas');
		setCourses(courses2);
	}

	const onChangeCategory = (category: any) => {
		setCategory(category.value);
	};

	return (
		<React.Fragment>
			<section className="main-blog-header flex flex-col items-center pt-10 mx-[18px]">
				<div className="container mx-auto mt-16 lg:flex lg:justify-between lg:items-center">
					<h1 className="flex justify-center generic-title generic-title--mb generic-title--left">
						Cursos y Talleres
					</h1>
					<div className="flex justify-center w-full space-x-4 lg:justify-end select-row lg:w-1/2">
						<CategoryFilter categories={categories} setCategory={onChangeCategory} />
					</div>
				</div>
			</section>
			<section className="main-blog-cards mt-10 mx-[18px] font-['Roboto'] mb-12">
				<div className="container mx-auto">
					<div className="grid grid-cols-1 gap-8 mt-10 main-blog-cards-container md:grid-cols-2 lg:grid-cols-3">
						{renderCards()}
					</div>
					{/* load more start */}
					{loadMore === true ? (
						<div className="load-more-box">
							<div
								className="container mx-auto"
								onClick={() => {
									loadAllCourses();
									setLoadMore(false);
								}}
							>
								<a className="global-button">Cargar Más</a>
							</div>
						</div>
					) : (
						<></>
					)}

					{/* load more end */}
				</div>
			</section>
		</React.Fragment>
	);
}

export default MainCourses;
