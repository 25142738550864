import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import StartInvestPersonForm from "../components/forms/start-invest-form/StartInvestPersonForm";
import { getFormProgress } from "../services/FormularioPersonaServices";

interface StateType {
	code: string;
	lastStep: number;
}

const INITIAL_STATE: any = {};

function PersonForm() {
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: INITIAL_STATE,
    delayError: 1500,
  });
  const location = useLocation();
  const state = location.state as StateType;

  useEffect(() => {
    state
      ? getFormProgress(state.code).then((value) => {
          methods.reset(value);
        })
      : methods.reset(INITIAL_STATE);
  }, []);

  return (
    <FormProvider {...methods}>
      <StartInvestPersonForm lastStep={state? state.lastStep:1} />
    </FormProvider>
  );
}

export default PersonForm;
