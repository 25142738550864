import React, { useEffect, useState } from 'react';
import PreguntaReadDto from '../../dtos/PreguntaReadDto';
import { getAllQuestions } from '../../services/RiskProfileServices';
import RiskProfileQuestion from './RiskProfileQuestion';
import FeeValue from '../../img/home-tools/fee-value-icon.svg';
import FundBuyer from '../../img/home-tools/fund-buyer-icon.svg';
import FundRecommender from '../../img/home-tools/fund-recommender-icon.svg';
import PerformanceCalc from '../../img/home-tools/performance-calc-icon.svg';
import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';
import { useForm } from 'react-hook-form';
import PageInfoDto from '../../dtos/PageInfoDto';
import { getPageInfo } from '../../services/GenericServices';

function MainRiskProfile() {
	const navigate = useNavigate();
	const [questions, setQuestions] = useState<PreguntaReadDto[]>([]);
	// const [isCompleted, setIsCompleted] = useState([]);

	const {
		register,
		getValues,
		formState: { isValid },
	} = useForm({ mode: 'onChange', reValidateMode: 'onChange' });

	const fetchData = async () => {
		const data = await getAllQuestions();
		setQuestions(data);
	};

	const [pageInfo, setPageInfo] = useState<PageInfoDto>(new PageInfoDto());

	const fetchInfo = async () => {
		const fetchedInfo = await getPageInfo('Perfil de Riesgo del Inversionista');
		setPageInfo(fetchedInfo);
	};

	useEffect(() => {
		fetchInfo();
	}, []);

	useEffect(() => {
		fetchData();
	}, []);

	console.log(getValues());

	useEffect(() => {
		console.log(isValid);
		// isValid && changeStatus(isValid);
	}, [isValid]);

	function renderQuestions() {
		return questions.map((q, i) => (
			<RiskProfileQuestion question={q} answers={q.answers} qId={i} register={register} />
		));
	}

	function getRadioValue(): number {
		const total = Object.values(getValues()).reduce(
			(a: string, b: string) => Number(a) + Number(b)
		);
		console.log(total);
		return total;
	}

	// const changeStatus = (status: boolean) => {
	// 	setIsCompleted((state) => [...state, status]);
	// 	console.log(isCompleted);
	// };

	return (
		<React.Fragment>
			<section
				className="comparador"
				style={{
					backgroundImage: `linear-gradient(
                              180deg,
                              #00235c00 0%,
                              #00235ca3 20%,
                              #00235c 100%
                            ),
                            url(${AppVariables.IMG_URL + 'bg-img-podcast1.jpg'})`,
				}}
			>
				<div className="container mx-auto container--fix">
					<div className="comparador__intro">
						<h1>{pageInfo.title}</h1>
						<p>{pageInfo.description}</p>
					</div>
					<div className="comparador__box">
						{renderQuestions()}

						<div className="inline-block w-full ml-auto text-center">
							<button
								className={
									isValid ? 'w-full global-button' : 'w-full global-button !bg-gray-500'
								}
								type="button"
								disabled={!isValid}
								onClick={() => {
									const sum = getRadioValue();

									navigate({
										pathname: `${AppVariables.REACT_ROUTER_URL}/perfil-de-riesgo-resultado`,
										search: `?Value=${sum}`,
									});
								}}
							>
								Conocer mi perfil de riesgo
							</button>
						</div>
					</div>
				</div>
			</section>
			{/*tools*/}
			<div className="tools tools--fix">
				<h1 className="generic-title">Herramientas</h1>
				<div className="tools-container">
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/calculadora`);
						}}
					>
						<img
							className="generic-tool__logo"
							src={PerformanceCalc}
							alt="performance-calc-icon"
						/>
						<h4 className="generic-tool__title">Calculadora de rendimiento</h4>
					</div>
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/recomendador`);
						}}
					>
						<img
							className="generic-tool__logo"
							src={FundRecommender}
							alt="fund-recommender-icon"
						/>
						<h4 className="generic-tool__title">Recomendador de fondos</h4>
					</div>
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/valor-cuota`);
						}}
					>
						<img className="generic-tool__logo" src={FeeValue} alt="fee-value-icon" />
						<h4 className="generic-tool__title">Valor de las cuotas</h4>
					</div>
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/comparador-de-fondos`);
						}}
					>
						<img className="generic-tool__logo" src={FundBuyer} alt="fund-buyer-icon" />
						<h4 className="generic-tool__title">
							Comparador <br />
							de fondos
						</h4>
					</div>
				</div>
			</div>
			{/*tools end*/}
		</React.Fragment>
	);
}

export default MainRiskProfile;
