import { useState } from "react";
import "./preguntas.css";

export type QuestionProps = {
  order: number;
  question: string;
  content: string;
};

export default function Question({ question, content }: QuestionProps) {
  const [toggleSection, setToggleSection] = useState(false);

  const handleClick = () => {
    setToggleSection(!toggleSection);
  };

  return (
    <div className="preguntas">
      <div className="flex flex-col p-4 preguntas__item">
        <div
          onClick={handleClick}
          className="flex items-center justify-between p-4 preguntas__question cursor-pointer"
        >
          <p className={toggleSection ? "opened" : ""}>{question}</p>
          {toggleSection ? (
            <span className="text-2xl font-extrabold cursor-pointer">–</span>
          ) : (
            <span className="text-2xl font-extrabold cursor-pointer">+</span>
          )}
        </div>
        {toggleSection ? (
          <div
            className="p-4 preguntas__respuesta"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
