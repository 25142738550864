class AfiContactoDto {
	public id: number;
	public facebookUrl: string;
	public twitterUrl: string;
	public instagramUrl: string;
	public linkedInUrl: string;
	public escribenosMail: string;
	public reclamosMail: string;
	public telefono: string;
	public direccion: string;
	public direccionUrl: string;
	public denunciasPlaft:string;
}

export default AfiContactoDto;