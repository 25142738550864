const defaultBackgroundColor = "rgba(1, 33, 105, 0.08)";

const defaultStyles = {
  fontFamily: "Roboto, sans-serif",
  fontSizeI: "16px !important",
  backgroundColor: defaultBackgroundColor,
  border: {
    focused: "2px solid #000",
    unfocused: "2px solid transparent"
  },
  transition: {
    opacity: "opacity 300ms"
  }
};

export const multiSelectStyle = {
  option: (provided: any, state: { isSelected: any; isFocused: any }) => {
    const color = state.isFocused ? "#fff" : "#012169";
    const background = state.isFocused ? "#012169" : "";
    return {
      ...provided,
      fontWeight: "bold",
      display: "block",
      minHeight: "1.2em",
      padding: "12px 23px",
      fontSize: "16px",
      fontFamily: defaultStyles.fontFamily,
      background,
      margin: "0",
      color,
      width: "100%",
      cursor: "pointer",
    };
  },
  control: (provided: any, state: { isFocused: any }) => {
    const border = state.isFocused ? defaultStyles.border.focused : defaultStyles.border.unfocused;
    return {
      width: "100%",
      display: "flex",
      minHeight: "44px",
      backgroundColor: defaultStyles.backgroundColor,
      borderRadius: "32px",
      borderTopLeftRadius: "0",
      padding: "5px 8px",
      fontSize: "16px",
      fontFamily: defaultStyles.fontFamily,
      border,
    };
  },
  placeholder: () => ({
    gridArea: "1/1/2/3",
    marginLeft: "2px",
    marginRight: "2px",
    fontSize: defaultStyles.fontSizeI,
    fontFamily: defaultStyles.fontFamily,
    fontWeight: "bold",
    color: "#012169",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    fontWeight: "bold",
    color: "#012169",
  }),
  singleValue: (provided: any, state: { isDisabled: any }) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = defaultStyles.transition.opacity;

    return { ...provided, opacity, transition, backgroundColor: "red" };
  },
  menuList: (provided: any) => ({
    ...provided,
    backgroundColor: defaultStyles.backgroundColor,
    borderRadius: "0",
  }),
  menu: (provided: any) => ({
    ...provided,
    margin: "0",
    borderRadius: "6px",
    height: "300px",
    overflow: "auto",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    borderColor: "transparent",
    color: "#012169",
    backgroundColor: "transparent",
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    maxHeight: "30px",
    flex: "1",
    color: "#012169",
  }),
  multiValue: (provided: any) => ({
    ...provided,
    color: "#012169",
    backgroundColor: "transparent",
    fontFamily: defaultStyles.fontFamily,
    fontWeight: "bold",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    cursor: "pointer",
  }),
};

export const selectStyle = {
  option: (provided: any, state: { isSelected: any; isFocused: any }) => {
    const color = state.isFocused
      ? "#fff"
      : "#012169" || (state.isSelected && "#012169");
    const background = state.isFocused
      ? "#012169"
      : defaultStyles.backgroundColor ||
      (state.isSelected && defaultStyles.backgroundColor);
    return {
      ...provided,
      fontWeight: "bold",
      display: "block",
      minHeight: "1.2em",
      padding: "12px 23px",
      fontSize: "16px",
      fontFamily: defaultStyles.fontFamily,
      background,
      margin: "0",
      color,
      width: "100%",
      cursor: "pointer",
    };
  },
  control: (provided: any, state: { isFocused: any }) => {
    const border = state.isFocused ? defaultStyles.border.focused : defaultStyles.border.unfocused;
    return {
      width: "100%",
      display: "flex",
      minHeight: "44px",
      backgroundColor: defaultStyles.backgroundColor,
      borderRadius: "32px",
      borderTopLeftRadius: "0",
      padding: "5px 8px",
      fontSize: "16px",
      fontFamily: defaultStyles.fontFamily,
      border,
    };
  },
  placeholder: () => ({
    gridArea: "1/1/2/3",
    marginLeft: "2px",
    marginRight: "2px",
    fontSize: defaultStyles.fontSizeI,
    fontFamily: defaultStyles.fontFamily,
    fontWeight: "bold",
    color: "#012169",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    fontWeight: "bold",
    color: "#012169",
  }),
  singleValue: (provided: any, state: { isDisabled: any }) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = defaultStyles.transition.opacity;

    return {
      ...provided,
      opacity,
      transition,
      fontFamily: defaultStyles.fontFamily,
      fontWeight: "bold",
      color: "#012169",
    };
  },
  menuList: (provided: any) => ({
    ...provided,
    backgroundColor: defaultStyles.backgroundColor,
    borderRadius: "0",
  }),
  menu: (provided: any) => ({
    ...provided,
    margin: "0",
    borderRadius: "6px",
    height: "auto",
    overflow: "auto",
  }),
};
export const selectStyleGeneral = {
  option: (provided: any, state: { isSelected: any; isFocused: any }) => {
    const color = state.isFocused
      ? "#fff"
      : "#012169" || (state.isSelected && "#012169");
    const background = state.isFocused
      ? "#012169"
      : defaultStyles.backgroundColor ||
      (state.isSelected && defaultStyles.backgroundColor);
    return {
      ...provided,
      fontWeight: "bold",
      display: "block",
      minHeight: "1.2em",
      padding: "12px 23px",
      fontSize: "16px",
      fontFamily: defaultStyles.fontFamily,
      background,
      margin: "0",
      color,
      width: "100%",
      cursor: "pointer",
      minWidth: "80px",
    };
  },
  control: (provided: any, state: { isFocused: any }) => {
    return {
      width: "100%",
      display: "flex",
      minHeight: "44px",
      backgroundColor: defaultStyles.backgroundColor,
      borderRadius: "32px",
      padding: "5px 8px",
      fontSize: "16px",
      fontFamily: defaultStyles.fontFamily,
      cursor: "pointer",
      minWidth: "80px",
    };
  },
  placeholder: () => ({
    gridArea: "1/1/2/3",
    marginLeft: "2px",
    marginRight: "2px",
    fontSize: defaultStyles.fontSizeI,
    fontFamily: defaultStyles.fontFamily,
    fontWeight: "bold",
    color: "#012169",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    fontWeight: "bold",
    color: "#012169",
  }),
  singleValue: (provided: any, state: { isDisabled: any }) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = defaultStyles.transition.opacity;

    return {
      ...provided,
      opacity,
      transition,
      fontFamily: defaultStyles.fontFamily,
      fontWeight: "bold",
      color: "#012169",
      width: "100%",
    };
  },
  menuList: (provided: any) => ({
    ...provided,
    backgroundColor: defaultStyles.backgroundColor,
    borderRadius: "0",
    width: "100%",
  }),
  menu: (provided: any) => ({
    ...provided,
    margin: "0",
    borderRadius: "6px",
    height: "auto",
    overflow: "auto",
    width: "100%",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  selectContainer: (provided: any) => ({
    ...provided,
    width: "100%",
    minWidth: "80px",
  }),
  input: (provided: any) => ({
    ...provided,
    width: "100%",
    minWidth: "80px",
  }),
};

export const yearFilterStyleDesktop = {
  option: (provided: any, state: { isSelected: any; isFocused: any }) => {
    const color = state.isFocused ? '#fff' : '#012169' || (state.isSelected && '#012169');
    const background = state.isFocused
      ? '#012169'
      : defaultBackgroundColor || (state.isSelected && defaultBackgroundColor);
    return {
      ...provided,
      fontWeight: 'bold',
      display: 'block',
      minHeight: '1.2em',
      padding: '12px 23px',
      fontSize: '16px',
      fontFamily: defaultStyles.fontFamily,
      background,
      margin: '0',
      color,
      width: '100%',
      cursor: 'pointer',
    };
  },
  control: (provided: any, state: { isFocused: any }) => {
    const border = state.isFocused ? defaultStyles.border.focused : defaultStyles.border.unfocused;
    return {
      width: '100%',
      display: 'flex',
      minHeight: '66px',
      minWidth: '114px',
      backgroundColor: defaultBackgroundColor,
      borderRadius: '32px',
      padding: '5px 12px',
      fontSize: '16px',
      fontFamily: defaultStyles.fontFamily,
      border,
    };
  },
  placeholder: () => ({
    gridArea: '1/1/2/3',
    marginLeft: '2px',
    marginRight: '2px',
    fontSize: defaultStyles.fontSizeI,
    fontFamily: defaultStyles.fontFamily,
    fontWeight: 'bold',
    color: '#012169',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    fontWeight: 'bold',
    color: '#012169',
  }),
  singleValue: (provided: any, state: { isDisabled: any }) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = defaultStyles.transition.opacity;

    return {
      ...provided,
      opacity,
      transition,
      fontFamily: defaultStyles.fontFamily,
      fontWeight: 'bold',
      color: '#012169',
    };
  },
  menuList: (provided: any) => ({
    ...provided,
    backgroundColor: defaultBackgroundColor,
    borderRadius: '0',
  }),
  menu: (provided: any) => ({
    ...provided,
    margin: '0',
    borderRadius: '6px',
    height: 'auto',
    overflow: 'auto',
  }),
};

export const yearFilterStyleMobile = {
  option: (provided: any, state: { isSelected: any; isFocused: any }) => {
    const color = state.isFocused ? '#fff' : '#012169' || (state.isSelected && '#012169');
    const background = state.isFocused
      ? '#012169'
      : defaultBackgroundColor || (state.isSelected && defaultBackgroundColor);
    return {
      ...provided,
      fontWeight: 'bold',
      display: 'block',
      minHeight: '1.2em',
      padding: '12px 23px',
      fontSize: '16px',
      fontFamily: defaultStyles.fontFamily,
      background,
      margin: '0',
      color,
      width: '100%',
      cursor: 'pointer',
    };
  },
  control: (provided: any, state: { isFocused: any }) => {
    const border = state.isFocused ? defaultStyles.border.focused : defaultStyles.border.unfocused;
    return {
      width: '100%',
      display: 'flex',
      minHeight: '66px',
      backgroundColor: defaultBackgroundColor,
      borderRadius: '32px',
      padding: '5px 12px',
      fontSize: '16px',
      fontFamily: defaultStyles.fontFamily,
      border,
    };
  },
  placeholder: () => ({
    gridArea: '1/1/2/3',
    marginLeft: '2px',
    marginRight: '2px',
    fontSize: defaultStyles.fontSizeI,
    fontFamily: defaultStyles.fontFamily,
    fontWeight: 'bold',
    color: '#012169',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    fontWeight: 'bold',
    color: '#012169',
  }),
  singleValue: (provided: any, state: { isDisabled: any }) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = defaultStyles.transition.opacity;

    return {
      ...provided,
      opacity,
      transition,
      fontFamily: defaultStyles.fontFamily,
      fontWeight: 'bold',
      color: '#012169',
    };
  },
  menuList: (provided: any) => ({
    ...provided,
    backgroundColor: defaultBackgroundColor,
    borderRadius: '0',
  }),
  menu: (provided: any) => ({
    ...provided,
    margin: '0',
    borderRadius: '6px',
    height: 'auto',
    overflow: 'auto',
  }),
  // container: (provided: any) => ({
  // 	...provided,
  // 	padding: '20px 18px 0',
  // }),
};


export const fundSelectStyle = {
  option: (provided: any, state: { isSelected: any; isFocused: any }) => {
    const color = state.isFocused ? '#fff' : '#012169' || (state.isSelected && '#012169');
    const background = state.isFocused
      ? '#012169'
      : defaultBackgroundColor || (state.isSelected && defaultBackgroundColor);
    return {
      ...provided,
      fontWeight: 'bold',
      display: 'block',
      minHeight: '1.2em',
      padding: '12px 23px',
      fontSize: '16px',
      fontFamily: defaultStyles.fontFamily,
      background,
      margin: '0',
      color,
      width: '100%',
      cursor: 'pointer',
    };
  },
  control: (provided: any, state: { isFocused: any }) => {
    const border = state.isFocused ? '2px solid #000' : defaultStyles.border.unfocused;
    return {
      width: '100%',
      display: 'flex',
      minHeight: '44px',
      backgroundColor: defaultBackgroundColor,
      borderRadius: '32px',
      padding: '5px 8px',
      fontSize: '16px',
      fontFamily: defaultStyles.fontFamily,
      border,
    };
  },
  placeholder: () => ({
    gridArea: '1/1/2/3',
    marginLeft: '2px',
    marginRight: '2px',
    fontSize: defaultStyles.fontSizeI,
    fontFamily: defaultStyles.fontFamily,
    fontWeight: 'bold',
    color: '#012169',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    fontWeight: 'bold',
    color: '#012169',
  }),
  singleValue: (provided: any, state: { isDisabled: any }) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = defaultStyles.transition.opacity;

    return {
      ...provided,
      opacity,
      transition,
      fontFamily: defaultStyles.fontFamily,
      fontWeight: 'bold',
      color: '#012169',
    };
  },
  menuList: (provided: any) => ({
    ...provided,
    backgroundColor: defaultBackgroundColor,
    borderRadius: '0',
  }),
  menu: (provided: any) => ({
    ...provided,
    margin: '0',
    borderRadius: '6px',
    height: 'auto',
    overflow: 'auto',
  }),
};