import DocumentCategory from "./DocumentCategory";

interface IProps {
  categories: string[];
  setCurrentCategory: React.Dispatch<React.SetStateAction<string>>;
}

function DocumentFilterByCategory(props: IProps) {
  function renderCategoriesOptions() {
    return props.categories.map((category, index) => (
      <DocumentCategory
        key={category}
        id={(index + 1).toString()}
        category={category}
        setCurrentCategory={props.setCurrentCategory}
      />
    ));
  }

  return (
    <div id="document-category-filter" className="main-documents-options">
      {renderCategoriesOptions()}
    </div>
  );
}

export default DocumentFilterByCategory;
