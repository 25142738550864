import axios from 'axios';
import AppVariables from '../Variables.json';
import CourseReadDto from '../dtos/CourseReadDto';
import { getRand } from '../util/util';
import { AxiosError, AxiosResponse } from '../dtos/types/Axios';
import { SharePointConsts } from '../util/constants';

async function fetchData(url: string): Promise<any> {
	try {
		const response = await axios.get(url, {
			headers: {
				'X-RequestDigest': (document.getElementById('__REQUESTDIGEST') as HTMLInputElement)
					.value,
				Accept: SharePointConsts.acceptVerbose,
			},
		});
		return response.data;
	} catch (error: any) {
		const axiosError = error as AxiosError;
		console.log(axiosError);
		return null;
	}
}

function createCourseReadDto(item: any): CourseReadDto {
	const course = new CourseReadDto();
	assignBasicCourseDetails(course, item);
	assignAttachmentUrls(course, item);

	return course;
}

function assignBasicCourseDetails(course: CourseReadDto, item: any) {
	course.id = item.ID;
	course.title = item.Title;
	course.fecha = item.Fechadepublicacion;
	course.description = item.Descripcion;
	course.categoty = item.Categoria;
}

function assignAttachmentUrls(course: CourseReadDto, item: any) {
	if (!item.AttachmentFiles || !item.AttachmentFiles.results.length) {
		course.videoUrl = '';
		course.imgUrl = '';
		return;
	}

	const attachments = item.AttachmentFiles.results;
	const firstFileExtension = getFileExtension(attachments[0].FileName);

	if (isMp4(firstFileExtension)) {
		course.videoUrl = attachments.length > 1 ? attachments[0].ServerRelativeUrl : '';
		course.imgUrl = attachments.length > 1 ? attachments[1].ServerRelativeUrl : '';
	} else {
		course.videoUrl = attachments.length > 1 ? attachments[1].ServerRelativeUrl : '';
		course.imgUrl = attachments.length > 1 ? attachments[0].ServerRelativeUrl : '';
	}
}

function getFileExtension(filename: string): string {
	const splitFilename = filename.split('.');
	return splitFilename[splitFilename.length - 1].toLowerCase();
}

function isMp4(extension: string): boolean {
	return extension === 'mp4';
}

export async function getCourseDetailById(id: number): Promise<CourseReadDto> {
	const url = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Cursos y Talleres')/items?$filter= ID eq ${id}&$expand=AttachmentFiles`;
	const data = await fetchData(url);
	return data && data.d.results.length
		? createCourseReadDto(data.d.results[0])
		: new CourseReadDto();
}

export async function getCourseDetailByTitle(title: string): Promise<CourseReadDto> {
	const url = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Cursos y Talleres')/items?$filter=Title eq '${title}'&$expand=AttachmentFiles`;
	const data = await fetchData(url);
	return data && data.d.results.length
		? createCourseReadDto(data.d.results[0])
		: new CourseReadDto();
}

export async function getTopCourses(num: number): Promise<CourseReadDto[]> {
	const url = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Cursos y talleres')/items?$expand=AttachmentFiles&$filter=Estado eq 'Activo'&$orderby=Orden desc,Fechadepublicacion desc&$top=${num}`;
	const data = await fetchData(url);
	return data ? data.d.results.map(createCourseReadDto) : [];
}

export async function getAllCourses(): Promise<CourseReadDto[]> {
	const url = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Cursos y talleres')/items?$expand=AttachmentFiles&$filter=Estado eq 'Activo'&$orderby=Orden desc,Fechadepublicacion desc`;
	const data = await fetchData(url);
	return data ? data.d.results.map(createCourseReadDto) : [];
}

export async function getCoursesByCategory(category: string): Promise<CourseReadDto[]> {
	const url = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Cursos y Talleres')/items?$filter=Categoria eq '${category}' and Estado eq 'Activo'&$expand=AttachmentFiles&$orderby=Orden desc,Fechadepublicacion desc`;
	const data = await fetchData(url);
	return data ? data.d.results.map(createCourseReadDto) : [];
}

export async function getAllCoursesCategories(): Promise<string[]> {
	const url = `${AppVariables.SITE_COLLECTION_URL}_api/web/lists/getbytitle('Cursos y talleres')/fields?$filter=EntityPropertyName eq 'Categoria'`;
	const data = await fetchData(url);
	return data ? data.d.results.map((item: any) => item.Choices.results) : [];
}

export async function getRandomCourses(courseid: number): Promise<CourseReadDto[]> {
	const allCourses = await getAllCourses();
	const validCourses = allCourses.map((c) => c.id !== courseid && c);
	const shuffled = [...validCourses].sort(() => 0.5 - getRand());
	return shuffled.slice(0, 3);
}
