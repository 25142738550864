import React, { useEffect, useState } from 'react';
import FeeValue from '../../img/home-tools/fee-value-icon.svg';
import PerformanceCalc from '../../img/home-tools/performance-calc-icon.svg';
import FundBuyer from '../../img/home-tools/fund-buyer-icon.svg';
import FundRecommender from '../../img/home-tools/fund-recommender-icon.svg';
import Riskprofile from '../../img/home-tools/risk-profile-icon.svg';
import RecomendadorFilter from './RecomendadorFilter';
import {
	getAllFundsLevels,
	getAllFundsTerms,
	getAllFundsTypes,
	getFundsByInvestmentTerm,
	getFundsByRiskLEvel,
	getFundsByType,
} from '../../services/RecomendadorServices';
import FundReadDto from '../../dtos/FundReadDto';
import RecomendadorCard from './RecomendadorCard';
import { useNavigate } from 'react-router-dom';
import AppVariables from '../../Variables.json';
import { getPageInfo } from '../../services/GenericServices';
import PageInfoDto from '../../dtos/PageInfoDto';

function MainRecomendador() {
	const navigate = useNavigate();
	const [category, setCategory] = useState('Tipodeactivo');

	const [tiposDeActivo, setTiposDeActivo] = useState<string[]>([]);
	const [niveles, setNiveles] = useState<string[]>([]);
	const [plazos, setPlazos] = useState<string[]>([]);

	const [optionChoosed, setOptionChoosed] = useState('Renta Fija');

	const [fondos, setFondos] = useState<FundReadDto[]>([]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOptionChoosed(event.target.value);
	};

	const [pageInfo, setPageInfo] = useState<PageInfoDto>(new PageInfoDto());

	const fetchInfo = async () => {
		const fetchedInfo = await getPageInfo('Recomendador de Fondos');
		setPageInfo(fetchedInfo);
	};

	useEffect(() => {
		fetchInfo();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			const TiposDeActivo: string[] = await getAllFundsTypes();
			const Niveles: string[] = await getAllFundsLevels();
			const Plazos: string[] = await getAllFundsTerms();

			setTiposDeActivo(TiposDeActivo);
			setNiveles(Niveles);
			setPlazos(Plazos);
		};

		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			let fetchedFondos: FundReadDto[] = [];

			switch (category) {
				case 'Tipodeactivo':
					fetchedFondos = await getFundsByType(optionChoosed);
					break;
				case 'Nivelderiesgo':
					fetchedFondos = await getFundsByRiskLEvel(optionChoosed);
					break;

				case 'Plazodeinversion':
					fetchedFondos = await getFundsByInvestmentTerm(optionChoosed);
					break;

				default:
					break;
			}

			setFondos(fetchedFondos);
		};

		fetchData();
	}, [optionChoosed]);

	const renderFunds = () => {
		return fondos.map((f) => <RecomendadorCard fondo={f} />);
	};

	return (
		<React.Fragment>
			<section
				className="comparador"
				style={{
					backgroundImage: `linear-gradient(
                              180deg,
                              #00235c00 0%,
                              #00235ca3 20%,
                              #00235c 100%
                            ),
                            url(${AppVariables.IMG_URL + 'bg-img-podcast1.jpg'})`,
				}}
			>
				<div className="container mx-auto container--fix">
					<div className="comparador__intro">
						<h1>{pageInfo.title}</h1>
						<p>{pageInfo.description}</p>
					</div>

					<div className="comparador__box__recomendador">
						<div className="p-6 mt-10 bg-white">
							<RecomendadorFilter
								currentCategory={category}
								setCurrentCategory={setCategory}
								tiposDeActivo={tiposDeActivo}
								Niveles={niveles}
								Plazos={plazos}
								handleChange={handleChange}
							/>
						</div>
					</div>
					<div className="comparador__table">
						<div className="justify-between md:space-x-4 md:flex comparador__table-top">
							<div className="flex-1 w-full">
								<h1>Resultado</h1>
							</div>
						</div>
					</div>
					<div className="grid gap-4 md:grid-cols-2">{renderFunds()}</div>
				</div>
			</section>
			{/*tools*/}
			<div className="tools tools--fix">
				<h1 className="generic-title">Herramientas</h1>
				<div className="tools-container">
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/perfil-de-riesgo`);
						}}
					>
						<img
							className="generic-tool__logo"
							src={Riskprofile}
							alt="fund-recommender-icon"
						/>
						<h4 className="generic-tool__title">Perfil de Riesgo del Inversionista</h4>
					</div>
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/valor-cuota`);
						}}
					>
						<img className="generic-tool__logo" src={FeeValue} alt="fee-value-icon" />
						<h4 className="generic-tool__title">Valor de las cuotas</h4>
					</div>
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/calculadora`);
						}}
					>
						<img
							className="generic-tool__logo"
							src={PerformanceCalc}
							alt="performance-calc-icon"
						/>
						<h4 className="generic-tool__title">Calculadora de rendimiento</h4>
					</div>
					<div
						className="generic-tool"
						onClick={() => {
							navigate(`${AppVariables.REACT_ROUTER_URL}/comparador-de-fondos`);
						}}
					>
						<img className="generic-tool__logo" src={FundBuyer} alt="fund-buyer-icon" />
						<h4 className="generic-tool__title">
							Comparador <br />
							de fondos
						</h4>
					</div>
				</div>
			</div>
			{/*tools end*/}
		</React.Fragment>
	);
}

export default MainRecomendador;
