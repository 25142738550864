import NewslettersDto from "../../dtos/NewslettersDto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import SocialMediaPopup from "../social-media/SocialMediaPopup";

interface IProps {
	newsletter: NewslettersDto;
}

function NewslettersCard({ newsletter }: IProps) {


	return (
		<div className="main-blog-card w-full flex flex-col shadow-[2px_2px_8px_#00235C29]">
			<div className="bg-[#012169] px-4 pt-4">
				<img
					src={newsletter.thumbnail}
					alt={newsletter.thumbnailName}
					className="object-cover object-center w-full blog-card-img"
				/>
			</div>
			<div className="flex-col p-4 bg-[#012169] blog-card-content flex h-full">
				<div className="flex justify-end pb-4 blog-card-content-header">
					<SocialMediaPopup position={"left center"} url={newsletter.downloadLink} />
				</div>
				<h5 className="pb-4 blog-card-content__title blog-card-content__title--blog blog-card-content__title--normal">
					{newsletter.shortDescription}
				</h5>
				<div className="flex justify-between pb-4 mt-auto blog-card-content-footer">
					<h5 className="blog-card-footer__date text-xs text-[#FFFFFF]/70 font-bold">
						{newsletter.publishDate}
					</h5>
					<a href={newsletter.downloadLink} download>
						<h6 className="blog-card-footer__link flex items-baseline hover:gap-1 hover:text-[#ED8B00] text-[#00C1D5] text-sm font-bold gap-2 transition-[gap] duration-200 cursor-pointer">
							<i>
								<FontAwesomeIcon icon={faChevronRight} />
							</i>
							Descargar
						</h6>
					</a>
				</div>
			</div>
		</div>
	);
}

export default NewslettersCard;
